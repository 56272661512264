<template>
  <div class="view" v-loading="loading">
    <div class="view-contion">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
        size="mini"
      >
        <el-form-item label="餐具费/人:" prop="tablewareFee">
          <el-input
            class="input-width"
            placeholder="请输入内容"
            v-model.trim="ruleForm.tablewareFee"
          >
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item label="餐位费/人:" prop="restaurantSeatFee">
          <el-input
            class="input-width"
            placeholder="请输入内容"
            v-model.trim="ruleForm.restaurantSeatFee"
          >
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item label="包装费/个:" prop="packingFee">
          <el-input
            class="input-width"
            placeholder="请输入内容"
            v-model.trim="ruleForm.packingFee"
          >
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item label="会员价开启:" prop="openCommonMermber">
          <el-select v-model="ruleForm.openCommonMermber" placeholder="请选择">
            <el-option
              v-for="(v, k) in openCommonMermberobj"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
          <div class="input-tishi">普通会员是否开启会员价</div>
        </el-form-item>
        <!-- <el-form-item label="优惠活动同享:" prop="openSpecialOffer">
          <el-select v-model="ruleForm.openSpecialOffer" placeholder="请选择">
            <el-option
              v-for="(v, k) in openSpecialOfferobj"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
          <div class="input-tishi">会员价格是否与优惠活动同享</div>
        </el-form-item> -->
        <el-form-item label="餐厅付费模式:" prop="paymentMode">
          <el-select v-model="ruleForm.paymentMode" placeholder="请选择">
            <el-option
              v-for="(v, k) in paymentModeobj"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="抹零金额:" prop="openReduce">
          <el-select v-model="ruleForm.openReduce" placeholder="请选择">
            <el-option
              v-for="(v, k) in openReduceobj"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="自动清台:" prop="autoClean">
          <el-select v-model="ruleForm.autoClean" placeholder="请选择">
            <el-option
              v-for="(v, k) in autoCleanobj"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
          <div class="input-tishi">确认结账后是否自动清台</div>
        </el-form-item>
        <el-form-item label="点餐台-菜品图片:" prop="pictureStatus">
          <el-switch
            v-model="ruleForm.pictureStatus"
            active-text="有图"
            inactive-text="无图"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-view-bottom" v-if="But.Set.power()">
      <el-button type="primary" size="mini" @click="submit">保存</el-button>
    </div>
  </div>
</template>

<script>
import number from "@/common/num.js";
import { mapMutations } from "vuex";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      let rez = /^[0-9]{1,3}(\.[0-9]{1,2})?$/;
      if (!rez.test(value)) {
        return callback(new Error("支持0—999.99"));
      }
      callback();
    };
    return {
      But: window.UserPower.RestRoomSet.Restaurant.basicSet,
      ruleForm: {
        tablewareFee: "0",
        restaurantSeatFee: "0",
        packingFee: "0",
        openCommonMermber: "",
        openSpecialOffer: "",
        paymentMode: "1",
        openReduce: "0",
        autoClean: "",
        opera: 1,
        pictureStatus: 0,
      },
      loading: false,
      openCommonMermberobj: {
        0: "关闭",
        1: "开启",
      },
      openSpecialOfferobj: {
        0: "否",
        1: "是",
      },
      paymentModeobj: {
        1: "先付费",
        2: "后付费",
        // 3: "后付费确认",
      },
      autoCleanobj: {
        0: "否",
        1: "是",
      },
      openReduceobj: {
        0: "否",
        1: "是",
      },
      rules: {
        tablewareFee: [{ validator: validatePass, trigger: "blur" }],
        restaurantSeatFee: [{ validator: validatePass, trigger: "blur" }],
        packingFee: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  methods: {
    ...mapMutations(["setShowImg"]),
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = {
            ...this.ruleForm,
          };
          data.tablewareFee = number(data.tablewareFee);
          data.restaurantSeatFee = number(data.restaurantSeatFee);
          data.packingFee = number(data.packingFee);
          this.$http
            .post("/order-admin/w/restaurant/save/set", { ...data })
            .then((res) => {
              this.loading = false;
              this.$message({
                message: "设置成功",
                type: "success",
              });
              localStorage.setItem("paymentMode", this.ruleForm.paymentMode);
              localStorage.setItem(
                "openCommonMermber",
                this.ruleForm.openCommonMermber
              );
              localStorage.setItem("openReduce", this.ruleForm.openReduce);
              localStorage.setItem("pictureStatus", this.ruleForm.pictureStatus);
              this.setShowImg(!!this.ruleForm.pictureStatus);
            })
            .catch((error) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    load() {
      let supportWebp = localStorage.getItem("supportWebp");
      this.loading = true;
      this.$http
        .post(
          `/order-admin/w/restaurant/detail?isSupportWebp=${supportWebp}`,
          {}
        )
        .then((res) => {
          this.loading = false;
          this.ruleForm.tablewareFee = res.data.tablewareFee / 100;
          this.ruleForm.restaurantSeatFee = res.data.restaurantSeatFee / 100;
          this.ruleForm.packingFee = res.data.packingFee / 100;
          this.ruleForm.openCommonMermber =
            res.data.openCommonMermber.toString();
          this.ruleForm.openSpecialOffer = res.data.openSpecialOffer.toString();
          this.ruleForm.paymentMode = res.data.paymentMode;
          this.ruleForm.openReduce = res.data.openReduce.toString();
          this.ruleForm.autoClean = res.data.autoClean.toString();
          this.ruleForm.pictureStatus = res.data.pictureStatus;
          localStorage.setItem("openReduce", res.data.openReduce);
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.input-width {
  width: 140px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.hr_title {
  margin: 0 0 20px 10px;
}
.view {
  position: relative;
}
.view-contion {
  height: calc(100% - 60px);
  overflow: auto;
}
.flex-view-bottom {
  position: fixed;
  bottom: 0;
  background: white;
}
</style>
