<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="formzinernil"
      class="demo-form-inline"
      size="mini"
      ref="formzinernil"
      :rules="rules"
    >
      <el-form-item label="菜品名称:" prop="itemName">{{val.dishersName}}</el-form-item>
      <el-form-item label="退菜数量:" prop="refuseCount">
        <el-select v-model="formzinernil.refuseCount" placeholder="请选择">
          <el-option v-for="(i,index) in options" :key="index" :label="i" :value="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="退菜原因:" prop="refuseReason">
        <el-input type="textarea" v-model="formzinernil.refuseReason" placeholder="请输入退菜原因"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitedzin" size="mini">确认退菜</el-button>
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
export default {
  created() {},
  props: { val: Object, orderNo: String },
  data() {
    return {
      formzinernil: {
        refuseCount: "",
        refuseReason: ""
      },
      rules: {
        refuseCount: [{ required: true, message: "必填", trigger: "blur" }],
        refuseReason: [{ min: 1, max: 30, message: "30字内", trigger: "blur" }]
      },
      options: [],
      loading: false
    };
  },
  methods: {
    loaddetail() {
      this.options = [];
      for (var i = 0; i < this.val.selectCount; i++) {
        console.log(i + 1);
        this.options.push(i + 1);
      }
    },
    close() {
      this.$emit("close");
      this.$refs["formzinernil"].resetFields();
    },
    submitedzin() {
      this.$refs["formzinernil"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/dishers/refuse", {
              merchantId: localStorage.getItem("merchantId"),
              orderNo: this.orderNo,
              dishersId: this.val.dishersId,
              orderDishesId: this.val.orderDishesId,
              ...this.formzinernil
            })
            .then(res => {
              this.loading = false;
              this.$emit("detail");
              this.$emit("close");
            })
            .catch(error => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>