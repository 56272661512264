<template>
  <el-container class="h100 bg">
    <el-header>
      <head-top ref="headtop"></head-top>
    </el-header>
    <el-main>
      <transition name="el-fade-in-linear">
        <router-view></router-view>
      </transition>
    </el-main>
    <el-footer>四川农信美食通 网联客（北京）科技有限公司提供技术支持</el-footer>
  </el-container>
</template>

<script>
import headTop from "@/components/headTopserve.vue";
export default {
  components: { headTop },
  created() {
    window.PowerButtons = JSON.parse(localStorage.getItem("PowerButtons"));
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.bg {
  background-color: #f9f9f9 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.el-header {
  height: 60px;
  background: #fff;
  color: #000;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f1f4;
}
.el-main {
  width: 100%;
  color: #333;
}
.el-footer {
  padding: 0;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #272727;
  font-size: 16px;
  color: #ffffff;
}
</style>