function getColor(c1, c2) {
    return {
        type: "linear",
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
            {
                offset: 0,
                color: c1, // 0% 处的颜色
            },
            {
                offset: 1,
                color: c2, // 100% 处的颜色
            },
        ],
        global: false, // 缺省为 false
    };
}
let ColorMap = {
    微信: getColor("#54FD39", "#39FDBC"),
    支付宝: getColor("#6475FF", "#39B5FD"),
    银联: getColor("#EE3E55", "#FF7F68"),
    现金: getColor("#FD6B3A", "#FDA23A"),
    蜀信e支付: getColor("#FFED3F", "#AEFD39"),
    挂帐: getColor("#7B7DFE", "#E182F0"),
    免单: getColor("#36E8B2", "#09B1E8"),
    账户余额: getColor("#eebe66", "#eebe66"),
    其它: getColor("#FF8F8B", "#FE6687"),
};


var option;
var data = [
    { value: 1048, name: "微信" },
    { value: 735, name: "支付宝" },
    { value: 580, name: "银联" },
    { value: 484, name: "现金" },
    { value: 300, name: "蜀信e支付" },
    { value: 300, name: "挂帐" },
    { value: 300, name: "免单" },
    { value: 300, name: "账户余额" },
    { value: 300, name: "其它" },
];

option = {
    title: {
        text: "流水统计",
        top: 20,
        left: 20,
    },
    tooltip: {
        trigger: "item",
    },
    legend: {
        type: "scroll",
        orient: "vertical",
        right: 50,
        top: 55,
        formatter: (name) => {
            let target = 0,
                item;
            for (var i = 0, l = data.length; i < l; i++) {
                if (data[i].name == name) {
                    item = +data[i].value;
                }
                target += +data[i].value;
            }
            return name + "    " + (((item / target) || 0) * 100).toFixed(2) + "%";
        },
    },
    series: [
        {
            type: "pie",
            radius: ["35%", "60%"],
            center: ["35%", "55%"],
            avoidLabelOverlap: false,
            selectedOffset: 20,
            itemStyle: {
                borderRadius: 3,
                borderColor: "#fff",
                borderWidth: 2,
                color(a) {
                    return ColorMap[a.name];
                },
            },
            label: {
                show: false,
                position: "center",
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: "20",
                    fontWeight: "bold",
                },
            },
            labelLine: {
                show: false,
            },
            data,
        },
    ],
};

//option && myChart.setOption(option);
export default function (chartDom) {
    var myChart = echarts.init(chartDom);
    return function (serveData) {
        option.series[0].data = data = serveData;
        myChart.setOption(option);
    }
}