<template>
  <div>
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="会员姓名" prop="mermberName">
          <el-input v-model.trim="formInline.mermberName" clearable></el-input>
        </el-form-item>
        <el-form-item label="会员手机号" prop="phone">
          <el-input v-model.trim="formInline.phone" clearable></el-input>
        </el-form-item>
        <el-form-item label="会员类型" prop="memberType">
          <el-select v-model="formInline.memberType" placeholder="请选择" clearable>
            <el-option
              v-for="item in typelist"
              :key="item.memberTypeId"
              :label="item.memberTypeName"
              :value="item.memberTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号" prop="memberCardNo">
          <el-input v-model.trim="formInline.memberCardNo" clearable></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
        >查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <el-button v-if="But.Add.power()" type="primary" @click="add" size="mini">添加会员</el-button>
        <!-- <el-button v-if="But.Import.power()" @click="exportz" size="mini">导入会员</el-button> -->
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
    >
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="mermberName" label="姓名"></el-table-column>
      <el-table-column prop="phone" label="手机号" width="120"></el-table-column>
      <el-table-column prop="accountBalance" label="账户余额" width="100" v-if="openMemberStore==1">
        <template slot-scope="scope">{{scope.row.accountBalance|money}}</template>
      </el-table-column>
      <el-table-column prop="memberTypeName" label="会员类型"></el-table-column>
      <el-table-column prop="realNameFlag" label="是否实名认证" width="120">
        <template slot-scope="scope">{{realNameFlag[scope.row.realNameFlag]}}</template>
      </el-table-column>
      <el-table-column prop="memberCardNo" label="卡号" width="250"></el-table-column>
      <el-table-column prop="createTm" label="创建时间" width="150"></el-table-column>
      <el-table-column prop="updateUser" label="操作人" width="150"></el-table-column>
      <el-table-column prop="updateTm" label="操作时间" width="160"></el-table-column>
      <el-table-column prop="sourceIp" label="操作" width="250">
        <template slot-scope="scope">
          <el-button v-if="But.Logout.power()" type="text" @click="distory(scope.row.memberId)">注销</el-button>
          <el-button v-if="But.Edit.power()" type="text" @click="edit(scope.row.memberId)">编辑</el-button>
          <el-button v-if="But.Editmoney.power()" type="text" @click="editmoney(scope.row)">修改金额</el-button>
          <el-button
            v-if="But.Recharge.power()"
            type="text"
            @click="allchange(scope.row.memberId)"
          >充值</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="450px">
      <view-addmember
        @close="closeorder"
        :title="title"
        :memberId="memberId"
        @search="search"
        v-if="dialogVisible"
        :typelist="typelist"
      ></view-addmember>
    </el-dialog>

    <el-dialog
      title="修改金额"
      :visible.sync="dialogVisiblezin"
      width="450px"
      :close-on-click-modal="false"
      v-loading="loading1"
    >
      <el-form
        :inline="true"
        :model="formzine"
        class="demo-form-inline"
        size="mini"
        :rules="rules"
        ref="formzine"
      >
        <el-form-item label="账户余额:" class="addPlace" prop="accountBalance">
          <el-input v-model.trim="formzine.accountBalance"></el-input>
        </el-form-item>
        <el-form-item label="姓名:" class="addPlace" prop="mermberName">
          <span>{{formzine.mermberName}}</span>
        </el-form-item>
        <br />
        <el-form-item label="手机号:" class="addPlace" prop="phone">
          <span>{{formzine.phone}}</span>
        </el-form-item>
        <br />
        <el-form-item label="卡号:" class="addPlace" prop="memberCardNo">
          <span>{{formzine.memberCardNo}}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit" size="mini">保存</el-button>
        <el-button @click="dialogVisiblezin = false" size="mini">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="导入" :visible.sync="Visibleupdatezin" width="400px" height="400px" top="5vh">
      <dialog-updata v-if="Visibleupdatezin" @close="tclose" @load="search" :type="type"></dialog-updata>
    </el-dialog>
  </div>
</template>

<script>
import viewAddmember from "./addmember";
import dialogUpdata from "./dialog.vue"; //导入
export default {
  created() {
    this.loadlist();
    this.searchzin();
  },
  props: { activeName: String },
  components: {
    viewAddmember,
    dialogUpdata,
  },
  data() {
    var numzin = (rule, value, callback) => {
      let rez = /^[0-9]{0,7}(\.[0-9]{1,2})?$/;
      if (!rez.test(value)) {
        return callback(new Error("支持0—9999999之间"));
      }
      callback();
    };
    return {
       openMemberStore: localStorage.getItem("openMemberStore"),
      But: window.UserPower.serve.servicemember.member,
      type: 2,
      formzine: {
        accountBalance: "",
      },
      rules: {
        accountBalance: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" },
        ],
      },
      title: "添加会员",
      dialogVisible: false,
      Visibleupdatezin: false,
      dialogVisiblezin: false,
      loading1: false,
      formInline: {
        mermberName: "",
        phone: "",
        memberCardNo: "",
        memberType: "",
        createTimeBegin: "",
        createTimeEnd: "",
        value1: null,
      },
      realNameFlag: {
        0: "未实名",
        1: "已实名",
      },
      tableData: [],
      typelist: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      memberId: "",
    };
  },
  methods: {
    distory(id) {
      this.$confirm("确认注销该会员吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$http
            .post("/cashier-api/f/member/logoff", {
              memberId: id,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                type: "success",
                offset: 160,
                message: "注销成功!",
              });
              this.search();
            })
            .catch((error) => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            offset: 160,
            message: "已取消",
          });
        });
    },
    onSubmit() {
      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          this.loading1 = true;
          this.$http
            .post("/cashier-api/f/member/update/accountBalance", {
              ...this.formzine,
            })
            .then((res) => {
              this.loading1 = false;
              this.$message({
                type: "success",
                offset: 160,
                message: "修改成功!",
              });
              this.dialogVisiblezin = false;
              this.search();
            })
            .catch((error) => {
              this.loading1 = false;
            });
        }
      });
    },
    editmoney(inner) {
      this.dialogVisiblezin = true;
      this.formzine = inner;
    },
    loadlist() {
      this.loading = true;
      this.$http
        .post("/cashier-api/f/memberType/s/all", {})
        .then((res) => {
          this.loading = false;
          this.typelist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    exportz() {
      this.Visibleupdatezin = true;
    },
    tclose() {
      this.Visibleupdatezin = false;
    },
    closeorder() {
      this.dialogVisible = false;
    },
    add() {
      this.dialogVisible = true;
      this.title = "添加会员";
    },
    edit(id) {
      this.dialogVisible = true;
      this.title = "编辑会员";
      this.memberId = id;
    },
    detail(id) {
      this.dialogVisible = true;
      this.title = "会员详情";
      this.memberId = id;
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    allchange(id) {
      this.$router.push({
        path: "memberrecharge",
        query: { id: id },
      });
    },
    search() {
      this.formInline.createTimeBegin = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.createTimeEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.$http
        .post("/cashier-api/f/member/search", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.tableData = res.page.records;
          this.page.total = res.page.total;
        });
    },
  },
};
</script>

<style scoped>
.foter {
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0px;
  text-align: center;
  font-size: 16px;
  line-height: 60px;
  margin-left: 0;
}
.foter span {
  margin-left: 30px;
}
.foter span span {
  color: #ff6969;
}
</style>


