<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="formzinernil"
      class="demo-form-inline"
      size="mini"
      ref="formzinernil"
      :rules="rules"
    >
      <el-form-item label="账户余额:" v-if="obj.inner == '账户余额支付'">
        <span class="redfont">{{ accountBalance | money }}元</span>
      </el-form-item>
      <el-form-item label="应收金额:" prop="itemName">
        <span class="redfont">{{ shouldReceiveAmount | money }}元</span>
      </el-form-item>
      <div v-if="obj.inner == '现金支付'">
        <el-form-item label="支付金额:" prop="cashPaymentAmount">
          <el-input
            v-model="formzinernil.cashPaymentAmount"
            placeholder="请输入支付金额"
            @input="givechange"
          ></el-input>
        </el-form-item>
        <el-form-item label="找零:" prop="cashChangeAmount">
          <span class="redfont"
            >{{
              formzinernil.cashChangeAmount
                ? (formzinernil.cashChangeAmount / 100).toFixed(2)
                : 0
            }}元</span
          >
        </el-form-item>
      </div>
      <div v-if="obj.inner == '其他支付'">
        <el-form-item label="其他收款方式:" prop="otherPaymentRemark">
          <el-input
            v-model="formzinernil.otherPaymentRemark"
            maxlength="30"
          ></el-input>
        </el-form-item>
        <el-form-item label="收取金额:" prop="reallyReceiveAmount">
          <el-input
            v-model="formzinernil.reallyReceiveAmount"
            placeholder="请输入支付金额"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="submitedzin"
        size="mini"
        v-if="obj.inner != '免单' && paymentstatus != '4'"
        >确认收款</el-button
      >
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
import number from "@/common/num.js";
export default {
  created() {
    this.orderMode = this.$route.query.orderMode;
  },
  mounted() {
    if (this.inner == "现金付款") {
      //this.$emit("detail");
    }
    this.shouldReceiveAmount = this.obj.shouldReceiveAmount;
    this.accountBalance = this.obj.accountBalance;

    this.formzinernil.cashPaymentAmount = "";
    this.formzinernil.otherPaymentRemark = "";
    this.formzinernil.shouldReceiveAmount = "";
    this.formzinernil.reallyReceiveAmount = "";
    this.formzinernil.name = "";
    this.result = "";
  },
  props: { obj: Object },
  data() {
    var numzin = (rule, value, callback) => {
      let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
      if (!rez.test(value)) {
        return callback(new Error("支持0.01—999999.99之间"));
      }
      callback();
    };
    var numzint = (rule, value, callback) => {
      let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
      if (!rez.test(value)) {
        return callback(new Error("支持0.01—999999.99之间"));
      }
      if (number(value) < this.shouldReceiveAmount) {
        return callback(new Error("支付金额不能小于应收金额"));
      }
      callback();
    };
    return {
      accountBalance: "",
      loading: false,
      formzinernil: {
        name: "",
        shouldReceiveAmount: "",
        cashPaymentAmount: "",
        cashChangeAmount: "",
        otherPaymentRemark: "",
        reallyReceiveAmount: "",
      },
      paymentstatus: "",
      shouldReceiveAmount: "",
      rules: {
        cashPaymentAmount: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzint, trigger: "blur" },
        ],
        shouldReceiveAmount: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" },
        ],
        otherPaymentRemark: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        reallyReceiveAmount: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" },
        ],
      },
      result: "",
      code: "",
      timer: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    givechange() {
      this.$refs["formzinernil"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/order/cashChangeAmount", {
              shouldReceiveAmount: this.shouldReceiveAmount,
              cashPaymentAmount: number(this.formzinernil.cashPaymentAmount),
            })
            .then((res) => {
              this.loading = false;
              this.formzinernil.cashChangeAmount = res.cashChangeAmount;
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    load() {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/order/toPay", {
          activityType: this.obj.activityType,
          orderNo: this.obj.orderNo,
          memberpayment: this.obj.memberpayment,
          paymentType: this.obj.paymentType,
          notCountAmount: number(this.obj.notCountAmount),
        })
        .then((res) => {
          this.loading = false;
          this.shouldReceiveAmount = res.shouldReceiveAmount;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
      this.formzinernil.name = "";
      this.$refs["formzinernil"].resetFields();
    },
    submitedzin() {
      this.$refs["formzinernil"].validate((valid) => {
        if (valid) {
          let data = { ...this.formzinernil };
          data.shouldReceiveAmount = number(data.shouldReceiveAmount);
          data.cashPaymentAmount = number(data.cashPaymentAmount);
          data.reallyReceiveAmount = number(data.reallyReceiveAmount);
          this.loading = true;
          if (this.orderMode) {
            data.orderMode = this.orderMode;
          }
          this.$http
            .post("/cashier-api/api/order/offline/payment", {
              orderNo: this.obj.orderNo,
              notCountAmount: number(this.obj.notCountAmount),
              paymentType: this.obj.paymentType,
              ...data,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                type: "success",
                offset: 160,
                message: "付款成功!",
              });
              let data = {
                ...res.data,
                flag2: false,
              };
              this.$emit("close", data);
              this.$emit("detail");
              this.$emit("pay");
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.redfont {
  color: #ff6969;
}
</style>