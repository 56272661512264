let paymentStatus = {
    1: "待付款",
    2: "已付款",
    3: "支付中",
    4: "付款失败",
    5: "已取消",
}
let paymentType = {
    1: "现金",
    3: "刷卡",
    4: "其他",
    5: "免单",
    6: "账户支付",
    7: "微信",
    8: "支付宝",
    9: "蜀信e支付",
}
let recordMode = {
    1: "充值",
    2: "支付",
    3: "手动"
}
export {
    paymentStatus,
    paymentType,
    recordMode
};