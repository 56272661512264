<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        style="padding-top:14px;"
        ref="formInline"
      >
        <el-form-item label="活动类型" prop="activityType">
          <el-select v-model="formInline.activityType" placeholder="请选择" clearable>
            <el-option v-for="(v,k) in activityTypeobj" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动名称" prop="activityName">
          <el-input v-model.trim="formInline.activityName" clearable></el-input>
        </el-form-item>
        <el-form-item label="活动状态" prop="activityStatus">
          <el-select v-model="formInline.activityStatus" placeholder="请选择" clearable>
            <el-option v-for="(v,k) in equipmentobj" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="添加时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-date-picker
            v-model="formInline.value2"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="serachzin"
          size="mini"
          class="serach"
        >查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div></div>
          <div>
            <el-button
              v-if="But.AddFull.power()"
              type="primary"
              @click="addreduction"
              size="mini"
            >添加满减活动</el-button>
            <el-button
              v-if="But.AddDiscount.power()"
              type="primary"
              @click="addDiscount"
              size="mini"
            >添加打折活动</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
    >
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="activityType" label="活动类型">
        <template slot-scope="scope">{{activityTypeobj[scope.row.activityType]}}</template>
      </el-table-column>
      <el-table-column prop="activityName" label="活动名称" width="120px"></el-table-column>
      <el-table-column prop="activityStatus" label="活动状态">
        <template slot-scope="scope">{{equipmentobj[scope.row.activityStatus]}}</template>
      </el-table-column>
      <el-table-column prop="createTm" label="添加时间" width="150px"></el-table-column>
      <el-table-column prop="price" label="活动时间" width="300px">
        <template slot-scope="scope">{{scope.row.activityBeginTm}}～{{scope.row.activityEndTm}}</template>
      </el-table-column>
      <el-table-column prop="sourceIp" label="操作" width="120px">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="edit(scope.row.activityId,scope.row.activityType)"
            v-if="But.Edit.power()&&(scope.row.activityStatus==0)"
          >编辑</el-button>
          <el-button type="text" @click="detail(scope.row.activityId,scope.row.activityType)">详情</el-button>
          <el-button
            type="text"
            @click="change(scope.row.activityStatus,scope.row.activityId)"
            v-if="But.Stop.power()&&scope.row.activityStatus==1"
          >停止活动</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  created() {
  //  if (this.$route.query.type) {
    this.serach();
   // }
  },
  data() {
    return {
      loading: false,
      But: window.UserPower.Preferential.promotion,
      equipmentobj: {
        0: "未开始",
        1: "活动中",
        2: "已结束"
      },
      activityTypeobj: {
        1: "满减活动",
        2: "打折活动"
      },
      formInline: {
        activityType: "",
        activityName: "",
        activityStatus: "",
        createTmEnd: "",
        createTmStart: "",
        activityBeginTm: "",
        activityEndTm: "",
        value1: null,
        value2: null
      },
      tableData: [],
      searchGoods: "",
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
    };
  },
  methods: {
    detail(id, type) {
      if (type == 1) {
        this.$router.push({
          path: "Discountdetail",
          query: { id: id, type: 1 }
        });
      } else {
        this.$router.push({
          path: "Discountdetail",
          query: { id: id, type: 2 }
        });
      }
    },
    edit(id, type) {
      if (type == 1) {
        this.$router.push({
          path: "addreduction",
          query: { id: id, type: 1 }
        });
      } else {
        this.$router.push({
          path: "addDiscount",
          query: { id: id, type: 2 }
        });
      }
    },
    change(indexzin, tid) {
      // let inner = indexzin == 1 ? "启用" : "停用";
      this.$confirm("确认停止该活动?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.loading = true;
          this.$http
            .post(`/order-admin/w/activity/modify/status/${tid}/0`, {})
            .then(data => {
              this.loading = false;
              this.$message({
                message: `停用成功`,
                offset: 160,
                type: "success"
              });
              this.serach();
            })
            .catch(error => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            offset: 160,
            message: "已取消"
          });
        });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
      this.formInline.value2 = [];
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    addreduction() {
      this.$router.push("/addreduction");
    },
    addDiscount() {
      this.$router.push("/addDiscount");
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.serach();
    },
    serachzin() {
      this.page.pageNo = 1;
      this.serach();
    },
    serach() {
      this.formInline.createTmStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.createTmEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.formInline.activityBeginTm = this.formInline.value2
        ? this.formInline.value2[0]
        : "";
      this.formInline.activityEndTm = this.formInline.value2
        ? this.formInline.value2[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/activity/s/page", {
          ...this.formInline,
          ...this.page
        })
        .then(res => {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.total = +res.data.total;
        })
        .catch(error => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.goodsClassify li {
  margin-bottom: 8px;
}
</style>


