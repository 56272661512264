<template>
  <div v-loading="loading">
    <div class="flex">
      <div class="class-list">
        <div
          class="class-li"
          :class="{ active: element.categoryId == activeId }"
          v-for="element in myArray"
          :key="element.categoryId"
          @click="activeItem(element.categoryId)"
        >
          <span>{{ element.categoryName }}</span>
        </div>
      </div>
      <div class="list">
        <div class="warning">温馨小提示：鼠标点击拖拽即可改变顺序</div>
        <div class="li li-head">
          <span class="xuhao">序号</span>
          <span class="name">分类名称</span>
          <span class="oper center">排序操作</span>
        </div>
        <draggable v-model="caiList">
          <transition-group>
            <div
              class="li"
              v-for="(element, index) in caiList"
              :key="element.dishersName"
            >
              <span class="xuhao">{{ index + 1 }}</span>
              <span class="name">{{ element.dishersName }}</span>
              <span class="oper center">
                <img src="@/assets/image/icon_paixun.png" alt="" />
              </span>
            </div>
          </transition-group>
        </draggable>
        <div class="center">
           <br />
          <span v-if="caiList.length == 0">暂无菜品</span>       
          <el-button
            v-if="caiList.length != 0"
            type="primary"
            @click="onSubmit"
            size="mini"
            >保存</el-button
          >
        </div>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  created() {
    this.loadClassList();
  },
  data() {
    return {
      loading: false,
      activeId: "",
      myArray: [],
      caiList: [],
    };
  },
  methods: {
    activeItem(categoryId) {
      this.activeId = categoryId;
      this.loadCai(categoryId);
    },
    loadCai(id) {
      console.log(id)
      this.caiList = [];
      this.loading = true;
      this.$http.post("/order-admin/w/disher/s/all", { categoryId: id }).then(
        (res) => {
          this.caiList = res.data;
          this.loading = false;
        },
        (e) => {
          this.loading = false;
        }
      );
    },
    loadClassList() {
      this.$http.post("/order-admin/w/disher/category/s/all").then((res) => {
        this.myArray = res.data;
        res.data[0] && this.activeItem(res.data[0].categoryId);
      });
    },
    onSubmit() {
      let data = this.caiList.map((v, index) => {
        return {
          dishesSequence: index + 1,
          dishesId: v.dishersId,
        };
      });
      this.$http
        .post("/order-admin/w/disher/sort", { dishesInfos: data })
        .then((res) => {
          this.$emit("load");
          this.$emit("close");
          this.$message({
            message: "保存成功",
            type: "success",
          });
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.class-list {
  width: 164px;
  font-size: 14px;
  color: #333333;
  border-right: 1px solid #ecedee;
  margin-right: 22px;
}
.class-li {
  margin: 10px 0;
  cursor: pointer;
  text-indent: 20px;
}
.warning {
  color: #f85959;
  margin-bottom: 20px;
  font-size: 14px;
}
.li-head {
  background: #fafafa;
  font-weight: bold;
}
.list {
  overflow: auto;
  width: 400px;
  padding: 20px;
}
.li {
  padding: 15px 0;
  cursor: pointer;
  transition: all 0.2s;
  text-indent: 10px;
  border: 1px solid #ecedee;
  border-bottom: none;
  display: flex;
}
.li:last-child {
  margin: 0;
}
.li:last-child {
  border-bottom: 1px solid #ecedee;
}
.li .xuhao {
  width: 50px;
}
.li .name {
  flex: 1;
}
.li .oper {
  width: 100px;
}
.li .oper img {
  width: 16px;
}
.flex {
  display: flex;
  max-height: 700px;
  font-size: 14px;
}
.active, .class-li:hover{
  color: #108AF9;
}
</style>