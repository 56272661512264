<template>
  <div class="view" v-loading="loading">
    <div class="view-contion">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
        size="mini"
      >
        <el-row :gutter="20">
          <el-col :span="14">
            <el-form-item label="商户名称:" prop="merchantName">
              <span>{{ ruleForm.merchantName }}</span>
            </el-form-item>
            <el-form-item label="餐厅名称:" prop="restaurantName">
              <el-input
                class="input-width"
                v-model.trim="ruleForm.restaurantName"
                clearable
              ></el-input>
              <div class="input-tishi">可修改成餐厅名称，20字以内</div>
            </el-form-item>
            <el-form-item label="餐厅后台头像:" prop="name">
              <el-upload
                class="avatar-uploader"
                :action="baseurl + '/order-admin/w/file/upload/pic'"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :headers="myHeaders"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <div v-else class="avatar-uploader-icon">
                  上传
                  <br />图片
                </div>
              </el-upload>
              <div class="input-tishi">
                建议尺寸：640x640像素；支持：jpg、png格式。
              </div>
            </el-form-item>
            <el-form-item label="手机端顶图:" prop="name">
              <croppa
                :initial-image="imageUrl1"
                :remove-button-size="20"
                :width="360"
                :placeholder-font-size="16"
                :height="100"
                placeholder="选择图像"
                v-model="croppa"
                @change="upImg"
              ></croppa>
              <div class="el-upload__tip" slot="tip">
                为了美观，请尽量上传360*220的图片
              </div>
              <div class="pngfont">
                <el-button size="mini" @click="croppa.rotate()">旋转</el-button>
                <el-button size="mini" @click="croppa.zoomIn()">放大</el-button>
                <el-button size="mini" @click="croppa.zoomOut()"
                  >缩小</el-button
                >
                <!-- <el-button type="primary" size="mini" @click="upImg()">上传</el-button> -->
                <el-button type="primary" size="mini" @click="viewall"
                  >查看示例</el-button
                >
              </div>
              <div class="pngfontw">
                建议尺寸：720x200像素；支持：jpg、png格式
              </div>
            </el-form-item>
            <el-form-item label="店铺码:" prop="merchantName">
              <span class="el-button--text" @click="downImg">下载</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="flex-view-bottom" v-if="But.Set.power()">
      <el-button type="primary" size="mini" @click="upImg">保存</el-button>
    </div>
    <div class="zmarack" v-show="viewallflag">
      <img src="@/assets/image/img-shili.png" />
      <img src="@/assets/image/icon_guanbi_1.png" @click="close" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { baseurl } from "../../../../base.js";
export default {
  created() {},
  data() {
    return {
      croppa: {},
      But: window.UserPower.RestRoomSet.Restaurant.basicInfo,
      disabled: false,
      baseurl: baseurl,
      myHeaders: { "jwt-ov3": localStorage.getItem("tokenIdzin") },
      ruleForm: {
        restaurantName: "",
        manageImgId: "",
        frontImgId: "",
        opera: 0,
        merchantName: "",
      },
      loading: false,
      rules: {
        restaurantName: [
          { min: 1, max: 20, message: "最多20个字", trigger: "blur" },
        ],
      },
      imageUrl: "",
      imageUrl1: "",
      viewallflag: false,
    };
  },
  methods: {
    downImg() {
      let merchantId = localStorage.getItem("merchantId");
      window.open(`${baseurl}/main-server/api/qrcode/download/${merchantId}`);
    },
    rotate() {
      this.croppa.rotate();
    },
    upImg() {
      if (!this.croppa.hasImage()) {
        // this.$message.error("请选择需要上传的图片！！！");
        this.ruleForm.frontImgId = "";
        this.submit();
        return;
      }
      this.croppa.generateBlob((blob) => {
        var fd = new FormData();
        fd.append("file", blob, "blob.png");
        this.$http.post("/order-admin/w/file/upload/pic", fd).then((res) => {
          // this.$message.success("上传成功！！！");
          this.ruleForm.frontImgId = res.data;
          this.submit();
        });
      });
    },
    ...mapMutations(["geturl", "getname"]),
    beforeAvatarUpload(file) {
      var isJPG = /^image\/(png|jpeg)$/.test(file.type);
      if (!isJPG) {
        this.$message.error("支持jpg、png格式");
      }
      return isJPG;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.ruleForm.manageImgId = res.data;
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/order-admin/w/restaurant/save/info", {
              ...this.ruleForm,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                message: "设置成功",
                type: "success",
              });
              this.load();
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    load() {
      this.ruleForm.restaurantName = localStorage.getItem("restaurantName");
      let supportWebp = localStorage.getItem("supportWebp");
      this.loading = true;
      this.$http
        .post(
          `/order-admin/w/restaurant/detail?isSupportWebp=${supportWebp}`,
          {}
        )
        .then((res) => {
          this.loading = false;
          this.ruleForm.frontImgId = res.data.frontImgId;
          this.ruleForm.manageImgId = res.data.manageImgId;
          this.ruleForm.restaurantName = res.data.restaurantName;
          this.ruleForm.merchantName = res.data.merchantName;
          this.imageUrl = res.data.manageImgUrl;
          var image = new Image();
          image.setAttribute("crossorigin", "anonymous");
          image.src = res.data.frontImgUrl;
          this.imageUrl1 = image;
          this.croppa.refresh();
          this.$store.commit("geturl", res.data.manageImgUrl);
          this.$store.commit("getname", res.data.restaurantName);
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    viewall() {
      this.viewallflag = true;
    },
    close() {
      this.viewallflag = false;
    },
  },
};
</script>

<style scoped>
.el-button--text {
  cursor: pointer;
}
.zmarack {
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.zmarack img:nth-of-type(1) {
  width: 254px;
  z-index: 3000;
  position: fixed;
  top: 230px;
  right: 100px;
}
.zmarack img:nth-of-type(2) {
  width: 40px;
  position: fixed;
  top: 600px;
  right: 205px;
}
.input-width {
  width: 238px;
}
.demo-ruleForm {
  /* padding: 20px 20px 0 20px; */
  height: calc(100% - 60px);
}
.hr_title {
  margin: 0 0 20px 10px;
}
.uploa_cs {
  display: flex;
  justify-content: flex-start;
  color: #108af9;
}
.uploa_cs div:nth-of-type(2) {
  margin-left: 30px;
}
.avatar {
  width: 100px;
  height: 100px;
}
.avatar_a {
  width: 240px;
  height: 66px;
}
.view {
  position: relative;
}
.view-contion {
  height: calc(100% - 60px);
  overflow: auto;
}
.flex-view-bottom {
  position: fixed;
  bottom: 0;
  background: white;
}
.pngfont {
  margin: 20px 0 10px;
  width: 360px;
}
.pngfontw {
  margin: 5px 0 20px;
  font-size: 10px;
  color: #666666;
}
.croppa-container {
  width: 360px;
  height: 100px;
}
</style>
