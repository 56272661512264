<template>
  <div class="view-contion" :loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        size="mini"
        label-width="80px"
        :model="form"
        ref="formInlinezin"
      >
        <el-form-item label="菜品分类" prop="categoryName">
          <el-input v-model.trim="form.categoryName" clearable></el-input>
        </el-form-item>
        <el-form-item label="菜品名称" prop="dishersName">
          <el-input
            v-model.trim="form.dishersName"
            clearable
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="变动类型" prop="operationType">
          <el-select
            v-model="form.operationType"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(k, v) in depotScopeList"
              :key="v"
              :label="k"
              :value="v"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="Search" size="mini">查询</el-button>
          <el-button @click="Export" size="mini">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-bottom: 10px; font-weight: bold">
      查询时间：{{ searchTimer }}
    </div>
    <el-table :data="tableData" style="width: 100%" size="mini">
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column prop="operationType" label="操作类型">
        <template slot-scope="scope">
          {{ depotScopeList[scope.row.operationType] }}
        </template>
      </el-table-column>
      <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
      <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
      <el-table-column prop="changeGoodNum" label="调整值">
        <template slot-scope="scope">
          {{
            scope.row.operationType == 1
              ? scope.row.changeGoodNum == 0
                ? "清零"
                : scope.row.changeGoodNum
              : scope.row.changeGoodNum
          }}
        </template>
      </el-table-column>
      <el-table-column prop="beforeGoodNum" label="操作前"></el-table-column>
      <el-table-column prop="afterGoodNum" label="操作后"></el-table-column>
      <el-table-column prop="operatorUserNo" label="操作人"></el-table-column>
      <el-table-column
        prop="operationTime"
        label="操作时间"
        width="200px"
      ></el-table-column>
      <el-table-column prop="orderNo" label="订单编号">
        <template slot-scope="scope">
          <el-button type="text" @click="detailes(scope.row.orderNo)">{{
            scope.row.orderNo
          }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
    <el-dialog
      title="订单详情"
      :visible.sync="dialogVisibleOrder"
      width="1320px"
      :close-on-click-modal="false"
    >
      <detaileOrder :orderId="orderId" v-if="dialogVisibleOrder" />
    </el-dialog>
    <export-file
      URL="/order-admin/w/depot/detail/export"
      ref="File"
    ></export-file>
  </div>
</template>

<script>
import detaileOrder from "./detaileOrder.vue";
export default {
  components: { detaileOrder },
  created() {
    this.loadlist();
    this.Load();
  },
  data() {
    return {
      searchTimer: "",
      dialogVisibleOrder: false,
      equipmentlist: [],
      loading: false,
      form: {
        operationType: "",
        categoryName: "",
        dishersName: "",
      },
      depotScopeList: {
        1: "删除",
        2: "销售",
        3: "进货",
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      orderId: null,
    };
  },
  methods: {
    detailes(orderId) {
      this.orderId = orderId;
      this.dialogVisibleOrder = true;
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    Load() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/depot/detail/page", {
          ...this.form,
          ...this.page,
        })
        .then(
          (res) => {
            this.loading = false;
            this.searchTimer = new Date().formatDate("yyyy-MM-dd hh:mm:ss");
            this.tableData = res.data.records;
            this.page.total = res.data.total;
          },
          (e) => {
            this.loading = false;
          }
        );
    },
    loadlist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/disher/category/s/all", {})
        .then((res) => {
          this.loading = false;
          this.equipmentlist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.Load();
    },
    Search() {
      this.page.pageNo = 1;
      this.Load();
    },
    Export() {
      this.$refs.File.export({
        ...this.form,
        authToken: localStorage.getItem("tokenIdzin"),
      });
    },
  },
};
</script>

<style scoped>
</style>