<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="formzine"
      class="demo-form-inline"
      size="mini"
      ref="formzine"
      :rules="rules"
    >
      <el-form-item label="备注:" prop="remark">
        <el-input
          type="textarea"
          v-model="formzine.remark"
          placeholder="请输入备注"
          maxlength="30"
          style="width: 200px"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submited" size="mini">保存</el-button>
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
export default {
  created() {
    if (this.marka == 2) {
      this.formzine.remark = this.orderRemark;
    } else {
      this.formzine.remark = this.val.dishersRemark;
    }
  },
  props: { val: Object, orderNo: String, orderRemark: String, marka: String },
  data() {
    return {
      loading: false,
      formzine: {
        remark: "",
      },
      rules: {
        remark: [{ min: 1, max: 30, message: "最多30字", trigger: "blur" }],
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.$refs["formzine"].resetFields();
    },
    submited() {
      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/dishers/remark", {
              remark: this.formzine.remark,
              orderNo: this.orderNo,
              dishersId: this.val.dishersId,
              orderDishesId: this.val.orderDishesId,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                message: "添加备注成功",
                offset: 160,
                type: "success",
              });
              this.$emit("close");
              this.$emit("detail");
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>