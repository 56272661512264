<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="formzinernil"
      class="demo-form-inline"
      size="mini"
      ref="formzinernil"
      :rules="rules"
    >
      <div class="form_ant">
        <el-form-item label="菜品名称:" prop="itemName">{{val.dishersName}}</el-form-item>
        <el-form-item label="口味:" prop="tasteId">
          <el-select v-model="formzinernil.tasteId" placeholder="请选择" @change="zint">
            <el-option
              v-for="item in options"
              :key="item.tasteId"
              :label="item.tasteName"
              :value="item.tasteId"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitedzin" size="mini">确认更改</el-button>
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
export default {
  created() {
    this.formzinernil.tasteId = this.val.tasteId;
    this.zint(this.val.tasteId);
  },
  props: { val: Object, orderNo: String, options: Array },
  data() {
    return {
      loading: false,
      formzinernil: {
        tasteId: "",
        tasteName: ""
      },
      rules: {
        tasteName: [{ required: true, message: "必填", trigger: "blur" }]
      },
      optionszint: []
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.$refs["formzinernil"].resetFields();
    },
    zint(val) {
      this.optionszint = this.options;
      var obj = {};
      obj = this.optionszint.find(function(item) {
        return item.tasteId === val;
      });
      this.formzinernil.tasteId = obj.tasteId;
      this.formzinernil.tasteName = obj.tasteName;
    },
    // loadzint() {
    //   this.$http
    //     .get("/cashier-api/api/dishers/taste/list", {
    //       params: {
    //         dishersId: this.val.dishersId
    //       }
    //     })
    //     .then(res => {
    //       this.options = res.data;
    //     });
    // },
    submitedzin() {
      this.$refs["formzinernil"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/dishers/taste/select", {
              ...this.formzinernil,
              orderDishesId: this.val.orderDishesId,
              orderNo: this.orderNo
            })
            .then(res => {
              this.loading = false;
              this.$message({
                message: "确认更改口味成功",
                offset: 160,
                type: "success"
              });
              this.$emit("close");
              this.$emit("detail");
            })
            .catch(error => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.form_ant {
  display: flex;
  flex-direction: column;
}
</style>