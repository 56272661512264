<template>
  <div class="guige" v-loading="loading">
    <el-form
      :inline="true"
      :model="formzinernil"
      class="demo-form-inline"
      size="mini"
      ref="formzinernil"
      :rules="rules"
    >
      <div v-if="priceType == 2">
        <div class="fontal">选择规格</div>
        <div class="radio">
          <div
            v-for="(item, index) in departsarr"
            :class="index == isActive ? 'active' : ''"
            @click="checkedItem(index, item)"
            :key="index"
          >
            {{ item.priceUnitName }}
          </div>
        </div>
      </div>
      <div v-if="tastearr.length != 0">
        <div class="fontal">口味</div>
        <div class="radio">
          <div
            v-for="(item, index) in tastearr"
            :class="index == isActiveTaste ? 'active' : ''"
            @click="checkedTasteItem(index, item)"
            :key="index"
          >
            {{ item.tasteName }}
          </div>
        </div>
      </div>
      <!-- <div class="fontal_a">选择配菜</div>
      <div class="contain_inner">
        <div
          class="contain_inner_a"
          v-for="(item,index) in formzinernil.garnishInfoVos"
          :key="index"
        >
          <div>{{item.garnishName}}</div>
          <div>
            <div>¥{{item.price/100}}</div>
            <div class="add_reduce">
              <img
                src="@/assets/image/icon-jian.png"
                @click="loadfuntion('减',item)"
                v-if="item.garnishCount>0"
              />
              <span>{{item.garnishCount}}</span>
              <img src="@/assets/image/icon-jia.png" @click="loadfuntion('加',item)" />
            </div>
          </div>
        </div>
      </div>-->
    </el-form>
    <div class="footer_a">
      <el-button type="primary" @click="submitedzin" size="mini"
        >确认</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: { dishersId: String, orderNo: String },
  data() {
    return {
      formzinernil: {
        garnishInfoVos: [],
        dishersId: "",
        tasteId: "",
        tasteName: "",
        departs: {},
      },
      departsarr: [],
      tastearr: [],
      rules: {},
      isActive: 0,
      isActiveTaste: 0,
      priceType: "",
      loading: false,
    };
  },
  methods: {
    checkedItem(index, item) {
      this.isActive = index;
      this.formzinernil.departs = item;
    },
    checkedTasteItem(index, item) {
      this.isActiveTaste = index;
      this.formzinernil.tasteId = item.tasteId;
      this.formzinernil.tasteName = item.tasteName;
    },
    close() {
      this.$emit("close");
    },
    loaddetail() {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/dishers/detail", {
          merchantId: localStorage.getItem("merchantId"),
          dishersId: this.dishersId,
          orderNo: this.orderNo,
        })
        .then((res) => {
          this.loading = false;
          this.departsarr = res.dishersInfoVo.departs;
          this.tastearr = res.dishersInfoVo.selectedTaste;
          for (let i in this.departsarr) {
            if (res.dishersInfoVo.priceId == this.departsarr[i].priceId) {
              this.formzinernil.departs = this.departsarr[i];
            }
          }
          if (this.tastearr[0]) {
            this.formzinernil.tasteId = this.tastearr[0].tasteId;
            this.formzinernil.tasteName = this.tastearr[0].tasteName;
          }

          this.priceType = res.dishersInfoVo.priceType;
          this.formzinernil.garnishInfoVos = res.dishersInfoVo.garnishInfoVos;
          this.formzinernil.dishersId = res.dishersInfoVo.dishersId;
          this.isActive = 0;
          this.isActiveTaste = 0;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submitedzin() {
      if (this.priceType != 2) {
        delete this.formzinernil.departs;
      }
      this.loading = true;
      this.$http
        .post("/cashier-api/api/dishers/add-detail", {
          merchantId: localStorage.getItem("merchantId"),
          orderNo: this.orderNo,
          ...this.formzinernil,
        })
        .then((res) => {
          this.loading = false;
          this.$message({
            message: "添加成功",
            type: "success",
            offset: 160,
          });
          this.$emit("close");
          this.$emit("detail");
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    loadfuntion(a, item) {
      if (a == "加") {
        item.garnishCount++;
      } else {
        item.garnishCount--;
      }
    },
  },
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.fontal {
  font-size: 14px;
  margin: 10px 0 10px 0;
}
.radio {
  display: flex;
  justify-content: flex-start;
}
.radio div {
  width: 150px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
  margin-right: 20px;
}
.active {
  background: #ecf6ff !important;
  border: 1px solid #108af9 !important;
  border-radius: 4px !important;
  color: #108af9 !important;
}
.fontal_a {
  margin: 30px 0 10px 0;
}
.contain_inner {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.contain_inner_a {
  width: 150px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10px;
  margin: 0 20px 20px 0;
}
.contain_inner_a div:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
}
.contain_inner_a div:nth-of-type(2) img {
  width: 12px;
  height: 12px;
}
.add_reduce {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.add_reduce img,
span {
  margin-right: 5px;
}
.footer_a {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}
</style>