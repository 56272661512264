<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini" ref="formInline">
        <el-form-item label="菜品分类" prop="categoryId">
          <el-select v-model="formInline.categoryId" placeholder="请选择" clearable>
            <el-option v-for="item in equipmentlist" :key="item.categoryId" :label="item.categoryName" :value="item.categoryId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜品名称" prop="dishersName">
          <el-input v-model.trim="formInline.dishersName"></el-input>
        </el-form-item>
        <el-form-item label="菜品状态" prop="dishersStatus">
          <el-select v-model="formInline.dishersStatus" placeholder="请选择" clearable>
            <el-option v-for="(v, k) in equipmentstatus" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="添加时间">
          <el-date-picker v-model="formInline.value1" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button v-if="But.List.power()" type="primary" @click="searchzin" size="mini" class="serach">查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div>
            <el-button v-if="But.AllUp.power()" @click="statusall(1)" size="mini">批量上架</el-button>
            <el-button v-if="But.AllDown.power()" @click="statusall(0)" size="mini">批量下架</el-button>
            <el-button v-if="But.Export.power()" @click="exporT" size="mini">导出</el-button>
            <el-button v-if="But.Delete.power()" type="primary" size="mini" @click="deleItem">批量删除</el-button>
          </div>
          <div>
            <el-button v-if="But.Sort.power()" type="primary" size="mini" @click="sortClass">菜品排序</el-button>
            <el-button v-if="But.Add.power()" type="primary" @click="adddishes" size="mini">添加菜品</el-button>
            <el-button v-if="But.Import.power()" @click="exportcai" size="mini">导入菜品</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" size="mini" highlight-current-row @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
      <el-table-column prop="thumbnailUrl" label="菜品图片">
        <template slot-scope="scope">
          <el-popover placement="right" title trigger="hover">
            <img :src="scope.row.previewUrl" style="width: 150px; height: 150px" />
            <img slot="reference" :src="scope.row.previewUrl" :alt="scope.row.previewUrl" style="width: 50px; height: 50px" />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
      <el-table-column prop="dishersStatus" label="菜品状态">
        <template slot-scope="scope">{{
          equipmentstatus[scope.row.dishersStatus]
        }}</template>
      </el-table-column>
      <el-table-column prop="disherUnitPriceSearchVoList[0].priceType" label="计价方式">
        <template slot-scope="scope">{{
          options1[scope.row.disherUnitPriceSearchVoList[0].priceType]
        }}</template>
      </el-table-column>
      <el-table-column prop="disherUnitPriceSearchVoList[0].price" label="单价">
        <template slot-scope="scope">
          <div v-if="scope.row.disherUnitPriceSearchVoList[0].priceType == 2">
            <el-popover trigger="hover" placement="top">
              <div v-for="(i, index) in scope.row.disherUnitPriceSearchVoList" :key="index">
                <p>{{i.priceUnitName}}: {{ i.price | money }}</p>
              </div>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{
                  scope.row.disherUnitPriceSearchVoList[0].price | money
                }}</el-tag>
              </div>
            </el-popover>
          </div>
          <div v-if="scope.row.disherUnitPriceSearchVoList[0].priceType != 2">
            {{ scope.row.disherUnitPriceSearchVoList[0].price | money }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="disherUnitPriceSearchVoList[0].memberPrice" label="会员价" v-if="openCommonMermber != '0'">
        <template slot-scope="scope">
          <div v-if="scope.row.disherUnitPriceSearchVoList[0].priceType == 2">
            <el-popover trigger="hover" placement="top">
              <div v-for="(i, index) in scope.row.disherUnitPriceSearchVoList" :key="index">
                <p>会员价: {{ i.memberPrice | money }}</p>
              </div>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{
                  scope.row.disherUnitPriceSearchVoList[0].memberPrice | money
                }}</el-tag>
              </div>
            </el-popover>
          </div>
          <div v-if="scope.row.disherUnitPriceSearchVoList[0].priceType != 2">
            {{ scope.row.disherUnitPriceSearchVoList[0].memberPrice | money }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="sourceIp" label="操作">
        <template slot-scope="scope">
          <el-button v-if="But.Edit.power()" type="text" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="dishersStatus" label="上架/下架">
        <template slot-scope="scope">
          <el-switch v-if="But.UpDown.power()" v-model="scope.row.dishersStatus" :active-value="1" :inactive-value="0" @change="change($event, scope.row.dishersId)"></el-switch>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination @current-change="handleCurrentChange" :current-page="page.pageNo" :page-size="page.pageSize" layout="total, prev, pager, next, jumper" :total="page.total" prev-text="上一页"
        next-text="下一页"></el-pagination>
    </div>
    <export-file URL="/order-admin/w/disher/export/excel" ref="File"></export-file>

    <el-dialog title="导入" :visible.sync="Visibleupdatezin" width="450px" height="400px" top="5vh" :close-on-click-modal="false">
      <dialog-updata v-if="Visibleupdatezin" @close="tclose" @load="search"></dialog-updata>
    </el-dialog>
    <el-dialog title="菜品排序" :visible.sync="caiListSort" width="670px" :close-on-click-modal="false">
      <caiList v-if="caiListSort" @close="caiListSort = false" @load="search" />
    </el-dialog>
  </div>
</template>

<script>
import { baseurl } from "../../../../base.js";
import dialogUpdata from "./dialog.vue"; //导入
import caiList from "./caiList";
export default {
  components: {
    dialogUpdata,
    caiList,
  },
  created() {
    this.loadlist();
    this.formInline.supportWebp = localStorage.getItem("supportWebp");
    this.formInline.authToken = localStorage.getItem("tokenIdzin");
    //  if (this.$route.query.type) {
    this.search();
    // }
    this.openCommonMermber = localStorage.getItem("openCommonMermber");
  },
  mounted() {
    this.url = `${this.baseurl}/order-admin/w/disher/export/excel`;
  },
  data() {
    return {
      caiListSort: false,
      openCommonMermber: "",
      loading: false,
      But: window.UserPower.variety.sinformation,
      url: "",
      baseurl: baseurl,
      options1: {
        0: "常规计价",
        // 1: "称重计价",
        2: "份量计价",
        3: "时价",
      },
      formInline: {
        categoryId: "",
        dishersName: "",
        dishersStatus: "",
        createTmStart: "",
        createTmEnd: "",
        supportWebp: null,
        value1: null,
        authToken: "",
        isContainTimeDishes: "1",
      },
      equipmentstatus: {
        1: "启用",
        0: "停用",
      },
      equipmentlist: [],
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      multipleSelection: [],
      Visibleupdatezin: false,
    };
  },
  methods: {
    deleItem() {
      if (this.multipleSelection.length == 0) {
        return this.$message({
          message: "请选择需要删除的菜品！",
          type: "warning",
        });
      }
      let data = this.multipleSelection.map((v) => {
        return {
          dishersId: v.dishersId,
          dishersName: v.dishersName,
        };
      });
      this.$confirm("此操作将删除菜品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .post("/order-admin/w/disher/delete-batch", {
            dishesIdList: data,
          })
          .then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.search();
          });
      });
    },
    sortClass() {
      this.caiListSort = true;
    },
    tclose() {
      this.Visibleupdatezin = false;
    },
    exportcai() {
      this.Visibleupdatezin = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exporT() {
      this.$refs.File.export({ ...this.formInline });
    },
    statusall(a) {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择要批量进行的内容",
          type: "warning",
        });
      } else {
        let inner = a == 0 ? "批量下架" : "批量上架";
        let disherIds = [];
        for (let i in this.multipleSelection) {
          disherIds.push(this.multipleSelection[i].dishersId);
        }
        this.loading = true;
        this.$http
          .post(`/order-admin/w/disher/modify/status/batch`, {
            disherIds: disherIds,
            status: a,
          })
          .then((data) => {
            this.loading = false;
            this.$message({
              message: `${inner}成功`,
              type: "success",
            });
            this.search();
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    change(indexzin, dishersId) {
      let inner = indexzin == 0 ? "下架" : "上架";
      this.loading = true;
      this.$http
        .post(
          `/order-admin/w/disher/modify/status/single/${dishersId}/${indexzin}`,
          {}
        )
        .then((data) => {
          this.loading = false;
          this.$message({
            message: `${inner}成功`,
            type: "success",
          });
          this.search();
        })
        .catch((error) => {
          this.loading = false;
          this.search();
        });
    },
    loadlist() {
      this.$http
        .post("/order-admin/w/disher/category/s/all", {})
        .then((res) => {
          this.equipmentlist = res.data;
        });
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    adddishes() {
      this.$router.push({ path: "adddashed", query: { title: "添加菜品" } });
    },
    edit(inner) {
      this.$router.push({
        path: "adddashed",
        query: {
          title: "编辑菜品",
          dishersId: inner.dishersId,
          priceId: inner.disherUnitPriceSearchVoList[0].priceId,
        },
      });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.createTmStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.createTmEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/disher/s/page", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          for (let i in res.data.records) {
            res.data.records[i].dishersStatus = Number(
              res.data.records[i].dishersStatus
            );
          }
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>


