<template>
  <div class="shop-container" v-loading="loading||Loading">
    <div class="shop-header">
      <div v-show="orderMode==1">
        <span>{{orderInfo.tableRegionName}}-{{orderInfo.tableName}}</span>
        <span>{{orderInfo.dinersCount}}/{{orderInfo.seatingCapacity}}座</span>
      </div>
      <div>
        <span><span class="c999999">下单员：</span>{{orderInfo.operatorName}}</span>
        <span>{{orderInfo.openTableTimeStr}}</span>
      </div>
    </div>
    <div class="shop-body">
      <el-scrollbar style="height:100%">
        <div class="shop-li" :class="{active:item===indexActive,time:item.priceType==2}" @click="selectDisher(item)" v-for="item in disherData" :key="item.orderDishesId">
          <div class="li-details">
            <div class="details-name">
              <span class="cFF3B3B" v-if="item.priceType==2">(时)</span>
              <span class="cFF3B3B" v-if="item.priceType==3">(赠)</span>
              <span class="cFF3B3B" v-if="item.priceType==4">(特)</span>
              {{item.dishesName}}
            </div>
            <div class="details-size">
              <span class="c999999">{{[item.priceUnitName,item.tasteName]|filterNullToString}}</span>
              <el-popover v-if="item.dishersRemark" placement="right" title="备注" width="200" trigger="click" :content="item.dishersRemark">
                <span class="details-remark c108AF9" slot="reference">【查看备注】</span>
              </el-popover>
            </div>
          </div>
          <img class="time-logo" v-if="item.priceType==2" src="@/assets/image/img_shijiacai_wutu.png" alt="">
          <div class="li-num">×{{item.selectCount}}</div>
          <div class="li-price">
            <template v-if="item.priceType==1">
              <div class="cFF3B3B">￥{{item.memberTotalAmount|money}}</div>
              <div :class="{openvip:vipStatus}">￥{{item.totalAmount|money}}</div>
            </template>
            <template v-else>
              <div class="cFF3B3B">￥{{item.totalAmount|money}}</div>
            </template>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  props: ["orderNo", "orderMode"],
  created() {
    this.getShopingCard({ orderNo: this.orderNo });
  },
  filters: {
    filterNullToString(val) {
      return val.filter((v) => !!v).join("+");
    },
  },
  computed: mapState({
    disherData: (state) => state.shopingCard.shopingCardList,
    indexActive: (state) => state.shopingCard.shopingCardActive,
    orderInfo: (state) => state.shopingCard.shopingCardOrder,
    loading: (state) => state.shopingCard.shopingLoading,
    vipStatus: (state) => state.shopingCard.shopingCardVip,
  }),
  data() {
    return {
      Loading: false,
    };
  },
  watch: {
    disherData(nv) {
      this.restActiveFn(nv);
    },
  },
  methods: {
    ...mapActions(["getShopingCard"]),
    ...mapMutations(["SetShopingCardActive"]),
    restActiveFn(data) {
      if (this.indexActive) {
        let Status = true;
        for (let value of data) {
          if (value.orderDishesId === this.indexActive.orderDishesId) {
            this.SetShopingCardActive(value);
            Status = false;
            break;
          }
        }
        if (Status) this.SetShopingCardActive(null);
      } else {
        this.SetShopingCardActive(null);
      }
    },
    selectDisher(item) {
      let data = item;
      if (this.indexActive === item) {
        data = null;
      }
      this.SetShopingCardActive(data);
    },
  },
};
</script>

<style scoped src="../../selectMenu/shoppingCart/index.css">
</style>