<template>
  <div>
    <el-form v-loading="loading" :model="formzinernil" label-width="100px" size="mini" ref="formzinernil" :rules="rules">
      <el-form-item label="就餐人数:" prop="dinersCount">
        <el-input v-model.trim="formzinernil.dinersCount"></el-input>
      </el-form-item>
      <el-form-item label="预约时间:" prop="reservedTime">
        <el-date-picker v-model="formzinernil.reservedTime" type="datetime" placeholder="选择日期" style="width:167px" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions"></el-date-picker>
      </el-form-item>
      <el-form-item label="备注:" prop="reservedRemark">
        <el-input type="textarea" v-model.trim="formzinernil.reservedRemark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitedzin" size="mini">保存</el-button>
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
export default {
  props: { tableId: String },
  data() {
    var numzin = (rule, value, callback) => {
      let rez = /^[1-9][0-9]?$/;
      if (!rez.test(value)) {
        return callback(new Error("请输入两位以内的正整数"));
      }
      callback();
    };
    return {
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      formzinernil: {
        reservedRemark: "",
        reservedTime: "",
        dinersCount: "",
        merchantId: localStorage.getItem("merchantId"),
      },
      rules: {
        dinersCount: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" },
        ],
        reservedTime: [{ required: true, message: "必填", trigger: "blur" }],
        reservedRemark: [
          { min: 1, max: 20, message: "最多20个字符", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.$refs["formzinernil"].resetFields();
    },
    submitedzin() {
      this.$refs["formzinernil"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/tableInfoNew/reserved", {
              ...this.formzinernil,
              tableId: this.tableId,
            })
            .then(() => {
              this.loading = false;
              this.$message({
                message: "预约成功",
                offset: 160,
                type: "success",
              });
              this.$emit("close");
              this.$emit("load");
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>