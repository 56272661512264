<template>
  <div class="h100" v-loading="loading">
    <div class="header_a">
      <div>{{val.tableRegionName}} {{val.tableName}}</div>
      <div>
        <span>{{val.tableStatusDesc}}</span>
        {{val.seatingCapacity}}座
      </div>
    </div>
    <div class="header_zin" v-if="flag">
      <div>
        <span>订餐人数:</span>
        <span>{{val.dinersCount}}人</span>
      </div>
      <div>
        <span>预约时间:</span>
        <span>{{val.reservedTime}}</span>
      </div>
      <div>
        <span>备注:</span>
        <span>{{val.reservedRemark}}</span>
      </div>
    </div>
    <div class="header_btn" v-if="But.DownDan.power()">
      <el-button type="primary" class="btn" @click="toorder">点餐</el-button>
      <el-button type="primary" class="btn_z" @click="Reserve" v-if="!flag">预定</el-button>
      <el-button type="primary" class="btn_red" @click="Reservedown" v-if="flag">取消预定</el-button>
    </div>
    <el-dialog
      title="点餐"
      :visible.sync="dialogVisible"
      width="450px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-order @close="closeorder" :tableId="val.tableId"></view-order>
    </el-dialog>
    <el-dialog
      title="预定"
      :visible.sync="dialogVisiblezin"
      width="450px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-serve @close="close" :tableId="val.tableId" @load="load"></view-serve>
    </el-dialog>
  </div>
</template>

<script>
import viewOrder from "../operation/order"; //点餐
import viewServe from "../operation/seserve"; //预定
export default {
  created() {
    this.changestatus();
  },
  watch: {
    val: function (val) {
      this.changestatus();
    },
  },
  components: {
    viewOrder,
    viewServe,
  },
  props: { val: Object },
  data() {
    return {
      But: window.UserPower.serve.statusopen,
      dialogVisible: false,
      dialogVisiblezin: false,
      flag: null,
      loading: false,
    };
  },
  methods: {
    changestatus() {
      if (this.val.tableStatus == "1") {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    load() {
      this.$emit("load");
      this.$emit("clear");
    },
    toorder() {
      this.dialogVisible = true;
    },
    Reserve() {
      this.dialogVisiblezin = true;
    },
    close(val) {
      this.dialogVisiblezin = false;
    },
    closeorder() {
      this.dialogVisible = false;
    },
    Reservedown() {
      this.$confirm(
        "是否取消预定桌位, 取消后桌位状态变为空闲，确认取消?",
        "取消预定",
        {
          confirmButtonText: "确认取消",
          cancelButtonText: "我再想想",
        }
      )
        .then(() => {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/table/reserved/cancel", {
              merchantId: localStorage.getItem("merchantId"),
              tableId: this.val.tableId,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                type: "success",
                message: "取消成功!",
              });
              this.$emit("load");
              this.$emit("clear");
            })
            .catch((error) => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已关闭",
          });
        });
    },
  },
};
</script>

<style scoped>
.header_a {
  width: 100%;
  height: 80px;
  color: #333333;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #dedede;
}
.header_a div {
  margin-left: 25px;
  font-size: 20px;
}
.header_a div:nth-of-type(2) {
  font-size: 14px;
  margin-top: 7px;
}
.header_a div:nth-of-type(2) span {
  color: #108af9;
}
.header_btn {
  padding: 20px 26px;
}
.header_btn button {
  width: 184px;
  height: 50px;
  background: #108af9;
  border-radius: 4px;
  border: none;
  margin-bottom: 16px;
  margin-left: 0px;
}
.btn_z {
  background: #ffae00 !important;
}
.btn_red {
  background: #f85959 !important;
}
.header_zin div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin: 0 26px;
  font-size: 14px;
  color: #999999;
  margin-bottom: 16px;
}
.header_zin div span:nth-of-type(1) {
  width: 60px;
  color: #999999;
  text-align: right;
}
.header_zin div span:nth-of-type(2) {
  word-break: break-all;
  margin-left: 5px;
  flex: 1;
}
.header_zin div:nth-of-type(1) {
  width: 200px;
  margin-top: 38px;
}
.header_zin div span {
  color: #333333;
}
</style>