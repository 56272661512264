<template>
  <div style="width:300px" v-loading="fullscreenLoading">
    <el-form
      autocomplete="off"
      size="mini"
      :model="ruleForm2"
      status-icon
      :rules="rules2"
      ref="ruleForm2"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="登陆账号:" prop>
        <span>{{loginName}}</span>
      </el-form-item>
      <el-form-item label="原密码:" prop="age">
        <el-input type="password" v-model="ruleForm2.age"></el-input>
      </el-form-item>
      <el-form-item label="新密码:" prop="pass">
        <el-input type="password" v-model="ruleForm2.pass" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码:" prop="checkPass">
        <el-input type="password" v-model="ruleForm2.checkPass" auto-complete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm2')">提交</el-button>
        <el-button @click="resetForm('ruleForm2')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { JSEncrypt } from "jsencrypt";
export default {
  created() {
    this.loginName = localStorage.getItem("loginName");
  },
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        callback(new Error("原密码不能为空"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm2.checkPass !== "") {
          this.$refs.ruleForm2.validateField("checkPass");
        }
        let rez = /^(?![^A-z]+$)(?!\D+$)[A-z\d]{8,20}$/;
        if (!rez.test(value)) {
          return callback(new Error("8-20位,支持数字和字母"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm2.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        let rez = /^(?![^A-z]+$)(?!\D+$)[A-z\d]{8,20}$/;
        if (!rez.test(value)) {
          return callback(new Error("8-20位,支持数字和字母"));
        }
        callback();
      }
    };
    return {
      fullscreenLoading: false,
      ruleForm2: {
        pass: "",
        checkPass: "",
        age: "",
        loginName: ""
      },
      rules2: {
        pass: [
          { validator: validatePass, trigger: "blur" },
          { min: 8, max: 20, message: "允许输入8到20位字符", trigger: "blur" }
        ],
        checkPass: [
          { validator: validatePass2, trigger: "blur" },
          { min: 8, max: 20, message: "允许输入8到20位字符", trigger: "blur" }
        ],
        age: [{ validator: checkAge, trigger: "blur" }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const enc = new JSEncrypt();
          enc.setPublicKey(
            "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDHostYAjUJLiwehBWnFesYUorfMICgXLcAPC3MApRyp/Qif5DvpodtVJSyMw5ztOeKaVnJ4aYmPQzcyjSoeW3NLY+3n9l4Yf5a4S7/U4jru2Sl1k6JPLlax3W8Oozb1SI4S3y0hhWf7ujO1fh15DaDH5BDIwaLNbvTQFeo9V1bswIDAQAB"
          );
          let rasPw = enc.encrypt(this.ruleForm2.age);
          let rasPwpasss = enc.encrypt(this.ruleForm2.checkPass);
          let data = {
            loginName: this.loginName,
            oldPassword: rasPw,
            newPassword: rasPwpasss
          };
          if (this.ruleForm2.age == this.ruleForm2.pass) {
            this.$message.error("新密码不能和原密码一样");
          } else {
            this.fullscreenLoading = true;
            this.$http
              .post("/order-admin/w/auth/passwd-update", data)
              .then(data => {
                this.$message.success("修改密码成功");
                this.$router.push({
                  path: "/login"
                });
                if (localStorage.getItem("checked")) {
                  localStorage.setItem("password", this.ruleForm2.checkPass);
                }
                this.$refs["ruleForm2"].resetFields();
                this.fullscreenLoading = false;
              })
              .catch(error => {
                this.fullscreenLoading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.demo-ruleForm {
  margin-top: 20px;
}
</style>