<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="会员姓名" prop="mermbername">
          <el-input v-model="formInline.mermbername" clearable></el-input>
        </el-form-item>
        <el-form-item label="会员手机号" prop="phone">
          <el-input v-model="formInline.phone" clearable></el-input>
        </el-form-item>
        <el-form-item label="会员类型" prop="memberType">
          <el-select
            v-model="formInline.memberType"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in typelist"
              :key="item.memberTypeId"
              :label="item.memberTypeName"
              :value="item.memberTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
          >查询</el-button
        >
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div></div>
          <div>
            
            <el-button
              v-if="But.Add.power()"
              type="primary"
              @click="addmember"
              size="mini"
              >添加会员</el-button
            >
            <el-button
              v-if="But.Export.power()"
              type="primary"
              @click="exportall"
              size="mini"
              >导出</el-button
            >
            <el-button v-if="But.Import.power()" @click="exportzin" size="mini"
              >批量导入</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
    >
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column prop="mermberName" label="姓名"></el-table-column>
      <el-table-column
        prop="phone"
        label="手机号"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="accountBalance"
        label="账户余额"
        width="120"
        v-if="openMemberStore == 1"
      >
        <template slot-scope="scope">{{
          scope.row.accountBalance | money
        }}</template>
      </el-table-column>
      <el-table-column prop="memberTypeName" label="会员类型"></el-table-column>
      <el-table-column
        prop="realNameFlag"
        label="是否实名认证"
        v-if="openMemberStore == 1"
      >
        <template slot-scope="scope">{{
          realNameFlag[scope.row.realNameFlag]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="memberCardNo"
        label="卡号"
        width="250"
      ></el-table-column>
      <el-table-column
        prop="createTm"
        label="创建时间"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="updateUser"
        label="操作人"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="updateTm"
        label="操作时间"
        width="160"
      ></el-table-column>
      <el-table-column prop="sourceIp" label="操作" width="250">
        <template slot-scope="scope">
          <el-button
            v-if="But.Edit.power()"
            type="text"
            @click="edit(scope.row.memberId)"
            >编辑</el-button
          >
          <el-button
            v-if="But.Detail.power()"
            type="text"
            @click="detail(scope.row.memberId)"
            >详情</el-button
          >
          <el-button
            type="text"
            v-if="But.Logout.power()"
            @click="distory(scope.row.memberId)"
            >注销</el-button
          >
          <el-button
            type="text"
            v-if="But.Editmoney.power()"
            @click="editmoney(scope.row)"
            >修改金额</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
    >
      <view-add
        @close="close"
        v-if="dialogVisible"
        :title="title"
        @search="search"
        :memberId="memberId"
      ></view-add>
    </el-dialog>

    <el-dialog
      title="导入"
      :visible.sync="Visibleupdatezin"
      width="450px"
      top="5vh"
      :close-on-click-modal="false"
    >
      <dialog-updata
        v-if="Visibleupdatezin"
        @close="tclose"
        @load="search"
        :type="type"
      ></dialog-updata>
    </el-dialog>

    <el-dialog
      title="修改金额"
      :visible.sync="dialogVisiblezin"
      width="450px"
      :close-on-click-modal="false"
      v-loading="loading1"
    >
      <el-form
        :inline="true"
        :model="formzine"
        class="demo-form-inline"
        size="mini"
        :rules="rules"
        ref="formzine"
      >
        <el-form-item label="账户余额:" class="addPlace" prop="accountBalance">
          <el-input v-model.trim="formzine.accountBalance">
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
        <el-form-item label="姓名:" class="addPlace" prop="mermberName">
          <span>{{ formzine.mermberName }}</span>
        </el-form-item>
        <br />
        <el-form-item label="手机号:" class="addPlace" prop="phone">
          <span>{{ formzine.phone }}</span>
        </el-form-item>
        <br />
        <el-form-item label="卡号:" class="addPlace placeCard" prop="memberCardNo">
          <span>{{ formzine.memberCardNo }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit" size="mini">保存</el-button>
        <el-button @click="dialogVisiblezin = false" size="mini"
          >取 消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import viewAdd from "./add.vue";
import viewDetail from "./detaile.vue";
import dialogUpdata from "./dialog.vue"; //导入
export default {
  created() {
    this.loadlist();
    this.searchzin();
  },
  components: {
    viewAdd,
    dialogUpdata,
    viewDetail,
  },
  data() {
    var numzin = (rule, value, callback) => {
      let rez = /^[0-9]{0,6}(\.[0-9]{1,2})?$/;
      if (!rez.test(value)) {
        return callback(new Error("支持0—999999之间"));
      }
      callback();
    };
    return {
      openMemberStore: localStorage.getItem("openMemberStore"),
      rules: {
        accountBalance: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" },
        ],
      },
      dialogVisiblezin: false,
      But: window.UserPower.Membership.Membership,
      dialogVisible: false,
      type: 1,
      Visibleupdatezin: false,
      formzine: {},
      formInline: {
        mermbername: "",
        phone: "",
        createTimeBegin: "",
        createTimeEnd: "",
        value1: null,
        authToken: localStorage.getItem("tokenIdzin"),
      },
      typelist: [],
      realNameFlag: {
        0: "未实名",
        1: "已实名",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      title: "添加会员",
      memberId: "",
      loading: false,
      loading1: false,
    };
  },
  methods: {
    onSubmit() {
      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          let Data = { ...this.formzine };
          Data.accountBalance = (Data.accountBalance * 100).toFixed(0);
          this.loading1 = true;
          this.$http
            .post("/order-admin/w/member/update/accountBalance", Data)
            .then((res) => {
              this.loading1 = false;
              this.$message({
                type: "success",
                message: "修改成功!",
              });
              this.dialogVisiblezin = false;
              this.search();
            })
            .catch((error) => {
              this.loading1 = false;
            });
        }
      });
    },
    editmoney(inner) {
      this.dialogVisiblezin = true;
      let data = { ...inner };
      data.accountBalance = data.accountBalance.money();
      this.formzine = data;
    },
    exportall() {
      this.formInline.createTimeBegin = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.createTimeEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.$http
        .post("/order-admin/w/member/export/excel", {
          ...this.formInline,
        })
        .then((res) => {
          window.open(res.data);
        });
    },
    distory(id) {
      this.$confirm("确认注销该会员吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$http
            .post("/order-admin/w/member/logoff", {
              memberId: id,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                type: "success",
                message: "注销成功!",
              });
              this.search();
            })
            .catch((error) => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    tclose() {
      this.Visibleupdatezin = false;
    },
    close() {
      this.dialogVisible = false;
    },
    edit(id) {
      this.dialogVisible = true;
      this.title = "修改会员";
      this.memberId = id;
    },
    detail(row) {
      this.$router.push({
        path: "huiyuandetail",
        query: { id: row },
      });
    },
    addmember() {
      this.dialogVisible = true;
      this.title = "添加会员";
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.createTimeBegin = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.createTimeEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/member/search", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.page.records;
          this.page.total = res.page.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    loadlist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/memberType/s/all", {})
        .then((res) => {
          this.loading = false;
          this.typelist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
    exportzin() {
      this.Visibleupdatezin = true;
    },
  },
};
</script>

<style scoped>
.addPlace {
  margin-bottom: 10px;
}
</style>
<style>
.placeCard .el-form-item__content{
  width: 200px;
}
</style>


