<template>
  <div class="view">
    <div class="view-contion" v-loading="fullscreenLoading">
      <div class="view-search">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="登录名" prop="loginName">
            <el-input v-model.trim="formInline.loginName" clearable></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="userName">
            <el-input v-model.trim="formInline.userName" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input v-model.trim="formInline.phone" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="formInline.status" placeholder="请选择" clearable>
              <el-option v-for="(v,k) in  equipment" :key="k" :label="v" :value="k"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="添加时间">
            <el-date-picker
              v-model="formInline.value1"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="notTodayPass"
            ></el-date-picker>
          </el-form-item>
          <el-form-item></el-form-item>
        </el-form>
        <div class="view-button">
          <el-button
            v-if="But.List.power()"
            type="primary"
            @click="searchMes"
            size="mini"
            class="serach"
          >查询</el-button>
          <span class="view-button-rest" @click="reset">重置筛选条件</span>
          <div class="int_view">
            <div>
              <el-button v-if="But.SwitchAble.power()" @click="statusarr(1)" size="mini">批量启用</el-button>
              <el-button v-if="But.SwitchAble.power()" @click="statusarr(3)" size="mini">批量禁用</el-button>
            </div>
            <div>
              <el-button v-if="But.Add.power()" type="primary" @click="addPeople" size="mini">添加员工</el-button>
            </div>
          </div>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="60" :selectable="checkboxT"></el-table-column>
        <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
        <el-table-column prop="loginName" label="登录名" width="120"></el-table-column>
        <el-table-column prop="userName" label="姓名" width="120"></el-table-column>
        <el-table-column prop="phone" label="手机号" width="120"></el-table-column>
        <el-table-column prop="role" label="角色" width="120"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">{{equipment[scope.row.status]}}</template>
        </el-table-column>
        <el-table-column prop="createTime" label="添加时间" width="160"></el-table-column>
        <el-table-column prop="sourceIp" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="But.Edit.power()&&(scope.row.flag!='1')"
              type="text"
              @click="edit(scope.row)"
            >编辑</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="禁用启用">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="3"
              @change="change($event,scope.row.userNo)"
              v-if="But.SwitchAble.power()&&(scope.row.flag!='1')"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
      <div class="view-page">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="formInline.pageNo"
          :page-size="formInline.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
          prev-text="上一页"
          next-text="下一页"
        ></el-pagination>
      </div>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="450px"
        :close-on-click-modal="false"
      >
        <el-form
          :inline="true"
          :model="formzine"
          class="demo-form-inline"
          size="mini"
          :rules="rules"
          ref="formzine"
        >
          <el-form-item label="登录名:" prop="loginName">
            <el-input v-model.trim="formzine.loginName" v-show="title!='编辑员工'"></el-input>
            <span v-show="title=='编辑员工'">{{formzine.loginName}}</span>
          </el-form-item>
          <el-form-item label="姓名:" prop="userName">
            <el-input v-model.trim="formzine.userName"></el-input>
          </el-form-item>
          <el-form-item label="手机号:" prop="phone">
            <el-input v-model.trim="formzine.phone"></el-input>
          </el-form-item>
          <el-form-item label="角色:" prop="roleNo">
            <el-select
              style="width:159px"
              v-model="formzine.roleNo"
              placeholder="请选择"
              @change="changeall"
              :disabled="flag"
            >
              <el-option
                v-for="item in roleList"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input v-model.trim="formzine.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="confirmPassword">
            <el-input v-model.trim="formzine.confirmPassword" type="password"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submite" size="mini">保存</el-button>
          <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { JSEncrypt } from "jsencrypt";
export default {
  created() {
    this.searchMes();
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value == "********") {
        callback();
      } else {
        if (this.formzine.confirmPassword !== "") {
          this.$refs.formzine.validateField("checkPass");
        }
        let rez = /^(?![0-9]+$)(?![a-zA-Z]+$)[a-z0-9A-Z]{8,20}$/;
        if (!rez.test(value)) {
          return callback(new Error("密码由8—20数字、字母组成"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formzine.password) {
        callback(new Error("两次输入密码不一致!"));
      } else if (value == "********") {
        callback();
      } else {
        let rez = /^(?![0-9]+$)(?![a-zA-Z]+$)[a-z0-9A-Z]{8,20}$/;
        if (!rez.test(value)) {
          return callback(new Error("密码由8—20数字、字母组成"));
        }
        callback();
      }
    };
    var loginName = (rule, value, callback) => {
      let rez = /^[a-z0-9A-Z]{2,20}$/;
      if (!rez.test(value)) {
        return callback(new Error("支持2—20个数字字母"));
      }
      callback();
    };
    var userName = (rule, value, callback) => {
      if (value) {
        let rez = /^[0-9a-zA-Z\u4e00-\u9fa5]+$/;
        if (!rez.test(value)) {
          return callback(new Error("支持2—10个字"));
        }
      }

      callback();
    };
    var phonezin = (rule, value, callback) => {
      if (value) {
        let rez = /^1[0-9]{10}$/;
        if (!rez.test(value)) {
          return callback(new Error("请输入正确的手机号"));
        }
      }
      callback();
    };
    return {
      But: window.UserPower.RestRoomSet.Staffmanagement.management,
      title: "",
      formInline: {
        loginName: "",
        userName: "",
        phone: "",
        status: "",
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10,
        value1: null,
      },
      equipment: {
        1: "启用",
        3: "禁用",
      },
      total: 0,
      flag: false,
      roleList: [],
      formzine: {
        loginName: "",
        userName: "",
        phone: "",
        roleNo: "",
        password: "",
        confirmPassword: "",
        userNo: "",
      },
      tableData: [],
      dialogVisible: false,
      fullscreenLoading: false,
      multipleSelection: [],
      rules: {
        loginName: [
          { required: true, message: "请输入登录名称", trigger: "blur" },
          { validator: loginName, trigger: "blur" },
        ],
        userName: [
          { min: 2, max: 10, message: "支持2—10个字", trigger: "blur" },
          { validator: userName, trigger: "blur" },
        ],
        phone: [{ validator: phonezin, trigger: "blur" }],
        roleNo: [
          {
            required: true,
            message: "请选择角色",
            trigger: ["change", "blur"],
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        confirmPassword: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    checkboxT(row, index) {
      if (row.flag == "1") {
        return false;
      } else {
        return true;
      }
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
    changeall() {
      this.$forceUpdate();
    },
    handleCurrentChange(val) {
      this.formInline.pageNo = val;
      this.load();
    },
    change(indexzin, userNo) {
      let inner = indexzin == 3 ? "禁用" : "启用";
      this.$http
        .post(`/order-admin/w/user/delete`, {
          userNo: userNo,
          status: indexzin,
        })
        .then((data) => {
          this.$message({
            message: `${inner}成功`,
            type: "success",
          });
          this.load();
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    statusarr(a) {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择要操作的内容",
          type: "warning",
        });
      } else {
        let inner = a == 1 ? "启用" : "禁用";
        this.$confirm(`此操作将批量${inner}?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let userNo = "";
            for (let i in this.multipleSelection) {
              userNo += this.multipleSelection[i].userNo + ",";
            }
            this.fullscreenLoading = true;
            this.$http
              .post(`/order-admin/w/user/delete`, {
                userNo: userNo,
                status: a,
              })
              .then((d) => {
                this.fullscreenLoading = false;
                this.$message({
                  message: `${inner}成功`,
                  offset: 160,
                  type: "success",
                });
                this.load();
              })
              .catch((error) => {
                this.fullscreenLoading = false;
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              offset: 160,
              message: "已取消",
            });
          });
      }
    },
    searchMes() {
      this.formInline.pageNo = 1;
      this.load();
    },
    load() {
      this.formInline.startTime = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.endTime = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.fullscreenLoading = true;
      this.$http
        .post(`/order-admin/w/user/search`, { ...this.formInline })
        .then((d) => {
          this.fullscreenLoading = false;
          this.tableData = d.page.records;
          for (let i in this.tableData) {
            this.tableData[i].status = +this.tableData[i].status;
          }
          this.total = +d.page.total;
        })
        .catch((error) => {
          this.fullscreenLoading = false;
        });
    },
    rolelist() {
      this.$http.get("/order-admin/w/role/list", {}).then((d) => {
        this.roleList = d.roleList;
        // if (this.title == "编辑员工") {
        //   let item = { roleId: "0", roleName: "管理员" };
        //   this.roleList.unshift(item);
        // }
      });
    },
    addPeople() {
      this.rolelist();
      this.dialogVisible = true;
      this.title = "添加员工";
      for (let i in this.formzine) {
        this.formzine[i] = "";
      }
      this.flag = false;
      this.$refs["formzine"] && this.$refs["formzine"].resetFields();
    },
    indexMethod(index) {
      return (
        (this.formInline.pageNo - 1) * this.formInline.pageSize + index + 1
      );
    },
    submite() {
      const enc = new JSEncrypt();
      enc.setPublicKey(
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDHostYAjUJLiwehBWnFesYUorfMICgXLcAPC3MApRyp/Qif5DvpodtVJSyMw5ztOeKaVnJ4aYmPQzcyjSoeW3NLY+3n9l4Yf5a4S7/U4jru2Sl1k6JPLlax3W8Oozb1SI4S3y0hhWf7ujO1fh15DaDH5BDIwaLNbvTQFeo9V1bswIDAQAB"
      );
      let data = { ...this.formzine };
      let url = "";
      this.title == "添加员工"
        ? (url = "/order-admin/w/user/create")
        : (url = "/order-admin/w/user/modify");
      if (this.formzine.password == "********") {
        data.password = "";
        data.confirmPassword = "";
      } else {
        data.password = enc.encrypt(this.formzine.password);
        data.confirmPassword = enc.encrypt(this.formzine.confirmPassword);
      }

      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          this.fullscreenLoading = true;
          this.$http
            .post(`${url}`, { ...data })
            .then((data) => {
              this.fullscreenLoading = false;
              this.$message({
                message: `${this.title}成功`,
                type: "success",
              });
              this.load();
              this.dialogVisible = false;
            })
            .catch((error) => {
              this.fullscreenLoading = false;
            });
        }
      });
    },
    edit(a) {
      this.$refs["formzine"] && this.$refs["formzine"].resetFields();
      this.rolelist();
      if (a.flag != "1") {
        this.flag = false;
      } else {
        this.flag = true;
      }
      this.dialogVisible = true;
      this.title = "编辑员工";
      this.fullscreenLoading = true;
      this.$http
        .post("/order-admin/w/user/detail", { userNo: a.userNo })
        .then((data) => {
          this.fullscreenLoading = false;
          data.password = "********";
          data.confirmPassword = "********";
          this.formzine = data;
          this.formzine.userNo = a.userNo;
          if (data.roleId == "0") {
            let item = { roleId: "0", roleName: "管理员" };
            this.roleList.push(item);
          }
          this.formzine.roleNo = data.roleId;
        })
        .catch((error) => {
          this.fullscreenLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.view {
  position: relative;
}
</style>
