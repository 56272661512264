// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "babel-polyfill";
import Vue from "vue";
//import "@/common/onerror.js";
import App from "./App";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueRouter from "vue-router";
import router from "@/router";
import "./assets/css/base.css";
import "./assets/css/style.css";
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
import exportFile from '@/components/exportFile.vue';
import formatDate from '@/common/date.js'
import store from "@/store/index.js";
import axios from "./http.js";
import './config/UserPower.js';
import { notTodayPass } from "@/common/mixins.js";
router.beforeEach((to, from, next) => {
  if (to.path === "/") {
    if (!axios.defaults.headers.post["jwt-ov3"]) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Croppa);
Vue.use(VueRouter);
Vue.filter('formatDate', formatDate);
Vue.prototype.$http = axios;
Vue.component('exportFile', exportFile);
Number.prototype.power = String.prototype.power = function () {
  if (window.PowerButtons) {
    return window.PowerButtons.indexOf(this) >= 0;
  } else {
    return false;
  }
};
Number.prototype.money = String.prototype.money = function () {
  if (this === "") return this;
  return (this / 100).toFixed(2);
};
Vue.filter('money', function (value) {
  if (value) {
    return value.money()
  } else {
    return 0;
  }
})
Vue.mixin({
  data() {
    return {
      notTodayPass: {
        disabledDate: (time) => {
          if (notTodayPass(time)) {
            return true;
          }
        },
      },
    }
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");