<template>
  <div class="box" v-loading="loading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" size="mini">
      <div class="hr_title">特价菜品</div>
      <el-form-item label="特价时间" prop="value1">
        <el-date-picker :picker-options="pickerOptions" v-model="ruleForm.value1" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"></el-date-picker>
      </el-form-item>
      <el-form-item label="菜品名称" prop="dishersName">
        <el-select v-model="ruleForm.dishersName" filterable placeholder="请输入菜品名称" value-key="dishersId" @change="alllistseven">
          <el-option v-for="item in tableData1" :key="item.dishersId" :label="item.dishersName" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="菜品份量" v-if="flag" prop="priceId">
        <el-select v-model="ruleForm.priceId" placeholder="请选择" clearable @change="pricechange">
          <el-option v-for="item in equipmentlist" :key="item.priceId" :label="item.priceUnitName" :value="item.priceId"></el-option>
        </el-select>
      </el-form-item>
      <div style="margin:0 0 20px 100px">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 60%" size="mini" highlight-current-row>
          <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
          <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
          <el-table-column prop="disherUnitPriceSearchVoList[0].priceType" label="菜品单位">
            <template slot-scope="scope">{{options1[scope.row.disherUnitPriceSearchVoList[0].priceType]}}</template>
          </el-table-column>
          <el-table-column prop="disherUnitPriceSearchVoList[0].price" label="单价">
            <template slot-scope="scope">{{scope.row.disherUnitPriceSearchVoList[0].price|money}}</template>
          </el-table-column>
          <el-table-column prop="disherUnitPriceSearchVoList[0].memberPrice" label="会员价">
            <template slot-scope="scope">{{scope.row.disherUnitPriceSearchVoList[0].memberPrice|money}}</template>
          </el-table-column>
        </el-table>
      </div>
      <el-form-item label="特价价格" prop="bargainPrice">
        <el-input class="input-width" placeholder="请输入金额" v-model="ruleForm.bargainPrice">
          <template slot="prepend">￥</template>
        </el-input>
      </el-form-item>
      <el-form-item label="限量份数" prop="currentPriceCount" title="请输入1-100的整数">
        <el-input-number v-model="ruleForm.currentPriceCount" :min="1" :max="100"></el-input-number>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.alllist();
  },
  data() {
    let validator100 = (rule, value, callback) => {
      if (!/^\d{1,3}$/.test(value)) {
        return callback(new Error("请输入1-100整数"));
      }
      if (+value == 0 || +value > 100) {
        return callback(new Error("请输入1-100整数"));
      }
      callback();
    };
    var numall = (rule, value, callback) => {
      if (+value <= 0) {
        return callback(new Error("大于0"));
      }
      if (this.priceType != "3") {
        if (+value >= this.price / 100) {
          return callback(new Error("小于原价"));
        }
      }
      callback();
    };
    return {
      loading: false,
      pickerOptions: {
        // 限制时间不让选择今天以前的
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      options1: {
        0: "常规计价",
        // 1: "称重计价",
        2: "份量计价",
        3: "时价",
      },
      equipmentlist: [],
      list: [],
      ruleForm: {
        dishersName: "",
        value1: "",
        bargainStartTm: "",
        bargainEndTm: "",
        dishersId: "",
        bargainPrice: "",
        priceId: "",
        categoryId: "",
        isContainTimeDishes: "0",
        currentPriceCount: "1",
      },
      rules: {
        bargainPrice: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numall, trigger: "blur" },
        ],
        dishersName: [
          { required: true, message: "必填", trigger: ["blur", "change"] },
        ],
        priceId: [{ required: true, message: "必填", trigger: "blur" }],
        value1: [
          { required: true, message: "请选择活动日期", trigger: "blur" },
        ],
        currentPriceCount: [
          { required: true, message: "必填项", trigger: "blur" },
        ],
      },
      tableData: [],
      flag: false,
      price: 0,
      tableData1: [],
      priceType: "",
      obj: {},
    };
  },
  methods: {
    alllist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/disher/s/page", {
          dishersName: this.ruleForm.dishersName,
          isContainTimeDishes: this.ruleForm.isContainTimeDishes,
          pageNo: 1,
          pageSize: 100000000,
        })
        .then((res) => {
          this.loading = false;
          this.tableData1 = res.data.records;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    alllistseven(val) {
      this.ruleForm.dishersId = val.dishersId;
      this.ruleForm.priceId = val.disherUnitPriceSearchVoList[0].priceId;
      this.ruleForm.categoryId = val.categoryId;
      this.tableData = [];
      this.tableData.push(val);
      this.equipmentlist = JSON.parse(
        JSON.stringify(this.tableData[0].disherUnitPriceSearchVoList)
      );
      this.price = this.tableData[0].disherUnitPriceSearchVoList[0].price;
      this.priceType =
        this.tableData[0].disherUnitPriceSearchVoList[0].priceType;
      if (this.priceType == 2 || this.priceType == 1) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    pricechange(val) {
      for (let i in this.equipmentlist) {
        if (this.equipmentlist[i].priceId == val) {
          this.obj = this.equipmentlist[i];
        }
      }
      this.price = this.obj.price;
      this.tableData[0].disherUnitPriceSearchVoList[0].price = this.obj.price;
      this.tableData[0].disherUnitPriceSearchVoList[0].memberPrice =
        this.obj.memberPrice;
    },
    onSubmit() {
      this.ruleForm.bargainStartTm = this.ruleForm.value1
        ? this.ruleForm.value1[0]
        : "";
      this.ruleForm.bargainEndTm = this.ruleForm.value1
        ? this.ruleForm.value1[1]
        : "";
      let datainner = { ...this.ruleForm };
      datainner.bargainPrice = +(datainner.bargainPrice * 100).toFixed(2);
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/order-admin/w/disher/bargain/add", datainner)
            .then((res) => {
              this.loading = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.$router.push({
                path: "shareProfitAdminHis",
                query: { type: 1 },
              });
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.input-width {
  width: 170px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.hr_title {
  margin-bottom: 20px;
}
.footer {
  width: 100%;
  position: fixed;
  height: 60px;
  bottom: 2px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.box {
  padding-bottom: 60px;
}
</style>
