import { render, staticRenderFns } from "./guazhang.vue?vue&type=template&id=09803419&scoped=true&"
import script from "./guazhang.vue?vue&type=script&lang=js&"
export * from "./guazhang.vue?vue&type=script&lang=js&"
import style0 from "./guazhang.vue?vue&type=style&index=0&id=09803419&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09803419",
  null
  
)

export default component.exports