<template>
  <div class="h200">
    <el-tabs class="h100" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first" class="h100">
        <view-info class="h100" :activeName="activeName" ref="viewInfo" @handleClick="handleClick"></view-info>
      </el-tab-pane>
      <el-tab-pane label="基本设置" name="second" class="h100">
        <view-set class="h100" ref="viewSet" @handleClick="handleClick"></view-set>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import viewInfo from "./info"; //基本信息
import viewSet from "./set"; //基本设置
export default {
  mounted() {
    this.$refs.viewInfo.load();
  },
  components: {
    viewInfo,
    viewSet,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(val) {
      if (val == "first") {
        this.$refs.viewInfo.load();
      } else {
        this.$refs.viewSet.load();
      }
    },
  },
};
</script>

<style scoped>
</style>