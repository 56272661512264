<template>
  <div :loading="loading">
    <el-form
      size="mini"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="姓名" prop="pendingOrderUserName">
        <el-input
          v-model="ruleForm.pendingOrderUserName"
          placeholder="请输入姓名"
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="pendingOrderPhone">
        <el-input
          v-model="ruleForm.pendingOrderPhone"
          placeholder="请输入手机号"
          maxlength="11"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="pendingOrderRemark">
        <el-input
          v-model="ruleForm.pendingOrderRemark"
          type="textarea"
          :rows="2"
          placeholder="请输入备注"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <div class="text-right">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button size="mini" type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  created() {
    this.ruleForm.orderNo = this.$route.query.orderNo;
    this.loading_();
  },
  data() {
    let mobleValite = (rule, value, callback) => {
      if (value === "") {
        return callback();
      }
      let moblieReg = /^1\d{10}$/;
      if (!moblieReg.test(value)) {
        return callback(new Error("请输入正确的手机号！"));
      }
      return callback();
    };
    return {
      loading: false,
      ruleForm: {
        orderNo: "",
        pendingOrderUserName: "",
        pendingOrderPhone: "",
        pendingOrderRemark: "",
      },
      rules: {
        pendingOrderUserName: [
          { required: true, message: "必填项", trigger: "blur" },
        ],
        pendingOrderPhone: [
          { required: true, message: "必填项", trigger: "blur" },
          { validator: mobleValite, trigger: "blur" },
        ],
      },
      formdata: null,
    };
  },
  methods: {
    loading_() {
      this.$http
        .post("/cashier-api/api/order/getOnAccountMessage", {
          orderNo: this.ruleForm.orderNo,
        })
        .then((res) => {
          let data = res;
          if (data.pendingOrderPhone) {
            this.ruleForm.pendingOrderUserName = data.pendingOrderUserName;
            this.ruleForm.pendingOrderPhone = data.pendingOrderPhone;
            this.ruleForm.pendingOrderRemark = data.pendingOrderRemark || "";
            this.formdata = { ...this.ruleForm };
          }
        });
    },
    getData() {
      return this.formdata;
    },
    close() {
      this.$emit("close");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.$http.post("/cashier-api/api/order/onAccount", this.ruleForm).then(
          (res) => {
            this.formdata = { ...this.ruleForm };
            this.loading = false;
            this.$emit("close");
            this.$refs[formName].resetFields();
          },
          (e) => {
            this.loading = false;
          }
        );
      });
    },
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}
</style>