<template>
  <div class="h100">
    <el-tabs class="h100" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-if="But.viewInfo.power.power()" label="菜品分类" name="first" class="h200">
        <view-info ref="viewInfo" class="h100"></view-info>
      </el-tab-pane>
      <el-tab-pane v-if="But.viewSet.power.power()" label="计价单位" name="second" class="h200">
        <view-set ref="viewSet" class="h100" :activeName="activeName"></view-set>
      </el-tab-pane>
      <el-tab-pane v-if="But.viewEat.power.power()" label="做法口味" name="third" class="h200">
        <view-eat ref="viewEat" class="h100"></view-eat>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import viewInfo from "./variety"; //菜品分类
import viewSet from "./Valuation"; //计价单位
import viewEat from "./practice"; //做法口味
export default {
  mounted() {
    this.$refs.viewInfo.search();
  },
  components: {
    viewInfo,
    viewSet,
    viewEat,
  },
  data() {
    return {
      But: window.UserPower.variety.classification,
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {
      this.$refs.viewInfo.search();
      this.$refs.viewSet.load();
      this.$refs.viewEat.load();
    },
  },
};
</script>

<style scoped>
</style>
