import Vue from 'vue'
import Vuex from "vuex"
import createPersistedState from 'vuex-persistedstate'
import shopingCard from "./shopingCard.js"
Vue.use(Vuex);
var store = new Vuex.Store({
    modules: { shopingCard },
    state: {
        manageImgUrl: "",
        restaurantName: "",
        showImg: true,
    },
    mutations: {
        geturl(state, val) {
            state.manageImgUrl = val
        },
        getname(state, val) {
            state.restaurantName = val
        },
        setShowImg(state, val) {
            state.showImg = val;
        }
    },
    getters: {

    },
    plugins: [createPersistedState({
        reducer(val) {
            return {
                manageImgUrl: val.manageImgUrl,
                restaurantName: val.restaurantName,
                showImg: val.showImg
            }
        }
    })]
})
export default store