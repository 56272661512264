<template>
  <div>
    <div class="warning">温馨小提示：鼠标点击拖拽即可改变顺序</div>
    <div class="list">
      <div class="li li-head">
        <span class="xuhao">序号</span>
        <span class="name">分类名称</span>
        <span class="oper center">排序操作</span>
      </div>
      <draggable v-model="myArray">
        <transition-group>
          <div
            class="li"
            v-for="(element, index) in myArray"
            :key="element.categoryId"
          >
            <span class="xuhao">{{ index + 1 }}</span>
            <span class="name"> {{ element.categoryName }}</span>
            <span class="oper center">
              <img src="@/assets/image/icon_paixun.png" alt="" />
            </span>
          </div>
        </transition-group>
      </draggable>
    </div>
    <br />
    <div class="center">
      <el-button type="primary" @click="onSubmit" size="mini">保存</el-button>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
let myArrayWaibu = [];
export default {
  components: {
    draggable,
  },
  created() {
    this.loadClassList();
  },
  data() {
    return {
      myArray: [],
    };
  },
  methods: {
    loadClassList() {
      this.$http.post("/order-admin/w/disher/category/s/all").then((res) => {
        this.myArray = res.data;
        myArrayWaibu = [...res.data];
      });
    },
    getCloseStatus() {
      for (let i = 0; i < myArrayWaibu.length; i++) {
        if (myArrayWaibu[i].categoryId != this.myArray[i].categoryId) {
          this.$message({
            message: "请保存菜品分类操作结果！",
            type: "warning",
          });
          return false;
        }
      }
      return true;
    },
    onSubmit() {
      let data = this.myArray.map((v, index) => {
        return {
          displayOrder: index + 1,
          categoryId: v.categoryId,
        };
      });
      this.$http
        .post("/order-admin/w/disher/category/sort", { categoryInfos: data })
        .then((res) => {
          this.$emit("load");
          this.$emit("close");
          this.$message({
            message: "保存成功",
            type: "success",
          });
        });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.warning {
  color: #f85959;
  margin-bottom: 20px;
  font-size: 14px;
}
.li-head {
  background: #fafafa;
  font-weight: bold;
}
.list {
  max-height: 700px;
  overflow: auto;
  font-size: 14px;
}
.li {
  padding: 15px 0;
  cursor: pointer;
  transition: all 0.2s;
  text-indent: 10px;
  border: 1px solid #ecedee;
  border-bottom: none;
  display: flex;
}
.li:last-child {
  border-bottom: 1px solid #ecedee;
}
.li .xuhao {
  width: 50px;
}
.li .name {
  flex: 1;
}
.li .oper {
  width: 100px;
}
.li .oper img {
  width: 16px;
}
</style>