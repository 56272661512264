<template>
  <div class="view_contant" v-loading="loading">
    <el-scrollbar style="height:100%">
      <div style="width:99%;position:relative">
        <div class="contant-box">
          <div class="contant-col flex1">
            <div class="contant-tilte">金额汇总</div>
            <div class="contant-body">
              <div class="contant-li">
                <img class="money-img" src="@/assets/image/img_dingdanjine.png" alt="">
                <div>
                  <div class="contant-explain">
                    订单金额(元)
                  </div>
                  <div class="contant-value">
                    {{ orderVo.shouldPaymentAmount | money }}
                  </div>
                </div>
              </div>
              <div class="contant-li">
                <img class="money-img" src="@/assets/image/img_yingshoujine.png" alt="">
                <div>
                  <div class="contant-explain">
                    应收金额(元)
                  </div>
                  <div class="contant-value">
                    {{ orderVo.shouldReceiveAmount | money }}
                  </div>
                </div>
              </div>
              <div class="contant-li">
                <img class="money-img" src="@/assets/image/img_youhuijine.png" alt="">
                <div>
                  <div class="contant-explain">
                    优惠金额(元)
                  </div>
                  <div class="contant-value">
                    {{ orderVo.discountsAmount | money }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contant-box">
          <div class="contant-col flex1">
            <div class="contant-tilte">其它金额</div>
            <div class="contant-body">
              <div class="contant-li">
                <div>
                  <div class="contant-explain">
                    桌位费(元)
                  </div>
                  <div class="contant-value">
                    {{ orderVo.restaurantSeatFeeAmount | money }}
                  </div>
                  <div class="money-unit">
                    {{ mer.restaurantSeatFee | money}}元/人
                  </div>
                </div>
              </div>
              <div class="contant-li">
                <div>
                  <div class="contant-explain">
                    打包费(元)
                  </div>
                  <div class="contant-value">
                    {{ orderVo.packingFeeAmount | money }}
                  </div>
                  <div class="money-unit">
                    {{ mer.packingFee | money}}元/个
                  </div>
                </div>
              </div>
              <div class="contant-li">
                <div>
                  <div class="contant-explain">
                    餐具费(元)
                  </div>
                  <div class="contant-value">
                    {{ orderVo.tablewareFeeAmount | money }}
                  </div>
                  <div class="money-unit">
                    {{ mer.tablewareFee | money}}元/人
                  </div>
                </div>
              </div>
              <div class="contant-li">
                <div>
                  <div class="contant-explain">
                    菜品原价(元)
                  </div>
                  <div class="contant-value">
                    {{ orderVo.orderOriginAmount | money }}
                  </div>
                </div>
              </div>
              <div class="contant-li">
                <div>
                  <div class="contant-explain">
                    菜品会员价(元)
                  </div>
                  <div class="contant-value">
                    {{ orderVo.orderMemberAmount | money }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contant-box">
          <div class="contant-col col-width margin-right-120">
            <div class="contant-tilte">订单信息</div>
            <div class="contant-body">
              <div class="margin-left-40">
                <div class="contant-explain">
                  订单编号
                </div>
                <div class="order-no" style="width:190px">
                  {{ orderVo.orderNo}}
                </div>
              </div>
            </div>
          </div>
          <div class="contant-col">
            <div class="contant-tilte">&nbsp;</div>
            <div class="contant-body">
              <div class="margin-left-40">
                <div class="contant-explain">
                  整单备注
                </div>
                <div class="order-no">
                  {{ orderVo.orderRemark||"无"}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contant-box">
          <div class="contant-col col-width">
            <div class="contant-tilte">订单抹零</div>
            <div class="contant-body">
              <div class="margin-left-40">
                <div class="contant-explain">
                  抹零金额(元)
                </div>
                <div class="order-count-amount">
                  <div>￥</div>
                  <input :disabled="mer.openReduce != '1'" type="text" v-model="obj.notCountAmount" @blur="notCountAmountChange" placeholder="请输入抹零金额">
                </div>
                <div v-if="notCountAmountError" style="color:red">{{notCountAmountError}}</div>
              </div>
            </div>
          </div>
          <div class="contant-col flex1">
            <div class="contant-tilte">选择优惠</div>
            <div class="contant-body">
              <div class="margin-left-40 flex1">
                <div class="order-activity mr20">
                  <el-radio-group v-model="obj.activityType" @change="changeradio">
                    <el-radio label="1">不使用优惠</el-radio>
                    <template v-if="mer.openCommonMermber=='1'">
                      <el-popover placement="top" width="380" trigger="click" v-if="obj.username">
                        <table class="vip-table">
                          <tr>
                            <td>会员帐号</td>
                            <td>会员名称</td>
                            <td>操作</td>
                          </tr>
                          <tr>
                            <td>{{obj.mobile}}</td>
                            <td>{{obj.username}}</td>
                            <td class="rest-vail-btn" @click="restVailVip">重新验证</td>
                          </tr>
                        </table>
                        <span slot="reference">
                          <el-radio label="5" style="margin-right:5px">会员</el-radio>
                          <span class="rest-vail-btn" style="margin-right:30px;font-size:12px">点击查看</span>
                        </span>
                      </el-popover>
                      <el-radio v-else label="5">会员</el-radio>
                    </template>
                    <el-radio :label="item.activityType" v-for="item in activityList" :key="item.activityType" :disabled="!item.usable">{{item.activityName}}</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contant-box">
          <div class="contant-col">
            <div class="contant-tilte">选择支付方式</div>
            <div class="contant-body">
              <div style="margin-left:40px;display: flex;flex-wrap: wrap;">
                <div class="payment-type" v-for="(item, idx) in paymentlist" @click="cashpayment(item.name, idx, item.id)" :key="item.id" :class="idx == index2 ? 'active' : ''" v-show="
                      item.id != 6 ||
                      (mer.openMemberStore == 1 && obj.username)
                    ">
                  <img class="pay-icon" :src="item.icon" alt="" />
                  <span>{{ item.name }}</span>
                  <img src="@/assets/image/icon_duigou_1.png" class="duigou" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br> <br> <br>
      </div>
    </el-scrollbar>
    <div class="footer_zin">
      <div>
        <span class="footer_btn">
          <el-button class="actclass" type="primary" size="mini" @click="backOrder" v-if="orderMode==2">返回餐台</el-button>
        </span>
        <template v-if="orderMode==1">
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <span class="dayinReceipt" v-if="mer.paymentMode != '1'">打印至厨房</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleCommand">打印整单</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button class="btn" @click="printBeforeOrder">打印预结单</el-button>
        </template>
      </div>
      <div class="footer_btn">
        <el-button type="primary" size="mini" @click="orderpay" class="actclass" :disabled="disabled1">确认结账</el-button>
      </div>
    </div>
    <el-dialog :title="obj.inner" :visible.sync="dialogVisible" width="450px" :close-on-click-modal="false" :destroy-on-close="true">
      <payCard @close="closeorder" @pay="pay" @cancal="dialogVisible=false" @detail="detail" ref="vieworderzin" :obj="obj" />
    </el-dialog>
    <el-dialog :showClose="showbtnclose" title="扫码支付" :visible.sync="dialogVisible1" width="450px" :close-on-click-modal="false" :destroy-on-close="true">
      <Result @close="closeresult" :obj="obj" @closebtn="closebtn" @cancal="dialogVisible1=false" />
    </el-dialog>
    <el-dialog title="挂账信息" :visible.sync="dialogVisibleGua" width="450px" :close-on-click-modal="false" :destroy-on-close="true">
      <guaZhang ref="gua" @close="dialogVisibleGua = false" />
    </el-dialog>
    <el-dialog title="会员验证" :visible.sync="dialogVisibleVip" width="450px" :close-on-click-modal="false" :destroy-on-close="true">
      <verifyVip @setMember="member" @close="dialogVisibleVip=false" />
    </el-dialog>
    <cancal-pay :detail="orderVo" @load="detail" @colseAllDialog="colseAllDialog" />
  </div>
</template>
<script>
import number from "@/common/num.js";
import verifyVip from "./operationPay/verifyVip.vue"; //挂账
import guaZhang from "./operationPay/guazhang.vue"; //挂账
import payCard from "./operationPay/paycard.vue"; //支付方式
import Result from "./operationPay/result.vue"; //扫码支付
import cancalPay from "./operationPay/cancalPay.vue"; //取消支付
import { mapState, mapActions, mapMutations } from "vuex";
import Bus from "@/common/bus.js";
import mqtt, { getMerchantInfoVo } from "../../mqtt.js";
export default {
  props: ["orderNo", "orderMode"],
  components: { guaZhang, payCard, Result, verifyVip, cancalPay },
  async created() {
    this.obj.orderNo = this.$route.query.orderNo;
    this.obj.tableId = this.$route.query.tableId;
    this.mer = await this.getMerchantInfo();
    this.detail();
    this.getActivityList();
    Bus.$on("restCountMoney", () => {
      this.detail();
    });
    Bus.$on("restActivity", () => {
      this.getActivityList();
    });
    if (this.obj.tableId) {
      this.MQTT = mqtt({
        themeNo: getMerchantInfoVo().merchantId + "-" + this.orderNo,
        messageFn: (body) => {
          if (body.indexPage == 10) {
            let query = { ...this.$route.query };
            query.tableId = body.tableId;
            return this.$router.replace({
              path: "/selectMenu",
              query,
            });
          }
          if (body.indexPage == 8 || body.indexPage == 9) {
            return this.$router.replace({
              path: "/selectTable",
            });
          }
          if (body.indexPage == 3 || body.indexPage == 2) {
            this.$router.replace({
              path: "/selectMenu",
              query: {
                orderNo: body.orderNo,
                orderMode: this.orderMode,
                tableId: this.obj.tableId,
              },
            });
            return;
          }
          if (body.indexPage == 4 || body.indexPage == 5) {
            this.detail();
            this.getShopingCard({ orderNo: this.obj.orderNo });
            return;
          }
        },
      });
    }
  },
  beforeDestroy() {
    Bus.$off("restCountMoney");
    Bus.$off("restActivity");
    this.MQTT && this.MQTT.end();
  },
  computed: mapState({
    disherData: (state) => state.shopingCard.shopingCardList,
  }),
  data() {
    return {
      activityList: [],
      notCountAmountRadio: "0",
      mer: {},
      /*********/
      notCountAmountError: false,
      dialogVisibleVip: false,
      dialogVisibleGua: false,
      crmMobile: "",
      openCommonMermbera: "",
      CrmVaildate: 0,
      typezin: "3",
      showbtnclose: true,
      loading: false,
      dialogVisible3: false,
      flag3: false,
      disabled1: true,
      dialogVisible2: false,
      disabled: false,
      disadle: false,
      obj: {
        orderNo: "",
        tableId: "",
        inner: "",
        notCountAmount: "",
        activityType: "1",
        activityNo: "",
        memberpayment: "0",
        username: "",
        phone: "",
        mobile: "",
        paymentType: "",
        shouldReceiveAmount: "",
      },
      dialogVisible: false,
      dialogVisible1: false,
      paymentlist: [
        {
          id: 1,
          name: "现金支付",
          icon: require("@/assets/image/icon_xianjinzhifu@2x.png"),
        },
        {
          id: 2,
          name: "扫码支付",
          icon: require("@/assets/image/icon_saomazhifu@2x.png"),
        },
        {
          id: 3,
          name: "刷卡支付",
          icon: require("@/assets/image/icon_shuakazhifu@2x.png"),
        },
        {
          id: 4,
          name: "其他支付",
          icon: require("@/assets/image/icon_qitazhifu@2x.png"),
        },
        {
          id: 5,
          name: "免单",
          icon: require("@/assets/image/icon_miandan@2x.png"),
        },
        {
          id: 10,
          name: "挂账",
          icon: require("@/assets/image/guazhang.png"),
        },
        {
          id: 6,
          name: "账户余额支付",
          icon: require("@/assets/image/icon_yuezhifu@2x.png"),
        },
      ],
      reduction: [],
      discount: "",
      orderVo: {},
      radio1: 1,
      index2: null,
      flag: false,
      flag1: false,
      flag2: true,
      index1: 0,
      act: false,
      resultobj: {},
      printKinds: [],
      printTypes: [],
      printKindsi: "",
      IScrmMobile: false,
    };
  },
  watch: {
    "obj.notCountAmount"(value) {},
  },
  methods: {
    ...mapActions(["getShopingCard"]),
    ...mapMutations(["setShopingCardVip"]),
    notCountAmountChange() {
      let value = this.obj.notCountAmount;
      this.notCountAmountError = false;
      if (value != "") {
        let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
        if (!rez.test(value)) {
          this.obj.notCountAmount = "";
          this.notCountAmountError = "支持0.01—999999.99之间";
        }
        if (value >= this.orderVo.shouldReceiveAmount / 100) {
          this.obj.notCountAmount = "";
          this.notCountAmountError = "抹零金额不能大于等于应收金额";
        }
      }
    },
    async getMerchantInfo() {
      let { merchantInfoVo } = await this.$http.get(
        `/order-admin/w/base/merchantInfo`
      );
      return merchantInfoVo;
    },
    closeresult() {
      this.dialogVisible1 = false;
      this.paymentSuccess();
    },
    closeorder() {
      this.dialogVisible = false;
      this.paymentSuccess();
    },
    async paymentSuccess() {
      try {
        await this.$confirm("支付成功！", "提示", {
          confirmButtonText: "关闭并打印支付凭证",
          cancelButtonText: "关闭不打印",
          type: "success",
        });
        await this.printOrder({ printTypes: ["1", "3"], printKinds: ["3"] });
      } finally {
        this.successAfterPush();
      }
    },
    successAfterPush() {
      if (this.orderMode == 1) {
        return this.$router.push("/selectTable");
      }
      return this.$router.push("/notTable");
    },
    handleCommand() {
      this.printOrder({
        printTypes: ["2", "3"],
        printKinds: ["0"],
        orderPrintType: 2,
      });
    },
    printBeforeOrder() {
      this.printOrder({
        printTypes: ["1", "3"],
        printKinds: ["4"],
        orderPrintType: 1,
      });
    },
    async printOrder({ printTypes, printKinds, orderPrintType }) {
      // printTypes-打印机类型:1前台，2后台，3公用。
      // printKinds-打印菜单类型:0-点菜单，1-加菜单，2-退菜单，3-结账单，4-预结单
      // orderPrintType?-打印指定类型:1-前台打印机-点餐单，预结单,  2-后厨打印机-点餐单-厨房单
      let data = {
        orderNo: this.orderNo,
        printTypes,
        printKinds,
        orderPrintType,
      };
      await this.$http.post("/cashier-api/api/print/getPrintMsg", data);
      this.$message({
        message: "打印成功",
        type: "success",
      });
    },
    cashpayment(val, idx, id) {
      this.index2 = idx;
      this.obj.inner = val;
      this.obj.paymentType = id;
      if (id == 10) {
        this.dialogVisibleGua = true;
      }
      if (this.disherData.length < 1 || this.orderVo.shouldReceiveAmount == 0) {
        this.disabled1 = true;
        this.act = false;
      } else {
        this.disabled1 = false; //确认结账按钮
        this.act = true;
      }
    },
    changeradio(value) {
      this.setShopingCardVip(false);
      this.obj.activityNo = "";
      this.obj.memberpayment = "";
      this.obj.mobile = "";
      this.obj.username = "";
      if (value == 1) {
      } else if (value == 5) {
        this.dialogVisibleVip = true;
      } else {
        for (let v of this.activityList) {
          if (v.activityType == value) {
            this.obj.activityNo = v.activityId;
            break;
          }
        }
      }
      this.setOrderActivity();
    },
    restVailVip() {
      this.dialogVisibleVip = true;
    },
    member(res) {
      this.setShopingCardVip(true);
      this.obj.username = res.data.mermberName;
      this.obj.memberpayment = res.data.memberpayment;
      this.obj.mobile = res.data.phone;
      this.setOrderActivity();
    },
    async detail() {
      let formData = {
        orderNo: this.obj.orderNo,
        activityType: this.obj.activityType,
        activityNo: this.obj.activityNo,
        mobile: this.obj.mobile,
      };
      this.setShopingCardVip(!!this.obj.mobile);
      let res = await this.$http.post(
        "/cashier-api/api/orderNew/updateOrderDetailTotalPrice",
        formData
      );
      res = res.data;
      this.orderVo = res;
      this.obj.shouldReceiveAmount = res.shouldReceiveAmount;
    },
    async setOrderActivity() {
      this.detail();
    },
    async getActivityList() {
      let { data } = await this.$http.post(
        "/cashier-api/api/activity/getActivityList",
        { orderNo: this.orderNo }
      );
      return (this.activityList = data);
    },
    async backOrder() {
      try {
        this.loading = true;
        let data = {
          orderNo: this.orderNo,
          tableId: this.tableId,
        };
        await this.$http.post("/cashier-api/api/orderNew/clearTable", data);
        this.successAfterPush();
      } finally {
        this.loading = false;
      }
    },
    closebtn() {
      this.showbtnclose = false;
    },
    disable() {
      this.disabled1 = true;
      this.act = false;
    },
    pay() {
      this.act = true;
      this.disabled1 = false;
      this.paied();
    },
    paied() {
      this.loading = true;
      let formData = {
        orderNo: this.obj.orderNo,
      };
      formData.orderMode = this.orderMode;
      this.$http
        .post(`/cashier-api/api/order/confirm/paied`, formData)
        .then(() => {
          this.loading = false;
          if (this.orderMode == 2) {
            return this.$router.push("/notTable");
          }
          this.dialogVisible2 = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    stap() {
      this.$router.go(-1);
    },
    close() {
      this.dialogVisible2 = false;
      this.$router.push("/selectTable");
    },
    async vaildActive() {
      if (this.obj.activityType == 5 || this.obj.activityType == 1) {
        return;
      }
      let List = await this.getActivityList();
      for (let i = 0; i < List.length; i++) {
        if (List[i].activityType == this.obj.activityType) {
          return;
        }
      }
      this.changeradio((this.obj.activityType = "1"));
      throw new Error("优惠活动关闭，请重新确认订单，再发起支付！");
    },
    async vaildMerVipNotCout() {
      if (this.obj.notCountAmount == "" && this.obj.activityType != 5) {
        return;
      }
      let mer = (this.mer = await this.getMerchantInfo());
      if (this.obj.activityType == 5 && mer.openCommonMermber != 1) {
        this.changeradio((this.obj.activityType = "1"));
        this.obj.notCountAmount = "";
        throw new Error("会员功能关闭，请重新确认订单，再发起支付！");
      }
      if (this.obj.notCountAmount != "" && mer.openReduce != 1) {
        this.obj.notCountAmount = "";
        throw new Error("抹零功能关闭，请重新确认订单，再发起支付！");
      }
    },
    async vaildPay() {
      try {
        await this.vaildActive();
        await this.vaildMerVipNotCout();
      } catch (err) {
        this.$alert(err.message, "提示", {
          confirmButtonText: "知道了",
          callback: () => {},
        });

        throw err;
      }
    },
    async orderpay() {
      if (this.notCountAmountError) return;
      if (this.obj.activityType == 5 && this.obj.mobile == "") {
        return this.$message({
          message: "请验证会员手机号",
          type: "warning",
        });
      }
      try {
        let notCountAmount = 0;
        if (this.obj.notCountAmount) {
          notCountAmount = number(this.obj.notCountAmount);
        }
        this.loading = true;
        await this.vaildPay();
        let { data } = await this.$http.post(
          "/cashier-api/api/orderNew/toPay",
          {
            orderNo: this.obj.orderNo,
            paymentType: this.obj.paymentType,
            notCountAmount,
            activityType: this.obj.activityType,
            activityNo: this.obj.activityNo,
            mobile: this.obj.mobile,
          }
        );
        let res = data;
        this.obj.shouldReceiveAmount = res.shouldReceiveAmount;
        this.obj.accountBalance = res.accountBalance;
        switch (this.obj.paymentType) {
          case 2: //扫码
            this.dialogVisible1 = true;
            break;
          case 5: //免单
            await this.$confirm("是否继续免单?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            });
          case 10: //挂单
            await this.$http.post(
              "/cashier-api/api/orderNew/newOfflinePayment",
              {
                orderNo: this.obj.orderNo,
                paymentType: this.obj.paymentType,
                reallyReceiveAmount: 0,
                activityType: this.obj.activityType,
                activityNo: this.obj.activityNo,
                mobile: this.obj.mobile,
                notCountAmount,
              }
            );
            this.$message({
              type: "success",
              offset: 160,
              message: "付款成功!",
            });
            this.paymentSuccess();
            break;
          default:
            //现金 刷卡 其他
            this.dialogVisible = true;
        }
      } finally {
        this.loading = false;
      }
    },
    colseAllDialog() {
      this.dialogVisible = false;
      this.dialogVisible1 = false;
      this.dialogVisibleGua = false;
      this.dialogVisibleVip = false;
    },
  },
};
</script>

<style scoped src="./index.css">
</style>
