<template>
  <div class="operation-container" v-loading="loading">
    <div class="operation-div">
      <div @click="packaging">打包</div>
    </div>
  </div>
</template>

<script>
import { myMixin } from "./minxins.js";
export default {
  mixins: [myMixin],
};
</script>

<style scoped src="./index.css">
</style>