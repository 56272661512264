<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="会员类型" prop="memberTypeId">
          <el-select
            v-model="formInline.memberTypeId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in typelist"
              :key="item.memberTypeId"
              :label="item.memberTypeName"
              :value="item.memberTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员类型状态" prop="memberTypeStatus">
          <el-select
            v-model="formInline.memberTypeStatus"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(v, k) in memberTypeStatus"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
          >查询</el-button
        >
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div></div>
          <div>
            <el-button
              v-if="But.Add.power()"
              @click="add"
              size="mini"
              type="primary"
              >添加会员类型</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
    >
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column prop="memberTypeName" label="会员类型"></el-table-column>
      <el-table-column prop="memberTypeStatus" label="会员类型状态" width="100">
        <template slot-scope="scope">{{
          memberTypeStatus[scope.row.memberTypeStatus]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="createUser"
        label="操作人"
        width="130"
      ></el-table-column>
      <el-table-column prop="sourceIp" label="操作" width="180">
        <template slot-scope="scope" v-if="scope.row.memberTypeId!=1">
          <el-button
            v-if="But.Edit.power()"
            type="text"
            @click="edit(scope.row)"
            >编辑</el-button
          >
          <el-button
            v-if="But.Delete.power()"
            type="text"
            @click="dele(scope.row.memberTypeId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
    >
      <el-form
        :inline="true"
        :model="formzine"
        class="demo-form-inline"
        size="mini"
        :rules="rules"
        ref="formzine"
        label-position="left"
      >
        <el-form-item label="会员类型:" class="addPlace" prop="memberTypeName">
          <el-input
            v-model.trim="formzine.memberTypeName"
            maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item label="会员类型状态:" prop="memberTypeStatus">
          <el-switch
            v-model="formzine.memberTypeStatus"
            active-color="#409EFF"
            inactive-color="#dcdfe6"
          ></el-switch>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model.trim="formzine.remark"
            style="width: 200px"
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit" size="mini">保存</el-button>
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.loadlist();
    this.searchzin();
  },
  data() {
    return {
      dialogVisible: false,
      But: window.UserPower.Membership.MemberType,
      rules: {
        memberTypeName: [
          { required: true, message: "必填", trigger: "change" },
        ],
      },
      type: 1,
      equipmentlist: [],
      formzine: {
        remark: "",
        memberTypeStatus: false,
        memberTypeName: "",
      },
      memberTypeStatus: {
        1: "可用",
        0: "停用",
      },
      typelist: [],
      formInline: {
        memberTypeId: "",
        memberTypeStatus: "",
        createTmStart: "",
        createTmEnd: "",
        value1: null,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      title: "添加会员",
      memberid: "",
      loading: false,
    };
  },
  methods: {
    dele(id) {
      this.$confirm("是否继续删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$http
            .post("/order-admin/w/memberType/del", {
              memberTypeId: id,
            })
            .then((res) => {
              this.loading = false;
              this.typelist = res.data;
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.search();
              this.loadlist();
            })
            .catch((error) => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    loadlist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/memberType/s/all", {})
        .then((res) => {
          this.loading = false;
          this.typelist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    edit(inner) {
      this.dialogVisible = true;
      this.title = "修改会员类型";
      this.formzine = { ...inner };
      this.formzine.memberTypeStatus =
        this.formzine.memberTypeStatus == "1" ? true : false;
    },
    detail(id) {
      this.$router.push("/huiyuandetail");
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    onSubmit() {
      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          let Data = {
            ...this.formzine,
          };
          Data.memberTypeStatus = Data.memberTypeStatus == true ? "1" : "0";
          this.loading = true;
          let url =
            this.title == "修改会员类型"
              ? "/order-admin/w/memberType/update"
              : "/order-admin/w/memberType/add";
          this.$http
            .post(`${url}`, Data)
            .then((res) => {
              this.loading = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.search();
              this.loadlist();
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    add() {
      this.dialogVisible = true;
      this.title = "添加会员类型";
      for (let i in this.formzine) {
        this.formzine[i] = "";
      }
      this.formzine.memberTypeStatus=true;
      this.$refs["formzine"].clearValidate();
    },
    search() {
      this.formInline.createTmStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.createTmEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/memberType/s/page", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.page.records;
          this.page.total = res.page.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
  },
};
</script>

<style scoped>
.fonsize span {
  margin-left: 10px;
}
.fonsize span span {
  color: #409eff;
  margin-left: 0px;
}
</style>


