<template>
  <div class="view">
    <div class="view-contion" v-loading="loading">
      <el-button
        v-if="But.Add.power()"
        type="primary"
        @click="add"
        size="mini"
        style="margin:10px 0 30px 0;"
      >添加口味</el-button>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        size="mini"
        highlight-current-row
      >
        <el-table-column label="序号" type="index" :index="indexMethod" width="150"></el-table-column>
        <el-table-column prop="itemName" label="口味名称"></el-table-column>
        <el-table-column prop="sourceIp" label="操作">
          <template slot-scope="scope">
            <el-button v-if="But.Delete.power()" size="mini" type="text" @click="dele(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        title="添加做法口味"
        :visible.sync="dialogVisible"
        width="450px"
        :close-on-click-modal="false"
      >
        <el-form
          :inline="true"
          :model="formzine"
          class="demo-form-inline"
          size="mini"
          ref="formzine"
          :rules="rules"
        >
          <el-form-item label="做法口味名称:" prop="itemName">
            <el-input v-model.trim="formzine.itemName"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submited" size="mini">保存</el-button>
          <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      But: window.UserPower.variety.classification.viewEat,
      dialogVisible: false,
      formzine: {
        itemName: "",
        dictType: "1002",
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      rules: {
        itemName: [
          { required: true, message: "不可为空", trigger: "blur" },
          { min: 1, max: 5, message: "支持1-5个字", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    add() {
      this.$refs["formzine"] && this.$refs["formzine"].resetFields();
      this.dialogVisible = true;
    },
    indexMethod(index) {
      return index + 1;
    },
    submited() {
      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          for (let i in this.tableData) {
            if (this.tableData[i].itemName == this.formzine.itemName) {
              this.$message({
                message: `${this.formzine.itemName}口味名称已存在请重新输入`,
                type: "warning",
              });
              return;
            }
          }
          this.loading = true;
          this.$http
            .post("/order-admin/w/dict/add", {
              ...this.formzine,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.load();
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    dele(inner) {
      this.$confirm(`确认删除${inner.itemName}口味名称?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$http
            .post("/order-admin/w/dict/del", {
              dictIds: inner.dictId,
              dictType: this.formzine.dictType,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                type: "success",
                offset: 160,
                message: "删除成功!",
              });
              this.load();
            })
            .catch((error) => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            offset: 160,
            message: "已取消",
          });
        });
    },
    load() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/dict/s/all", {
          dictType: "1002",
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.view {
  position: relative;
}
</style>


