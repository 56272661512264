import Bus from "@/common/bus.js";
import { mapState, mapActions } from "vuex";

export let myMixin = {

    props: ["orderNo", "tableId", "orderMode"],
    computed: mapState({
        indexActive: (state) => state.shopingCard.shopingCardActive,
        orderInfo: (state) => state.shopingCard.shopingCardOrder,
    }),
    created() { },
    data() {
        return {
            loading: false,
            dialogQuitVailt: false,
            paymentMode: localStorage.getItem("paymentMode"),
        };
    },
    methods: {
        ...mapActions(["getShopingCard"]),
        async packaging() {
            let { value } = await this.$prompt("请输入餐盒数量", "提示", {
                closeOnClickModal: false,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputValidator(v) {
                    let reg = /^\d{1,2}$/;
                    if (!reg.test(v)) return "请输入大于0小于100的整数";
                    if (+v === 0) return "请输入大于0小于100的整数";
                    return true;
                },
            });
            await this.$http.post("/cashier-api/api/orderNew/orderDishesPack", {
                orderNo: this.orderNo,
                packagingCount: value,
            });
            Bus.$emit("restCountMoney");
        },
        async quitDishes() {
            this.ifReturnFn();
            this.dialogQuitVailt = true;
        },
        colseReduce() {
            this.dialogQuitVailt = false;
            this.getShopingCard({ orderNo: this.orderNo });
            Bus.$emit("restCountMoney");
            Bus.$emit("restActivity");
        },
        async continueAddDishes() {
            try {
                this.loading = true;
                let option = {
                    orderNo: this.orderNo,
                };
                let { data } = await this.$http.post(
                    "/cashier-api/api/orderNew/addCreateOrder",
                    option
                );
                this.$router.replace({
                    path: "/selectMenu",
                    query: {
                        orderNo: data,
                        orderMode: this.orderMode,
                        tableId: this.tableId,
                    },
                });
            } finally {
                this.loading = false;
            }
        },
        async clearTable() {
            await this.$confirm("此操作将清除桌位状态, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            try {
                this.loading = true;
                let data = {
                    tableId: this.tableId,
                    orderNo: this.orderNo,
                };
                await this.$http.post("/cashier-api/api/orderNew/clearTable", data);
                this.$router.replace("/selectTable");
            } finally {
                this.loading = false;
            }
        },
        changeTable() {
            this.$router.push({
                path: "/changeTable",
                query: {
                    orderNo: this.orderNo,
                    tableId: this.tableId,
                    path: "toPayment",
                },
            });
        },
        ifReturnFn() {
            if (!this.indexActive) {
                this.$message({
                    message: "请选择菜品！",
                    type: "warning",
                });
                throw new Error("选择菜品！");
            }
        },
    },
}