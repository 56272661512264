<template>
  <div class="h200" v-loading="loading">
    <div class="view_hreader h200">
      <div class="container_left h100" v-if="flag == 1">
        <el-tabs v-model="editableTabsValue" type="card" @tab-click="hend" class="h200">
          <el-tab-pane class="h200" v-for="(item, index) in tableRegions" :key="index" :label="item.categoryName" :name="item.categoryId" @click="handleClick(item, index)"
            :class="{ active: index == indexzin }">
            <div class="htab_box">
              <div class="searchGoodsBox">
                <el-input v-model="ruleForm.dishersName" size="mini" clearable>
                  <i slot="suffix" class="el-icon-search el-input__icon" @click="loadlist"></i>
                </el-input>
              </div>
              <div class="contanin">
                <div class="contanin_dan" v-for="(item, index) in option" :key="index" @click="add(item)">
                  <div class="contanin_dan_a" v-if="isImg">
                    <img :src="item.imageShowOriginalUrl || require('@/assets/image/no-cai-img.jpg')" />
                    <div class="zmark">{{ item.dishersName }}</div>
                  </div>
                  <div v-else class="contanin_dan_noimg">
                    {{ item.dishersName }}
                  </div>
                  <div class="contanin_dan_b">
                    <div class="jian_jia">
                      <span v-if="item.alertPrice">时价菜:¥{{ item.alertPrice | money }}</span>
                      <span v-if="item.specialPrice">特价菜:¥{{ item.specialPrice | money }}</span>
                      <div v-if="!item.alertPrice && !item.specialPrice">
                        <span v-if="item.priceType == '3'" class="font_wen">时价菜</span>
                        <span v-if="item.priceType != '3'" class="font_wen">¥{{ item.price | money }}</span>
                        <span v-if="
                            merchantInfo.openCommonMermber != 0 &&
                            item.priceType != '3'
                          ">¥{{ item.memberPrice | money }}</span>
                      </div>
                    </div>
                    <div>
                      <img src="@/assets/image/icon-jia.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="contant_left" v-if="flag == 2">
        <view-topay :orderNo="orderNo" :tableId="tableId"></view-topay>
      </div>
      <div class="contant_right">
        <view-phone :dishersdetailall="dishersdetailall" ref="viewPhone" :orderNo="orderNo" :typezin="typezin" :dishersId="dishersId" @load="load"></view-phone>
      </div>
    </div>
  </div>
</template>

<script>
import viewPhone from "./rightside/rightmiddle"; //右侧中间手机页面
import viewTopay from "./topay.vue"; //下单页面
import Eve from "@/common/bus.js";
export default {
  created() {
    localStorage.setItem("payflag", true); //总计确认下单按钮
    this.merinfo();
  },
  computed: {
    isImg() {
      return this.$store.state.showImg;
    },
  },
  async mounted() {
    this.orderNo = this.$route.query.orderNo;
    this.orderMode = this.$route.query.orderMode;
    // this.$nextTick((_) => {
    //   this.$refs.viewPhone.dishersInfo();
    // });
    await this.load();
    this.hend(this.obj);
    if (this.orderMode == 2) {
      Eve.$emit("getTarget", "无桌点餐");
    }
  },
  components: {
    viewPhone,
    viewTopay,
  },
  data() {
    return {
      typezin: "1",
      obj: {
        index: 0,
        name: "all",
      },
      editableTabsValue: "all",
      merchantInfo: {},
      flag: 1,
      tableRegions: [],
      dialogVisible: false,
      indexzin: 0,
      categoryId: "",
      option: [],
      ruleForm: {
        dishersName: "",
      },
      dishersdetailall: {},
      orderNo: this.$route.query.orderNo,
      dishersInfoVo: {},
      dishersId: "",
      tableId: "",
      loading: false,
    };
  },
  methods: {
    hend(val) {
      this.indexzin = val.index;
      this.categoryId = val.name;
      this.loadlist();
    },
    merinfo() {
      this.$http.get(`/order-admin/w/base/merchantInfo`).then((res) => {
        this.merchantInfo = res.merchantInfoVo;
      });
    },
    add(item) {
      if (item.simpleDishers == 1) {
        this.loading = true;
        this.$http
          .post("/cashier-api/api/dishers/add", {
            merchantId: localStorage.getItem("merchantId"),
            dishersId: item.dishersId,
            dailyPresellCount: item.dailyPresellCount,
            orderNo: this.$route.query.orderNo,
          })
          .then((res) => {
            this.loading = false;
            this.$nextTick((_) => {
              this.$refs.viewPhone.dishersInfo();
            });
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        this.dishersId = item.dishersId;
        this.$nextTick((_) => {
          this.$refs.viewPhone.detailzin();
        });
      }
    },
    // handleClick(item, index) {
    //   this.indexzin = index;
    //   this.categoryId = item.categoryId;
    //   this.loadlist();
    // },
    load() {
      this.loading = true;
      return this.$http
        .post("/cashier-api/api/dishers/category", {
          merchantId: localStorage.getItem("merchantId"),
        })
        .then((res) => {
          this.loading = false;
          this.tableRegions = res.categoryList;
          this.tableRegions.unshift({
            categoryId: "all",
            categoryName: "全部",
          });
          this.categoryId = res.categoryList[0].categoryId;
          // this.loadlist();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    loadlist() {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/dishers/list", {
          categoryId: this.categoryId,
          merchantId: localStorage.getItem("merchantId"),
          dishersName: this.ruleForm.dishersName,
          orderMode: this.orderMode,
        })
        .then((res) => {
          this.loading = false;
          if (res.dishersList.length == 0) {
            this.option = [];
          }
          for (let i in res.dishersList) {
            if (res.dishersList[i].categoryId == this.categoryId) {
              this.option = res.dishersList[i].simpleInfoVos;
            }
            if (this.categoryId == "all") {
              this.option = res.dishersList[0].simpleInfoVos;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.htab_box {
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.name {
  white-space: nowrap;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  margin-top: 15px;
}
.header div {
  width: 80px;
  height: 38px;
  text-align: center;
  line-height: 38px;
  background: #fcfcfc;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  color: #333333;
  border-right: none;
}
.header div:last-child {
  border-right: 1px solid #e0e0e0;
}
.header div.active {
  background: #ffffff;
  border-bottom: none;
}
.view_hreader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.jian_huiyuan {
  font-size: 12px;
  color: #ff6969;
  margin-top: 3px;
}
.jian_huiyuan span:nth-of-type(2) {
  margin-left: 4px;
}
.container_left {
  width: calc(100% - 530px);
  flex: 1;
  padding-left: 20px;
  border: 1px solid #dedede;
}
.searchGoodsBox {
  width: 400px;
  height: 28px;
  margin-top: 15px;
}
.contanin {
  width: 100%;
  flex: 1;
  padding-top: 15px;
  overflow: auto;
}
.contanin_dan {
  float: left;
  width: 140px;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin: 0 20px 20px 4px;
  cursor: pointer;
}

.zmark {
  height: 35px !important;
  font-size: 18px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: none;
  background-image: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 100%
  );
  text-align: center;
  color: white;
  line-height: 30px;
  opacity: 1;
}
.contanin_dan div:nth-of-type(1) img {
  border-radius: 4px;
}
.contanin_dan_b {
  padding: 0 14px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contanin_dan_a {
  position: relative;
  height: 140px;
  width: 140px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contanin_dan_noimg {
  height: 40px;
  font-size: 14px;
  color: #222222;
  display: flex;
  align-items: center;
  padding: 0 14px;
}
.contanin_dan_a img {
  width: 140px;
  height: 140px;
  transition: all 0.3s;
  position: relative;
}
.contanin_dan_a img:hover {
  width: 160px;
  height: 160px;
}
.jian_jia div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
}
.jian_jia span:nth-of-type(2) {
  color: #ff6969;
}
.phone_tazy_sub {
  width: 400px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-left: 15px;
  position: relative;
}
.view_contant {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footer_zin {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-width {
  width: 238px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.dayinOrder {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 80px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.dayinReceipt {
  width: 100px;
  height: 40px;
  border: 1px solid #108af9;
  border-radius: 2px;
  color: #108af9;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
}
.dayinReceipt:nth-of-type(2) {
  margin-left: 10px;
}
.font {
  color: #ff6969;
}
.footer_btn button {
  width: 160px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #108af9;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 0;
}
.footer_btn button:nth-of-type(2) {
  background: #999999;
  color: #ffffff;
  margin-left: 0;
}
.phone_inner {
  width: 38%;
  border: 1px solid #dedede;
  margin: 0 1%;
}
.price {
  width: 200px;
  height: 70px;
  border: 1px solid #dcdee0;
  border-radius: 4px;
  margin-right: 20px;
}
.price_alls {
  display: flex;
  justify-content: flex-start;
}
.price div:nth-of-type(1) {
  width: 56px;
  height: 16px;
  background: #108af9;
  font-size: 12px;
  color: #ffffff;
  margin: 6px 0 0 10px;
  text-align: center;
}
.price div:nth-of-type(2) {
  font-size: 20px;
  color: #ff6969;
  text-align: center;
  margin-top: 12px;
}
.yel div:nth-of-type(1) {
  background: #feb822;
}
.yel {
  color: #333333;
}
.green div:nth-of-type(1) {
  background: #11cd6e;
}
.green {
  color: #333333;
}
.phone_tazy_sub {
  width: 420px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-left: 10px;
  position: relative;
}
.header_phone {
  height: 111px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header_phone div {
  display: flex;
  justify-content: space-between;
  margin: 0 26px;
  font-size: 22px;
}
.header_phone div:nth-of-type(2) {
  font-size: 14px;
  margin-top: 14px;
}
.header_container {
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
}
.header_container div {
  height: 66px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.price_all {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price_all span:nth-of-type(2) {
  color: #ff6969;
}
.reductionall {
  display: flex;
  justify-content: flex-start;
}
.redioyou {
  width: 140px;
  height: 36px;
  border: 1px solid #dcdee0;
  color: #333333;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-right: 20px;
}
.active {
  /* border: 1px solid #108af9; */
  color: #108af9;
}
.redioyou img {
  width: 30px;
}
.actclass {
  background: #108af9 !important;
}
.font_wen {
  color: black;
}
.contant_right {
  width: 530px;
  height: 100%;
}
</style>