<template>
  <div class="view-contion" :loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        size="mini"
        label-width="80px"
        :model="form"
        ref="formInlinezin"
      >
        <el-form-item label="菜品分类" prop="categoryId">
          <el-select v-model="form.categoryId" placeholder="请选择" clearable>
            <el-option
              v-for="item in equipmentlist"
              :key="item.categoryId"
              :label="item.categoryName"
              :value="item.categoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜品名称" prop="dishersName">
          <el-input
            v-model.trim="form.dishersName"
            clearable
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="库存范围" prop="depotScope">
          <el-select v-model="form.depotScope" placeholder="请选择" clearable>
            <el-option
              v-for="(k, v) in depotScopeList"
              :key="v"
              :label="k"
              :value="v"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="Search" size="mini">查询</el-button>
          <el-button @click="Export" size="mini">导出</el-button>
          <el-button @click="Delete" size="mini">删除</el-button>
          <el-button type="primary" @click="allCreateStore" size="mini"
            >批量建立库存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-bottom: 10px; font-weight: bold">
      查询时间：{{ searchTimer }}
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
      @selection-change="handleSelectionChange"
      :row-class-name="callClassRow"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
      <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
      <el-table-column prop="goodNum" label="当前库存"></el-table-column>
      <el-table-column prop="operatorUserNo" label="操作人"></el-table-column>
      <el-table-column prop="operationTime" label="操作时间"></el-table-column>
      <el-table-column prop="safeGoodNum" label="安全库存"></el-table-column>
      <el-table-column prop="categoryName" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="Jinhuo(scope.row)">进货</el-button>
          <el-button type="text" @click="Edit(scope.row)">编辑</el-button>
          <el-button type="text" @click="Flow(scope.row)">流水</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
    <el-dialog
      title="选择菜品"
      :visible.sync="dialogVisibleSelect"
      width="820px"
      :close-on-click-modal="false"
    >
      <selectCai
        class="h100"
        v-if="dialogVisibleSelect"
        @close="close"
        :arr="tableData"
      />
    </el-dialog>
    <el-dialog
      :title="flowTime"
      :visible.sync="dialogVisibleFlow"
      width="1350px"
      :close-on-click-modal="false"
    >
      <flowData :formdata="flowData" class="h100" v-if="dialogVisibleFlow" />
    </el-dialog>
    <export-file
      URL="/order-admin/w/depot/export/excel"
      ref="File"
    ></export-file>
  </div>
</template>

<script>
import selectCai from "./details";
import flowData from "./flowData";
export default {
  components: { selectCai, flowData },
  created() {
    this.loadlist();
    this.Search();
  },
  data() {
    return {
      searchTimer: "",
      flowTime: "",
      flowData: null,
      dialogVisibleFlow: false,
      dialogVisibleSelect: false,
      loading: false,
      form: {
        categoryId: "",
        dishersName: "",
        depotScope: "",
      },
      equipmentlist: [],
      depotScopeList: {
        0: "低于安全库存",
        1: "负库存",
      },
      multipleSelection: [],
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    callClassRow({ row }) {
      if (+row.goodNum < 0) {
        return "block-font";
      }
      if (+row.safeGoodNum > +row.goodNum) {
        return "red-font";
      }
      return "";
    },
    loadlist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/depot/category", {})
        .then((res) => {
          this.loading = false;
          this.equipmentlist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.Load();
    },
    close(val) {
      let data = val.map((v) => {
        return {
          categoryId: v.categoryId,
          categoryName: v.categoryName,
          dishersId: v.dishersId,
          dishersName: v.dishersName,
          imageId: v.imageId,
        };
      });
      this.$http
        .post("/order-admin/w/depot/save-batch", {
          operationType: 0,
          depotList: data,
        })
        .then((res) => {
          this.dialogVisibleSelect = false;
          this.Search();
          this.loadlist();
        });
    },
    Load() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/depot/page", {
          ...this.form,
          ...this.page,
        })
        .then((res) => {
          this.searchTimer = new Date().formatDate("yyyy-MM-dd hh:mm:ss");
          this.loading = false;
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Search() {
      this.page.pageNo = 1;
      this.Load();
    },
    Export() {
      this.$refs.File.export({
        ...this.form,
        authToken: localStorage.getItem("tokenIdzin"),
      });
    },
    Delete() {
      if (this.multipleSelection.length == 0)
        return this.$message({
          message: "请选择需要删除的数据",
          type: "warning",
        });
      this.$confirm("注意：是否删除已选数据？", "批量删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let depotIdList = this.multipleSelection.map((v) => {
          return {
            dishersId: v.dishersId,
            depotId: v.depotId,
            imageId: v.imageId,
            dishersName: v.dishersName,
            categoryName: v.categoryName,
          };
        });
        this.$http
          .post("/order-admin/w/depot/delete-batch", { depotIdList })
          .then(() => {
            this.Search();
            this.loadlist();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
      });
    },
    Edit(obj) {
      this.$prompt("安全库存", obj.dishersName, {
        inputPlaceholder: "请输入0-9999正整数0代表不进行安全提醒",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\d{1,4}$/,
        inputErrorMessage: "请输入0-9999正整数",
      }).then(({ value }) => {
        let data = {
          depotId: obj.depotId,
          safeGoodNum: value,
          dishersId: obj.dishersId,
          categoryId: obj.categoryId,
        };
        this.$http
          .post("/order-admin/w/depot/safeGoodNum/modify", data)
          .then((res) => {
            this.Search();
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
          });
      });
    },
    Jinhuo(obj) {
      this.$prompt("本次进货量", "进货—" + obj.dishersName, {
        inputPlaceholder: "请输入1-9999正整数",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^(?!0+$)\d{1,4}$/,
        inputErrorMessage: "请输入1-9999正整数",
      }).then(({ value }) => {
        let data = {
          depotId: obj.depotId,
          changeGoodNum: value,
          dishersId: obj.dishersId,
          categoryId: obj.categoryId,
        };
        this.$http.post("/order-admin/w/depot/modify", data).then((res) => {
          this.Search();
          this.$message({
            type: "success",
            message: "进货成功!",
          });
        });
      });
    },
    Flow(data) {
      this.flowTime = new Date().formatDate("yyyy-MM-dd hh:mm:ss");
      this.flowData = data;
      this.dialogVisibleFlow = true;
    },
    allCreateStore() {
      this.dialogVisibleSelect = true;
    },
  },
};
</script>

<style scoped>
</style>