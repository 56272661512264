<template>
  <div>
    <div class="cm10">
      <a v-if="openMemberStore==0" download="导入模版.xlsx" :href="require('@/assets/templet/itemmeb.xlsx')">下载excel模板</a>
        <a v-if="openMemberStore==1" download="导入模版.xlsx" :href="require('@/assets/templet/itemmeb2.xlsx')">下载excel模板</a>
    </div>
    <br />
    <div class="cm10">(请先下载模版,并按模版格式添加数据)</div>
    <br />
    <div class="flexbox">
      <a href="#" class="filea">
        选择文件
        <input type="file" ref="file" @change="changFilezin()" class="cm10" />
      </a>
      <p style="margin-top:4px;margin-left:47px;">{{fileinner}}</p>
    </div>
    <div class="cm10" style="margin-left:48px;margin-top:10px" v-show="totalFailNum==true">
      <a download :href="str">点击下载错误文件</a>
    </div>
    <el-row style="text-align:right;margin:15px 0px;">
      <el-button
        size="mini"
        :loading="loading"
        @click="submit()"
        type="primary"
        :disabled="disabled"
      >确认</el-button>
      <el-button size="mini" @click="close">取消</el-button>
    </el-row>
  </div>
</template>

<script>
import { baseurl } from "../../../../base.js";
export default {
  props: { type: Number },
  created() {},
  data() {
    return {
       openMemberStore: localStorage.getItem("openMemberStore"),
      fileinner: "",
      loading: false,
      str: "",
      header: { "Access-Control-Allow-Origin": "*" },
      disabled: true,
      msg: "",
      totalFailNum: false,
    };
  },
  methods: {
    changFilezin() {
      if (this.$refs["file"].files[0]) {
        this.fileinner = this.$refs["file"].files[0].name;
        this.disabled = false;
      } else {
        this.fileinner = "";
        this.disabled = true;
      }
    },
    submit(a) {
      this.loading = true;
      let data = new FormData();
      data.append("file", this.$refs["file"].files[0]);
      let url =
        this.type == 1
          ? "/order-admin/w/member/leading"
          : "/cashier-api/f/member/leading";
      this.$http
        .post(`${url}`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          (data) => {
            console.log(data);

            this.loading = false;
            this.$message({
              message: "导入成功",
              offset: 160,
              type: "success",
            });
            this.$emit("close");
            this.$emit("load");
            // if (data.data.failCnt > 0) {
            //   this.loading = false;
            //   this.$message.error(
            //     `有${data.data.failCnt}错误文件，请下载错误文件`
            //   );
            //   if (this.type == 1) {
            //     this.str = `${baseurl}/order-admin/w/member/export?batchNo=${data.data.batchNo}`;
            //   } else {
            //     this.str = `${baseurl}/cashier-api/f/member/export?batchNo=${data.data.batchNo}`;
            //   }
            //   this.totalFailNum = true;
            // } else {
            //   this.loading = false;
            //   this.$message({
            //     message: "导入成功",
            //     offset: 160,
            //     type: "success"
            //   });
            //   this.$emit("close");
            //   this.$emit("load");
            // }
          },
          (e) => {
            console.log(e);
            this.loading = false;
            // this.$message.error(`有错误文件，请下载错误文件`);
            this.str = e.data;
            this.totalFailNum = true;
          }
        );
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scope>
a {
  color: #409eff;
}
.cm10 {
  margin-left: 50px;
}
.filea {
  width: 50px;
  height: 20px;
  position: relative;
  display: inline-block;
  background: #409eff;
  border-radius: 4px;
  padding: 4px 12px;
  overflow: hidden;
  color: #1e88c7;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
  margin-left: 50px;
  color: white;
  font-size: 12px;
  text-align: center;
}
.filea input {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}
</style>
