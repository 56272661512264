<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="餐桌位置" prop="regionNo">
          <el-select v-model="formInline.regionNo" filterable placeholder="请选择" clearable>
            <el-option
              v-for="item in equipmentList"
              :key="item.regionNo"
              :label="item.regionName"
              :value="item.regionNo"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="添加时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
        >查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div></div>
          <div>
            <el-button v-if="But.Add.power()" @click="addSubmit" size="mini" type="primary">添加桌区</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
    >
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="regionName" label="餐桌位置"></el-table-column>
      <el-table-column prop="regionCount" label="桌位数"></el-table-column>
      <el-table-column prop="createTime" label="添加时间"></el-table-column>
      <el-table-column prop="sourceIp" label="操作">
        <template slot-scope="scope">
          <el-button v-if="But.Edit.power()" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-button v-if="But.Delete.power()" type="text" @click="deletezq(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
    >
      <el-form
        :inline="true"
        :model="formzine"
        class="demo-form-inline"
        size="mini"
        :rules="rules"
        ref="formzine"
      >
        <el-form-item label="餐桌位置:" class="addPlace" prop="regionName">
          <el-input v-model.trim="formzine.regionName"></el-input>
        </el-form-item>
        <p class="font_nie">餐桌位置最多10个字,例如大厅,包间等</p>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit" size="mini">保存</el-button>
        <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.loadlist();
    this.searchzin();
  },
  data() {
    return {
      loading: false,
      But: window.UserPower.Restauranttable.equipmentAdminCode,
      formzine: {
        regionName: "",
        regionNo: "",
      },
      title: "",
      dialogVisible: false,
      formInline: {
        regionNo: "",
        value1: null,
        startTime: "",
        endTime: "",
      },
      equipmentList: [],
      tableData: [],
      rules: {
        regionName: [
          { required: true, message: "请输入桌区名称", trigger: "blur" },
          { min: 1, max: 10, message: "支持1—10个字", trigger: "blur" },
        ],
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    loadlist() {
      this.$http.get("/order-admin/w/region/list", {}).then((res) => {
        this.equipmentList = res.data;
      });
    },
    deletezq(data) {
      this.$confirm(
        `该桌区位置有${data.regionCount}桌位，删除后该桌区位置下的桌位都将会删除，是否确认删除?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.loading = true;
          this.$http
            .post("/order-admin/w/region/delete", {
              regionNo: data.regionNo,
            })
            .then((res) => {
              this.loading = false;
              this.dialogVisible = false;
              this.$message({
                message: "删除成功",
                offset: 160,
                type: "success",
              });
              this.search();
              this.loadlist();
            })
            .catch((error) => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            offset: 160,
            message: "已取消删除",
          });
        });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
    search() {
      this.formInline.startTime = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.endTime = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/region/search", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.page.records;
          this.page.total = +res.page.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    addSubmit() {
      this.dialogVisible = true;
      this.title = "添加桌区";
      this.formzine.regionName = "";
      this.formzine.regionNo = "";
      this.$refs["formzine"] && this.$refs["formzine"].resetFields();
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    onSubmit() {
      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          let url =
            this.title == "添加桌区"
              ? "/order-admin/w/region/create"
              : "/order-admin/w/region/modify";
          this.loading = true;
          this.$http
            .post(`${url}`, {
              ...this.formzine,
            })
            .then((res) => {
              this.loading = false;
              this.dialogVisible = false;
              this.$message({
                message: `${this.title}成功`,
                type: "success",
              });
              this.search();
              this.loadlist();
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    edit(data) {
      this.dialogVisible = true;
      this.title = "修改桌区";
      this.$refs["formzine"] && this.$refs["formzine"].resetFields();
      this.loading = true;
      this.$http
        .post("/order-admin/w/region/detail", {
          regionNo: data.regionNo,
        })
        .then((res) => {
          this.loading = false;
          this.formzine.regionName = res.regionName;
          this.formzine.regionNo = res.regionNo;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.font_nie {
  font-size: 10px;
  margin-left: 90px;
  color: #666666;
}
.addPlace {
  margin-bottom: 10px;
}
</style>

