<template>
  <div class="view_contant" v-loading="loading">
    <div class="contant_left">
      <div class="contant_top">
        <el-form :model="obj" :rules="rules" ref="obj" label-width="100px" class="demo-ruleForm" size="mini">
          <el-row>
            <div class="hr_title" style="margin-bottom: 25px">
              <span>金额信息</span>
            </div>
            <el-col :span="12">
              <el-form-item label="原价:" prop="orderOriginAmount">
                <span>{{ orderVo.orderOriginAmount | money }}元</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员价:" prop="orderMemberAmount" v-if="mer.openCommonMermber == 1">
                <span>{{ orderVo.orderMemberAmount | money }}元</span>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="!orderMode">
              <div class="box_op">
                <span>
                  其他:
                  <span>{{ orderVo.totalOtherAmount | money }}元</span>
                </span>
                <span>
                  桌位费:
                  <span>{{ orderVo.restaurantSeatFeeAmount | money }}元 （{{
                      mer.restaurantSeatFee | money
                    }}元/人）</span>
                </span>
                <span>
                  打包费:
                  <span>{{ orderVo.packingFeeAmount | money }}元（{{
                      mer.packingFee | money
                    }}元/个）</span>
                </span>
                <span>
                  餐具费:
                  <span>{{ orderVo.tablewareFeeAmount | money }}元（{{
                      mer.tablewareFee | money
                    }}元/个）</span>
                </span>
              </div>
            </el-col>
            <el-col :span="24" class="price_alls">
              <div class="price">
                <div>订单金额</div>
                <div class="red">
                  {{ orderVo.shouldReceiveAmount | money }}元
                </div>
              </div>
              <div class="price yel">
                <div>应收金额</div>
                <div>{{ orderVo.reallyReceiveAmount | money }}元</div>
              </div>
              <div class="price green">
                <div>优惠金额</div>
                <div>{{ orderVo.discountsAmount | money }}元</div>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="flag2">
            <div class="hr_title" style="margin: 20px 0">订单操作</div>
            <el-col :span="24">
              <!--新的 v-if="orderVo.orderStatus=='3'&&orderVo.paymentStatus == '3'" -->
              <!--旧 v-if="orderVo.operatorType=='1'&&orderVo.lockedPageIndex != '1'" -->
              <div v-if="
                  orderVo.orderStatus == '3' && orderVo.paymentStatus == '3'
                ">
                <el-form-item label="会员手机号:" prop="phone">
                  <span>{{ obj.phone }}</span>
                </el-form-item>
                <el-form-item label="会员姓名:" prop="username">
                  <span>{{ obj.username }}</span>
                </el-form-item>
                <el-form-item label="会员卡号:" prop="memberCardNo">
                  <span>{{ obj.memberCardNo }}</span>
                </el-form-item>
                <el-form-item label="平台会员:" prop="" v-if="CrmVaildate == 1">
                  <el-input placeholder="请输入平台会员手机号" v-model="crmMobile" clearable style="width: 258px" :disabled="IScrmMobile"></el-input>
                  <el-button v-if="!IScrmMobile" type="primary" size="mini" @click="CrmVaildateClick" style="margin-left: 10px">验证</el-button>
                  <div class="user-text" v-if="IScrmMobile">验证成功</div>
                  <span class="btn-clor" @click="CrmVaildateCacal" v-if="IScrmMobile">
                    取消会员
                  </span>
                </el-form-item>
                <el-form-item label="选择优惠:" prop="phone">
                  <span>{{ orderVo.activityName }}</span>
                </el-form-item>
              </div>
              <!--新的 v-if="orderVo.orderStatus!='3'&&orderVo.paymentStatus != '3'" -->
              <!--旧 v-if="orderVo.operatorType!='1'&&orderVo.lockedPageIndex == '1'"-->
              <div v-if="
                  orderVo.orderStatus != '3' && orderVo.paymentStatus != '3'
                ">
                <div v-if="mer.openCommonMermber == 1">
                  <el-form-item label="商户会员:" prop="phone">
                    <el-input placeholder="请输入会员手机号" v-model="obj.phone" clearable style="width: 258px" :disabled="disabled"></el-input>
                    <el-button type="primary" size="mini" @click="leavename" v-if="obj.memberpayment != '1'" style="margin-left: 10px" :disabled="disadle">验证</el-button>
                    <div class="user-text" v-if="obj.memberpayment == '1'">
                      商户会员信息：{{ obj.username }}
                      {{ obj.memberCardNo }}
                    </div>
                    <span class="btn-clor" @click="closehuiyuan" v-if="obj.memberpayment == '1'">
                      取消会员
                    </span>
                  </el-form-item>
                </div>
                <el-form-item label="平台会员:" prop="" v-if="openCommonMermbera != 0">
                  <el-input placeholder="请输入平台会员手机号" v-model="crmMobile" clearable style="width: 258px" :disabled="IScrmMobile"></el-input>
                  <el-button v-if="!IScrmMobile" type="primary" size="mini" @click="CrmVaildateClick" style="margin-left: 10px">验证</el-button>
                  <div class="user-text" v-if="IScrmMobile">验证成功</div>
                  <span class="btn-clor" @click="CrmVaildateCacal" v-if="IScrmMobile">
                    取消会员
                  </span>
                  <!-- <el-button
                    v-if="IScrmMobile"
                    type="primary"
                    size="mini"
                    @click="CrmVaildateCacal"
                    style="margin-left: 25px"
                    >取消</el-button > -->
                </el-form-item>
                <el-form-item label="选择优惠" prop="reallyAmount">
                  <el-radio-group v-model="obj.activityType" @change="changeradio">
                    <el-radio :label="1" :disabled="disadle">不使用优惠</el-radio>
                    <el-radio :label="0" :disabled="disadle">使用优惠</el-radio>
                    <el-radio :label="4" v-if="openReduce == '1'" :disabled="disadle">抹零</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label prop="reallyAmount">
                  <div class="reductionall" v-if="flag">
                    <div class="redioyou">
                      <span class="spansize">{{ discount }}</span>
                      <img src="@/assets/image/img-xuanzhong-2.png" />
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="抹零金额:" prop="notCountAmount" v-if="flag1 && openReduce == '1'">
                  <el-input placeholder="请输入抹零金额" v-model="obj.notCountAmount" clearable style="width: 258px"></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <div class="hr_title" style="margin: 20px 0">选择支付方式</div>
            <span v-if="!flag2">支付方式{{ resultobj.paymentTypeStr }}</span>
            <div v-if="flag2">
              <el-form-item label="" label-width="0" prop="paymentType">
                <div class="reductionall">
                  <div class="redioyou1" v-for="(item, idx) in paymentlist" @click="cashpayment(item.name, idx, item.id)" :key="item.id" :class="idx == index2 ? 'active' : ''" v-show="
                      item.id != 6 ||
                      (openMemberStore == 1 && obj.memberpayment == 1)
                    ">
                    <img class="pay-icon" :src="item.icon" alt="" />
                    <span>{{ item.name }}</span>
                    <img src="@/assets/image/icon_duigou_1.png" v-if="idx == index2" />
                  </div>
                </div>
              </el-form-item>
            </div>
          </el-row>
        </el-form>
      </div>

      <div class="footer_zin">
        <div>
          <span class="footer_btn">
            <el-button class="actclass" type="primary" size="mini" @click="backOrder" v-if="orderMode==2">返回餐台</el-button>
          </span>

          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <span class="dayinReceipt" v-if="paymentMode != '1'">打印至厨房</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleCommand('a')">打印整单</el-dropdown-item>
              <!-- <el-dropdown-item command="b" v-if="flag3">打印加菜单</el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
          <!-- orderVo.paymentStatus=='1'&& -->
          <el-button class="btn" @click="print('4')" v-if="paymentMode != '1'">打印预结单</el-button>
          <!-- <el-button class="btn" @click="print('3')" v-if="orderVo.paymentStatus!='1'">打印结账单</el-button> -->
        </div>
        <div class="footer_btn">
          <el-button type="primary" size="mini" @click="stap" v-if="orderMode==2">上一步</el-button>
          <el-button type="primary" size="mini" @click="orderpay" class="actclass">确认结账</el-button>
        </div>
      </div>
    </div>
    <div class="height_100">
      <view-phone ref="viewinfo" type="支付" @detailleft="detail" :obj="obj" :act="act" @disable="disable" :typezin="typezin"></view-phone>
    </div>
    <el-dialog :title="obj.inner" :visible.sync="dialogVisible" width="450px" :close-on-click-modal="false">
      <view-order @close="closeorder" @pay="pay" @detail="detail" ref="vieworderzin" :obj="obj" v-if="dialogVisible"></view-order>
    </el-dialog>
    <el-dialog :showClose="showbtnclose" title="扫码支付" :visible.sync="dialogVisible1" width="450px" :close-on-click-modal="false">
      <view-result @close="closeresult" :obj="obj" v-if="dialogVisible1" @closebtn="closebtn"></view-result>
    </el-dialog>
    <el-dialog title="结果提示" :visible.sync="dialogVisible2" width="450px" :close-on-click-modal="false">
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submited" size="mini">关闭并打印支付凭证</el-button>
        <el-button @click="close" size="mini">关闭不打印</el-button>
      </span>
    </el-dialog>
    <el-dialog title="挂账信息" :visible.sync="dialogVisibleGua" width="450px" :close-on-click-modal="false">
      <guazhang ref="gua" @close="dialogVisibleGua = false" />
    </el-dialog>
  </div>
</template>

<script>
import number from "@/common/num.js";
import viewOrder from "./payment/paycard.vue"; //支付方式
import viewResult from "./payment/result.vue"; //扫码支付
import viewPhone from "./rightside/rightmiddle"; //右侧部分
import guazhang from "./payment/guazhang.vue"; //挂账
import Eve from "@/common/bus.js";
export default {
  components: {
    viewOrder,
    viewResult,
    viewPhone,
    guazhang,
  },
  created() {
    this.obj.orderNo = this.$route.query.orderNo;
    this.obj.tableId = this.$route.query.tableId;
    this.orderMode = this.$route.query.orderMode;
    this.detail();
  },
  mounted() {
    if (this.orderMode == 2) {
      Eve.$emit("getTarget", "无桌点餐");
    }
  },
  data() {
    var numzin = (rule, value, callback) => {
      if (value) {
        let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
        if (!rez.test(value)) {
          return callback(new Error("支持0.01—999999.99之间"));
        }
        if (
          value >=
          this.orderVo.shouldReceiveAmount / 100 -
            this.orderVo.discountsAmount / 100
        ) {
          return callback(new Error("抹零金额不能大于等于应收金额"));
        }
      }
      callback();
    };
    return {
      dialogVisibleGua: false,
      crmMobile: "",
      openCommonMermbera: "",
      CrmVaildate: 0,
      openMemberStore: localStorage.getItem("openMemberStore"),
      typezin: "3",
      showbtnclose: true,
      youhuiobj: {
        1: "不使用优惠",
        0: "使用优惠",
        4: "抹零",
      },
      loading: false,
      openReduce: "",
      dialogVisible3: false,
      paymentMode: "",
      flag3: false,
      disabled1: true,
      dialogVisible2: false,
      disabled: false,
      disadle: false,
      obj: {
        orderNo: "",
        tableId: "",
        inner: "",
        notCountAmount: "",
        activityType: 1,
        memberpayment: "0",
        username: "",
        phone: "",
        memberId: "",
        paymentType: "",
      },
      dialogVisible: false,
      dialogVisible1: false,
      paymentlist: [
        {
          id: 1,
          name: "现金支付",
          icon: require("@/assets/image/icon_xianjinzhifu@2x.png"),
        },
        {
          id: 2,
          name: "扫码支付",
          icon: require("@/assets/image/icon_saomazhifu@2x.png"),
        },
        {
          id: 3,
          name: "刷卡支付",
          icon: require("@/assets/image/icon_shuakazhifu@2x.png"),
        },
        {
          id: 4,
          name: "其他支付",
          icon: require("@/assets/image/icon_qitazhifu@2x.png"),
        },
        {
          id: 5,
          name: "免单",
          icon: require("@/assets/image/icon_miandan@2x.png"),
        },
        {
          id: 6,
          name: "账户余额支付",
          icon: require("@/assets/image/icon_yuezhifu@2x.png"),
        },
        {
          id: 10,
          name: "挂账",
          icon: require("@/assets/image/guazhang.png"),
        },
      ],
      reduction: [],
      discount: "",
      orderVo: {},
      mer: {},
      rules: {
        paymentType: [
          { required: true, message: "请选择支付方式", trigger: "blur" },
        ],
        notCountAmount: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" },
        ],
      },
      radio1: 1,
      index2: null,
      flag: false,
      flag1: false,
      flag2: true,
      index1: 0,
      act: false,
      resultobj: {},
      printKinds: [],
      printTypes: [],
      printKindsi: "",
      IScrmMobile: false,
    };
  },
  methods: {
    backOrder() {
      if (this.orderMode == 2) {
        return this.$http
          .post("/cashier-api/api/dishers/cleanTable", {
            merchantId: localStorage.getItem("merchantId"),
            orderNo: this.obj.orderNo,
            orderMode: this.orderMode,
          })
          .then((res) => {
            this.$router.push("/notTable");
          });
      }
      this.$router.push({
        path: "/selectTable",
        meta: {
          title: "点餐",
        },
      });
    },
    CrmVaildateCacal() {
      this.$http
        .post("/cashier-api/api/coupon/crm-mobile", {
          crmMobile: "",
          orderNo: this.obj.orderNo,
        })
        .then(
          (res) => {
            this.IScrmMobile = false;
            this.crmMobile = "";
            this.$message({
              message: "取消成功",
              type: "success",
            });
          },
          (e) => {
            this.IScrmMobile = true;
          }
        );
    },
    CrmVaildateClick() {
      let mobile = this.crmMobile;
      if (mobile == "") {
        return this.$message.error("请填写手机号");
      }
      if (!/^1[0-9]\d{9}$/.test(mobile)) {
        return this.$message.error("请填写正确的手机号");
      }
      this.$http
        .post("/cashier-api/api/coupon/crm-mobile", {
          crmMobile: mobile,
          orderNo: this.obj.orderNo,
        })
        .then(
          (res) => {
            this.IScrmMobile = true;
            this.$message({
              message: "验证成功",
              type: "success",
            });
          },
          (e) => {
            this.IScrmMobile = false;
            this.crmMobile = "";
          }
        );
    },
    closebtn() {
      this.showbtnclose = false;
    },
    closeresult(a) {
      this.dialogVisible1 = false;
      if (a.type == 2) {
        if (this.orderMode == 2) {
          return this.$router.push("/notTable");
          //this.submited();
        }
        this.dialogVisible2 = true;
      }
    },
    cashpayment(val, idx, id) {
      this.index2 = idx;
      this.obj.inner = val;
      this.obj.paymentType = id;
      if (id == 10) {
        return (this.dialogVisibleGua = true);
      }
      if (
        this.$refs.viewinfo.dishersInfoList.length < 1 ||
        this.orderVo.reallyReceiveAmount == 0
      ) {
        this.disabled1 = true;
        this.act = false;
      } else {
        this.disabled1 = false; //确认结账按钮
        this.act = true;
      }
    },
    disable() {
      this.disabled1 = true;
      this.act = false;
    },
    pay() {
      this.act = true;
      this.disabled1 = false;
      this.paied();
    },
    paied() {
      this.loading = true;
      let formData = {
        orderNo: this.obj.orderNo,
      };
      formData.orderMode = this.orderMode;
      this.$http
        .post(`/cashier-api/api/order/confirm/paied`, formData)
        .then((res) => {
          this.loading = false;
          if (this.orderMode == 2) {
            return this.$router.push("/notTable");
            //this.submited();
          }
          this.dialogVisible2 = true;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    sonzin(item, idx) {
      this.index1 = idx;
    },
    changeradio(value, a) {
      this.obj.activityType = Number(value);
      if (value == 0) {
        this.obj.notCountAmount = "";
        this.flag = true;
        this.flag1 = false;
      } else if (value == 4) {
        this.flag1 = true;
        this.flag = false;
      } else {
        this.obj.notCountAmount = "";
        this.flag1 = false;
        this.flag = false;
      }
      if (a != 2) {
        this.Member();
      }
    },
    Member() {
      this.loading = true;
      this.$http
        .post(`/cashier-api/api/order/activity/mostFavorable`, {
          merchantId: localStorage.getItem("merchantId"),
          orderNo: this.obj.orderNo,
          memberId: this.obj.memberId,
          memberpayment: this.obj.memberpayment,
          activityType: this.obj.activityType,
        })
        .then((res) => {
          this.loading = false;
          this.discount = res.activityName;
          this.detail();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    handleCommand(command) {
      if (command == "a") {
        this.printKindsi = "0";
      } else if (command == "b") {
        this.printKindsi = "1";
      } else {
        this.printKindsi = "2";
      }
      this.printTypes.push("2", "3");
      this.printKinds.push(this.printKindsi);
      this.printchencken();
    },
    print(val) {
      this.printKinds.push(val);
      this.printTypes = [];
      this.printchencken();
    },
    printchencken() {
      let data = {
        orderId: this.obj.orderNo,
        printTypes: this.printTypes,
        printKinds: this.printKinds,
        printerType: 1,
      };
      if (this.printKindsi == "0") {
        data.printKinds = this.printKinds;
        data.printstatus = "1";
      }
      if (this.orderMode == 2) {
        data.orderMode = this.orderMode;
      }
      this.loading = true;
      this.$http
        .post(`/order-admin/mqtt/print`, {
          ...data,
        })
        .then((res) => {
          this.loading = false;
          this.$message({
            message: "打印成功",
            offset: 160,
            type: "success",
          });
          this.printKinds = [];
          this.printTypes = [];
        })
        .catch((_) => {
          this.loading = false;
          this.printKinds = [];
          this.printTypes = [];
        });
    },
    stap() {
      this.$router.go(-1);
      // this.loading = true;
      // this.$http
      //   .post(`/cashier-api/api/order/goback`, {
      //     merchantId: localStorage.getItem("merchantId"),
      //     orderNo: this.obj.orderNo,
      //   })
      //   .then((res) => {
      //     this.loading = false;
      //     this.$router.push({
      //       path: "payorder",
      //       query: { orderNo: this.obj.orderNo },
      //     });
      //   })
      //   .catch((error) => {
      //     this.loading = false;
      //   });
    },
    leavename() {
      if (this.obj.phone == "") {
        this.$message.error("请填写手机号");
      } else if (!/^1[0-9]\d{9}$/.test(this.obj.phone)) {
        this.$message.error("请填写正确的手机号");
        return false;
      } else {
        this.member();
      }
    },
    member() {
      this.loading = true;
      this.$http
        .post(`/cashier-api/api/order/memberinfo`, {
          merchantId: localStorage.getItem("merchantId"),
          mobile: this.obj.phone,
          orderNo: this.obj.orderNo,
          activityType: this.obj.activityType,
          memberpayment: this.obj.memberpayment,
        })
        .then((res) => {
          this.loading = false;
          this.obj.username = res.data.mermberName;
          if (res.data.memberpayment == 0 && res.data.mermberName != "") {
            return this.$message({
              message: res.data.mermberName,
              type: "warning",
            });
          }
          this.obj.memberId = res.data.memberId;
          this.obj.memberpayment = res.data.memberpayment;
          this.obj.memberCardNo = res.data.memberCardNo;
          this.flagshow();
          this.detail();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    flagshow() {
      if (this.obj.memberpayment == "0") {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    closehuiyuan() {
      this.obj.memberId = "";
      this.obj.memberpayment = "1";
      this.obj.username = "";
      this.obj.phone = "";
      this.obj.memberCardNo = "";
      this.member();
    },
    closeorder(obj) {
      this.dialogVisible = false;
      if (obj) {
        this.flag2 = obj.flag2;
        this.resultobj = obj;
      }
    },
    close() {
      this.dialogVisible2 = false;
      this.$router.push("/selectTable");
    },
    detail() {
      if (!this.obj.orderNo) {
        return;
      }
      this.loading = true;
      let formData = {
        orderNo: this.obj.orderNo,
      };
      if (this.orderMode == 2) {
        formData.orderMode = this.orderMode;
      }

      this.$http
        .post(`/cashier-api/api/order/detail-for-checkout`, formData)
        .then((res) => {
          this.loading = false;
          this.IScrmMobile = !!res.crmMobile;
          this.crmMobile = res.crmMobile;
          this.CrmVaildate = res.haveCouponInfo;
          this.orderVo = res.orderVo;
          this.mer = res.orderVo.merchantInfo;
          this.openReduce = res.orderVo.merchantInfo.openReduce;
          this.openCommonMermbera = res.orderVo.merchantInfo.openCommonMermber; //openCommonMermber=0（0：关闭会员 1：开启会员）时 选择会员的输入框则隐藏
          this.paymentMode = res.orderVo.merchantInfo.paymentMode;
          this.obj.shouldReceiveAmount = this.orderVo.shouldReceiveAmount;
          if (this.paymentMode != "1") {
            this.flag3 = true;
          }
          this.obj.memberpayment = res.orderVo.memberPayment;
          this.obj.memberId = res.orderVo.memberId;
          this.obj.phone = res.orderVo.mobile;
          this.obj.username = res.orderVo.memberName;
          this.obj.notCountAmount = res.orderVo.notCountAmount
            ? res.orderVo.notCountAmount / 100
            : "";
          this.flagshow();
          if (res.orderVo.activityType) {
            this.changeradio(res.orderVo.activityType, 2);
          } else {
            this.changeradio(1);
          }
          this.discount = res.orderVo.activityName;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    orderpay() {
      if (this.index2 == null) {
        this.$message({
          message: "请选择支付方式",
          offset: 160,
          type: "warning",
        });
      }
      this.$refs["obj"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/order/toPay", {
              activityType: this.obj.activityType,
              orderNo: this.obj.orderNo,
              memberpayment: this.obj.memberpayment,
              paymentType: this.obj.paymentType,
              notCountAmount: number(this.obj.notCountAmount),
            })
            .then(async (res) => {
              if (this.obj.inner == "扫码支付") {
                this.dialogVisible1 = true;
              } else if (this.obj.inner == "免单") {
                this.$confirm("是否继续免单?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                }).then(() => {
                  this.payzin();
                });
              } else if (this.obj.inner == "账户余额支付") {
                if (this.obj.memberpayment == "0") {
                  this.loading = false;
                  return this.$message({
                    message: "请校验会员",
                    offset: 160,
                    type: "warning",
                  });
                } else {
                  this.dialogVisible = true;
                }
              } else if (this.obj.paymentType == 10) {
                await this.$http
                  .post("/cashier-api/api/order/offline/payment", {
                    orderNo: this.obj.orderNo,
                    paymentType: this.obj.paymentType,
                    orderMode: this.orderMode,
                    ...this.$refs.gua.getData(),
                  })
                  .then((res) => {
                    this.loading = false;
                    this.$message({
                      type: "success",
                      offset: 160,
                      message: "付款成功!",
                    });
                    if (this.orderMode == 2) {
                      return this.$router.push("/notTable");
                      //this.submited();
                    }
                    this.dialogVisible2 = true;
                  });
              } else {
                this.dialogVisible = true;
              }
              this.loading = false;
              this.obj.shouldReceiveAmount = res.shouldReceiveAmount;
              this.obj.accountBalance = res.accountBalance;
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    payzin() {
      let data = { ...this.obj };
      data.shouldReceiveAmount = number(data.shouldReceiveAmount);
      data.cashPaymentAmount = number(data.cashPaymentAmount);
      data.reallyReceiveAmount = number(data.reallyReceiveAmount);
      data.notCountAmount = number(data.notCountAmount);
      if (this.orderMode == 2) {
        data.orderMode = this.orderMode;
      }
      this.loading = true;
      this.$http
        .post("/cashier-api/api/order/offline/payment", {
          orderNo: this.obj.orderNo,
          ...data,
        })
        .then((res) => {
          this.loading = false;
          this.$message({
            type: "success",
            offset: 160,
            message: "付款成功!",
          });
          let data = {
            ...res.data,
            flag2: false,
          };
          this.detail();
          this.pay();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submited() {
      let data = {
        orderId: this.obj.orderNo,
        printKinds: [3],
        orderMode: this.orderMode,
        printerType: 1,
      };
      if (this.orderMode == 2) {
        data.orderMode = this.orderMode;
      }
      this.loading = true;
      this.$http
        .post(`/order-admin/mqtt/print`, {
          ...data,
        })
        .then((res) => {
          this.loading = false;
          this.$message({
            message: "打印成功",
            offset: 160,
            type: "success",
          });
          this.$router.push("/selectTable");
        })
        .catch((_) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.pay-icon {
  width: 16px;
  margin-right: 7px;
}
.btn-clor {
  font-size: 14px;
  color: #ff1b1b;
  margin-left: 10px;
  cursor: pointer;
}
.user-text {
  background: #e4f2ff;
  border-radius: 2px;
  display: inline-block;
  font-size: 14px;
  color: #333333;
  padding: 0 5px;
  margin-left: 10px;
}
.view_contant {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.contant_left {
  width: calc(100% - 530px);
  border: 1px solid #dedede;
  position: relative;
  height: 100%;
}
.contant_top {
  height: calc(100% - 70px);
  margin-bottom: 70px;
  overflow: auto;
}
.footer_zin {
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  margin-top: 15px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-width {
  width: 238px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.dayinOrder {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 80px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.dayinReceipt {
  width: 100px;
  height: 40px;
  border: 1px solid #108af9;
  border-radius: 2px;
  color: #108af9;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
}
.dayinReceipt:nth-of-type(2) {
  margin-left: 10px;
}
.font {
  color: #ff6969;
}
.footer_btn button {
  width: 160px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #108af9;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 0;
}
.footer_btn button:nth-of-type(2) {
  background: #999999;
  color: #ffffff;
  margin-left: 0;
}
.phone_inner {
  width: 38%;
  border: 1px solid #dedede;
  margin: 0 1%;
}
.price {
  width: 200px;
  margin-right: 20px;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
}
.price:nth-of-type(1) {
  margin-left: 53px;
}
.price:nth-of-type(2) {
  margin-left: 20px;
}
.price_alls {
  display: flex;
  justify-content: flex-start;
  margin-top: 13px;
}
.price div:nth-of-type(1) {
  height: 26px;
  font-size: 14px;
  background-color: #108af9;
  color: #fff;
  text-align: center;
  line-height: 26px;
}

.price div:nth-of-type(2) {
  padding: 8px 0;
  font-size: 20px;
  color: #000;
  text-align: center;
  border: 1px solid #dcdee0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.price .red {
  color: #ff6969 !important;
}
.yel div:nth-of-type(1) {
  background-color: #feb822;
}
.green div:nth-of-type(1) {
  background-color: #11cd6e;
}
.phone_tazy_sub {
  width: 400px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-left: 10px;
  position: relative;
}
.header_phone {
  height: 111px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header_phone div {
  display: flex;
  justify-content: space-between;
  margin: 0 26px;
  font-size: 22px;
}
.header_phone div:nth-of-type(2) {
  font-size: 14px;
  margin-top: 14px;
}
.header_container {
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
}
.header_container div {
  height: 66px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.price_all {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price_all span:nth-of-type(2) {
  color: #ff6969;
}
.reductionall {
  display: flex;
  justify-content: flex-start;
}
.redioyou,
.redioyou1 {
  height: 36px;
  padding: 0 10px;
  border: 1px solid #dcdee0;
  color: #333333;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-right: 20px;
  cursor: pointer;
}
.redioyou1 img {
  margin-left: 4px;
}
.active {
  border: 1px solid #108af9;
  color: #108af9;
}
.redioyou img {
  width: 25px;
}
.spansize {
  width: 75%;
  font-size: 12px;
  text-align: center;
}
.actclass {
  background: #108af9 !important;
  color: white !important;
}
.icon {
  color: #feb822;
  margin-right: 5px;
}
.btn {
  margin-left: 20px;
  color: #108af9;
  border: 1px solid #108af9;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 0;
}
.box_op {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 12px;
}
.box_op > span:nth-of-type(1) {
  margin-left: 45px;
}
.box_op > span:nth-of-type(2) {
  margin-left: 22px;
}
.box_op > span > span {
  margin-left: 10px;
}
.close_btn {
  margin-left: 25px;
  background: #ff6969;
  border-radius: 2px;
  border: none;
  text-align: center;
}
</style>
