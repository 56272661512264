<template>
  <div style="display:none">
    <form ref="form" :action="url" :method="Meth" id="form" target="if">
      <!-- method="post"  -->
    </form>
    <iframe ref="iframe" name="if" src></iframe>
  </div>
</template>

<script>
import { baseurl, sim_url } from "@/base.js";
export default {
  created() {
    if (this.source) {
      this.url = this.source + this.URL;
    } else {
      this.url = this.baseurl + this.URL;
    }
    !this.Method && (this.Meth = "post");
  },
  props: ["URL", "Method", "source"],
  data() {
    return {
      baseurl,
      url: "/",
      Meth: "get"
    };
  },
  watch: {
    URL(n) {
      if (this.source) {
        this.url = this.source + this.URL;
      } else {
        this.url = this.baseurl + this.URL;
      }
    }
  },
  methods: {
    export(searchData) {
      let str = "";
      for (let i in searchData) {
        if (!searchData[i]) {
          continue;
        }
        str += `<input type="text" name="${i}" value="${searchData[i]}" />`;
      }
      this.$refs["form"].innerHTML = str;
      this.$refs["form"].submit();
    }
  }
};
</script>

<style scoped>
</style>