<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="菜品名称" prop="dishersName">
          <el-input v-model="formInline.dishersName"></el-input>
        </el-form-item>
        <el-form-item label="销售时间">
          <el-date-picker
            v-model="formInline.value1"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
          >查询</el-button
        >
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
    >
      <el-table-column label="" width="50px" v-if="page.pageNo == 1">
        <template slot-scope="scope">
          <img v-if="scope.$index<3" class="line-3" :src="logom[scope.$index]" alt="" />
        </template>
      </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
      <el-table-column prop="salesQuantity" label="销售数量"></el-table-column>
      <!-- <el-table-column prop="amount" label="销售金额"></el-table-column> -->
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  created(){
    this.searchzin();
  },
  data() {
    return {
      logom: [
        require("@/assets/image/1.png"),
        require("@/assets/image/2.png"),
        require("@/assets/image/3.png"),
      ],
      But: window.UserPower.Salesstatistics.transactionAdminSear,
      formInline: {
        dishersName: "",
        salesDateStart: "",
        value1: null,
        salesDateEnd: "",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      loading: false,
    };
  },
  methods: {
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.salesDateStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.salesDateEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/statistic/sale/s/page", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
  },
};
</script>

<style scoped>
.line-3 {
  width: 25px;
  position: relative;
  top: 5px;
}
</style>


