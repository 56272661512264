<template>
  <div v-loading="loading">
    <div style="margin-left:44px">
      <input type="text" v-model="formzinernil.name" ref="barCodeInput" @change="zint" style="border:none;outline:0;color: transparent;" />
    </div>
    <div v-if="!paymentstatus" class="box_zin">
      <span>应收金额</span>
      <span>{{obj.shouldReceiveAmount|money}}元</span>
      <el-button type="primary" size="mini" @click="focus" class="btn">扫描客户付款码</el-button>
    </div>
    <div class="box" v-if="paymentstatus=='2'||paymentstatus=='3'||paymentstatus=='4'">
      <div class="box_zin_v">
        <p>
          应收金额:
          <span class="col">{{obj.shouldReceiveAmount|money}}元</span>
        </p>
        <p>支付结果:{{result}}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="payzint" size="mini" v-if="paymentstatus=='4'">重新付款</el-button>
        <el-button type="primary" @click="submitedzin" size="mini" v-if="paymentstatus!='4'">确认收款</el-button>
        <el-button @click="closezint" size="mini">取 消</el-button>
      </span>
    </div>
  </div>
</template>

<script>
import number from "@/common/num.js";
export default {
  created() {
    this.loading = false;
  },
  data() {
    return {
      formzinernil: {
        name: "",
      },
      loading: false,
      result: "",
      paymentstatus: "",
      atype: "",
    };
  },
  props: { obj: Object },
  methods: {
    focus() {
      this.loading = true;
      this.timer = setTimeout(() => {
        this.$refs["barCodeInput"].focus();
      }, 100);
    },
    payzint() {
      this.formzinernil.name = "";
      this.paymentstatus = "1";
      this.atype = "1";
    },
    zint(value) {
      if (value) {
        this.$refs["barCodeInput"].blur();
        this.formzinernil.name = value.target.value;
        this.loading = true;
        this.$http
          .post("/cashier-api/api/orderNew/scanPay", {
            state: this.formzinernil.name,
            orderNo: this.obj.orderNo,
            activityType: this.obj.activityType,
            activityNo: this.obj.activityNo,
            mobile: this.obj.mobile,
            notCountAmount: number(this.obj.notCountAmount),
          })
          .then((res) => {
            this.loading = false;
            this.result = res.data.paymentStatus;
            this.paymentstatus = res.data.paymentStatusStr;
            if (res.data.paymentStatus == "2") {
              this.$emit("close");
            }
          })
          .catch(() => {
            this.formzinernil.name = "";
            this.focus();
            this.loading = false;
          });
      }
    },
    submitedzin() {
      this.$emit("close");
    },
    closezint() {
      this.$emit("cancal");
    },
  },
};
</script>

<style  scoped>
.contan {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
span:nth-of-type(1) {
  margin-bottom: 6px;
}
span:nth-of-type(2) {
  font-size: 24px;
  color: #ff6969;
  margin-bottom: 38px;
}
.icon {
  color: #feb822;
  margin-right: 5px;
}
.img {
  width: 60px;
  margin-bottom: 13px;
}
.btni {
  margin-top: 20px;
}
.box_zin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
}
.box_zin_t {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box_zin_t p {
  padding: 2px 40px;
  line-height: 22px;
}
.box_zin_v {
  width: 100%;
  padding-left: 60px;
  margin-bottom: 15px;
}
.box_zin_v p {
  margin-top: 20px;
}
.col {
  color: #ff6969;
}
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.box {
  width: 100%;
}
</style>