import VueRouter from "vue-router";
import index from "../view/index";
import login from "../view/login";
import agree from "../view/agree"; //协议
import serve from "../view/serve"; //服务台
import home from "../view/page/home/index.vue"; //首页
import editPw from "../view/page/editPw/editPw.vue"; //修改密码
import information from "../view/page/RestRoomSet/Restaurant/information.vue"; //餐厅信息
import searchMechanism from "../view/page/RestRoomSet/searchMechanism/device.vue"; //打印机设置
import MechanismEdit from "../view/page/RestRoomSet/searchMechanism/edit.vue"; //编辑打印机
import Staffmanagement from "../view/page/RestRoomSet/Staffmanagement"; //员工管理
import Stafftree from "../view/page/RestRoomSet/Staffmanagement/tree"; //权限管理添加
import Preferential from "../view/page/Preferential/promotion"; //优惠促销 优惠活动
import addreduction from "../view/page/Preferential/promotion/addreduction.vue"; //优惠活动 添加满减活动
import addDiscount from "../view/page/Preferential/promotion/addDiscount.vue"; //优惠活动 添加打折活动
import Discountdetail from "../view/page/Preferential/promotion/detailes.vue"; //优惠活动  活动详情
import shareProfitAdminHis from "../view/page/Preferential/shareProfitAdminHis"; //特价菜品 特价活动
import addsharePro from "../view/page/Preferential/shareProfitAdminHis/add.vue"; //特价菜品 添加特价
import equipmentAdminCode from "../view/page/Restauranttable/equipmentAdminCode.vue"; //餐厅位置
import equipmentAdminBoard from "../view/page/Restauranttable/equipmentAdminBoard.vue"; //桌位信息
import addSalesman from "../view/page/variety/classification"; //菜品管理  菜品分类
import Dishesinformation from "../view/page/variety/sinformation"; //菜品管理  菜品信息
import adddashed from "../view/page/variety/sinformation/adddashed.vue"; //菜品管理 添加菜品
import Recommended from "../view/page/variety/Recommended"; //菜品管理  推荐菜品
import shanghuSearchInfo from "../view/page/order/shanghuSearchInfo.vue"; //订单管理 订单查询
import detailtwo from "../view/page/order/detailtwo.vue"; //订单管理详情
import transactionAdminSear from "../view/page/Salesstatistics/transactionAdminSear.vue"; //数据管理 销售统计
import transactionAdminQuit from "../view/page/Salesstatistics/transactionAdminQuit.vue"; //数据管理 流水统计
import tableCount from "../view/page/Salesstatistics/tableCount.vue"; //桌区统计
import Membership from "../view/page/Membership/Membermesage/Membership.vue"; //会员信息
import huiyuandetail from "../view/page/Membership/Membermesage/detaile.vue"; //会员详情
import MemberOrder from "../view/page/Membership/Memberorder"; //会员订单查询
import MemberType from "../view/page/Membership/Membershiptype"; //会员类型管理
import MemberActivity from "../view/page/Membership/Membershiprecharge"; //充值优惠
import Membershiprechargeadd from "../view/page/Membership/Membershiprecharge/add.vue"; //添加满送活动
import statusopen from "../view/page/statusopen/statusopen.vue"; //点餐
import notTable from "../view/page/notTable/index.vue"; //无桌点餐
import payorder from "../view/page/statusopen/payorder.vue"; //点餐中点餐功能
import topay from "../view/page/statusopen/topay.vue"; //点餐结账
import toorder from "../view/page/statusopen/toorder.vue"; //点餐下单
import orderdetail from "../view/page/orderdetail"; //订单服务台
import detail from "../view/page/orderdetail/details"; //订单详情
import servicemember from "../view/page/servicemember"; //会员服务台
import memberrecharge from "../view/page/servicemember/recharge/"; //会员
import clearOrder from "../view/page/orderdetail/clearOrder"; //清单查询
//库存管理
import stockBill from "../view/page/stockAdmin/stockBill";//库存流水
import stockSarch from "../view/page/stockAdmin/stockSarch";//库存管理
//开始点餐新版本
import selectTable from "../view/page/startPointDisher/selectTable";//开始选桌子
import selectMenu from "../view/page/startPointDisher/selectMenu";//开始点菜
import toPayment from "../view/page/startPointDisher/toPayment";//去支付
import changeTable from '@/view/page/startPointDisher/changeTable'//换桌子
export default new VueRouter({
  // mode: "history",
  routes: [{
    path: "/",
    name: "index",
    component: index,
    redirect: "/home",
    children: [{
      path: "/clearOrder",
      name: "clearOrder",
      component: clearOrder,
      meta: {
        title: "清台记录",
      }
    }, {
      path: "/editPw",
      name: "editPw",
      component: editPw,
      meta: {
        title: "修改密码",
      }
    },
    {
      path: "/tableCount",
      name: "tableCount",
      component: tableCount,
    },
    {
      path: "/stockBill",
      name: "stockBill",
      component: stockBill,
    },
    {
      path: "/stockSarch",
      name: "stockSarch",
      component: stockSarch,
    },

    {
      path: "/detailtwo",
      name: "detailtwo",
      component: detailtwo,
    },
    {
      path: "/information",
      name: "information",
      component: information,
      meta: {
        title: "餐厅信息",
      }
    },
    {
      path: "/searchMechanism",
      name: "searchMechanism",
      component: searchMechanism,
      meta: {
        title: "设备管理",
      }
    },
    {
      path: "/MechanismEdit",
      name: "MechanismEdit",
      component: MechanismEdit,
      meta: {
        title: "编辑打印机",
      }
    },
    {
      path: "/Staffmanagement",
      name: "Staffmanagement",
      component: Staffmanagement,
      meta: {
        title: "员工管理",
      }
    },
    {
      path: "/Stafftree",
      name: "Stafftree",
      component: Stafftree,
      meta: {
        title: "权限管理",
      }
    },
    {
      path: "/Preferential",
      name: "Preferential",
      component: Preferential,
      meta: {
        title: "优惠活动",
      }
    }, {
      path: "/Discountdetail",
      name: "Discountdetail",
      component: Discountdetail,
      meta: {
        title: "活动详情",
      }
    },
    {
      path: "/shareProfitAdminHis",
      name: "shareProfitAdminHis",
      component: shareProfitAdminHis,
      meta: {
        title: "特价菜品",
      }
    },
    {
      path: "/addsharePro",
      name: "addsharePro",
      component: addsharePro,
      meta: {
        title: "添加特价",
      }
    },
    {
      path: "/addreduction",
      name: "addreduction",
      component: addreduction,
      meta: {
        title: "添加满减活动",
      }
    },
    {
      path: "/addDiscount",
      name: "addDiscount",
      component: addDiscount,
      meta: {
        title: "添加打折活动",
      }
    },
    {
      path: "/home",
      name: "home",
      component: home,
      meta: {
        title: "",
      }
    },
    {
      path: "/equipmentAdminCode",
      name: "equipmentAdminCode",
      component: equipmentAdminCode,
      meta: {
        title: "餐厅位置",
      }
    },
    {
      path: "/equipmentAdminBoard",
      name: "equipmentAdminBoard",
      component: equipmentAdminBoard,
      meta: {
        title: "桌位信息",
      }
    },
    {
      path: "/addSalesman",
      name: "addSalesman",
      component: addSalesman,
      meta: {
        title: "菜品分类",
      }
    },
    {
      path: "/Recommended",
      name: "Recommended",
      component: Recommended,
      meta: {
        title: "推荐菜品",
      }
    },
    {
      path: "/Dishesinformation",
      name: "Dishesinformation",
      component: Dishesinformation,
      meta: {
        title: "菜品信息",
      }
    },
    {
      path: "/adddashed",
      name: "adddashed",
      component: adddashed,
      meta: {
        title: "添加菜品",
      }
    },
    {
      path: "/shanghuSearchInfo",
      name: "shanghuSearchInfo",
      component: shanghuSearchInfo,
      meta: {
        title: "订单管理",
      }
    },
    {
      path: "/transactionAdminSear",
      name: "transactionAdminSear",
      component: transactionAdminSear,
      meta: {
        title: "销售统计",
      }
    },
    {
      path: "/transactionAdminQuit",
      name: "transactionAdminQuit",
      component: transactionAdminQuit,
      meta: {
        title: "流水统计",
      }
    },
    {
      path: "/Membership",
      name: "Membership",
      component: Membership,
      meta: {
        title: "会员管理",
      }
    },
    {
      path: "/huiyuandetail",
      name: "huiyuandetail",
      component: huiyuandetail,
      meta: {
        title: "会员管理",
      }
    },
    {
      path: "/MemberOrder",
      name: "MemberOrder",
      component: MemberOrder,
      meta: {
        title: "会员订单查询",
      }
    },
    {
      path: "/MemberType",
      name: "MemberType",
      component: MemberType,
      meta: {
        title: "会员类型管理",
      }
    },
    {
      path: "/MemberActivity",
      name: "MemberActivity",
      component: MemberActivity,
      meta: {
        title: "充值优惠",
      }
    },
    {
      path: "/Membershiprechargeadd",
      name: "Membershiprechargeadd",
      component: Membershiprechargeadd,
      meta: {
        title: "充值优惠",
      }
    },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: login
  }, {
    path: "/serve",
    name: "serve",
    component: serve,
    redirect: '/selectTable',
    children: [
      {
        path: "/changeTable",
        name: "changeTable",
        component: changeTable,
        meta: {
          title: "点餐",
        }
      },
      {
        path: "/selectTable",
        name: "selectTable",
        component: selectTable,
        meta: {
          title: "点餐",
        }
      },
      {
        path: "/selectMenu",
        name: "selectMenu",
        component: selectMenu,
        meta: {
          title: "点餐",
        }
      },
      {
        path: "/toPayment",
        name: "toPayment",
        component: toPayment,
        meta: {
          title: "去支付",
        }
      },
      {
        path: "/statusopen",
        name: "statusopen",
        component: statusopen,
        meta: {
          title: "点餐",
        }
      },
      {
        path: "/notTable",
        name: "notTable",
        component: notTable,
        meta: {
          title: "无桌点餐",
        }
      }, {
        path: "/orderdetail",
        name: "orderdetail",
        component: orderdetail,
        meta: {
          title: "订单",
        }
      }, {
        path: "/detail",
        name: "detail",
        component: detail,
      }, {
        path: "/servicemember",
        name: "servicemember",
        component: servicemember,
        meta: {
          title: "会员",
        }
      },
      {
        path: "/memberrecharge",
        name: "memberrecharge",
        component: memberrecharge,
      },
      {
        path: "/payorder",
        name: "payorder",
        component: payorder,
      }, {
        path: "/topay",
        name: "topay",
        component: topay,
      },
      {
        path: "/toorder",
        name: "toorder",
        component: toorder,
      },
    ]
  }, {
    path: "/agree",
    name: "agree",
    component: agree
  }
  ]
});
