<template>
  <div style="width: 100%; height: 100%" :loading="loading"></div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  created() {
    this.beginCreateOrder();
  },
  computed: mapState({
    orderInfo: (state) => state.shopingCard.shopingCardOrder,
  }),
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    ...mapActions(["getShopingCard"]),
    async beginCreateOrder() {
      let res = await this.$http.post(
        "/cashier-api/api/orderNew/beginCreateOrder",
        {
          merchantId: localStorage.getItem("merchantId"),
          orderMode: 2,
        }
      );
      await this.getShopingCard({ orderNo: res.data });
      let indexPage = this.orderInfo.indexPage;
      if (indexPage == 2 || indexPage == 3) {
        this.$router.push({
          path: "selectMenu",
          query: { orderNo: res.data, orderMode: 2 },
        });
      }
      if (indexPage == 4 || indexPage == 5) {
        this.$router.push({
          path: "toPayment",
          query: { orderNo: res.data, orderMode: 2 },
        });
      }
    },
  },
};
</script>

<style scoped>
</style>