<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="菜品分类" prop="categoryId">
          <el-select v-model="formInline.categoryId" placeholder="请选择" clearable>
            <el-option
              v-for="item in equipmentlist"
              :key="item.categoryId"
              :label="item.categoryName"
              :value="item.categoryId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜品名称" prop="dishersName">
          <el-input v-model.trim="formInline.dishersName"></el-input>
        </el-form-item>
        <el-form-item label="添加时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="特价时间">
          <el-date-picker
            v-model="formInline.value2"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
        >查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <el-button v-if="But.Cancel.power()" @click="close" size="mini">取消特价</el-button>
          <el-button v-if="But.Add.power()" type="primary" @click="add" size="mini">添加特价菜品</el-button>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="dishersInfo.dishersName" label="菜品名称"></el-table-column>
      <el-table-column prop="dishersInfo.categoryName" label="菜品分类"></el-table-column>
      <el-table-column prop="dishersInfo.disherUnitPriceSearchVoList" label="计价方式">
        <template
          slot-scope="scope"
        >{{options1[scope.row.dishersInfo.disherUnitPriceSearchVoList[0].priceType]}}</template>
      </el-table-column>
      <el-table-column prop="dishersInfo.disherUnitPriceSearchVoList[0].price" label="单价">
        <template
          slot-scope="scope"
        >{{scope.row.dishersInfo.disherUnitPriceSearchVoList[0].price|money}}</template>
      </el-table-column>
      <el-table-column prop="dishersInfo.disherUnitPriceSearchVoList[0].memberPrice" label="会员价">
        <template
          slot-scope="scope"
        >{{scope.row.dishersInfo.disherUnitPriceSearchVoList[0].memberPrice|money}}</template>
      </el-table-column>
      <el-table-column prop="bargainPrice" label="特价">
        <template slot-scope="scope">{{scope.row.bargainPrice|money}}</template>
      </el-table-column>
      <el-table-column prop="createTm" label="添加时间" width="150px"></el-table-column>
      <el-table-column prop="bargainStartTm" label="特价时间" width="300px">
        <template slot-scope="scope">{{scope.row.bargainStartTm}}-{{scope.row.bargainEndTm}}</template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.loadlist();
   // if (this.$route.query.type) {
      this.search();
  //  }
  },
  data() {
    return {
      loading: false,
      But: window.UserPower.Preferential.shareProfitAdminHis,
      options1: {
        0: "常规计价",
        // 1: "称重计价",
        2: "份量计价",
        3: "时价"
      },
      formInline: {
        dishersName: "",
        categoryId: "",
        value1: null,
        value2: null,
        createTmStart: "",
        createTmEnd: "",
        bargainStartTm: "",
        bargainEndTm: ""
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      equipmentlist: [],
      multipleSelection: []
    };
  },
  methods: {
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    add() {
      this.$router.push("/addsharePro");
    },
    change(indexzin, tid) {
      let inner = indexzin == 0 ? "停用" : "启用";
      this.loading = true;
      this.$http
        .post(
          `/order-admin/w/disher/bargain/modify/status/single/${tid}/${indexzin}`,
          {}
        )
        .then(data => {
          this.loading = false;
          this.$message({
            message: `${inner}成功`,
            type: "success"
          });
          this.search();
        })
        .catch(error => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    close() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择要取消特价的菜品",
          type: "warning"
        });
      } else {
        let bargainIds = [];
        for (let i in this.multipleSelection) {
          bargainIds.push(this.multipleSelection[i].tId);
        }
        this.loading = true;
        this.$http
          .post(`/order-admin/w/disher/bargain/modify/status/batch`, {
            status: 0,
            bargainIds: bargainIds
          })
          .then(data => {
            this.loading = false;
            this.$message({
              message: "取消特价成功",
              type: "success"
            });
            this.search();
          })
          .catch(error => {
            this.loading = false;
          });
      }
    },
    search() {
      this.formInline.createTmStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.createTmEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.formInline.bargainStartTm = this.formInline.value2
        ? this.formInline.value2[0]
        : "";
      this.formInline.bargainEndTm = this.formInline.value2
        ? this.formInline.value2[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/disher/bargain/s/page", {
          ...this.formInline,
          ...this.page
        })
        .then(res => {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.total = +res.data.total;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    loadlist() {
      this.$http.post("/order-admin/w/disher/category/s/all", {}).then(res => {
        this.equipmentlist = res.data;
      });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
      this.formInline.value2 = [];
    }
  }
};
</script>

<style scoped>
</style>


