<template>
  <div>
    <div class="view-top_zin">
      <img :src="count" />
    </div>
    <div class="view_font">
      <!-- <div @click="zintclick" class="mer_name">
        <p>{{obj.merchantName}}</p>
        <img src="@/assets/image/icon_off.png" />
      </div>
      <div v-if="flagzin" @click="zmark_none">
        <div class="list_pl">
          <div @click="setpass">账号设置</div>
          <div @click="tapmer" v-if="flag==1">切换账号</div>
          <div @click="logout">退出</div>
        </div>
      </div>-->
      <el-dropdown trigger="click" placement="bottom">
        <span class="el-dropdown-link">
          <p>{{obj.merchantName}}</p>
          <img src="@/assets/image/icon_off.png" />
        </span>
        <el-dropdown-menu slot="dropdown" class="dolt">
          <el-dropdown-item @click.native="handleCommand('a')">账号设置</el-dropdown-item>
          <el-dropdown-item v-if="flag==1" @click.native="handleCommand('c')">切换账号</el-dropdown-item>
          <el-dropdown-item @click.native="handleCommand('b')">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <p class="mername">{{countname}}</p>
      <button @click="closezin">返回服务台</button>
    </div>
    <el-menu
      active-text-color="#108AF9"
      text-color="#777777"
      class="el-menu-vertical-demo"
      :unique-opened="true"
      :router="true"
    >
      <template v-for="item in menuList">
        <el-menu-item
          v-if="!item.children.length"
          :key="item.menuName"
          index="/home"
          class="zyinde"
        >
          <i :class="item.icon" class="icon"></i>
          <span>{{item.menuName}}</span>
        </el-menu-item>
        <el-submenu v-if="item.children.length" :index="item.menuName" :key="item.menuName">
          <template slot="title">
            <i :class="item.icon" class="icon"></i>
            <span>{{item.menuName}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="trem in item.children"
              :key="trem.menuName"
              :index="trem.menuUrl"
            >{{trem.menuName}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
      </template>
    </el-menu>

    <el-dialog
      title="切换商户"
      :visible.sync="dialogVisiblezint"
      :center="true"
      width="420px"
      :close-on-click-modal="false"
    >
      <view-mer
        class="h100"
        v-if="dialogVisiblezint"
        :merList="merList"
        @close="close"
        @menulist="menulist"
      ></view-mer>
    </el-dialog>
  </div>
</template>

<script>
import viewMer from "../view/page/mer/mer.vue";
export default {
  components: {
    viewMer,
  },
  created() {
    this.flag = localStorage.getItem("hasMultipleMerchant");
    this.menuList = JSON.parse(localStorage.getItem("menuList"));
    this.obj = JSON.parse(localStorage.getItem("merchantInfoVo"));
  },
  data() {
    return {
      dialogVisiblezint: false,
      flag: "",
      obj: {},
      menuList: [],
      flagzin: false,
    };
  },
  computed: {
    count() {
      return this.$store.state.manageImgUrl;
    },
    countname() {
      return this.$store.state.restaurantName;
    },
  },
  props: ["data"],
  methods: {
    menulist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/auth/user-menu", {
          loginName: localStorage.getItem("loginName"),
        })
        .then((data) => {
          this.loading = false;
          this.merinfo();
          this.$nextTick((_) => {
            localStorage.setItem("menuList", JSON.stringify(data.backurl));
            localStorage.setItem("frontUrl", JSON.stringify(data.frontUrl));
            localStorage.setItem("PowerButtons", JSON.stringify(data.buttons));
          });
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    merinfo() {
      this.loading = true;
      this.$http
        .get(`/order-admin/w/base/merchantInfo`)
        .then((res) => {
          this.loading = false;
          localStorage.setItem(
            "merchantInfoVo",
            JSON.stringify(res.merchantInfoVo)
          );
          this.obj.merchantName = res.merchantInfoVo.merchantName;
          localStorage.setItem("merchantName", res.merchantInfoVo.merchantName);
          this.$store.commit("geturl", res.merchantInfoVo.manageImgUrl);
          this.$store.commit("getname", res.merchantInfoVo.restaurantName);
          //1-先付费模式，2-后付费模式，3-后付费确认模式
          localStorage.setItem("paymentMode", res.merchantInfoVo.paymentMode);
          location.reload();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    handleCommand(command) {
      if (command == "b") {
        this.logout();
      } else if (command == "a") {
        this.setpass();
      } else {
        this.dialogVisiblezint = true;
      }
    },
    closezin() {
      this.$router.push("/serve");
    },
    zintclick() {
      this.flagzin = true;
    },
    zmark_none() {
      this.flagzin = false;
    },
    tapmer() {
      this.dialogVisiblezint = true;
    },
    close() {
      this.dialogVisiblezint = false;
    },
    logout() {
      this.$http.post("/order-admin/logout", {}).then((res) => {
        this.$message({
          message: "退出成功",
          type: "success",
        });
        this.$router.push("/login");
        localStorage.removeItem("tokenIdzin");
      });
    },
    setpass() {
      this.$router.push("/editPw");
    },
  },
};
</script>

<style scoped>
.view-top_zin {
  display: flex;
  justify-content: center;
}
.view-top_zin img {
  width: 100px;
  height: 100px;
  margin: 30px 0;
  border-radius: 50%;
}
.view-top_zin i {
  margin: 20px 0 0 20px;
}
.bell {
  position: relative;
}
.dot {
  width: 6px;
  height: 6px;
  background: #108af9;
  position: absolute;
  border-radius: 50%;
  top: 20px;
  left: 29px;
}
.el-icon-message-solid {
  color: #a3b7ca;
}
.view_font {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #333333;
  text-align: center;
  line-height: 18px;
}
.mername {
  font-size: 14px;
  color: #666666;
  margin-top: 8px;
}
button {
  width: 158px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border: 0;
  background: #108af9;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  margin: 30px 0 40px 0;
}
.icon {
  font-size: 16px;
  margin-right: 10px;
}
.title:active {
  color: #108af9;
}
.el-dropdown-link p {
  font-size: 16px;
  color: #666666;
}
.el-dropdown-link img {
  margin-left: 4px;
}
.mer_name {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mer_name img {
  width: 15px;
  height: 9px;
  margin-left: 5px;
}
</style>
