<template>
  <div class="container">
    <div class="disherlist">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane :label="item.tableRegionName" :name="item.tableRegionId" v-for="item in roomList" :key="item.tableRegionId"></el-tab-pane>
      </el-tabs>
      <div class="disherlist-content" v-loading="loading">
        <div class="content-left">
          <div class="status-ul">
            <div class="status-li" :class="{'no-active':item.code!=avtiveStatus}" v-for="item in tableStatusList" :key="item.code" @click="seleckStatus(item.code)">
              <span :style="{'border-color':item.color,'background-color':item.color}">
                <img src="@/assets/image/icon_duigou.png" alt="">
              </span>
              {{item.title}}({{item.tableCount}})
            </div>
            <span class="status-reload">
              <el-button type="primary" size="mini" @click="getTableInfoAndRegionList">刷新</el-button>
            </span>
          </div>
          <div class="table-ul" v-show="tableList.length!=0">
            <div class="table-li" :class="{activetable:item.tableId==activeTable}" @click="clickTable(item)" :style="{'background-image':statusMap[item.tableStatus].bg}" v-for="item in tableList"
              :key="item.tableId">
              <img v-if="item.tableStatus==0" class="table-img" src="@/assets/image/img-xuanzhong-1.png" alt="">
              <img v-if="item.tableStatus==1" class="table-img" src="@/assets/image/img-xuanzhong-2.png" alt="">
              <div class="table-name">{{item.tableName}}</div>
              <div class="table-status">{{statusMap[item.tableStatus].text}}</div>
              <div class="table-info">
                <span v-if="item.dinersCount">{{item.dinersCount}}/</span>{{item.seatingCapacity}}座
              </div>
            </div>
          </div>
          <div v-show="firstLoading&&tableList.length==0" class="empty-list empty-height">
            <img src="@/assets/image/img_wuxinxi.png" alt="">
            <div>当前页面无桌位，请添加桌位信息</div>
          </div>
        </div>
        <div class="content-right">
          <transition name="el-zoom-in-top">
            <div class="tableview" v-if="tableInfo">
              <div class="tableview-header">
                <div class="tableview-name">桌区：{{tableInfo.tableRegionName}}</div>
                <div class="tableview-site">桌位：{{tableInfo.tableName}}</div>
                <div class="tableview-status">
                  <span class="c108af9" v-if="tableInfo.tableStatus">{{statusMap[tableInfo.tableStatus].text}}</span>
                  {{tableInfo.seatingCapacity}}座
                </div>
              </div>
              <div class="tableview-content">
                <template v-if="tableInfo.tableStatus==1">
                  <div class="order-num"><span class="order-label">订餐人数：</span> {{tableInfo.dinersCount}}</div>
                  <div class="order-time"><span class="order-label">预约时间：</span>{{tableInfo.reservedTime}}</div>
                  <div class="order-remark-text"><span class="order-label">备注：</span>{{tableInfo.reservedRemark}}</div>
                </template>
                <div>
                  <el-button @click="startOrder" class="btn-order" type="primary">点餐</el-button>
                </div>
                <div @click="reservationOrder" v-if="tableInfo.tableStatus==0">
                  <el-button class="btn-before">预定</el-button>
                </div>
                <div @click="cancalOrder" v-if="tableInfo.tableStatus==1">
                  <el-button class="btn-order" type="danger">取消预定</el-button>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <el-dialog title="预定" :visible.sync="dialogVisiblezin" width="450px" append-to-body :close-on-click-modal="false">
      <reservationTable @close="dialogVisiblezin=false" :tableId="TableId" @load="getTableInfoAndRegionList" />
    </el-dialog>
  </div>
</template>
<script>
import reservationTable from "./reservationTable.vue";
import mqtt, { getMerchantInfoVo } from "../mqtt.js";
export default {
  components: { reservationTable },
  name: "selectTable",
  created() {
    this.getRegionList();
    this.getTableInfoAndRegionList();
    this.MQTT = mqtt({
      themeNo: getMerchantInfoVo().merchantId,
      messageFn: () => {
        this.getTableInfoAndRegionList();
      },
    });
  },
  beforeDestroy() {
    this.MQTT.end();
  },
  data() {
    return {
      firstLoading: false,
      MQTT: null,
      dialogVisiblezin: false, //预定
      loading: false,
      merchantId: localStorage.getItem("merchantId"),
      activeName: "-1", //选中房间
      avtiveStatus: "-2",
      activeTable: null,
      roomList: [{ tableRegionId: "-1", tableRegionName: "全部" }], //房间列表
      tableList: [], //桌位列表
      tableStatusList: [], //桌位状态列表,
      tableInfo: null,
      TableId: null,
      statusMap: {
        0: {
          text: "",
          bg: "none",
        },
        1: {
          text: "已预定",
          bg: "linear-gradient(-180deg, #85f700 0%, #2bb611 100%)",
        },
        2: {
          text: "就餐中",
          bg: "linear-gradient(-180deg, #25befd 0%, #108af9 100%)",
        },
        3: {
          text: "",
          bg: "none",
        },
        4: {
          text: "待支付",
          bg: "linear-gradient(-180deg, #ffb80c 0%, #ff8205 100%)",
        },
        5: {
          text: "待清台",
          bg: "linear-gradient(-180deg, #ffb80c 0%, #ff8205 100%)",
        },
      },
    };
  },
  watch: {
    activeName() {
      this.avtiveStatus = "-2";
      this.getTableInfoAndRegionList();
    },
    avtiveStatus() {
      this.getTableInfoAndRegionList();
    },
  },
  methods: {
    reservationOrder() {
      this.dialogVisiblezin = true;
      this.TableId = this.tableInfo.tableId;
    },
    async cancalOrder() {
      await this.$confirm(
        "是否取消预定桌位, 取消后桌位状态变为空闲，确认取消?",
        "取消预订",
        {
          confirmButtonText: "确定取消",
          cancelButtonText: "我在想想",
          type: "warning",
        }
      );
      await this.$http.post("/cashier-api/api/tableInfoNew/reserved/cancel", {
        tableId: this.tableInfo.tableId,
      });
      this.getTableInfoAndRegionList();
    },
    async startOrder() {
      let { value } = await this.$prompt("请输入就餐人数", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[1-9][0-9]?$/,
        inputErrorMessage: "请输入两位以内的正整数",
      });
      let parmas = {
        tableId: this.tableInfo.tableId,
        dinersCount: value,
        orderMode: 1,
      };
      let { data } = await this.$http.post(
        "/cashier-api/api/orderNew/beginCreateOrder",
        parmas
      );
      this.$router.push({
        path: "/selectMenu",
        query: { orderNo: data, orderMode: 1, tableId: this.tableInfo.tableId },
      });
    },
    async clickTable({ tableId }) {
      this.loading = true;
      this.activeTable = tableId;
      let userNo = getMerchantInfoVo().userNo;
      try {
        let { data } = await this.$http.post(
          "/cashier-api/api/tableInfoNew/getOneTableInfo",
          { tableId }
        );
        switch (data.tableStatus) {
          case "0": //空闲
            this.tableInfo = data;
            break;
          case "1": //预定
            this.tableInfo = data;
            break;
          case "2": //就餐中
            this.tableInfo = null;
            if (data.openTableSource !== "1") {
              if (data.openTableUser !== userNo) {
                await this.$confirm(
                  "非开桌人无法操作，是否清台，订单作废？",
                  "提示",
                  {
                    confirmButtonText: "清台",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                );
                await this.$http.post("/cashier-api/api/orderNew/clearTable", {
                  orderNo: data.orderNo,
                  tableId,
                });
                this.getTableInfoAndRegionList();
                return;
              }
            }
            this.$router.push({
              path: "/selectMenu",
              query: {
                orderNo: data.orderNo,
                tableId: data.tableId,
                orderMode: 1,
              },
            });
            break;
          case "3": //待确认
            this.tableInfo = null;
            break;
          case "4": {
            //待支付
            let path = "/toPayment";
            let query = {
              orderNo: data.orderNo,
              tableId: data.tableId,
              orderMode: 1,
            };
            if (data.indexPage == 3) {
              path = "/selectMenu";
              query.orderNo = data.additionalOrderNo || data.orderNo;
            }
            this.$router.push({
              path,
              query,
            });
            break;
          }
          case "5": //带清台
            await this.$confirm("是否继续清台?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            });
            await this.$http.post("/cashier-api/api/orderNew/clearTable", {
              orderNo: data.orderNo,
              tableId,
            });
            this.getTableInfoAndRegionList();
            break;
          default:
        }
      } finally {
        this.loading = false;
      }
    },
    async getRegionList() {
      try {
        this.loading = true;
        let { data } = await this.$http.post(
          "/cashier-api/api/tableInfoNew/getRegionList",
          {
            merchantId: this.merchantId,
            tableRegionId: this.activeName,
            tableStatus: this.avtiveStatus,
          }
        );
        this.roomList.push(...data);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async getTableInfoAndRegionList() {
      try {
        this.tableInfo = null;
        this.loading = true;
        this.activeTable = null;
        let { data } = await this.$http.post(
          "/cashier-api/api/tableInfoNew/getTableInfoAndRegionList",
          {
            merchantId: this.merchantId,
            tableRegionId: this.activeName,
            tableStatus: this.avtiveStatus,
          }
        );
        this.tableList = data.tableInfoList;
        this.tableStatusList = data.tableStatusList;
        this.loading = false;
        this.firstLoading=true;
      } catch (e) {
        this.loading = false;
      }
    },
    seleckStatus(code) {
      this.avtiveStatus = code;
    },
  },
};
</script>

<style scoped src="./index.css">
</style>