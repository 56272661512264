<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="formzine"
      class="demo-form-inline"
      size="mini"
      ref="formzine"
      :rules="rules"
    >
      <el-form-item label="餐盒数量:" prop="packingCount">
        <el-input v-model="formzine.packingCount" placeholder="请输入餐盒数量"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submited" size="mini">保存</el-button>
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
export default {
  props: { orderNo: String },
  created() {
    this.formzine.packingCount = "";
  },
  data() {
    var numzin = (rule, value, callback) => {
      let rez = /^[1-9]\d{0,2}$/;
      if (!rez.test(value)) {
        return callback(new Error("请输入三位以内的正整数"));
      }
      callback();
    };
    return {
      formzine: {
        packingCount: ""
      },
      rules: {
        packingCount: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" }
        ]
      },
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.$refs["formzine"].resetFields();
    },
    submited() {
      this.$refs["formzine"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/dishers/packaging", {
              packingCount: this.formzine.packingCount,
              orderNo: this.orderNo
            })
            .then(res => {
              this.loading = false;
              this.$message({
                message: "打包成功",
                offset: 160,
                type: "success"
              });
              this.$emit("close");
            })
            .catch(error => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>