<template>
  <div class="flex-view-tb">
    <div class="view-top">基本信息</div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="110px"
      class="demo-ruleForm"
      size="mini"
    >
      <el-form-item label="打印机名称:">{{ruleForm.printName}}</el-form-item>
      <el-form-item label="打印机编号:">{{ruleForm.printNo}}</el-form-item>
      <el-form-item label="打印机分类:" prop="category">
        <el-select v-model="ruleForm.category" placeholder="请选择" @change="catechange">
          <el-option v-for="(v,k) in categoryli" :key="k" :label="v" :value="k" clearable></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="打印方式:" prop="printMode">
        <el-select v-model="ruleForm.printMode" placeholder="请选择" @change="changezin">
          <el-option v-for="(v,k) in options" :key="k" :label="v" :value="k" clearable></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="打印份数:" prop="copies">
        <el-select v-model="ruleForm.copies" placeholder="请选择">
          <el-option v-for="(v,k) in options1" :key="k" :label="v" :value="k" clearable></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="切单方式:" prop="cutType" v-show="flag">
        <el-select v-model="ruleForm.cutType" placeholder="请选择">
          <el-option v-for="(v,k) in options2" :key="k" :label="v" :value="k" clearable></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="分类:" prop="disherCategoryslist" v-if="this.ruleForm.printMode!='1'">
        <template>
          <el-checkbox-group v-model="ruleForm.disherCategoryslist" @change="fenlei">
            <el-checkbox
              v-for="i in cities"
              :key="i.categoryId"
              :label="i.categoryId"
            >{{i.categoryName}}</el-checkbox>
          </el-checkbox-group>
        </template>
      </el-form-item>
      <el-row style="padding-top: 20px;"></el-row>
    </el-form>
    <div class="flex-view-bottom">
      <el-button type="primary" size="mini" style="margin-right:20px" @click="subimted">保存</el-button>
      <el-button size="mini" @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.tid = this.$route.query.tid;
    this.load();
  },
  data() {
    return {
      tid: "",
      cities: [],
      ruleForm: {
        printName: "",
        printNo: "",
        category: "",
        printMode: "",
        copies: "",
        cutType: "",
        disherCategorys: "",
        tid: "",
        disherCategoryslist: [],
      },
      rules: {
        category: [
          { required: true, message: "请选择打印机分类", trigger: "blur" },
        ],
        printMode: [
          { required: true, message: "请选择打印方式", trigger: "blur" },
        ],
        copies: [
          { required: true, message: "请选择打印份数", trigger: "blur" },
        ],
        cutType: [
          { required: true, message: "请选择切单方式", trigger: "blur" },
        ],
        disherCategoryslist: [
          { required: true, message: "请选择分类", trigger: "blur" },
        ],
      },
      categoryli: {
        1: "前台打印机",
        2: "后厨打印机",
        3: "前后台共用",
      },
      options: {
        1: "整单打印",
        2: "分类打印",
      },
      options1: {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
      options2: {
        1: "分类切",
      },
      flag: false,
    };
  },
  methods: {
    fenlei(val) {
      console.log(val);
    },
    loadCatechange(val) {
      if (val == "1") {
        this.options = {
          1: "整单打印",
        };
      } else if (val == "2") {
        this.options = {
          1: "整单打印",
          2: "分类打印",
        };
      } else {
        this.options = {
          1: "整单打印",
        };
      }
    },
    catechange(val) {
      this.ruleForm.cutType = "";
      this.ruleForm.disherCategorys = "";
      if (val == "1") {
        this.options = {
          1: "整单打印",
        };
        this.ruleForm.printMode = "1";
        this.ruleForm.copies = "1";
        this.flag = false;
      } else if (val == "2") {
        this.options = {
          1: "整单打印",
          2: "分类打印",
        };
        this.ruleForm.printMode = "2";
        this.changezin(this.ruleForm.printMode);
      } else {
        this.options = {
          1: "整单打印",
        };
        this.ruleForm.printMode = "1";
        this.ruleForm.copies = "1";
        this.flag = false;
      }
    },
    changezin(val) {
      if (this.ruleForm.printMode == "1") {
        this.ruleForm.copies = "1";
        this.flag = false;
      } else {
        this.ruleForm.copies = "1";
        this.flag = true;
      }
    },
    subimted() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.printMode != "1") {
            if (this.ruleForm.disherCategoryslist.length == 0) {
              this.$confirm("您还有菜品分类没有设置，确定保存?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.config();
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    offset: 160,
                    message: "已取消",
                  });
                });
            } else {
              this.config();
            }
          } else {
            this.config();
          }
        }
      });
    },
    config() {
      this.ruleForm.tid = this.tid;
      for (let i in this.ruleForm.disherCategoryslist) {
        this.ruleForm.disherCategorys +=
          this.ruleForm.disherCategoryslist[i] + ",";
      }
      this.$http
        .post(`/order-admin/w/print/modify`, {
          ...this.ruleForm,
        })
        .then((data) => {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.$router.push("/searchMechanism");
        });
    },
    load() {
      this.$http
        .get(`/order-admin/w/print/disher`, {
          tid: this.tid,
        })
        .then((data) => {
          this.cities = data.data;
          this.loaddetail();
        });
    },
    loaddetail() {
      this.$http
        .post(`/order-admin/w/print/detail`, {
          tid: this.tid,
        })
        .then((data) => {
          this.ruleForm = { ...data, disherCategoryslist: [] };
          this.loadCatechange(data.category);
          let arr = [];
          for (let i in this.cities) {
            for (let j in data.disherCategorys) {
              if (data.disherCategorys[j] == this.cities[i].categoryId) {
                arr.push(data.disherCategorys[j]);
              }
            }
          }
          this.ruleForm.disherCategoryslist = arr;
          this.ruleForm.disherCategorys=''
        });
    },
    close() {
      this.$router.push("/searchMechanism");
    },
  },
};
</script>

<style scoped>
.input-width {
  width: 140px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.view-top {
  margin: 26px 30px 10px 20px;
  padding: 10px;
  border-bottom: 1px solid #dcdee0;
  font-size: 14px;
}
</style>
