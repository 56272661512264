<template>
  <div v-loading="loading">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="mini"
    >
      <el-row>
        <div class="hr_title">会员信息</div>
        <el-col :span="12" style="margin-top: 10px">
          <el-form-item label="会员姓名" prop="mermberName">
            <span>{{ ruleForm.mermberName }}</span>
          </el-form-item>
          <el-form-item label="账户余额">
            <span class="red">{{ ruleForm.accountBalance | money }}</span>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top: 10px">
          <el-form-item label="手机号码" prop="phone">
            <span>{{ ruleForm.phone }}</span>
          </el-form-item>
          <el-form-item label="会员卡号" prop="activityBeginTm">
            <span>{{ ruleForm.memberCardNo }}</span>
            <span class="tage" v-if="openMemberStore == 1">{{
              ruleForm.realNameFlag == 1 ? "已实名" : "未实名"
            }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="openMemberStore == 1">
        <div class="hr_title">交易记录</div>
        <el-button @click="exportzin" size="mini" type="primary" class="btn"
          >导出</el-button
        >
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;overflow: auto;"
          max-height="430"
          size="mini"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            label="序号"
            type="index"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            prop="recordId"
            label="订单编号"
            width="180"
          ></el-table-column>
          <el-table-column prop="paymentStatus" label="订单状态">
            <template slot-scope="scope">{{
              paymentStatus[scope.row.paymentStatus]
            }}</template>
          </el-table-column>
          <el-table-column prop="recordMode" label="支付类型">
            <template slot-scope="scope">{{
              recordMode[scope.row.recordMode]
            }}</template>
          </el-table-column>
          <el-table-column prop="paymentType" label="支付方式">
            <template slot-scope="scope">{{
              paymentType[scope.row.paymentType]
            }}</template>
          </el-table-column>
          <el-table-column prop="originAmount" label="订单金额(元)" width="100">
            <template slot-scope="scope">{{
              scope.row.originAmount | money
            }}</template> </el-table-column
          >e
          <el-table-column
            prop="discountsAmount"
            label="优惠金额(元)"
            width="100"
          >
            <template slot-scope="scope">{{
              scope.row.discountsAmount | money
            }}</template>
          </el-table-column>
          <el-table-column
            prop="reallyReceiveAmount"
            label="实收金额(元)"
            width="100"
          >
            <template slot-scope="scope">{{
              scope.row.reallyReceiveAmount | money
            }}</template>
          </el-table-column>
          <el-table-column
            prop="balanceChange"
            label="账户余额变化(元)"
            width="130"
          >
            <template slot-scope="scope">{{
              scope.row.balanceChange
            }}</template>
          </el-table-column>
          <el-table-column
            prop="accountBalance"
            label="账户可用余额(元)"
            width="130"
          >
            <template slot-scope="scope">{{
              scope.row.accountBalance | money
            }}</template>
          </el-table-column>
          <el-table-column
            prop="paymentTime"
            label="创建时间"
            width="160"
          ></el-table-column>
        </el-table>
        <div class="view-page">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page.pageNo"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="page.total"
            prev-text="上一页"
            next-text="下一页"
          ></el-pagination>
        </div>
      </el-row>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="closew" size="small">关闭</el-button>
    </div>
  </div>
</template>

<script>
import {
  paymentStatus,
  paymentType,
  recordMode,
} from "@/common/options.js";
export default {
  created() {
    this.serach();
  },
  data() {
    return {
      page: { pageNo: 1, pageSize: 10, total: 0 },
      tableData: [],
      ruleForm: {},
      paymentStatus: paymentStatus,
      paymentType: paymentType,
      recordMode: recordMode,
      loading: false,
      openMemberStore: localStorage.getItem("openMemberStore"),
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.serach();
    },
    indexMethod(index) {
      return index + 1;
    },
    serach() {
      this.loading = true;
      this.$http
        .post(`/order-admin/w/member/detail`, {
          memberId: this.$route.query.id,
        })
        .then((res) => {
          this.loading = false;
          this.ruleForm = res;
        })
        .catch((error) => {
          this.loading = false;
        });
      if (this.openMemberStore != 1) {
        return;
      }
      this.loading = true;
      this.$http
        .post(`/order-admin/w/member/order/s`, {
          memberId: this.$route.query.id,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.page.records;
          this.page.total = res.page.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    exportzin() {
      this.$http
        .post("/order-admin/w/member/order/export/excel", {memberId: this.$route.query.id})
        .then((res) => {
          window.open(res.data);
        });
    },
    closew() {
      this.$router.push("/Membership");
    },
  },
};
</script>

<style scoped>
.input-width {
  width: 238px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.dayinOrder {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 80px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.dayinReceipt {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 100px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.dayinToKitchen {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 80px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.footer {
  width: 100%;
  position: fixed;
  height: 60px;
  bottom: 2px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  margin: 20px 0;
}
.tage {
  border: 1px solid #108af9;
  border-radius: 2px;
  color: #108af9;
  font-size: 12px;
  padding: 2px 4px;
}
.red {
  font-size: 14px;
  color: #ff3e3e;
}
</style>
