<template>
  <div :loading="loading">
    <el-table :data="tableData" style="width: 100%" size="mini">
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column prop="operationType" label="操作类型" width="80px">
        <template slot-scope="scope">
          {{ depotScopeList[scope.row.operationType] }}
        </template>
      </el-table-column>
      <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
      <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
      <el-table-column
        prop="changeGoodNum"
        label="调整值"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="beforeGoodNum"
        label="操作前"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="afterGoodNum"
        label="操作后"
        width="100px"
      ></el-table-column>
      <el-table-column prop="operatorUserNo" label="操作人"></el-table-column>
      <el-table-column
        prop="operationTime"
        label="操作时间"
        width="195px"
      ></el-table-column>
      <el-table-column prop="orderNo" label="订单编号"></el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: ["formdata"],
  created() {
    this.Load();
  },
  data() {
    return {
      loading: false,
      tableData: [],
      depotScopeList: {
        1: "删除",
        2: "销售",
        3: "进货",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    Load() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/depot/detail/page", {
          depotId: this.formdata.depotId,
          ...this.page,
        })
        .then(
          (res) => {
            this.loading = false;
            this.tableData = res.data.records;
            this.page.total = res.data.total;
          },
          (e) => {
            this.loading = false;
          }
        );
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.Load();
    },
  },
};
</script>

<style scoped>
</style>