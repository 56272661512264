<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini" ref="formInline">
        <el-form-item label="订单号" prop="orderId">
          <el-input v-model.trim="formInline.orderId"></el-input>
        </el-form-item>
        <el-form-item label="桌位名称" prop="tableName">
          <el-input v-model.trim="formInline.tableName"></el-input>
        </el-form-item>
        <el-form-item label="订单状态" prop="orderStatus">
          <el-select v-model="formInline.orderStatus" placeholder="请选择" clearable>
            <el-option v-for="(v, k) in orderStatusobj" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付方式" prop="paymentType">
          <el-select v-model="formInline.paymentType" placeholder="请选择" clearable>
            <el-option v-for="(v, k) in paymentTypeobj" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker :picker-options="pickerOptions" v-model="formInline.value1" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker :picker-options="pickerOptions" v-model="formInline.value2" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button v-if="But.List.power()" type="primary" @click="searchzin" size="mini" class="serach">查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div>
            <el-button v-if="But.Export.power()" size="mini" @click="exporT()">导出</el-button>
            <span class="fonsize">
              <span>
                订单总金额:
                <span>{{
                  statistics.orderTatalAmount
                    ? (statistics.orderTatalAmount / 100).toFixed(2)
                    : "0.00"
                }}</span>
              </span>
              <span>
                实收总金额:
                <span>{{
                  statistics.realTotalAmount
                    ? (statistics.realTotalAmount / 100).toFixed(2)
                    : "0.00"
                }}</span>
              </span>
              <span>
                优惠总金额:
                <span>{{
                  statistics.discountAmount
                    ? (statistics.discountAmount / 100).toFixed(2)
                    : "0.00"
                }}</span>
              </span>
            </span>
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" size="mini" highlight-current-row>
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="orderId" label="订单号" width="180"></el-table-column>
      <el-table-column prop="tableNo" label="桌位名称" width="100"></el-table-column>
      <el-table-column prop="orderStatus" label="订单状态">
        <template slot-scope="scope">{{
          orderStatusobj[scope.row.orderStatus]
        }}</template>
      </el-table-column>
      <el-table-column prop="paymentType" label="支付方式">
        <template slot-scope="scope">{{ scope.row.paymentType }}</template>
      </el-table-column>
      <el-table-column prop="orderTotalAmount" label="订单金额">
        <template slot-scope="scope">{{
          scope.row.orderTotalAmount | money
        }}</template>
      </el-table-column>
      <el-table-column prop="realAmount" label="实收金额">
        <template slot-scope="scope">{{
          scope.row.realAmount == 0
            ? ""
            : (scope.row.realAmount / 100).toFixed(2)
        }}</template>
      </el-table-column>
      <el-table-column prop="discountAmount" label="优惠金额">
        <template slot-scope="scope">{{
          scope.row.discountAmount | money
        }}</template>
      </el-table-column>
        <!-- <el-table-column label="抹零金额">
        <template slot-scope="scope">{{
          scope.row.notCountAmount | money
        }}</template>
      </el-table-column> -->
      <el-table-column prop="orderTime" label="下单时间" width="145"></el-table-column>
      <el-table-column prop="paymentTime" label="支付时间" width="145"></el-table-column>
      <el-table-column prop="sourceIp" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="detailes(scope.row.orderId)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination @current-change="handleCurrentChange" :current-page="page.pageNo" :page-size="page.pageSize" layout="total, prev, pager, next, jumper" :total="page.total" prev-text="上一页"
        next-text="下一页"></el-pagination>
    </div>
    <export-file URL="/order-admin/w/order/export/excel" ref="File"></export-file>
    <el-dialog title="订单详情" :visible.sync="dialogVisibleDetile" custom-class="order-dialog">
      <Detail :query="detailQuery" v-if="dialogVisibleDetile" />
    </el-dialog>
  </div>
</template>

<script>
import Detail from "./detailtwo.vue";
import { baseurl } from "../../../base.js";
import formatDate from "@/common/date.js";
import { notTodayPass } from "@/common/mixins.js";
export default {
  created() {
    let today = formatDate(new Date(), "yyyy-MM-dd");
    this.formInline.value1 = [today + " 00:00:00", today + " 23:59:59"];
    // this.formInline.value2 = [today + " 00:00:00", today + " 23:59:59"];
    this.searchzin();
  },
  components: { Detail },
  data() {
    return {
      detailQuery:{},
      dialogVisibleDetile:false,
      choiceDate:null,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate = minDate.getTime();
          if (maxDate) {
            this.choiceDate = "";
          }
        },
        disabledDate: (time) => {
          if (notTodayPass(time)) {
            return true;
          }
          if (!!this.choiceDate) {
            let nums = 3 * 30 * 24 * 60 * 60 * 1000;
            let minTime = this.choiceDate - nums;
            let maxTime = this.choiceDate + nums;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
          return false
        },
      },
      loading: false,
      But: window.UserPower.order.shanghuSearchInfo,
      baseurl: baseurl,
      dialogVisible: false,
      equipmentList: [],
      value1: [],
      formInline: {
        tableName: "",
        orderId: "",
        orderStartTime: "",
        orderEndTime: "",
        payStartTime: "",
        payEndTime: "",
        orderStatus: "",
        paymentType: "",
        value1: [],
        value2: [],
        authToken: localStorage.getItem("tokenIdzin"),
      },
      paymentTypeobj: {
        //  2: "扫码",
        // 6: "账户支付",
        4: "其他",
        5: "免单",
        3: "刷卡",
        1: "现金",
        7: "微信",
        8: "支付宝",
        10: "挂账",
      },
      orderStatusobj: {
        1: "待付款",
        2: "已付款",
        3: "支付中",
        // 4: "挂单"
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      statistics: {},
    };
  },
  methods: {
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
      this.formInline.value2 = [];
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.orderStartTime = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.orderEndTime = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.formInline.payStartTime = this.formInline.value2
        ? this.formInline.value2[0]
        : "";
      this.formInline.payEndTime = this.formInline.value2
        ? this.formInline.value2[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/order/search/page", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.page.records;
          this.page.total = res.data.page.total;
          this.statistics = res.data.statistics;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    detailes(orderId) {
      // this.$router.push({
      //   path: "shanghuSearchInfoDetails",
      //   query: { id: orderId }
      // });
      this.dialogVisibleDetile=true;
      this.detailQuery = { orderId };
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    exporT() {
      this.formInline.orderStartTime = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.orderEndTime = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.formInline.payStartTime = this.formInline.value2
        ? this.formInline.value2[0]
        : "";
      this.formInline.payEndTime = this.formInline.value2
        ? this.formInline.value2[1]
        : "";
      this.$refs.File.export({ ...this.formInline });
    },
  },
};
</script>

<style scoped>
.fonsize span {
  margin-left: 10px;
}
.fonsize span span {
  color: #409eff;
  margin-left: 0px;
}
.view-contion>>>.order-dialog{
  width: 95%;
  height: 90%;
  margin-top: 5vh!important;
  overflow: hidden;
}
.view-contion>>>.el-dialog__body{
  height: calc(100% - 86px);
}
</style>


