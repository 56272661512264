<template>
  <div class="container">
    <div class="disherlist" style="box-shadow:none">
      <orderInfo :orderNo="form.orderNo" :orderMode="form.orderMode" :tableId="form.tableId" />
    </div>
    <div class="shoppingcart">
      <shoppingCart :orderNo="form.orderNo" :orderMode="form.orderMode" :tableId="form.tableId" />
    </div>
    <div class="operation">
      <operationList v-if="isOrderEnter!='orderInfo'" :orderNo="form.orderNo" :tableId="form.tableId" :orderMode="form.orderMode" />
      <orderEnterIndex v-else :orderNo="form.orderNo" :tableId="form.tableId" :orderMode="form.orderMode" />
    </div>
  </div>
</template>

<script>
import orderInfo from "./orderInfo/index.vue";
import operationList from "./operationList/index.vue";
import orderEnterIndex from "./operationList/orderEnterIndex.vue";
import shoppingCart from "./shoppingCart/index.vue";
export default {
  components: { shoppingCart, orderInfo, operationList, orderEnterIndex },
  created() {
    let query = this.$route.query;
    this.form.orderNo = query.orderNo;
    this.form.tableId = query.tableId;
    this.form.orderMode = query.orderMode;
    this.isOrderEnter = query.isOrderEnter;
  },
  data() {
    return {
      form: {
        orderNo: "",
        tableId: "",
        orderMode: "",
      },
    };
  },
  methods: {},
};
</script>

<style scoped src="../selectMenu/index.css">
</style>