<template>
  <div class="view_hreader" v-loading="loading">
    <div class="zincontain" :class="{
        container_left: status == 'all' || status == '0' || status == '1',
        container_left_a: status == 2,
      }">
      <div class="status_all" v-if="type != 2">
        <div v-for="(item, index) in tableStatusList" :key="index" class="status_1" @click="load(item.tableStatus, index)">
          <div :class="{
              activegrow: idxcolor == index && item.tableStatus == '0',
              activered: idxcolor == index && item.tableStatus == 'all',
              activeblur:
                (idxcolor == index && item.tableStatus == '2') ||
                (idxcolor == index && item.tableStatus == '3'),
              activeorange:
                (idxcolor == index && item.tableStatus == '4') ||
                (idxcolor == index && item.tableStatus == '5'),
              red: item.tableStatus == 'all',
              grow: item.tableStatus == '0',
              blur:
                item.tableStatus == '1' ||
                item.tableStatus == '2' ||
                item.tableStatus == '3',
              orange: item.tableStatus == '4' || item.tableStatus == '5',
              greetable: item.tableStatus == '1',
            }">
            <img src="@/assets/image/icon_duigou.png" v-if="idxcolor == index" />
          </div>
          <span>{{ item.tableStatusDesc }}({{ item.tableCount }})</span>
        </div>
        <div>
          <el-button type="primary" @click="submited" size="mini" class="btn">刷新</el-button>
        </div>
      </div>

      <div class="inner">
        <div v-for="(item, idx) in whietalist" :key="idx" @click="son(item, idx)" :class="{
            status_grouer: item.tableStatus == '4' || item.tableStatus == '5',
            status_blue:
              item.tableStatus == '1' ||
              item.tableStatus == '2' ||
              item.tableStatus == '3',
            status_whiter: item.tableStatus == '0',
            status_gree: item.tableStatus == '1',
          }">
          <img src="@/assets/image/img-xuanzhong-1.png" v-show="idx == index && status == '0'" />
          <img src="@/assets/image/img-xuanzhong-2.png" v-show="idx == index && status != '0'" />
          <div class="status_grouer_a">{{ item.tableName }}</div>
          <div class="status_grouer_b">
            {{ tableStatusobj[item.tableStatus] }}
          </div>
          <div class="status_grouer_c">
            <span>
              <span v-if="item.dinersCount">{{ item.dinersCount + "/" }}</span>
              {{ item.seatingCapacity }}座
            </span>
            <span v-if="item.orderoriginamount">{{
              item.orderoriginamount == 0
                ? ""
                : (item.orderoriginamount / 100).toFixed(2)
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="phone_tazy" v-if="status != 2">
      <div v-if="viewRight">
        <view-right :val="val" @load="load" @clear="clear"></view-right>
      </div>
    </div>
  </div>
</template>

<script>
import viewRight from "./rightside/right"; //空闲桌点餐右侧部分
export default {
  created() {
    this.type = this.$route.query.type;
    if (this.type == 2) {
      this.changetables(); //清桌
    }
  },
  components: {
    viewRight,
  },
  props: { activeName: String },
  data() {
    return {
      intervalFunc: "",
      type: "",
      dialogside: false,
      whietalist: [],
      tableStatusobj: {},
      tableStatusList: [],
      val: {},
      index: null,
      status: "",
      tableId: "",
      tableRegionName: "",
      activeNameone: "",
      viewRight: false,
      formold: {
        tableId: "",
        tableName: "",
      },
      idxcolor: 0,
      loading: false,
    };
  },
  methods: {
    submited() {
      this.load("all", 0);
    },
    clear() {
      this.index = null;
      this.viewRight = false;
    },
    changetables() {
      this.status = "2";
      let tableInfoList = JSON.parse(localStorage.getItem("tableInfoList"));
      var obj = {};
      obj = tableInfoList.find(function (item) {
        return item.tableId === localStorage.getItem("tableId");
      });
      this.formold.tableId = obj.tableId;
      this.formold.tableName = obj.tableName;
    },
    load(val, index) {
      this.idxcolor = index;
      if (val == "all") {
        val = "";
      }
      if (this.activeName == "all") {
        this.activeNameone = "";
      } else {
        this.activeNameone = this.activeName;
      }
      if (this.type == 2) {
        val = 0;
      }
      this.loading = true;
      this.$http
        .post("/cashier-api/api/table/list", {
          tableRegionId: this.activeNameone,
          merchantId: localStorage.getItem("merchantId"),
          tableStatus: val,
        })
        .then((res) => {
          this.loading = false;
          this.whietalist = res.tableInfoList;
          localStorage.setItem(
            "tableInfoList",
            JSON.stringify(res.tableInfoList)
          );
          this.tableStatusList = res.tableStatusList;
          for (let i in this.tableStatusList) {
            this.tableStatusobj[this.tableStatusList[i].tableStatus] =
              this.tableStatusList[i].tableStatusDesc;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    topay() {
      this.$router.push("/topay");
    },
    son(item, idx) {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/table/query", {
          tableId: item.tableId,
          merchantId: localStorage.getItem("merchantId"),
        })
        .then((res) => {
          this.loading = false;
          this.val = res.tableInfoCacheVo;
          this.index = idx;
          this.status = this.val.tableStatus;
          this.tableId = this.val.tableId;
          localStorage.setItem("tableId", this.val.tableId);
          this.tableName = this.val.tableName;
          if (this.type == 2) {
            this.config();
          } else {
            if (this.val.tableStatus == "0") {
              //空闲
              this.viewRight = true;
            } else if (this.val.tableStatus == "1") {
              //已预订
              this.viewRight = true;
            } else if (this.val.tableStatus == "2") {
              //就餐中
              if (this.type == 2) {
                this.$router.push({
                  path: "/payorder",
                  query: {
                    orderNo: this.$route.query.orderNo,
                    tableId: this.val.tableId,
                  },
                });
              } else {
                this.$router.push({
                  path: "/payorder",
                  query: {
                    orderNo: this.val.orderNo,
                    tableId: this.val.tableId,
                  },
                });
              }
            } else if (this.val.tableStatus == "3") {
              //待确认
              this.$router.push({
                path: "/toorder",
                query: {
                  orderNo: this.val.orderNo,
                  tableId: this.val.tableId,
                  orderMode: "1",
                  type: "2",
                },
              });
            } else if (this.val.tableStatus == "4") {
              //待支付
              localStorage.setItem("payflag", false);
              this.$router.push({
                path: "/topay",
                query: {
                  orderNo: this.val.orderNo,
                  tableId: this.val.tableId,
                  type: "1",
                },
              });
            } else if (this.val.tableStatus == "5") {
              //待清台
              this.viewRight = false;
              this.$confirm("是否继续清台?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.loading = true;
                  this.$http
                    .post("/cashier-api/api/dishers/cleanTable", {
                      merchantId: localStorage.getItem("merchantId"),
                      orderNo: this.val.orderNo,
                      tableId: this.tableId,
                    })
                    .then(() => {
                      this.loading = false;
                      this.$message({
                        type: "success",
                        offset: 160,
                        message: "清台成功!",
                      });
                      this.load();
                      this.index = null;
                    })
                    .catch(() => {
                      this.loading = false;
                    });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    offset: 160,
                    message: "已取消",
                  });
                });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.retCode == "30025") {
            const msg = error.retMessage.split("，");
            this.$confirm(`${msg[0] || ""},是否需要清台?`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.loading = true;
                this.$http
                  .post("/cashier-api/api/dishers/cleanTable", {
                    merchantId: localStorage.getItem("merchantId"),
                    orderNo: item.orderNo,
                    tableId: item.tableId,
                  })
                  .then(() => {
                    this.loading = false;
                    this.$message({
                      type: "success",
                      offset: 160,
                      message: "清台成功!",
                    });
                    this.load();
                  })
                  .catch(() => {
                    this.loading = false;
                  });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  offset: 160,
                  message: "已取消",
                });
              });
          }
        });
    },
    config() {
      this.$confirm(
        `是否将${this.formold.tableName}换到${this.tableName}`,
        "换桌",
        {
          confirmButtonText: "确定换桌",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/dishers/swap/table", {
              orderNo: this.$route.query.orderNo,
              merchantId: localStorage.getItem("merchantId"),
              tableId: this.tableId,
            })
            .then(() => {
              this.loading = false;
              this.$message({
                type: "success",
                offset: 160,
                message: "换桌成功!",
              });
              this.type = 1;
              this.index = null;
              this.load();
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            offset: 160,
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style scoped>
.view_hreader {
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.container_left {
  width: calc(100% - 248px);
  border: 1px solid #dedede;
  padding: 15px 15px;
  overflow-y: auto;
}
.container_left_a {
  width: calc(100% - 530px);
  border: 1px solid #dedede;
  padding: 15px 15px;
  overflow-y: auto;
}
.phone_tazy {
  width: 248px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-left: 15px;
}

.status_all {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.status_1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 28px 0px 0;
}
.status_1 div {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.red {
  border: 2px solid #ff6969;
}
.blur {
  border: 2px solid #108af9;
}
.orange {
  border: 2px solid #ff8205;
}
.grow {
  border: 2px solid #979797;
}
.activegrow {
  background: #979797;
}
.activered {
  background: #ff6969;
}
.activeblur {
  background: #108af9;
}
.activeorange {
  background: #ff8205;
}
.inner {
  /* flex:1; */
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
}
.status_grouer,
.status_whiter,
.status_blue {
  width: 124px;
  height: 120px;
  border-radius: 4px;
  margin: 0 20px 20px 0;
  padding: 10px 8px;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(63, 133, 196, 0.3);
  cursor: pointer;
}
.status_grouer img {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 0;
}
.status_grouer,
.status_blue {
  background-image: linear-gradient(-180deg, #ffb80c 0%, #ff8205 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: #222222;
}
.status_grouer_b {
  margin-left: 30px;
  font-size: 18px;
  color: #ffffff;
}
.status_grouer_c {
  display: flex;
  justify-content: space-between;
}
.status_whiter {
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(63, 133, 196, 0.3);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.status_blue {
  background-image: linear-gradient(-180deg, #25befd 0%, #108af9 100%);
}
.status_whiter img,
.status_blue img {
  width: 29px;
  position: absolute;
  top: 0;
  right: 0;
}
.header_a {
  width: 100%;
  height: 80px;
  color: #333333;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #dedede;
}
.header_a div {
  margin-left: 25px;
  font-size: 20px;
}
.header_a div:nth-of-type(2) {
  font-size: 14px;
  margin-top: 7px;
}
.header_a div:nth-of-type(2) span {
  color: #108af9;
}

.header_phone {
  height: 111px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header_phone div {
  display: flex;
  justify-content: space-between;
  margin: 0 26px;
  font-size: 22px;
}
.header_phone div:nth-of-type(2) {
  font-size: 14px;
  margin-top: 14px;
}
.header_container {
  font-size: 14px;
  color: #333333;
  height: calc(100% - 232px);
  /* overflow: auto; */
}
.header_container div {
  /* height: 66px; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.header_container div:hover {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
.active {
  border: 1px solid #108af9;
}
.price_all {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price_all span:nth-of-type(2) {
  color: #ff6969;
}
.header_phone_containe {
  width: 100%;
  height: 60px;
  border-top: 1px solid #dedede;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 60px;
}
.header_phone_containe div span {
  color: #999999;
  margin-left: 34px;
}
.zongproce {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 26px;
}
.zongproce div:nth-of-type(2) {
  margin-left: 11px;
}
.zongproce div p:nth-of-type(2) {
  color: #ff6969;
}
.header_botton {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
  background: #108af9;
  position: absolute;
  bottom: 0;
}
.addpeicai {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.addpeicai span:nth-of-type(2) {
  color: #108af9;
}
.zincontain {
  flex: 1;
  border: 1px solid #dedede;
  padding: 15px 15px;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.greetable {
  border: 2px solid #85f700;
}
.status_gree {
  background-image: linear-gradient(-180deg, #85f700 0%, #2bb611 100%);
}
</style>