<template>
  <el-container class="h100 bg aad">
    <el-aside width="220px">
      <el-scrollbar style="height: 100%">
        <menu-list @addList="pushCont"></menu-list>
      </el-scrollbar>
    </el-aside>
    <el-container>
      <el-header>
        <head-top></head-top>
      </el-header>
      <el-main>      
          <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Bus from "@/common/bus.js";
import menuList from "@/components/menu.vue";
import headTop from "@/components/headTop.vue";
export default {
  components: { menuList, headTop },
  created() {
    window.PowerButtons = JSON.parse(localStorage.getItem("PowerButtons"));
  },
  mounted() {
    Bus.$emit("getTarget", this.$route.meta.title);
  },
  data() {
    return {
      alllist: [],
    };
  },
  watch: {
    $route(to) {
      Bus.$emit("getTarget", to.meta.title);
    },
  },
  methods: {
    pushCont(a) {
      this.$refs.body.addTab(a);
    },
  },
};
</script>

<style scoped>
.bg {
  background-color: #f9f9f9;
}
.el-header {
  background: #fff;
  color: #000;
  background-color: #ffffff;
  border-bottom: 1px solid #f0f1f4;
}
.el-aside {
  height: 100%;
  background: #ffffff;
  border-right: 1px solid #f0f1f4;
  overflow: auto;
}
.el-main {
  color: #333;
  background-color: #fff;
}
</style>