<template>
 <div class="view">
    <div class="view-contion" v-loading="fullscreenLoading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model.trim="formInline.roleName"></el-input>
        </el-form-item>
        <el-form-item label="添加时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
        <el-form-item></el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="serachzin"
          size="mini"
          class="serach"
        >查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div></div>
          <div>
            <el-button v-if="But.Add.power()" type="primary" @click="add" size="mini">添加角色</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
    >
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="roleName" label="角色名称"></el-table-column>
      <el-table-column prop="createUser" label="添加人"></el-table-column>
      <el-table-column prop="createTime" label="添加时间" show-overflow-tooltip></el-table-column>
      <el-table-column prop="sourceIp" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="detail(scope.row)" v-if="But.Detail.power()">详情</el-button>
          <el-button
            type="text"
            v-if="But.Edit.power()&&(scope.row.roleState!='1')"
            @click="edit(scope.row)"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="formInline.pageNo"
        :page-size="formInline.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="formInline.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
 </div>
</template>

<script>
export default {
  created() {
    this.serachzin();
    // if (this.$router.query.type == "2") {
    //   this.serach();
    // }
  },
  data() {
    return {
      But: window.UserPower.RestRoomSet.Staffmanagement.privilege,
      options: [],
      equipmentList: [],
      formInline: {
        roleName: "",
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10,
        value1: [],
        total: 0,
      },
      tableData: [],
      currentPage4: 4,
      formzine: {},
      fullscreenLoading: false,
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.formInline.pageNo = val;
      this.serach();
    },
    indexMethod(index) {
      return (
        (this.formInline.pageNo - 1) * this.formInline.pageSize + index + 1
      );
    },
    add() {
      this.$router.push({ path: "Stafftree" });
    },
    edit(inner) {
      this.$router.push({ path: "Stafftree", query: { roleId: inner.roleNo } });
    },
    detail(inner) {
      this.$router.push({
        path: "Stafftree",
        query: { title: "详情", roleId: inner.roleNo },
      });
    },
    serachzin() {
      this.formInline.pageNo = 1;
      this.serach();
    },
    serach() {
      this.formInline.startTime = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.endTime = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.fullscreenLoading = true;
      this.$http
        .post(`/order-admin/w/role/search`, { ...this.formInline })
        .then((d) => {
          this.fullscreenLoading = false;
          this.tableData = d.page.records;
          this.formInline.total = +d.page.total;
        })
        .catch((error) => {
          this.fullscreenLoading = false;
        });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
  },
};
</script>

<style scoped>
.view {
  position: relative;
}
</style>
