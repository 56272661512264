<template>
  <div class="operation-container" v-loading="loading">
    <div class="operation-div">
      <div @click="quitDishes" v-if="orderMode==1&&paymentMode!=1">退菜</div>
      <div @click="continueAddDishes">继续加菜</div>
      <template v-if="orderMode==1">
        <div @click="changeTable">换桌</div>
        <div @click="clearTable">清台</div>
        <div @click="packaging">打包</div>
      </template>
    </div>
    <el-dialog title="退菜" :visible.sync="dialogQuitVailt" width="450px" append-to-body :close-on-click-modal="false" :destroy-on-close="true">
      <Reduce :orderNo="orderNo" @close="colseReduce" />
    </el-dialog>
  </div>
</template>

<script>
import Reduce from "./reduce.vue";
import { myMixin } from "./minxins.js";
export default {
  mixins: [myMixin],
  components: { Reduce },
};
</script>

<style scoped src="./index.css">
</style>