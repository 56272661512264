<template>
  <div class="view">
    <div class="view-contion" v-loading="loading">
      <div class="view-search">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <div>
            <el-form-item label="打印机分类" prop="category">
              <el-select v-model="formInline.category" placeholder="请选择">
                <el-option v-for="(v,k) in categoryli" :key="k" :label="v" :value="k" clearable></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="打印机编号" prop="printNo">
              <el-input v-model.trim="formInline.printNo"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="打印机状态" prop="status">
            <el-select v-model="formInline.status" placeholder="请选择">
              <el-option v-for="(v,k) in options" :key="k" :label="v" :value="k" clearable></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="绑定时间">
            <el-date-picker
              v-model="formInline.value1"
              :picker-options="notTodayPass"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <div class="view-button">
          <el-button
            v-if="But.List.power()"
            type="primary"
            @click="searchzin"
            size="mini"
            class="serach"
          >查询</el-button>
          <span class="view-button-rest" @click="reset">重置筛选条件</span>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        size="mini"
        highlight-current-row
      >
        <el-table-column type="index" width="55" label="序号"></el-table-column>
        <el-table-column prop="printName" label="打印机名称" width="120"></el-table-column>
        <el-table-column prop="printNo" label="打印机编号" width="160"></el-table-column>
        <el-table-column prop="category" label="打印机分类" width="120">
          <template slot-scope="scope">{{categoryli[scope.row.category]}}</template>
        </el-table-column>
        <el-table-column prop="printMode" label="打印方式">
          <template slot-scope="scope">{{options1[scope.row.printMode]}}</template>
        </el-table-column>
        <el-table-column prop="copies" label="打印份数"></el-table-column>
        <el-table-column prop="status" label="打印机状态" width="100">
          <template slot-scope="scope">{{options[scope.row.status]}}</template>
        </el-table-column>
        <el-table-column prop="bindTime" label="绑定时间" width="140"></el-table-column>
        <el-table-column prop="address" label="编辑">
          <template slot-scope="scope">
            <el-button
              v-if="But.Edit.power()&&scope.row.status!='3'"
              type="text"
              @click="editData(scope.row.tid)"
            >设置</el-button>
            <el-button
              v-if="But.Delete.power()&&scope.row.status=='3'"
              type="text"
              @click="delet(scope.row.tid)"
            >删除</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="停用/启用" width="120">
          <template
            slot-scope="scope"
            v-if="But.SwitchAble.power()&&(scope.row.status=='1'||scope.row.status=='2')"
          >
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="2"
              @change="change($event,scope.row.tid)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
      <div class="view-page">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="formInline.pageNo"
          :page-size="formInline.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="formInline.total"
          prev-text="上一页"
          next-text="下一页"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.search();
  },
  data() {
    return {
      loading: false,
      But: window.UserPower.RestRoomSet.searchMechanism.Dayinji,
      formInline: {
        category: "",
        printNo: "",
        startTime: "",
        endTime: "",
        status: "",
        region: "",
        value1: null,
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      options1: {
        1: "整单打印",
        2: "分类打印",
      },
      options: {
        1: "启用",
        2: "关闭",
        3: "解绑",
      },
      categoryli: {
        1: "前台打印机",
        2: "后厨打印机",
        3: "前后台共用",
      },
      tableData: [],
    };
  },
  methods: {
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
    searchzin() {
      this.formInline.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.startTime = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.endTime = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.loading = true;
      this.$http
        .post(`/order-admin/w/print/search`, { ...this.formInline })
        .then((d) => {
          this.loading = false;
          this.tableData = d.page.records;
          for (let i in this.tableData) {
            this.tableData[i].status = +this.tableData[i].status;
          }
          this.formInline.total = +d.page.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    change(indexzin, tid) {
      let inner = indexzin == 2 ? "停用" : "启用";
      this.loading = true;
      this.$http
        .post(`/order-admin/w/print/close`, {
          tid: tid,
          status: indexzin,
        })
        .then((data) => {
          this.loading = false;
          this.$message({
            message: `${inner}成功`,
            type: "success",
          });
          this.search();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageNo = val;
      this.search();
    },
    editData(tid) {
      this.$router.push({ path: "MechanismEdit", query: { tid: tid } });
    },
    delet(tid) {
      this.$msgbox({
        title: "删除打印机",
        message: "是否确认删除该台设备？",
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      }).then((action) => {
        this.loading = true;
        this.$http
          .post(`/order-admin/w/print/delete`, {
            tid: tid,
          })
          .then((data) => {
            this.loading = false;
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.search();
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style scoped>
.view {
  position: relative;
}
</style>
