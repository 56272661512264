<template>
  <div class="h100">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="h100" type="border-card">
      <el-tab-pane label="全部订单" name="1" class="h100">
        <view-info class="h100" :namezin="namezin" ref="viewa"></view-info>
      </el-tab-pane>
      <el-tab-pane label="待付款" name="2" class="h100">
        <view-info class="h100" :namezin="namezin" ref="viewb"></view-info>
      </el-tab-pane>
      <el-tab-pane label="已付款" name="3" class="h100">
        <view-info class="h100" :namezin="namezin" ref="viewc"></view-info>
      </el-tab-pane>
      <el-tab-pane label="清台记录" name="4">
        <clearOrder class="h100" :namezin="namezin" ref="clearOrder" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import viewInfo from "./serach.vue";
import clearOrder from "./clearOrder";
export default {
  mounted() {
       this.$refs.viewa.search();
  },
  components: {
    viewInfo,
    clearOrder,
  },
  data() {
    return {
      activeName: "1",
      namezin: "1",
    };
  },
  methods: {
    handleClick(val) {
      this.namezin = val.name;
      this.$nextTick(() => {
        if (val.name == "1") {
          this.$refs.viewa.search();
        } else if (val.name == "2") {
          this.$refs.viewb.search();
        } else if (val.name == "3") {
          this.$refs.viewc.search();
        } else {
          this.$refs.clearOrder.search();
        }
      });
    },
  },
};
</script>

<style scoped>
.iconfont {
  color: #333333;
}
</style>