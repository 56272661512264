<template>
  <div>
    <div v-if="But.List.power()" class="containe_a">
      <div class="jinex">
        <div class="blue_border"></div>
        <span>金额概况</span>
        <span>更新时间:  {{today|formatDate('yyyy-MM-dd hh:mm:ss')}}</span>
      </div>
      <div class="contan">
        <div class="contaniner_font">
          <div class="font_left">
            <img src="@/assets/image/img-zongjine.png" />
          </div>
          <div class="font_right">
            <span>今日收款总额(元)</span>
            <span>{{info.amountCount|money}}</span>
            <span>总笔数:{{info.tradeCount}}笔</span>
          </div>
        </div>
        <div class="contaniner_font">
          <div class="font_left">
            <img src="@/assets/image/img_weixin.png" />
          </div>
          <div class="font_right">
            <span>微信收款金额(元)</span>
            <span>{{info.wechatAmount|money}}</span>
            <span>总笔数:{{info.wechatCount}}笔</span>
          </div>
        </div>
        <div class="contaniner_font">
          <div class="font_left">
            <img src="@/assets/image/zhifubao.png" />
          </div>
          <div class="font_right">
            <span>支付宝收款金额(元)</span>
            <span>{{info.aliAmount|money}}</span>
            <span>总笔数:{{info.aliCount}}笔</span>
          </div>
        </div>
        <div class="contaniner_font">
          <div class="font_left">
            <img src="@/assets/image/img-qita.png" />
          </div>
          <div class="font_right">
            <span>其他收款金额(元)</span>
            <span>{{info.otherAmount|money}}</span>
            <span>总笔数:{{info.otherCount}}笔</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="But.List.power()" class="containe_a">
      <div class="jinex">
        <div class="blue_border"></div>
        <span>就餐概况</span>
        <!-- <span>更新时间: {{today|formatDate('yyyy-MM-dd hh:mm:ss')}}</span> -->
      </div>
      <div class="contan">
        <div class="contaniner_font_a">
          <div class="font_left">
            <img src="@/assets/image/img_kaitai.png" />
          </div>
          <div class="font_right">
            <span>今日开台桌数(桌)</span>
            <span>{{info.tableCount}}</span>
          </div>
        </div>
        <div class="contaniner_font">
          <div class="font_left">
            <img src="@/assets/image/img_jiucan.png" />
          </div>
          <div class="font_right">
            <span>今日就餐人数(人)</span>
            <span>{{info.peopleCount}}</span>
          </div>
        </div>
        <div class="contaniner_font">
          <div class="font_left">
            <img src="@/assets/image/img_xiaoliang.png" />
          </div>
          <div class="font_right">
            <span>今日菜品数量(个)</span>
            <span>{{info.disherCount}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="containe_c">
      <div class="jinex">
        <div class="blue_border"></div>
        <span>快捷操作</span>
      </div>
      <div class="contan">
        <div class="contaniner_icon">
          <div v-if="But.searchMechanism.power()">
            <img src="@/assets/image/icon_dayinji.png" />
            <router-link to="searchMechanism">
              <span>打印机设置</span>
            </router-link>
          </div>
          <div v-if="But.sinformation.power()">
            <img src="@/assets/image/icon-caipinshezhi.png" />
            <router-link to="Dishesinformation">
              <span>菜品设置</span>
            </router-link>
          </div>
        </div>
        <div class="contaniner_icon">
          <div v-if="But.Staffmanagement.power()">
            <img src="@/assets/image/icon_yuangong.png" />
            <router-link to="Staffmanagement">
              <span>员工管理</span>
            </router-link>
          </div>
          <div v-if="But.transactionAdminSear.power()">
            <img src="@/assets/image/icon_tongji.png" />
            <router-link to="transactionAdminSear">
              <span>数据统计</span>
            </router-link>
          </div>
        </div>

        <div class="contaniner_icon">
          <div v-if="But.information.power()">
            <img src="@/assets/image/icon_jibenxinxi.png" />
            <router-link to="information">
              <span>基本信息设置</span>
            </router-link>
          </div>
          <div v-if="But.Membership.power()">
            <img src="@/assets/image/icon_huiyuan.png" />
            <router-link to="Membership">
              <span>会员信息</span>
            </router-link>
          </div>
        </div>
        <div class="contaniner_icon">
          <div v-if="But.equipmentAdminBoard.power()">
            <img src="@/assets/image/icon_zhuowei.png" />
            <router-link to="equipmentAdminBoard">
              <span>桌位设置</span>
            </router-link>
          </div>
          <div v-if="But.shanghuSearchInfo.power()">
            <img src="@/assets/image/icon_dayinji.png" />
            <router-link to="shanghuSearchInfo">
              <span>订单查询</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.load();
  },
  data() {
    return {
      But: window.UserPower.home,
      info: {},
      today:new Date
    };
  },
  methods: {
    load() {
      this.$http.get("/order-admin/w/statistic/today", {}).then(res => {
        this.info = res.data;
      });
    }
  }
};
</script>

<style scoped>
.containe_a {
  width: 100%;
  height: 210px;
  background: #ffffff;
  padding-top: 20px;
  margin-bottom: 10px;
}
.containe_c {
  width: 100%;
  height: 160px;
  background: #ffffff;
  /* padding-top: 20px; */
  margin-bottom: 10px;
}
.jinex {
  width: calc(100% - 40px);
  height: 40px;
  margin-left: 20px;
  background: #fafafa;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.blue_border {
  width: 4px;
  height: 20px;
  background: #108af9;
  margin-left: 20px;
}
.jinex span:nth-of-type(1) {
  font-size: 14px;
  color: #333333;
  margin-left: 10px;
}
.jinex span:nth-of-type(2) {
  font-size: 14px;
  color: #999999;
  margin-left: 20px;
}
.contaniner_font {
  height: 86px;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}
.contaniner_font_a {
  height: 86px;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}
.contaniner_font:nth-of-type(1) {
  margin-left: 38px;
}
.contaniner_font_a:nth-of-type(1) {
  margin-left: 40px;
}
.font_left {
  display: flex;
  align-items: center;
}
.font_right {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}
.font_right span:nth-of-type(1) {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
}
.font_right span:nth-of-type(2) {
  font-size: 20px;
  color: #333333;
  line-height: 18px;
}
.font_right span:nth-of-type(3) {
  font-size: 14px;
  color: #999999;
  line-height: 14px;
}
.contan {
  height: 170px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contaniner_icon {
  flex: 1;
  height: 120px;
  margin-left: 50px;
  font-size: 14px;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contaniner_icon div:nth-of-type(2) {
  margin-top: 18px;
}
.contaniner_icon div {
  display: flex;
  align-items: center;
}
.contaniner_icon div span {
  margin-left: 13px;
}
</style>