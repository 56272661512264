<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form :inline="true" :model="formInline" size="mini">
        <el-form-item label="桌区位置" prop="tableRegionName">
          <el-input v-model.trim="formInline.tableRegionName"></el-input>
        </el-form-item>
        <el-form-item label="桌位名称" prop="tableName">
          <el-input v-model.trim="formInline.tableName"></el-input>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="value1" type="monthrange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM" format="yyyy-MM"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search" size="mini">查询</el-button>
        </el-form-item>
        <el-form-item>
          <span class="view-button-rest" @click="reset">重置筛选条件</span>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="mini" highlight-current-row>
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="tableRegionName" label="桌区位置"></el-table-column>
      <el-table-column prop="tableName" label="桌台名称"></el-table-column>
      <el-table-column prop="useCount" label="使用次数"></el-table-column>
      <el-table-column prop="turntableRate" label="月均翻台率"></el-table-column>
      <el-table-column prop="staMonth" label="统计日期"></el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination @current-change="handleCurrentChange" :current-page="page.pageNo" :page-size="page.pageSize" layout="total, prev, pager, next, jumper" :total="page.total" prev-text="上一页"
        next-text="下一页"></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.search();
  },
  data() {
    return {
      tableData: [],
      loading: false,
      value1: null,
      formInline: {
        tableRegionName: "",
        tableName: "",
        startDate: "",
        endDate: "",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      selectDate: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "本月",
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "最近3个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 3);
              end.setMonth(end.getMonth() - 1);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate: (date) => {
          let today = new Date();
          let toMonth = today.getMonth();
          if (date.getFullYear() > today.getFullYear()) {
            return true;
          }
          if (date.getMonth() > toMonth) {
            return true;
          }
          if (!this.selectDate) {
            return false;
          }
          if (this.selectDate.getMonth() == toMonth) {
            return date.getMonth() !== toMonth;
          } else {
            return date.getMonth() == toMonth;
          }
        },
        onPick: ({ maxDate, minDate }) => {
          if (maxDate) {
            this.selectDate = null;
          } else {
            this.selectDate = minDate;
          }
        },
      },
    };
  },
  methods: {
    reset() {
      this.formInline.tableRegionName = "";
      this.formInline.tableName = "";
      this.value1 = null;
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    load() {
      this.loading = true;
      this.formInline.startDate = this.value1 ? this.value1[0] : "";
      this.formInline.endDate = this.value1 ? this.value1[1] : "";
      this.$http
        .post("/order-admin/w/statistic/table/page", {
          ...this.formInline,...this.page
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        });
    },
    search() {
      this.page.pageNo = 1;
      this.load();
    },
    handleCurrentChange(v) {
      this.page.pageNo = v;
      this.load();
    },
  },
};
</script>

<style scoped>
</style>