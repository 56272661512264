<template>
  <div class="view">
    <div class="contain">
      <div class="header">
        <!-- <img src="@/assets/image/img-logo.png" class="logo_img" /> -->
        <img src="@/assets/image/img_logo_zhangjiakou.png" class="logo_img" v-if="tuimage==2" />
        <img src="@/assets/image/img-logo.png" class="logo_img_w" />
        <span>网联客点餐系统服务与协议</span>
      </div>
      <p>网联客点餐系统服务与协议</p>
      <div class="con">
        <br />欢迎使用网联客（北京）数字科技有限公司（以下简称“网联客”）提供的网联客点餐管理系统（以下简称“点餐系统”），本服务条款详述您在使用网联客点餐系统服务所须遵守的条款和条件。
        <br />
        <span style="font-size:18px;font-weight:bold">服务内容</span>
        <br />网联客点餐系统提供商，负责技术开发和维护点餐系统，确保点餐系统能够正常运营。关于点餐系统的具体服务内容包含但不限于：
        向您提供顾客自助扫描二维码点餐服务，及后台管理（桌位管理、菜品管理、订单管理、桌位状态管理、销售统计）等服务。
        <br />一、提供电子点餐系统：服务员点餐、客户扫码点餐
        <br />二、提供点餐后台管理系统
        <br />1.餐厅基本信息设置：餐厅简称、餐具费、餐位费、包装费、餐厅付费模式
        <br />2.桌位信息：餐桌位置、桌位信息、桌位二维码
        <br />3.菜品管理：菜品分类、计价单位、口味信息、推荐菜品
        <br />4.订单管理
        <br />5.优惠活动：满减活动、打折活动、特价菜
        <br />6.会员管理
        <br />7.数据统计：菜品销售统计、交易流水统计
        <br />8.员工管理：员工管理、员工权限。
        <br />9.打印机设置：网联客云打印机支持Wi-Fi设置，打印机可设置前台打印机、后厨打印机
        <br />
        <span style="font-size:18px;font-weight:bold">商户使用规则</span>
        <br />商户单独承担发布内容的责任。商户对系统的使用是根据所有适用于网站服务的地方法律、国家法律和国际法律标准的。
        商户所有的发布内容不得包含下述的任一信息：
        <br />1.煽动抵抗或违反中华人民共和国宪法、法律或行政法规执行的；煽动颠覆政府或社会主义制度的；煽动分裂国家、破坏祖国统一的；煽动民族仇恨、民族歧视，破坏民族团结的；制造谎言或歪曲事实、散布谣言、破坏社会秩序的；宣扬封建迷信、传播有性挑逗性质的材料、赌博、暴力或凶杀的；宣扬恐怖主义或唆使他人犯罪；公开侮辱他人或歪曲事实诽谤他人的；损害国家机关声誉的；其它违反中华人民共和国宪法、法律和行政法规的内容。
        <br />2.根据规定，您不得销售非法物品或提供非法服务：血液、体液和人体器官；入室行窃工具；假冒或非法制造的产品；火药、破坏性设备和爆炸性物质；赌博和打赌；任何形式的身份证明文件、个人财务记录以及个人信息；医疗保健，药品和医疗器械；彩票、博彩内容和老虎机；淫秽物品和儿童色情物品；令人反感的内容；卖淫；金字塔系统、多层市场推广以及“快速致富计划”等通过发展上下线的传销方式；盗得的财物；武器和相关物品，例如枪炮、弹夹、弹药、BB枪和铅弹枪、催泪瓦斯，眩晕枪、弹簧刀具和武术兵器；野生动物。
        <br />3.点餐系统是通过网络发送和收取信息。所以，商户的行为指引是根据国家有关因特网的法规，政策和程序的。商户须承诺不发送任何妨碍社会治安或非法、虚 假、骚扰性、侮辱性、恐吓性、伤害性、破坏性、挑衅性、淫秽色情性等内容的信息内容。另外，商户也不能传输任何教唆他人构成犯罪行为的内容；不能传输助长国内不利条件和涉及国家安全的内容；不能传输任何不符合当地法规、国家法律和国际法律的内容。若商户的行为不符合以上提到的服务条款，网联客将作出独立判断立即取消商户点餐系统服务帐号。若您违反有关法律法规及本服务条款的规定了，须自行承担完全的法律责任，并承担因此给本公司造成的损失的法律责任。
        <br />
        <span style="font-size:18px;font-weight:bold">服务风险及免责声明</span>
        <br />商户须明白，本服务仅依其当前所呈现的状况提供，本服务涉及到互联网及移动通讯等服务，可能会受到各个环节不稳定因素的影响。因此服务存在因上述不可抗力、计算机病毒或黑客攻击、系统不稳定、商户所在位置、商户关机、GSM网络、互联网络、通信线路原因等造成的服务中断或不能满足商户要求的风险。开通服务的商户须承担以上风险， 如本公司的系统发生故障影响到本服务的正常运行，本公司承诺在第一时间内与相关单位配合，及时处理进行修复。但商户因此而产生的经济损失，本公司和合作公司不承担责任。
        <br />
        <span style="font-size:18px;font-weight:bold">服务变更、中断或终止及服务条款的修改</span>
        <br />服务的所有权和运作权、一切解释权归网联客（北京）数字科技有限公司。
        网联客在必要时修改服务条款，服务条款一旦发生变动，将会在相关页面上公布修改后的服务条款。如果不同意所改动的内容，商户应主动取消此项服务。如果商户继续使用服务，则视为接受服务条款的变动。
        <br />网联客（北京）数字科技有限公司保留本服务条款之解释权。
        <br />
      </div>
    </div>
    <div class="footer_w">
      <el-button type="primary" @click="goback" size="mini">返回</el-button>
      <el-button type="primary" @click="give" size="mini" class="btn">我已阅读并同意</el-button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.tuimage = localStorage.getItem("tuimage");
  },
  data() {
    return {
      tuimage: ""
    };
  },
  methods: {
    goback() {
      this.$router.push("/login");
      window.localStorage.removeItem("checked1");
    },
    give() {
      this.$router.push({
        path: "login"
      });
      localStorage.setItem("checked1", true);
    }
  }
};
</script>

<style  scoped>
.view {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PingFangSC-Regular;
  overflow: auto;
}
.contain {
  width: 900px;
}
.header {
  height: 96px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: flex-start;
}
.logo_img {
  width: 97px;
  height: 24px;
  margin: 52px 20px 0 0;
}
.logo_img_w {
  width: 97px;
  height: 24px;
  margin-top: 52px;
  padding-right: 10px;
  border-right: 1px solid #e8e8e8;
}
.header span {
  margin: 52px 0 0 10px;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}
p:nth-of-type(1),
p:nth-of-type(2) {
  font-size: 24px;
  color: #333333;
  line-height: 24px;
  margin: 31px 0 16px 0;
  text-align: center;
}
.con {
  font-size: 14px;
  margin-top: 16px;
  line-height: 40px;
  color: #333333;
}
.pinner {
  text-align: right !important;
  font-size: 14px !important;
  margin: 4px !important;
}
.footer_w {
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 60px;
}
.footer_w button {
  font-size: 14px;
  text-align: center;
  border-radius: 2px;
}
.footer_w button:nth-of-type(1) {
  width: 90px;
  height: 32px;
  border: 1px solid #108af9;
  color: #108af9;
  background: #ffffff;
}
.btn {
  margin-left: 20px;
  text-align: center;
  height: 32px;
  background: #108af9;
  color: #ffffff;
}
</style>