<template>
  <div class="h100">
    <el-tabs class="h100" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        label="打印机管理 "
        name="first"
        class="h100"
        v-if="But.Dayinji.power.power()"
      >
        <view-dayinji
          class="h100"
          :activeName="activeName"
          ref="viewDayinji"
          @handleClick="handleClick"
        ></view-dayinji>
      </el-tab-pane>
      <el-tab-pane
        label="播报器管理"
        name="second"
        class="h100"
        v-if="But.Bobaoqi.power.power()"
      >
        <view-bobaoqi
          class="h100"
          ref="viewBobaoqi"
          @handleClick="handleClick"
        ></view-bobaoqi>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import viewDayinji from "./dayinji"; //打印机
import viewBobaoqi from "./bobaoqi"; //播报器
export default {
  created() {
    if (this.But.Bobaoqi.power.power()) {
      this.activeName = "second";
    }
    if (this.But.Dayinji.power.power()) {
      this.activeName = "first";
    }
  },
  mounted() {},
  components: {
    viewDayinji,
    viewBobaoqi,
  },
  data() {
    return {
      But: window.UserPower.RestRoomSet.searchMechanism,
      activeName: "",
    };
  },
  methods: {
    handleClick(val) {
      if (val == "first") {
        this.$refs.viewDayinji.load();
      } else {
        this.$refs.viewBobaoqi.search();
      }
    },
  },
};
</script>

<style scoped>
</style>