<template>
  <div class="container">
    <div class="disherlist">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane :label="item.tableRegionName" :name="item.tableRegionId" v-for="item in roomList" :key="item.tableRegionId"></el-tab-pane>
      </el-tabs>
      <div class="disherlist-content" v-loading="loading">
        <div class="content-left">
          <div class="status-ul">
            <span class="status-reload">
              <el-button type="primary" size="mini" @click="getTableInfoAndRegionList">刷新</el-button>
              <el-button type="primary" size="mini" @click="cancal">取消换桌</el-button>
            </span>
          </div>
          <div class="table-ul">
            <div class="table-li" :class="{activetable:item.tableId==activeTable}" @click="clickTable(item)" v-for="item in tableList" :key="item.tableId">
              <div class="table-name">{{item.tableName}}</div>
              <div class="table-status"></div>
              <div class="table-info">
                <span v-if="item.dinersCount">{{item.dinersCount}}/</span>
                {{item.seatingCapacity}}座
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    let query = this.$route.query;
    this.path = query.path;
    this.tableId = query.tableId;
    this.orderNo = query.orderNo;
    this.getRegionList();
    this.getTableInfoAndRegionList();
  },
  data() {
    return {
      orderNo: "",
      tableId: "",
      path: "",
      loading: false,
      merchantId: localStorage.getItem("merchantId"),
      activeName: "-1", //选中房间
      avtiveStatus: "0",
      activeTable: null,
      roomList: [{ tableRegionId: "-1", tableRegionName: "全部" }], //房间列表
      tableList: [], //桌位列表
      tableStatusList: [], //桌位状态列表,
    };
  },
  watch: {
    activeName() {
      this.getTableInfoAndRegionList();
    },
  },
  methods: {
    cancal() {
      this.$router.go(-1);
    },
    async clickTable({ tableId }) {
      this.loading = true;
      try {
        let option = {
          orderNo: this.orderNo,
          tableId: this.tableId,
          changTableId: tableId,
        };
        await this.$http.post(
          "/cashier-api/api/tableInfoNew/changeTableInfo",
          option
        );
        this.$router.replace({
          path: this.path,
          query: { orderNo: this.orderNo, tableId, orderMode: 1 },
        });
        this.$message({
          message: "换桌成功！",
          type: "success",
        });
      } finally {
        this.loading = false;
      }
    },
    async getRegionList() {
      try {
        this.loading = true;
        let { data } = await this.$http.post(
          "/cashier-api/api/tableInfoNew/getRegionList",
          {
            merchantId: this.merchantId,
            tableRegionId: this.activeName,
            tableStatus: this.avtiveStatus,
          }
        );
        this.roomList.push(...data);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async getTableInfoAndRegionList() {
      try {
        this.loading = true;
        this.activeTable = null;
        let { data } = await this.$http.post(
          "/cashier-api/api/tableInfoNew/getTableInfoAndRegionList",
          {
            merchantId: this.merchantId,
            tableRegionId: this.activeName,
            tableStatus: this.avtiveStatus,
          }
        );
        this.tableList = data.tableInfoList;
        this.tableStatusList = data.tableStatusList;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped src="../selectTable/index.css">
</style>