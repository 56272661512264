<template>
  <div v-loading="loading">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="120px"
      class="demo-ruleForm"
      size="mini"
      :rules="rules"
    >
      <el-row>
        <div class="hr_title">会员信息</div>
        <el-col :span="12" style="margin-top: 10px">
          <el-form-item label="会员姓名:" prop="mermberName">
            <span>{{ ruleForm.mermberName }}</span>
          </el-form-item>
          <el-form-item label="会员类型:" prop="memberTypeName">
            <span>{{ ruleForm.memberTypeName }}</span>
          </el-form-item>
          <el-form-item label="账户余额:">
            <span style="color: red"
              >{{ ruleForm.accountBalance | money }}元</span
            >
          </el-form-item>
        </el-col>
        <el-col :span="12" style="margin-top: 10px">
          <el-form-item label="手机号码:" prop="phone">{{
            ruleForm.phone
          }}</el-form-item>
          <el-form-item label="会员卡号:" prop="memberCardNo">
            {{ ruleForm.memberCardNo }}
            <div class="div_box">
              {{ ruleForm.realNameFlag == "0" ? "未实名" : "已实名" }}
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="ruleForm.memberCardNo">
        <div class="hr_title">会员卡号</div>
        <el-form-item label>
          <div class="reductionall_z">
            <div class="redioyou2">
              <span class="spansize">{{ ruleForm.memberCardNo }}</span>
              <img src="@/assets/image/img-xuanzhong-2.png" />
            </div>
          </div>
        </el-form-item>
      </el-row>
      <el-row>
        <div class="hr_title">充值金额</div>
        <el-form-item label="充值优惠:">
          <div class="reductionall">
            <div
              class="redioyou"
              v-for="(item, idx) in rebateList"
              @click="
                changetype(
                  idx,
                  item.activityId,
                  item.rebateId,
                  item.startAmount
                )
              "
              :key="idx"
              :class="idx == index1 ? 'active' : ''"
            >
              <span class="spansize" v-if="item.rebateId"
                >满{{ item.startAmount | money }}送{{
                  item.reduceAmount | money
                }}</span
              >
              <span v-else> 其他金额 </span>
              <img
                src="@/assets/image/img-xuanzhong-2.png"
                v-if="idx == index1"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item
          label="其他金额充值:"
          class="item"
          prop="money"
          v-if="index1 == rebateList.length - 1"
        >
          <el-input
            v-model="ruleForm.money"
            placeholder="请输入充值金额"
            style="width: 200px"
            @input="changemoney"
          ></el-input>
          <span class="redioyou-span" style="" v-if="activeQita.startAmount">
            满{{ activeQita.startAmount | money }}送{{
              activeQita.reduceAmount | money
            }}
          </span>
        </el-form-item>
      </el-row>
      <el-row>
        <div class="hr_title" style="margin: 20px 0">选择充值方式</div>
        <div class="reductionall">
          <div
            class="redioyou1"
            v-for="(item, idx) in paymentlist"
            @click="cashpayment(item.id, idx, item.name)"
            :key="idx"
            :class="idx == index2 ? 'active' : ''"
          >
            <span>{{ item.name }}</span>
            <img src="@/assets/image/icon_duigou_1.png" v-if="idx == index2" />
          </div>
        </div>
      </el-row>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="closew" size="small">上一步</el-button>
      <el-button type="primary" @click="submit" size="small"
        >确定充值</el-button
      >
    </div>

    <el-dialog
      :title="ruleForm.name"
      :visible.sync="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
    >
      <view-money
        @close="closeorder"
        ref="vieworderzin"
        :ruleForm="ruleForm"
        v-if="dialogVisible"
        @status="status"
      ></view-money>
    </el-dialog>

    <el-dialog
      title="扫码支付"
      :visible.sync="dialogVisible1"
      width="450px"
      :close-on-click-modal="false"
    >
      <view-saoma
        :ruleForm="ruleForm"
        v-if="dialogVisible1"
        @closebtn="closebtn"
        @status="status"
      ></view-saoma>
    </el-dialog>

    <el-dialog
      title="结果提示"
      :visible.sync="dialogVisible2"
      width="400px"
      :close-on-click-modal="false"
    >
      <div class="box_zin_v">
        <img src="@/assets/image/img_shoukuan_chenggong.png" />
        <p>{{ sussess.name }}</p>
        <p v-if="sussess.paymentStatus == '2'">
          {{ sussess.chargeAmt | money }}元
        </p>
        <p v-if="sussess.paymentStatus == '2'">
          实际入账金额:{{ sussess.chargeAmt | money }}元(含{{
            sussess.discountAmt | money
          }}元优惠)
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close" size="mini" type="primary">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import viewMoney from "./moneypay.vue"; //支付
import viewSaoma from "./saomapay.vue"; //扫码支付
export default {
  components: {
    viewMoney,
    viewSaoma,
  },
  mounted() {
    this.detail().then((res) => {
      this.changeradio();
    });
  },
  data() {
    var numzin = (rule, value, callback) => {
      console.log(value, "aa");
      if (value) {
        let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
        if (!rez.test(value)) {
          return callback(new Error("支持0.01—999999.99之间"));
        }
      }
      callback();
    };
    return {
      manjian: "",
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      rebateList: [],
      paymentlist: [
        {
          id: 1,
          name: "现金支付",
        },
        {
          id: 2,
          name: "扫码支付",
        },
        {
          id: 3,
          name: "刷卡支付",
        },
        {
          id: 4,
          name: "其他支付",
        },
        {
          id: 5,
          name: "免单",
        },
      ],
      rules: {
        money: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "change" },
        ],
      },
      rebate: [],
      ruleForm: {
        money: 123,
        paymentType: "",
        name: "",
        activityUsed: 0,
        rebateId: "",
        activityType: "",
        activityId: "",
      },
      loading: false,
      index2: null,
      index1: null,
      sussess: {},
      activeQita: {},
    };
  },
  methods: {
    close() {
      this.dialogVisible2 = false;
      this.$router.push("/servicemember");
    },
    status(data) {
      this.dialogVisible2 = true;
      this.sussess = data;
      if (data.paymentStatus == "2") {
        this.sussess.name = "充值成功";
      } else {
        this.sussess.name = "充值失败";
      }
    },
    detail() {
      return this.$http
        .post("/cashier-api/f/member/detail", {
          memberId: this.$route.query.id,
        })
        .then((res) => {
          res.activityUsed = 0;
          res.money = 0;
          this.ruleForm = res;
        });
    },
    changemoney(value) {
      if (this.rebateList.length == 1) {
        return value;
      }
      for (let i = 0; i < this.rebateList.length; i++) {
        let obj = this.rebateList[i];
        if (+value >= obj.startAmount / 100) {
          this.ruleForm.activityId = obj.activityId;
          this.ruleForm.rebateId = obj.rebateId;
          this.activeQita = {
            reduceAmount: obj.reduceAmount,
            startAmount: obj.startAmount,
          };
          break;
        }
      }
      return value;
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.paymentType && this.ruleForm.paymentType != "") {
            if (this.ruleForm.activityUsed == "1") {
              if (this.ruleForm.rebateId == undefined) {
                return this.$message({
                  message: "请选择要使用的优惠",
                  offset: 160,
                  type: "warning",
                });
              } else {
                this.showout();
              }
            } else {
              this.showout();
            }
          } else {
            this.$message({
              message: "请选择支付方式",
              offset: 160,
              type: "warning",
            });
          }
        }
      });
    },
    showout() {
      if (this.ruleForm.paymentType == "2") {
        this.dialogVisible1 = true;
      } else {
        this.dialogVisible = true;
      }
    },
    closeorder() {
      this.dialogVisible = false;
    },
    closebtn() {
      this.dialogVisible1 = false;
    },
    cashpayment(id, idx, name) {
      this.ruleForm.paymentType = id;
      this.ruleForm.name = name;
      this.index2 = idx;
    },
    changetype(idx, id, id1, num = 0) {
      this.ruleForm.money = num.money();
      this.index1 = idx;
      this.ruleForm.activityId = id;
      this.ruleForm.rebateId = id1;
      this.activeQita = {};
    },
    changeradio() {
      this.$http
        .post("/cashier-api/f/member/activity/s/ongoing", {
          activityType: "1",
          memberTypeId: this.ruleForm.memberTypeId,
          merchantId:this.ruleForm.merchantId
        })
        .then((res) => {
          this.rebateList = res.data.concat({
            activityId: 0,
            rebateId: 0,
            reduceAmount: 0,
            startAmount: "",
          });
          this.changetype(
            0,
            this.rebateList[0].activityId,
            this.rebateList[0].rebateId,
            this.rebateList[0].startAmount
          );
        });
    },
    closew() {
      this.$router.push("/servicemember");
    },
  },
};
</script>

<style scoped>
.input-width {
  width: 238px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.btn {
  margin: 20px 0;
}
.item {
  margin-top: 10px;
}
.dialog-footer {
  justify-content: center;
}
.reductionall,
.reductionall_z {
  display: flex;
  justify-content: flex-start;
}
.reductionall_z {
  margin-left: -70px;
}
.redioyou-span {
  display: inline-block;
  min-width: 140px;
  padding: 2px 11px;
  border: 1px solid #dcdee0;
  color: #333333;
  border-radius: 4px;
  text-align: center;
  margin-left: 15px;
}
.redioyou,
.redioyou1 {
  min-width: 140px;
  padding: 0 10px;
  height: 36px;
  border: 1px solid #dcdee0;
  color: #333333;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-right: 20px;
  cursor: pointer;
}
.redioyou2 {
  width: 190px;
  height: 36px;
  border: 1px solid #dcdee0;
  color: #333333;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding-left: 10px;
}
.redioyou2 img {
  width: 28px;
  margin-top: 4px;
}
.redioyou1 {
  width: 101px;
  height: 36px;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.redioyou1 img {
  margin-left: 4px;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
.footer button {
  width: 160px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #108af9;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 0;
}
.footer button:nth-of-type(2) {
  background: #108af9;
  color: #ffffff;
  margin-left: 0px;
}
.box_zin_v {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.box_zin_v p:nth-of-type(1) {
  font-size: 12px;
  color: #333333;
  margin-top: 6px;
}
.box_zin_v p:nth-of-type(2) {
  font-size: 18px;
  color: #333333;
  margin-top: 13px;
}
.box_zin_v p:nth-of-type(3) {
  font-size: 12px;
  color: #999999;
}
.div_box {
  width: 42px;
  height: 15px;
  border: 1px solid #108af9;
  border-radius: 2px;
  color: #108af9;
  font-size: 10px;
  text-align: center;
  line-height: 15px;
  display: inline-block;
}
</style>
