<template>
  <div class="view_con">
    <div class="view-search">
      <el-form :inline="true" :model="formInline" class="demo-form-inline" size="mini" ref="formInline">
        <el-form-item label="桌位名称" prop="tableName">
          <el-input v-model.trim="formInline.tableName" clearable></el-input>
        </el-form-item>
        <el-form-item label="订单号" prop="orderId">
          <el-input v-model.trim="formInline.orderId" clearable></el-input>
        </el-form-item>
        <el-form-item label="订单状态" prop="orderStatus" v-if="namezin == '1'">
          <el-select v-model="formInline.orderStatus" placeholder="请选择" clearable>
            <el-option v-for="(v, k) in equipmentstatus" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="挂单类型" prop="pendingType" v-if="namezin=='4'">
          <el-select v-model="formInline.pendingType" placeholder="请选择" clearable>
            <el-option v-for="(v,k) in pendingTypeall" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>-->
        <el-form-item label="下单时间">
          <el-date-picker :picker-options="pickerOptions" v-model="formInline.value1" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker v-model="formInline.value2" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button v-if="But.List.power()" type="primary" @click="searchzin" size="mini" class="serach">查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
      </div>
    </div>
    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" size="mini" highlight-current-row>
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="orderId" label="订单号" width="170"></el-table-column>
      <el-table-column prop="tableNo" label="桌位名称"></el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="scope">{{
          equipmentstatus[scope.row.orderStatus]
        }}</template>
      </el-table-column>
      <el-table-column label="支付方式" v-show="namezin != '2' && namezin != '4'">
        <template slot-scope="scope">{{ scope.row.paymentType }}</template>
      </el-table-column>
      <el-table-column label="订单总额">
        <template slot-scope="scope">{{
          scope.row.orderTotalAmount | money
        }}</template>
      </el-table-column>
      <el-table-column prop="orderOrignalAmount" label="订单原价">
        <template slot-scope="scope">{{
          scope.row.orderOrignalAmount | money
        }}</template>
      </el-table-column>
      <el-table-column prop="orderMemAmount" label="订单会员价" width="100">
        <template slot-scope="scope">{{
          scope.row.orderMemAmount | money
        }}</template>
      </el-table-column>
      <el-table-column prop="realAmount" label="实收金额" width="80">
        <template slot-scope="scope">{{
          scope.row.orderStatus == 2
            ? (scope.row.realAmount / 100).toFixed(2)
            : ""
        }}</template>
      </el-table-column>
       <el-table-column label="优惠金额" width="80">
        <template slot-scope="scope">{{
          scope.row.discountAmount | money
        }}</template>
      </el-table-column>
      <!-- <el-table-column label="抹零金额">
        <template slot-scope="scope">{{
          scope.row.notCountAmount | money
        }}</template>
      </el-table-column> -->
      <el-table-column prop="orderTime" label="下单时间" width="155"></el-table-column>
      <el-table-column prop="paymentTime" label="支付时间" v-show="namezin != '2' && namezin != '2'" width="155"></el-table-column>
      <!-- <el-table-column prop="pendTime" label="挂单时间" v-show="namezin=='4'"></el-table-column>
      <el-table-column prop="pendType" label="挂单类型" v-show="namezin=='4'">
        <template slot-scope="scope">{{pendingTypeall[scope.row.pendType]}}</template>
      </el-table-column>-->
      <el-table-column prop="userCnName" label="操作员" v-show="namezin == '4'"></el-table-column>
      <!-- <el-table-column prop="userCnName" label="被挂单人" v-show="namezin=='4'"></el-table-column> -->
      <el-table-column prop="sourceIp" label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="detail(scope.row.orderId)">详情</el-button>
          <el-button type="text" @click="searchorn(scope.row.orderId)" v-show="scope.row.orderStatus == '3'">查询</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page" style="margin-bottom: 70px">
      <el-pagination @current-change="handleCurrentChange" :current-page="page.pageNo" :page-size="page.pageSize" layout="total, prev, pager, next, jumper" :total="page.total" prev-text="上一页"
        next-text="下一页"></el-pagination>
    </div>
    <div class="foter">
      <span>
        订单总金额:
        <span>{{ statistics.orderTatalAmount | money }}元</span>
      </span>
      <span>
        实收总金额:
        <span>{{ statistics.realTotalAmount | money }}元</span>
      </span>
      <span>
        优惠总金额:
        <span>{{ statistics.discountAmount | money }}元</span>
      </span>
    </div>
    <el-dialog title="订单详情" :visible.sync="dialogVisibleDetile"  custom-class="order-dialog">
      <Detail :query="detailQuery" v-if="dialogVisibleDetile" />
    </el-dialog>
  </div>
</template>

<script>
import Detail from "./details.vue";
import formatDate from "@/common/date.js";
import { notTodayPass } from "@/common/mixins.js";
export default {
  created() {
    let today = formatDate(new Date(), "yyyy-MM-dd");
    this.formInline.value1 = [today + " 00:00:00", today + " 23:59:59"];
  },
  props: { namezin: String },
  components: { Detail },
  data() {
    return {
      detailQuery: {},
      dialogVisibleDetile: false,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate = minDate.getTime();
          if (maxDate) {
            this.choiceDate = "";
          }
        },
        disabledDate: (time) => {
          if (notTodayPass(time)) {
            return true;
          }
          const self = this;
          // eslint-disable-next-line no-extra-boolean-cast
          if (!!self.choiceDate) {
            let nums = 3 * 30 * 24 * 60 * 60 * 1000;
            let minTime = self.choiceDate - nums;
            let maxTime = self.choiceDate + nums;
            return time.getTime() < minTime || time.getTime() > maxTime;
          }
        },
      },
      But: window.UserPower.serve.orderdetail,
      formInline: {
        tableName: "",
        orderId: "",
        orderStartTime: "",
        orderEndTime: "",
        payEndTime: "",
        payStartTime: "",
        orderStatus: "",
        value1: [],
        value2: [],
      },
      tableData: [],
      statistics: {
        orderTatalAmount: "",
        realTotalAmount: "",
        discountAmount: "",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      equipmentstatus: {
        1: "待付款",
        2: "已付款",
        3: "支付中",
        // 4: "挂单"
      },
      pendingTypeall: {
        // 1: "确认挂单",
        // 2: "未确认挂单"
      },
      paymentType: {
        1: "现金",
        2: "扫码",
        3: "刷卡",
        4: "其他",
        5: "免单",
        6: "账户支付",
      },
    };
  },
  methods: {
    searchorn(id) {
      this.$http
        .post("/cashier-api/f/order/query-orderNo", {
          orderId: id,
        })
        .then(() => {
          this.search();
        });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
      this.formInline.value2 = [];
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      if (this.namezin == "2") {
        this.formInline.orderStatus = "1";
      } else if (this.namezin == "3") {
        this.formInline.orderStatus = "2";
      } else if (this.namezin == "4") {
        this.formInline.orderStatus = "4";
      } else {
        this.formInline.orderStatus = this.formInline.orderStatus;
      }
      this.formInline.orderStartTime = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.orderEndTime = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.formInline.payStartTime = this.formInline.value2
        ? this.formInline.value2[0]
        : "";
      this.formInline.payEndTime = this.formInline.value2
        ? this.formInline.value2[1]
        : "";
      this.$http
        .post("/cashier-api/f/order/search", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.tableData = res.page.records;
          this.statistics = res.statistics;
          this.page.total = res.page.total;
        });
    },
    detail(id) {
      this.detailQuery = { orderId: id };
      this.dialogVisibleDetile = true;
    },
  },
};
</script>

<style scoped>
.foter {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  text-align: center;
  font-size: 16px;
  line-height: 60px;
}
.foter span {
  margin-right: 30px;
}
.foter span span {
  color: #ff6969;
}
.view_con>>>.order-dialog{
  width: 95%;
  height: 90%;
  margin-top: 5vh!important;
  overflow: hidden;
}
.view_con>>>.el-dialog__body{
  height: calc(100% - 86px);
}
</style>


