<template>
  <div class="flex-view-tb_a" v-loading="loading">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      size="mini"
    >
      <div class="hr_title">基本信息</div>
      <el-form-item label="菜品编号" prop="dishersNo">
        <el-input class="input-width namezin" v-model.trim="ruleForm.dishersNo" clearable></el-input>
        <div class="input-tishi">用于商家内部管理，具体编码规则商家自定义</div>
      </el-form-item>
      <el-form-item label="菜品名称" prop="dishersName">
        <el-input class="input-width namezin" v-model.trim="ruleForm.dishersName" clearable></el-input>
      </el-form-item>
      <el-form-item label="菜品分类" prop="categoryId">
        <el-select v-model="ruleForm.categoryId" clearable placeholder="请选择" class="zinsele">
          <el-option
            v-for="item in options"
            :key="item.categoryId"
            :label="item.categoryName"
            :value="item.categoryId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="菜品图片" prop="pic">
        <div style="display:flex">
          <div style="line-height:0;border:1px dashed #e2e2e2;border-radius:3px;">
            <croppa
              :initial-image="ruleForm.licenseImage"
              @image-remove="imgSet"
              :remove-button-size="20"
              :width="250"
              :placeholder-font-size="16"
              :height="250"
              placeholder="选择图像"
              v-model="croppa"
            ></croppa>
          </div>
          <div class="btn-list">
            <div>
              <el-button size="mini" @click="croppa.rotate()">旋转</el-button>
            </div>
            <div>
              <el-button size="mini" @click="croppa.zoomIn()">放大</el-button>
            </div>
            <div>
              <el-button size="mini" @click="croppa.zoomOut()">缩小</el-button>
            </div>
            <div>
              <!-- <el-button type="primary" size="mini" @click="upImg()">上传</el-button> -->
            </div>
          </div>
          <div class="server">
            <div class="div" title="到服务器图库选择图片" @click="allpicter">
              <i class="el-icon-picture-outline"></i>
              图库选择
            </div>
            <div class="YY"></div>
          </div>
        </div>
        <div style="margin-top:10px;">建议尺寸：640x640像素；支持：jpg、png格式</div>
        <el-input type="hidden" v-model="ruleForm.pic"></el-input>
      </el-form-item>
      <el-form-item label="菜品描述:" prop="dishersDetail" style="width:351px">
        <el-input type="textarea" v-model.trim="ruleForm.dishersDetail"></el-input>
      </el-form-item>
      <div class="hr_title">价格信息</div>
      <el-form-item
        label="计价方式:"
        prop="disherUnitPriceReqVo.priceType"
        :rules="[
      { required: true, message: '必填', trigger: 'change' } 
    ]"
      >
        <el-select
          v-model="ruleForm.disherUnitPriceReqVo.priceType"
          placeholder="请选择"
          @change="zinchange"
          class="zinsele"
        >
          <el-option v-for="
            (v,k) in options1" :key="k" :label="v" :value="k"></el-option>
        </el-select>
        <span class="input-text" @click="addfliang" v-if="flagnum">添加</span>
        <el-table
          ref="multipleTable"
          :data="ruleForm.disherUnitPriceReqVo.depart"
          tooltip-effect="dark"
          style="width: 60%;margin-top:15px"
          size="mini"
          highlight-current-row
          v-if="flagnum"
        >
          <el-table-column prop="priceUnitName" label="份数"></el-table-column>
          <el-table-column prop="price" label="单价">
            <template slot-scope="scope">{{scope.row.price}}</template>
          </el-table-column>
          <el-table-column prop="memberPrice" label="会员价" v-if="ruleForm.openCommonMermber=='1'">
            <template slot-scope="scope">{{scope.row.memberPrice}}</template>
          </el-table-column>
          <el-table-column prop="dailyPresellCount" label="每日预售">
            <template
              slot-scope="scope"
            >{{scope.row.dailyPresellCount=="999999"?"":scope.row.dailyPresellCount}}</template>
          </el-table-column>
          <el-table-column prop="sourceIp" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="delefl(scope.$index,scope.row.priceId)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <!-- <el-form-item label="称重单位:" prop="priceUnit" v-if="flag">
        <el-select v-model="ruleForm.priceUnit" placeholder="请选择" @change="priceUnitchange">
          <el-option
            v-for="item in priceUnitlist"
            :key="item.dictId"
            :label="item.itemName"
            :value="item.dictId"
          ></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="单价:" prop="price" v-if="falgzin">
        <el-input class="input-width zinsele" v-model.trim="ruleForm.price">
          <template slot="prepend">￥</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="会员价格:"
        prop="memberPrice"
        v-if="falgzin&&ruleForm.openCommonMermber=='1'"
      >
        <el-input class="input-width zinsele" v-model.trim="ruleForm.memberPrice">
          <template slot="prepend">￥</template>
        </el-input>
      </el-form-item>
      <div class="hr_title">做法口味</div>
      <!-- <el-form-item label="是否有配菜:" prop="garnishStatus">
        <el-select v-model="ruleForm.garnishStatus" placeholder="请选择" class="zinsele">
          <el-option v-for="
            (v,k) in options2" :key="k" :label="v" :value="k"></el-option>
        </el-select>
        <span class="input-text" @click="addpc" v-if="ruleForm.garnishStatus!='0'">添加配菜</span>
        <div class="input-tishi">菜品添加配菜功能</div>
        <el-table
          v-if="ruleForm.garnishStatus!='0'"
          ref="multipleTable"
          :data="ruleForm.garnishszintwo"
          tooltip-effect="dark"
          style="width: 450px"
          size="mini"
          highlight-current-row
        >
          <el-table-column prop="garnishName" label="菜品名称"></el-table-column>
          <el-table-column prop="price" label="单价">
            <template slot-scope="scope">{{scope.row.price}}</template>
          </el-table-column>
          <el-table-column prop="sourceIp" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="dele(scope.$index,scope.row.garnishId)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>-->
      <el-form-item label="做法口味:" prop="selectedTastearr">
        <el-checkbox-group v-model="ruleForm.selectedTastearr">
          <el-checkbox v-for="i in cities" :key="i.dictId" :label="i.dictId">{{i.itemName}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="每日预售:" prop="dailyPresellCount">
        <el-input class="input-width" v-model="ruleForm.dailyPresellCount"></el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button type="primary" size="mini" @click="upImg">保存</el-button>
    </div>

    <el-dialog title="选择图片" :visible.sync="dialogVisible" width="70%">
      <wxf-goods @close="close" v-if="dialogVisible"></wxf-goods>
    </el-dialog>

    <el-dialog title="添加配菜" :visible.sync="dialogVisiblezin" width="450px">
      <el-form
        :inline="true"
        :model="formzine"
        class="demo-form-inline"
        size="mini"
        ref="formzine"
        :rules="rules2"
      >
        <el-form-item label="菜品名称:" prop="garnishName">
          <el-input v-model="formzine.garnishName"></el-input>
        </el-form-item>
        <el-form-item label="菜品价格:" prop="price">
          <el-input v-model="formzine.price"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
        <el-button @click="dialogVisiblezin = false" size="small">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加份量" :visible.sync="dialogVisiblezinflag" width="450px">
      <el-form
        :inline="true"
        :model="formzinefl"
        class="demo-form-inline"
        size="mini"
        ref="formzinefl"
        :rules="rules1"
      >
        <el-form-item label="份量:" prop="priceUnit">
          <el-select v-model="formzinefl.priceUnit" clearable placeholder="请选择" @change="fenliang">
            <el-option
              v-for="
            item in priceall"
              :key="item.dictId"
              :label="item.itemName"
              :value="item.dictId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单价:" prop="price">
          <el-input v-model="formzinefl.price"></el-input>
        </el-form-item>
        <el-form-item label="会员价:" prop="memberPrice" v-if="ruleForm.openCommonMermber=='1'">
          <el-input v-model="formzinefl.memberPrice"></el-input>
        </el-form-item>
        <el-form-item label="每日预售:" prop="dailyPresellCount">
          <el-input v-model="formzinefl.dailyPresellCount"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="onSubmitfl" size="small">保存</el-button>
        <el-button @click="dialogVisiblezinflag = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import wxfGoods from "./wxfAddGoods";
import { baseurl } from "../../../../base.js";
export default {
  created() {
    this.ruleForm.supportWebp =
      !![].map &&
      document
        .createElement("canvas")
        .toDataURL("image/webp")
        .indexOf("data:image/webp") == 0;
    this.title = this.$route.query.title;
    this.ruleForm.dishersId = this.$route.query.dishersId;
    this.ruleForm.priceId = this.$route.query.priceId;
    this.loadlist();
  },
  mounted() {
    this.$refs["ruleForm"] && this.$refs["ruleForm"].resetFields();
    this.pictuerurl = `${this.baseurl}/order-admin/w/file/upload/pic`;
  },
  components: {
    wxfGoods,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value) {
        let rez = /^[1-9][0-9]{0,5}$/;
        if (!rez.test(value)) {
          return callback(new Error("支持1—999999之间的正整数"));
        }
      }
      callback();
    };
    var dishersNozun = (rule, value, callback) => {
      let rez = /^[a-z0-9A-Z]{1,15}$/;
      if (!rez.test(value)) {
        return callback(new Error("最多支持15个数字、字母"));
      }
      callback();
    };
    var numzin = (rule, value, callback) => {
      if (value) {
        let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
        if (!rez.test(value)) {
          return callback(new Error("支持0.01—999999.99之间"));
        }
      }
      callback();
    };
    var numzinurl = (rule, value, callback) => {
      if (value) {
        let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
        if (!rez.test(value)) {
          return callback(new Error("支持0.01—999999.99之间"));
        }
        if (value > +this.formzinefl.price) {
          return callback(new Error("会员价格不能大于原价"));
        }
      }

      callback();
    };
    var numzinurlzit = (rule, value, callback) => {
      if (value) {
        let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
        if (!rez.test(value)) {
          return callback(new Error("支持0.01—999999.99之间"));
        }
        if (value > +this.ruleForm.price) {
          return callback(new Error("会员价格不能大于原价"));
        }
      }
      callback();
    };
    return {
      croppa: {},
      loading: false,
      myHeaders: { "jwt-ov3": localStorage.getItem("tokenIdzin") },
      baseurl: baseurl,
      pictuerurl: "",
      formzinefl: {
        memberPrice: "",
        price: "",
        priceUnit: "",
        priceUnitName: "",
        dailyPresellCount: "",
      },
      show: false,
      dialogVisiblezinflag: false,
      title: "",
      cities: [],
      dialogVisible: false,
      ruleForm: {
        openCommonMermber: "",
        priceId: "",
        dishersId: "",
        supportWebp: "",
        dishersNo: "",
        dishersName: "",
        imageId: "",
        categoryId: "",
        dishersDetail: "",
        disherUnitPriceReqVo: {
          priceType: "",
          normal: {
            price: "",
            memberPrice: "",
            priceId: "",
          },
          weight: {
            price: "",
            memberPrice: "",
            priceId: "",
          },
          time: {
            priceId: "",
          },
          depart: [],
        },
        selectedTastearr: [],
        selectedTaste: "",
        garnishStatus: "0",
        garnishs: [],
        garnishszintwo: [],
        dailyPresellCount: "",
        price: "",
        memberPrice: "",
        priceUnit: "",
        disherUnitPriceSearchVoList: [],
      },
      options: [],
      options1: {
        0: "常规计价",
        // 1: "称重计价",
        2: "份量计价",
        3: "时价",
      },
      options2: {
        0: "否",
        1: "有",
      },
      priceUnitlist: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      rules1: {
        priceUnit: [
          { required: true, message: "请选择份量", trigger: "change" },
        ],
        price: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" },
        ],
        memberPrice: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzinurl, trigger: "blur" },
        ],
        dailyPresellCount: [{ validator: validatePass, trigger: "blur" }],
      },
      rules2: {
        garnishName: [
          { required: true, message: "必填", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "最多支持15个字",
            trigger: "blur",
          },
        ],
        price: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" },
        ],
      },
      rules: {
        dishersNo: [
          { required: true, message: "必填", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "最多支持15个数字、字母",
            trigger: "blur",
          },
          { validator: dishersNozun, trigger: "blur" },
        ],
        dishersName: [
          { required: true, message: "必填", trigger: "blur" },
          {
            min: 1,
            max: 15,
            message: "最多支持15个字",
            trigger: "blur",
          },
        ],
        dishersDetail: [
          {
            min: 1,
            max: 100,
            message: "最多只能填写100字",
            trigger: "blur",
          },
        ],
        categoryId: [{ required: true, message: "必填", trigger: "blur" }],
        price: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" },
        ],
        memberPrice: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzinurlzit, trigger: "blur" },
        ],
        garnishStatus: [{ required: true, message: "必填", trigger: "blur" }],
        priceUnit: [
          {
            required: true,
            message: "请选择份量",
            trigger: ["blur", "change"],
          },
        ],
        selectedTastearr: [
          // {
          //   type: "array",
          //   required: true,
          //   message: "请至少选择一个口味",
          //   trigger: "change"
          // }
        ],
        dailyPresellCount: [{ validator: validatePass, trigger: "blur" }],
      },
      formzine: {
        garnishName: "",
        price: "",
      },
      dialogVisiblezin: false,
      isDisabledUpload: true,
      isShowUploadSelectBox: false,
      valgizin: "",
      objpriceUnit: {},
      flag: false,
      falgzin: true,
      flagnum: false,
      tabledatazin: [],
      priceall: [],
    };
  },
  methods: {
    upImg() {
      if (!this.croppa.hasImage()) {
        this.ruleForm.imageId = "";
        this.submited();
        return;
      }
      this.croppa.generateBlob(
        (blob) => {
          var fd = new FormData();
          fd.append("file", blob, "blob.png");
          this.$http.post(this.pictuerurl, fd).then((res) => {
            this.ruleForm.imageId = res.data;
            this.submited();
          });
        },
        "image/jpeg",
        1
      );
    },
    imgSet() {
      this.ruleForm.imageId = "";
    },
    fenliang(val) {
      var obj = {};
      obj = this.priceUnitlist.find(function (item) {
        return item.dictId === val;
      });
      this.formzinefl.priceUnitName = obj.itemName;
    },
    addfliang() {
      this.dialogVisiblezinflag = true;
      this.$refs["formzinefl"] && this.$refs["formzinefl"].resetFields();
      this.$http
        .post("/order-admin/w/dict/s/all", {
          dictType: "1001",
        })
        .then((res) => {
          this.priceall = res.data;
        });
      for (let i in this.formzinefl) {
        this.formzinefl[i] = "";
      }
    },
    addpc() {
      this.dialogVisiblezin = true;
      this.formzine.price = "";
      this.formzine.garnishName = "";
    },
    onSubmitfl() {
      this.$refs["formzinefl"].validate((valid) => {
        if (valid) {
          this.ruleForm.disherUnitPriceReqVo.depart.push({
            ...this.formzinefl,
          });
          this.dialogVisiblezinflag = false;
        }
      });
    },
    delefl(index, id) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.ruleForm.disherUnitPriceReqVo.depart.splice(index, 1);
          this.$message({
            type: "success",
            offset: 160,
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            offset: 160,
            message: "已取消",
          });
        });
    },
    dele(index, id) {
      this.$confirm("是否确认删除此配菜?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.title == "添加菜品") {
            this.ruleForm.garnishszintwo.splice(index, 1);
          } else {
            this.ruleForm.garnishszintwo.splice(index, 1);
            for (let i in this.ruleForm.garnishs) {
              if (this.ruleForm.garnishs[i].garnishId == id) {
                this.ruleForm.garnishs[i].garnishStatus = 0;
              }
            }
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            offset: 160,
            message: "已取消",
          });
        });
    },
    onSubmit() {
      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          if (this.title == "添加菜品") {
            this.ruleForm.garnishszintwo.push({ ...this.formzine });
          } else {
            this.ruleForm.garnishszintwo.push({ ...this.formzine });
            this.ruleForm.garnishs.push({ ...this.formzine });
          }
          this.$message({
            type: "success",
            offset: 160,
            message: `添加配菜成功`,
          });
          this.dialogVisiblezin = false;
        }
      });
    },
    close(val) {
      this.dialogVisible = false;
      this.ruleForm.imageId = val.imageId;
      var image = new Image();
      image.setAttribute("crossorigin", "anonymous");
      image.src = val.previewUrl;
      this.ruleForm.licenseImage = image;
      this.croppa.refresh();
    },
    zinchange(val) {
      this.valgizin = val.toString();
      if (val == 0) {
        this.flag = false;
        this.falgzin = true;
        this.flagnum = false;
      } else if (val == 1) {
        this.flag = true;
        this.falgzin = true;
        this.flagnum = false;
      } else if (val == 2) {
        this.flag = false;
        this.falgzin = false;
        this.flagnum = true;
      } else {
        this.ruleForm.disherUnitPriceReqVo.time.priceId = this.ruleForm.priceId;
        this.flag = false;
        this.falgzin = false;
        this.flagnum = false;
      }
    },
    priceUnitchange(val) {
      var obj = {};
      obj = this.priceUnitlist.find(function (item) {
        return item.dictId === val;
      });
      this.objpriceUnit = obj;
    },
    uploadSuc() {
      this.isDisabledUpload = false;
    },
    allpicter() {
      this.isDisabledUpload = true;
      this.dialogVisible = true;
    },
    enter() {
      this.isShowUploadSelectBox = true;
    },
    leave() {
      this.isShowUploadSelectBox = false;
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.imageId = res.data;
      this.ruleForm.licenseImage = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      var isJPG = /^image\/(png|jpeg)$/.test(file.type);
      if (!isJPG) {
        this.$message.error("支持jpg、png格式");
      }
      return isJPG;
    },
    loadlist() {
      this.$http
        .post(
          `/order-admin/w/restaurant/detail?isSupportWebp=${this.ruleForm.supportWebp}`,
          {}
        )
        .then((res) => {
          this.ruleForm.openCommonMermber = res.data.openCommonMermber.toString();
          this.$http
            .post("/order-admin/w/disher/category/s/all", {})
            .then((res) => {
              this.options = res.data;
              this.$http
                .post("/order-admin/w/dict/s/all", {
                  dictType: "1002",
                })
                .then((res) => {
                  this.cities = res.data;
                  this.$http
                    .post("/order-admin/w/dict/s/all", {
                      dictType: "1001",
                    })
                    .then((res) => {
                      this.priceUnitlist = res.data;
                      if (this.title == "添加菜品") {
                        this.$http
                          .post(
                            `/order-admin/w/disher/get/default/disherno`,
                            {}
                          )
                          .then((res) => {
                            this.ruleForm.dishersNo = res.data;
                          });
                      }
                      if (this.title == "编辑菜品") {
                        this.detail();
                      }
                    });
                });
            });
        });
    },
    detail() {
      this.$http
        .post("/order-admin/w/disher/detail", {
          disherId: this.ruleForm.dishersId,
          supportWebp: this.ruleForm.supportWebp,
        })
        .then((res) => {
          this.ruleForm.dishersNo = res.data.dishersNo;
          this.ruleForm.dishersName = res.data.dishersName;
          this.ruleForm.categoryId = res.data.categoryId;
          this.ruleForm.imageId = res.data.imageId;
          this.ruleForm.dishersDetail = res.data.dishersDetail;
          this.ruleForm.disherUnitPriceReqVo.priceType = res.data.disherUnitPriceSearchVoList[0].priceType.toString();
          this.ruleForm.price =
            res.data.disherUnitPriceSearchVoList[0].price / 100;
          this.ruleForm.memberPrice =
            res.data.disherUnitPriceSearchVoList[0].memberPrice / 100;
          this.ruleForm.priceUnit =
            res.data.disherUnitPriceSearchVoList[0].priceUnit;
          this.ruleForm.garnishStatus = res.data.garnishStatus.toString();
          for (let i in res.data.garnishes) {
            res.data.garnishes[i].price = res.data.garnishes[i].price / 100;
          }
          this.ruleForm.garnishs = JSON.parse(
            JSON.stringify(res.data.garnishes)
          );
          this.ruleForm.garnishszintwo = res.data.garnishes;
          if (res.data.dailyPresellCount == "999999") {
            this.ruleForm.dailyPresellCount = "";
          } else {
            this.ruleForm.dailyPresellCount = res.data.dailyPresellCount;
          }

          this.ruleForm.imageId = res.data.imageId;
          var image = new Image();
          image.setAttribute("crossorigin", "anonymous");
          image.src = res.data.previewUrl;
          this.ruleForm.licenseImage = image;
          this.croppa.refresh();
          let arrt = res.data.selectedTaste.split(",");
          let rarrey = [];
          this.cities.forEach((item) => {
            for (let i in arrt) {
              if (arrt[i] == item.dictId) {
                rarrey.push(arrt[i]);
              }
            }
          });
          for (let i in rarrey) {
            if (rarrey[i] != "") {
              this.ruleForm.selectedTastearr.push(rarrey[i]);
            }
          }
          // this.ruleForm.selectedTastearr = res.data.selectedTaste.split(",");
          this.valgizin = res.data.disherUnitPriceSearchVoList[0].priceType.toString();
          this.zinchange(this.valgizin);
          if (this.valgizin == "2") {
            for (let i in res.data.disherUnitPriceSearchVoList) {
              res.data.disherUnitPriceSearchVoList[i].price =
                res.data.disherUnitPriceSearchVoList[i].price / 100;
              res.data.disherUnitPriceSearchVoList[i].memberPrice =
                res.data.disherUnitPriceSearchVoList[i].memberPrice / 100;
            }
            // this.tabledatazin = res.data.disherUnitPriceSearchVoList;
            this.ruleForm.disherUnitPriceReqVo.depart = JSON.parse(
              JSON.stringify(res.data.disherUnitPriceSearchVoList)
            );
          }
        });
    },
    submited() {
      if (this.ruleForm.selectedTastearr.length > 3) {
        this.$message({
          message: "最多选择3种口味",
          type: "warning",
        });
        return;
      }
      this.ruleForm.selectedTaste = this.ruleForm.selectedTastearr.join(",");
      if (this.title == "添加菜品") {
        this.ruleForm.garnishs = this.ruleForm.garnishszintwo;
      }
      let datainner = JSON.parse(JSON.stringify(this.ruleForm));
      datainner.price = +(datainner.price * 100).toFixed(2);
      datainner.memberPrice = +(datainner.memberPrice * 100).toFixed(2);
      if (this.valgizin == "0") {
        datainner.disherUnitPriceReqVo.normal.price = datainner.price;
        datainner.disherUnitPriceReqVo.normal.memberPrice =
          datainner.memberPrice;
        datainner.disherUnitPriceReqVo.normal.priceId = datainner.priceId;
        datainner.disherUnitPriceReqVo.normal.priceUnit = this.objpriceUnit.dictId;
        datainner.disherUnitPriceReqVo.normal.priceUnitName = this.objpriceUnit.itemName;
        datainner.disherUnitPriceReqVo.weight = {};
        datainner.disherUnitPriceReqVo.depart = [];
      } else if (this.valgizin == "1") {
        datainner.disherUnitPriceReqVo.weight.price = datainner.price;
        datainner.disherUnitPriceReqVo.weight.memberPrice =
          datainner.memberPrice;
        datainner.disherUnitPriceReqVo.weight.priceId = datainner.priceId;
        datainner.disherUnitPriceReqVo.weight.priceUnit = this.objpriceUnit.dictId;
        datainner.disherUnitPriceReqVo.weight.priceUnitName = this.objpriceUnit.itemName;
        datainner.disherUnitPriceReqVo.normal = {};
        datainner.disherUnitPriceReqVo.depart = [];
      } else if (this.valgizin == "2") {
        datainner.disherUnitPriceReqVo.normal = {};
        datainner.disherUnitPriceReqVo.weight = {};
      } else {
        datainner.disherUnitPriceReqVo.normal = {};
        datainner.disherUnitPriceReqVo.depart = [];
        datainner.disherUnitPriceReqVo.weight = {};
      }
      if (this.ruleForm.disherUnitPriceReqVo.priceType == "2") {
        if (this.ruleForm.disherUnitPriceReqVo.depart.length < 1) {
          this.$message({
            message: "请添加一个计价方式",
            type: "warning",
          });
          return;
        }
      }

      let datalist = JSON.parse(
        JSON.stringify(this.ruleForm.disherUnitPriceReqVo.depart)
      );
      for (let i in datalist) {
        if (datalist[i].status != 0) {
          datalist[i].price = +(datalist[i].price * 100).toFixed(2);
          datalist[i].memberPrice = +(datalist[i].memberPrice * 100).toFixed(2);
        }
      }
      datainner.disherUnitPriceReqVo.depart = datalist;
      let datalist1 = JSON.parse(JSON.stringify(datainner.garnishs));
      for (let i in datalist1) {
        datalist1[i].price = +(datalist1[i].price * 100).toFixed(2);
      }
      datainner.garnishs = datalist1;
      let url =
        this.title == "编辑菜品"
          ? "/order-admin/w/disher/modify"
          : "/order-admin/w/disher/add";
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post(`${url}`, datainner)
            .then((res) => {
              this.loading = false;
              this.$message({
                type: "success",
                message: `${this.title}成功`,
              });
              let type = this.title == "编辑菜品" ? 2 : 1;
              this.$router.push({
                path: "Dishesinformation",
                query: { type: type },
              });
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.input-text {
  color: #108af9;
  margin-left: 10px;
}
.input-width {
  width: 170px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.hr_title {
  margin-bottom: 20px;
}
.footer {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  position: absolute;
  bottom: 0;
}
.uploadBox {
  position: relative;
}
.mbBox {
  width: 204px;
  height: 35px;
  line-height: 35px;
  display: flex;
  justify-content: space-between;
  border-radius: 2px;
  color: white;
  position: absolute;
  bottom: -15px;
  box-sizing: border-box;
  font-size: 12px;
  z-index: 100;
}
.mbBox div:hover {
  opacity: 0.85;
}
.mbBox div {
  background: black;
  opacity: 1;
}
.mbBox div:nth-of-type(1) {
  width: 50%;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.mbBox div:nth-of-type(2) {
  width: 50%;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.zinsele {
  width: 158px;
}
.namezin {
  width: 258px;
}
.flex-view-tb_a {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
}
.flex-view-tb_a .demo-ruleForm {
  flex: 1;
  overflow: auto;
}
.btn-list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80px;
  flex-direction: column;
  padding: 30px 0;
  margin-left: 30px;
}
.server {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  flex-direction: column;
}
.server > .div i {
  font-size: 30px;
}
.server > .div:hover {
  background-color: #409eff;
  box-shadow: 0 0px 10px 0 #d9dcda inset;
  color: #fff;
}
.server > .div {
  box-shadow: 0 0px 91px 1px #d9dcda inset;
  cursor: pointer;
  transition: all 0.2s;
  width: 98px;
  height: 98px;
  border-radius: 98px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
</style>
