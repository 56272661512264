<template>
  <div v-loading="Loading">
    <el-form :model="ruleForm" size="mini" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="会员帐号:" prop="phone">
        <el-input placeholder="请输入会员手机号" maxlength="11" v-model="ruleForm.phone" clearable></el-input>
      </el-form-item>
      <el-form-item style="text-align:right">
        <el-button type="primary" @click="submit">验证</el-button>
        <el-button @click="cancal">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    let vaildateVip = async (rule, value, cb) => {
      if (value.length == 0) {
        return cb(new Error("请填写手机号"));
      }
      if (!/^1[0-9]\d{9}$/.test(value)) {
        return cb(new Error("请填写正确的手机号"));
      }
      return cb();
    };
    return {
      Loading: false,
      rules: {
        phone: [{ validator: vaildateVip, trigger: "change" }],
      },
      ruleForm: {
        phone: "",
      },
    };
  },
  methods: {
    cancal() {
      this.$emit("close");
    },
    submit() {
      this.$refs.ruleForm.validate((v) => {
        if (!v) return;
        this.member(this.ruleForm.phone);
      });
    },
    async member(phone) {
      this.Loading = true;
      try {
        let res = await this.$http.post(
          "/cashier-api/api/merchantInfo/memberInfo",
          { phone }
        );
        this.cancal();
        this.$emit("setMember", res);
      } finally {
        this.Loading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>