<template>
  <div class="guige" v-loading="loading">
    <el-form :inline="true" :model="formzinernil" class="demo-form-inline" size="mini" ref="formzinernil" :rules="rules">
      <div v-if="dishersObj.tasteList.length != 0">
        <div class="fontal">选择口味</div>
        <div class="radio">
          <div v-for="(item, index) in dishersObj.tasteList" :class="index == isActiveTaste ? 'active' : ''" @click="checkedTasteItem(index, item)" :key="index">
            {{ item.tasteName }}
          </div>
        </div>
      </div>
      <div v-if="dishersObj.departList.length!=0" style="margin-top:30px;">
        <div class="fontal">选择规格</div>
        <div class="radio">
          <div v-for="(item, index) in dishersObj.departList" :class="index == isActive ? 'active' : ''" @click="checkedItem(index, item)" :key="index">
            {{ item.priceUnitName }}
          </div>
        </div>
      </div>
      <!-- <div class="fontal_a">选择配菜</div>
      <div class="contain_inner">
        <div
          class="contain_inner_a"
          v-for="(item,index) in formzinernil.garnishInfoVos"
          :key="index"
        >
          <div>{{item.garnishName}}</div>
          <div>
            <div>¥{{item.price/100}}</div>
            <div class="add_reduce">
              <img
                src="@/assets/image/icon-jian.png"
                @click="loadfuntion('减',item)"
                v-if="item.garnishCount>0"
              />
              <span>{{item.garnishCount}}</span>
              <img src="@/assets/image/icon-jia.png" @click="loadfuntion('加',item)" />
            </div>
          </div>
        </div>
      </div>-->
    </el-form>
    <div class="footer_a">
      <template>
        <span class="cFF6969" v-if="dishersObj.hasSpecialPrice">￥{{local.specialPrice|money}}<span class="price-txt">特价</span></span>
        <span class="cFF6969" v-if="!dishersObj.hasSpecialPrice&&dishersObj.openCommonMermber==1">￥{{local.memberPrice|money}}<span class="price-txt">会员价</span></span>
        <span style="margin-right:20px" v-if="local.price"> ￥{{local.price|money}}</span>
      </template>
      <el-button type="primary" @click="submitedzin" size="mini">确定</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["dishersObj", "orderNo"],
  created() {
    this.changeDIshers(this.dishersObj);
  },
  watch: {
    dishersObj(v) {
      this.changeDIshers(v);
    },
  },
  data() {
    return {
      local: {
        specialPrice: "",
        memberPrice: "",
        price: "",
      },
      formzinernil: {
        orderNo: "",
        dishesId: "",
        addCount: "1",
        tasteId: "",
        priceUnitId: "",
      },
      departsarr: [],
      tastearr: [],
      rules: {},
      isActive: 0,
      isActiveTaste: 0,
      priceType: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["getShopingCard"]),
    changeDIshers(v) {
      this.formzinernil.orderNo = this.orderNo;
      this.formzinernil.dishesId = v.dishesId;
      this.formzinernil.tasteId = v.tasteList[0]?.tasteId;
      this.formzinernil.priceUnitId = "";
      if (v.departList.length != 0) {
        this.formzinernil.priceUnitId = v.departList[0]?.priceUnitId;
        this.local = v.departList[0];
      } else {
        this.local = {
          specialPrice: v.specialPrice,
          memberPrice: v.memberPrice,
          price: v.price,
        };
      }
    },
    checkedItem(index, item) {
      this.isActive = index;
      this.local = item;
      this.formzinernil.priceUnitId = item.priceUnitId;
    },
    checkedTasteItem(index, item) {
      this.isActiveTaste = index;
      this.formzinernil.tasteId = item.tasteId;
    },
    close() {
      this.$emit("close");
    },
    submitedzin() {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/dishes/addDishes", {
          ...this.formzinernil,
        })
        .then(() => {
          this.loading = false;
          this.$message({
            message: "添加成功",
            type: "success",
            offset: 160,
          });
          this.$emit("close");
          this.getShopingCard({ orderNo: this.formzinernil.orderNo });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadfuntion(a, item) {
      if (a == "加") {
        item.garnishCount++;
      } else {
        item.garnishCount--;
      }
    },
  },
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.fontal {
  font-size: 14px;
  margin: 10px 0 10px 0;
}
.radio {
  display: flex;
  justify-content: flex-start;
}
.radio div {
  width: 150px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
  margin-right: 20px;
}
.active {
  background: #ecf6ff !important;
  border: 1px solid #108af9 !important;
  border-radius: 4px !important;
  color: #108af9 !important;
}
.fontal_a {
  margin: 30px 0 10px 0;
}
.contain_inner {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.contain_inner_a {
  width: 150px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10px;
  margin: 0 20px 20px 0;
}
.contain_inner_a div:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
}
.contain_inner_a div:nth-of-type(2) img {
  width: 12px;
  height: 12px;
}
.add_reduce {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.add_reduce img,
span {
  margin-right: 5px;
}
.footer_a {
  width: 100%;
  text-align: right;
  margin: 50px 0 20px;
}
.price-txt {
  background: #3c2c1f;
  font-size: 12px;
  color: #ffba4a;
  padding: 3px 5px;
  transform: scale(0.6, 0.6);
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 46px;
}

.price-txt::after {
  display: block;
  position: absolute;
  content: " ";
  left: -6px;
  z-index: 2;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #3c2c1f;
}
.cFF6969 {
  color: #ff6969;
}
</style>