<template>
  <div class="view-head h100">
    <div class="font_wzo">
      <div
        class="list"
        v-for="(item, idx) in meanlist"
        :key="idx"
        @click="handleSelect(item.menuId, idx)"
        :class="idx == index ? 'active' : ''"
      >
        {{ item.menuName }}
      </div>
    </div>
    <div>
      <el-switch
        v-model="value1"
        active-text="有图"
        inactive-text="无图"
        @change="changeValue1"
      >
      </el-switch>
    </div>
    <div>
      <div style="position: relative">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <img :src="count" />
            <span class="font_wenzi">{{ loginName }}</span>
            <img src="@/assets/image/icon_off.png" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleCommand('a')"
              >账号设置</el-dropdown-item
            >
            <el-dropdown-item
              v-if="flag == 1"
              @click.native="handleCommand('c')"
              >切换账号</el-dropdown-item
            >
            <el-dropdown-item @click.native="handleCommand('b')"
              >退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
      title="切换商户"
      :visible.sync="dialogVisiblezint"
      :center="true"
      width="420px"
      :close-on-click-modal="false"
    >
      <view-mer
        class="h100"
        v-if="dialogVisiblezint"
        :merList="merList"
        @close="close"
        @menulist="menulist"
      ></view-mer>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "@/common/bus.js";
import viewMer from "../view/page/mer/mer.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    viewMer,
  },
  created() {
    this.meanlist = JSON.parse(localStorage.getItem("frontUrl"));
    if (this.meanlist != "") {
      this.meanlist.push({ menuId: 100, menuName: "点餐后台" });
    }
    this.load();
    //this.handleSelect(this.meanlist[0].menuId, 0);
    this.flag = localStorage.getItem("hasMultipleMerchant");
    this.meanlist.unshift({ menuId: 99, menuName: "无桌点餐" });
    let pictureStatus =
      localStorage.getItem("pictureStatus") == 1 ? true : false;
    this.setShowImg(pictureStatus);
    this.value1 = pictureStatus;
  },
  computed: {
    count() {
      return this.$store.state.manageImgUrl;
    },
    showImg() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.value1 = this.$store.state.showImg;
      return this.$store.state.showImg;
    },
  },
  data() {
    return {
      value1: false,
      dialogVisiblezint: false,
      flag: "",
      loginName: localStorage.getItem("merchantName"),
      title: "",
      index: 1,
      meanlist: [],
    };
  },
  methods: {
    ...mapMutations(["setShowImg"]),
    changeValue1(a) {
      this.setShowImg(a);
    },
    load() {
      Bus.$on("getTarget", (target) => {
        if (target == "点餐") {
          this.index = 1;
        }
        if (target == "无桌点餐") {
          this.index = 0;
        }
      });
    },
    handleSelect(val, idx) {
      this.index = idx;
      if (val == 111) {
        this.$router.push("/selectTable");
      } else if (val == 110) {
        this.$router.push("/orderdetail");
      } else if (val == 104) {
        this.$router.push("/servicemember");
      } else if (val == 99) {
        this.$router.push("/notTable");
      } else {
        this.$router.push("/home");
      }
    },
    tabmer() {
      this.dialogVisiblezint = true;
    },
    handleCommand(command) {
      if (command == "b") {
        this.logout();
      } else if (command == "a") {
        this.setpass();
      } else {
        this.dialogVisiblezint = true;
      }
    },
    close() {
      this.dialogVisiblezint = false;
    },
    menulist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/auth/user-menu", {
          loginName: localStorage.getItem("loginName"),
        })
        .then((data) => {
          this.loading = false;
          this.merinfo();
          this.$nextTick(() => {
            localStorage.setItem("menuList", JSON.stringify(data.backurl));
            localStorage.setItem("frontUrl", JSON.stringify(data.frontUrl));
            localStorage.setItem("PowerButtons", JSON.stringify(data.buttons));
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    merinfo() {
      this.loading = true;
      this.$http
        .get(`/order-admin/w/base/merchantInfo`)
        .then((res) => {
          this.loading = false;
          localStorage.setItem(
            "merchantInfoVo",
            JSON.stringify(res.merchantInfoVo)
          );
          this.loginName = res.merchantInfoVo.merchantName;
          localStorage.setItem("openMemberStore", res.merchantInfoVo.openMemberStore);
          localStorage.setItem("openCommonMermber", res.merchantInfoVo.openCommonMermber);
          localStorage.setItem("merchantName", res.merchantInfoVo.merchantName)
          localStorage.setItem("merchantId", res.merchantInfoVo.merchantId);
          this.$store.commit("geturl", res.merchantInfoVo.manageImgUrl);
          this.$store.commit("getname", res.merchantInfoVo.restaurantName);
          //1-先付费模式，2-后付费模式，3-后付费确认模式
          localStorage.setItem("paymentMode", res.merchantInfoVo.paymentMode);
          location.reload();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    logout() {
      this.$http.post("/order-admin/logout", {}).then(() => {
        this.$message({
          message: "退出成功",
          type: "success",
        });
        this.$router.push("/login");
        localStorage.removeItem("tokenIdzin");
      });
    },
    setpass() {
      this.$router.push("/editPw");
    },
  },
};
</script>

<style scoped>
.font_wzo {
  width: 90%;
  flex: 1;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #666666;
  display: flex;
  justify-content: center;
}
.view-head {
  display: flex;
  align-items: center;
  background-color: #fff;
}
.view-head > div:nth-of-type(1) {
  font-size: 15px;
  letter-spacing: 2px;
  display: flex;
  align-items: flex-end;
}
.view-head > div:nth-of-type(1) span {
  border: 1px solid #e2e2e2;
  border-bottom: none;
  background-color: #fff;
  position: relative;
  top: 1px;
  padding: 10px;
  color: #8e7c7c;
}
.view-head > div:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
}
.view-head > div:nth-of-type(2) span {
  font-size: 15px;
}
span {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  margin: 0 5px 0 10px;
}
img:nth-of-type(1) {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
img:nth-of-type(2) {
  margin-right: 20px;
}
.hr {
  width: 1px;
  height: 18px;
  background: #e9e9e9;
  margin-right: 20px;
}
.setBox {
  position: absolute;
  background: white;
  width: 99px;
  height: 65px;
  font-size: 12px;
  padding-left: 20px;
  z-index: 99;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  top: 26px;
  left: 30px;
}
.setBox p {
  margin: 10px 0;
}
.meau {
  width: 100px;
  text-align: center;
  font-size: 16px;
  color: #333333;
}
.list {
  width: 100px;
  height: 60px;
  text-align: center;
  font-size: 16px;
  color: #333333;
  line-height: 60px;
}
.active {
  border-left: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  border-bottom: 4px solid #409eff;
  color: #303133;
}
.font_wzo > div:hover {
  cursor: pointer;
}
</style>