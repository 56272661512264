<template>
  <div class="container" v-loading="loading">
    <el-tabs v-model="activeCategory" type="card">
      <el-tab-pane label="全部" name=""></el-tab-pane>
      <el-tab-pane v-for="item in categoryList" :key="item.categoryName" :label="item.categoryName" :name="item.categoryId"></el-tab-pane>
    </el-tabs>
    <div class="disherlist-ul" :class="{'no-img':isImg}">
      <el-scrollbar style="height:100%">
        <div v-show="dishesList.length!=0" class="disherlist-scroll">
          <div class="disherlist-li" :title="'点击添加'+item.dishesName" :key="item.dishesId " v-for="item in dishesList" @click="addDisher(item)">
            <div class="disherlist-img">
              <img :src="item.imageShowOriginalUrl">
              <div class="disherlist-name">
                {{item.dishesName}}
              </div>
            </div>
            <div class="noimg-name"> {{item.dishesName}}</div>
            <div class="disherlist-body">
              <template v-if="item.priceType!='3'">
                <div class="disherlist-vip">
                  <span v-if="item.hasSpecialPrice">￥{{item.specialPrice|money}}<span class="price-txt">特价</span></span>
                  <span v-if="!item.hasSpecialPrice&&item.openCommonMermber==1">￥{{item.memberPrice|money}}<span class="price-txt">会员价</span></span>
                </div>
                <div class="disherlist-money">
                  ￥{{item.price|money}}
                </div>
              </template>
              <div v-else class="time-price">
                时价菜
                <img src="@/assets/image/img_shijiacai_wutu.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <div v-show="firstLoading&&dishesList.length==0" class="empty-list empty-height">
          <img src="@/assets/image/img_wucaipin.png" alt="">
          <div>当前页面无菜品，请添加菜品信息</div>
        </div>
      </el-scrollbar>
    </div>
    <el-dialog :destroy-on-close="true" title="菜品规格" :visible.sync="dialogVisible" width="630px" append-to-body :close-on-click-modal="false">
      <dishesSize @close="dialogVisible=false" :orderNo="form.orderNo" :dishersObj="dishersObj" />
    </el-dialog>
  </div>
</template>

<script>
import dishesSize from "./dishesSize.vue";
import { mapActions } from "vuex";
export default {
  props: ["orderNo"],
  components: { dishesSize },
  created() {
    this.form.orderNo = this.orderNo;
    this.getDishesCategory(localStorage.getItem("merchantId"));
  },
  data() {
    return {
      firstLoading: false,
      dishersObj: null,
      dialogVisible: false,
      loading: false,
      form: {
        orderNo: "",
      },
      categoryList: [],
      dishesList: [],
      activeCategory: "",
    };
  },
  computed: {
    isImg() {
      return !this.$store.state.showImg;
    },
  },
  watch: {
    activeCategory(v) {
      this.getDishesList(v === "0" ? "" : v);
    },
  },
  methods: {
    ...mapActions(["getShopingCard"]),
    addDisher(item) {
      if (item.priceType != 2) {
        if (item.tasteList.length == 0)
          return this.addDisherHttp({
            orderNo: this.form.orderNo,
            dishesId: item.dishesId,
          });
      }
      this.dishersObj = item;
      this.dialogVisible = true;
    },
    async addDisherHttp(option) {
      await this.$http.post("/cashier-api/api/dishes/addDishes", option);
      this.$message({
        message: "加菜成功！",
        type: "success",
      });
      this.getShopingCard({ orderNo: this.form.orderNo });
    },
    async getDishesList(categoryId) {
      try {
        this.loading = true;
        let { data } = await this.$http.post(
          "/cashier-api/api/dishesInfoNew/getDishesList",
          {
            categoryId,
          }
        );
        this.dishesList = data;
        this.firstLoading=true;
      } finally {
        this.loading = false;
      }
    },
    async getDishesCategory(merchantId) {
      try {
        this.loading = true;
        let { data } = await this.$http.post(
          "/cashier-api/api/dishesInfoNew/getDishesCategory",
          { merchantId }
        );
        this.categoryList = data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped src="./index.css">
</style>