<template>
  <div class="container">
    <div class="disherlist">
      <disherList :orderNo="form.orderNo" />
    </div>
    <div class="shoppingcart">
      <shoppingCart :orderNo="form.orderNo" :tableId="form.tableId" :orderMode="form.orderMode" />
    </div>
    <div class="operation">
      <operationList :orderNo="form.orderNo" :tableId="form.tableId" :orderMode="form.orderMode" />
    </div>
  </div>
</template>

<script>
import disherList from "./disherList/index.vue";
import shoppingCart from "./shoppingCart/index.vue";
import operationList from "./operationList/index.vue";
export default {
  components: { shoppingCart, operationList, disherList },
  created() {
    let query = this.$route.query;
    this.form.orderNo = query.orderNo;
    this.form.tableId = query.tableId;
    this.form.orderMode = query.orderMode;
  },
  data() {
    return {
      form: {
        orderNo: "",
        tableId: "",
      },
    };
  },
  methods: {},
};
</script>

<style scoped src="./index.css">
</style>