<template>
  <div class="view-logo" v-loading="loading">
    <div class="header_logo">
      <div class="logo_img_box">
        <img src="@/assets/image/img-logo.png" class="logo_img" v-if="tuimage == 3" />
        <img src="@/assets/image/img-logo.png" class="logo_img_w" v-if="tuimage == 1" />
        <img src="@/assets/image/img_logo_zhangjiakou.png" class="logo_img_w" v-if="tuimage == 2" />
        <img src="@/assets/image/jiayin.png" class="logo_img_a" v-if="tuimage == 2" />
        <img src="@/assets/image/img_logo_sichuan.png" class="logo_img_w" v-if="tuimage == 3" />
        <span v-if="tuimage != 2">网联客点餐系统</span>
      </div>
    </div>
    <div class="container_logo">
      <div class="login_form">
        <p>
          <span @click="tab" :class="{ active: index == 1 }" style="cursor: pointer">服务台登录</span>
          <font @click="tab1" :class="{ active: index == 2 }" style="cursor: pointer">管理后台登录</font>
        </p>
        <div class="container">
          <div class="containe_box" :class="{ activeblur: index2 == 0, redactive: indexred == 0 }">
            <input type="text" class="input_z" placeholder="请输入登录名" v-model="formLabelAlign.username" @focus="isfocus(0)" />
          </div>
          <div class="containe_box" :class="{ activeblur: index2 == 1 }">
            <input type="password" ref="input" class="input_zien" placeholder="请输入密码" v-model="formLabelAlign.password" @focus="isfocus(1)" />
            <img src="@/assets/image/icon_yincang.png" class="input_imga" v-if="flag" @click="showzin(false)" />
            <img src="@/assets/image/icon_xianshi.png" class="input_imga" v-if="!flag" @click="showzin(true)" />
          </div>
          <div class="containe_box" :class="{ activeblur: index2 == 2 }">
            <input type="text" class="input_zout" placeholder="请输入验证码" @focus="isfocus(2)" v-model.trim="formLabelAlign.captcha" />
            <div class="txtcode">
              <img :src="vaite" @click="clickVaite" title="点击获取验证码" style="width: 100%" />
            </div>
          </div>
          <div class="jizhupass">
            <el-checkbox v-model="checked">记住密码</el-checkbox>
          </div>
          <div class="btn" @click="login" :class="{ activebtn: checked1 == true }">
            登录
          </div>
          <div class="xieyi">
            <el-checkbox v-model="checked1" class="int">
              已阅读并同意
              <span @click="Agreement">《商户使用协议》</span>
            </el-checkbox>
            <span @click="passedit" style="cursor: pointer">忘记密码</span>
          </div>
        </div>
      </div>
      <div class="login_img">
        <!-- 网联 -->
        <img src="@/assets/image/img-peitu.png" v-if="tuimage != 2" />
        <img src="@/assets/image/img_tu.png" v-if="tuimage == 2" />
      </div>
    </div>
    <div class="zint">网联客（北京）数字科技有限公司 版权所有 备案号：冀ICP备19004665号</div>
    <div class="appdown" v-if="tuimage != 2">
      <div class="erwei">
        <div ref="qrCode" class="code"></div>
        <p class="fontp">网联客点餐</p>
      </div>
      <img class="img-def" src="@/assets/image/icon_app_lan.png" />
      <img class="img-act" src="@/assets/image/icon_app_bai.png" />
    </div>
    <el-dialog title="忘记密码" :visible.sync="dialogVisible" width="450px" :close-on-click-modal="false">
      <view-info class="h100" :username="formLabelAlign.username" @close="close" v-if="dialogVisible"></view-info>
    </el-dialog>
    <div class="merduo">
      <el-dialog title="请选择登录的商户" :visible.sync="dialogVisiblezint" :center="true" width="420px" :close-on-click-modal="false">
        <view-mer class="h100" v-if="dialogVisiblezint" :merList="merList" @tabmer="tabmer"></view-mer>
      </el-dialog>
    </div>
  </div>
</template>   
<script>
import { setToken } from "@/http.js";
import { baseurl, codeurl } from "../base.js";
import { JSEncrypt } from "jsencrypt";
import viewInfo from "./page/passedit";
import viewMer from "./page/mer";
import QRCode from "qrcodejs2";
import { mapMutations } from "vuex";
export default {
  created() {
    let supportWebp =
      !![].map &&
      document
        .createElement("canvas")
        .toDataURL("image/webp")
        .indexOf("data:image/webp") == 0;
    localStorage.setItem("supportWebp", supportWebp);
    localStorage.setItem("merchantId", "");
    //判断是否记住密码
    this.formLabelAlign.username = localStorage.getItem("loginName");
    if (localStorage.getItem("password")) {
      this.formLabelAlign.password = localStorage.getItem("password");
      this.checked = true;
    } else {
      this.checked = false;
    }
    if (localStorage.getItem("checked1")) {
      this.checked1 = true;
    } else {
      this.checked1 = false;
    }
  },
  components: {
    viewInfo,
    viewMer,
  },
  mounted() {
    //获取验证码图片
    this.clickVaite();
    var qrcode = new QRCode(this.$refs.qrCode, {
      text: codeurl,
      width: 140,
      height: 140,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H,
    });
  },
  data() {
    return {
      changyum: localStorage.getItem("changyum"),
      tuimage: localStorage.getItem("tuimage"),
      qrCodeConfig: {
        text: "",
        dotScale: 0.9,
        colorDark: "#000000",
      },
      dialogVisible: false,
      dialogVisiblezint: false,
      vaite: "",
      formLabelAlign: {
        username: "",
        password: "",
        captcha: "",
        preId: "",
      },
      flag: true,
      downflag: true,
      checked: "",
      checked1: "",
      password: "",
      index: 1,
      index2: null,
      indexred: null,
      loading: false,
      merList: [],
    };
  },
  methods: {
    ...mapMutations(["geturl", "getname"]),
    tab() {
      this.index = 1;
    },
    tab1() {
      this.index = 2;
    },
    downclick() {
      this.downflag = false;
    },
    close() {
      this.dialogVisible = false;
    },
    isfocus(val) {
      this.index2 = val;
    },
    showzin(a) {
      if (a == true) {
        this.$refs.input.type = "password";
      } else {
        this.$refs.input.type = "text";
      }
      this.flag = a;
    },
    passedit() {
      this.dialogVisible = true;
    },
    login() {
      localStorage.setItem("tokenIdzin", "");
      if (!this.formLabelAlign.username) {
        this.$message({
          type: "error",
          message: "请填写账号",
        });
        return false;
      }
      if (!this.formLabelAlign.password) {
        this.$message({
          type: "error",
          message: "请填写密码",
        });
        return false;
      }
      if (!this.formLabelAlign.captcha) {
        this.$message({
          type: "error",
          message: "请填写验证码",
        });
        return false;
      }
      if (!this.checked1) {
        this.$message({
          type: "error",
          message: "请阅读使用协议并勾选",
        });
        return false;
      }
      this.password = this.formLabelAlign.password;
      const enc = new JSEncrypt();
      enc.setPublicKey(
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDHostYAjUJLiwehBWnFesYUorfMICgXLcAPC3MApRyp/Qif5DvpodtVJSyMw5ztOeKaVnJ4aYmPQzcyjSoeW3NLY+3n9l4Yf5a4S7/U4jru2Sl1k6JPLlax3W8Oozb1SI4S3y0hhWf7ujO1fh15DaDH5BDIwaLNbvTQFeo9V1bswIDAQAB"
      );
      let data1 = {
        username: this.formLabelAlign.username,
        password: this.formLabelAlign.password,
        captcha: this.formLabelAlign.captcha,
        preId: this.formLabelAlign.preId,
        flag: this.index,
      };
      data1.password = enc.encrypt(data1.password);
      this.loading = true;
      this.$http
        .post("/order-admin/login", { ...data1 })
        .then((data) => {
          this.loading = false;
          localStorage.setItem("tokenIdzin", data.token);
          localStorage.setItem("checked1", this.checked1);
          setToken(data.token);
          localStorage.setItem("loginName", this.formLabelAlign.username);
          if (data.hasMultipleMerchant == 1) {
            this.merList = data.merList;
            this.dialogVisiblezint = true;
          } else {
            this.tabmer();
          }
          if (this.checked == true) {
            localStorage.setItem("password", this.password);
            localStorage.setItem("checked", true);
          } else {
            window.localStorage.removeItem("password");
            window.localStorage.removeItem("checked");
          }
        })
        .catch((error) => {
          this.loading = false;
          this.clickVaite();
        });
    },
    async tabmer() {
      await this.menulist();
      await this.merinfo();
      if (this.index == 1) {
        this.$router.push("/serve");
      } else {
        this.$router.push("/home");
      }
    },
    merinfo() {
      this.loading = true;
      return this.$http
        .get(`/order-admin/w/base/merchantInfo`)
        .then((res) => {
          this.loading = false;
          localStorage.setItem(
            "merchantInfoVo",
            JSON.stringify(res.merchantInfoVo)
          );
          localStorage.setItem(
            "hasMultipleMerchant",
            res.merchantInfoVo.hasMultipleMerchant
          );
          localStorage.setItem("merchantName", res.merchantInfoVo.merchantName);
          localStorage.setItem("merchantId", res.merchantInfoVo.merchantId);
          localStorage.setItem(
            "openMemberStore",
            res.merchantInfoVo.openMemberStore
          );
          this.$store.commit("geturl", res.merchantInfoVo.manageImgUrl);
          this.$store.commit("getname", res.merchantInfoVo.restaurantName);
          //1-先付费模式，2-后付费模式，3-后付费确认模式
          localStorage.setItem("paymentMode", res.merchantInfoVo.paymentMode);
          localStorage.setItem(
            "pictureStatus",
            res.merchantInfoVo.pictureStatus
          );
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    //点击切换验证码图片
    clickVaite() {
      let a = Math.floor(Math.random() * 999999 + 1);
      this.vaite = baseurl + `/order-admin/w/auth/captchaNum?preId=${a}`;
      this.formLabelAlign.preId = a;
    },
    menulist() {
      this.loading = true;
      return this.$http
        .post("/order-admin/w/auth/user-menu", {
          loginName: localStorage.getItem("loginName"),
        })
        .then((data) => {
          this.loading = false;
          localStorage.setItem("menuList", JSON.stringify(data.backurl));
          localStorage.setItem("frontUrl", JSON.stringify(data.frontUrl));
          localStorage.setItem("PowerButtons", JSON.stringify(data.buttons));
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    Agreement() {
      this.$router.push("/agree");
    },
  },
};
</script>
<style scoped>
.view-logo {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: auto;
}
.header_logo {
  width: 900px;
  height: 96px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 10px;
}
.logo_img {
  height: 46px;
  margin-right: 20px;
}
.logo_img_w {
  height: 46px;
  padding-right: 10px;
  border-right: 1px solid #e8e8e8;
}
.logo_img_a {
  height: 46px;
  margin-left: 10px;
}
.logo_img_box {
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.header_logo span {
  margin-left: 10px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #333333;
  line-height: 16px;
  font-weight: bolder;
}
.container_logo {
  width: 900px;
  height: 500px;
  display: flex;
  justify-content: flex-start;
}
.login_form {
  width: 410px;
  height: 381px;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  margin-top: 31px;
  padding-top: 29px;
}
.login_img {
  width: 380px;
  height: 293px;
  margin-top: 83px;
  margin-left: 70px;
}
.login_img img {
  width: 100%;
}
.login_form p {
  text-align: left;
  line-height: 18px;
  color: #999999;
}
.login_form p span {
  margin: 0 10px 0 37px;
  padding-right: 10px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  line-height: 18px;
  border-right: 1px solid #e8e8e8;
}
.active {
  color: black !important;
  line-height: 18px;
  font-weight: bold;
}
.container {
  padding: 0 30px;
}
.containe_box {
  width: 350px;
  height: 54px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: flex-start;
}
.containe_box:nth-of-type(1) {
  margin-top: 15px;
}
.input_z {
  font-size: 14px;
  width: 350px;
  height: 30px;
  margin-top: 20px;
  border: none;
  outline: none;
}
::-webkit-input-placeholder {
  font-size: 14px;
  color: #999999;
  line-height: 14px;
}
.input_zien {
  font-size: 14px;
  width: 340px !important;
  height: 30px;
  margin-top: 20px;
  border: none;
  outline: none;
}
.input_imga {
  margin-top: 28px;
}
.input_zout {
  font-size: 14px;
  width: 240px;
  height: 30px;
  margin-top: 20px;
  border: none;
  outline: none;
}
.txtcode {
  width: 110px;
  height: 44px;
  /* background: #d8d8d8; */
  margin-top: 11px;
}
.jizhupass {
  height: 30px;
  margin: 33px 0 4px 0;
}
.btn {
  height: 46px;
  background: #f8f8f8;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  text-align: center;
  font-size: 18px;
  color: #999999;
  text-align: center;
  line-height: 46px;
  cursor: pointer;
}
.xieyi {
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
}
.xieyi span {
  font-size: 12px;
  color: #108af9;
  text-align: center;
  line-height: 14px;
}
.int {
  font-size: 12px !important;
}
.activeblur {
  border-bottom: 2px solid #108af9;
}
.activebtn {
  background: #108af9;
  color: #ffffff;
}
.redactive {
  border-bottom: 2px solid #ff1818;
}
.zint {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20px;
  font-size: 14px;
  color: #777777;
}
.appdown {
  position: fixed;
  top: 359px;
  right: 0;
  display: flex;
  justify-content: flex-start;
}
.appdown > img {
  width: 78px;
  height: 78px;
}

.appdown .img-act {
  display: none;
}
.erwei {
  width: 156px;
  height: 178px;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  margin: 3px 2px 0 0;
  display: none;
  text-align: center;
}
.code {
  display: flex;
  justify-content: center;
  margin: 7px 0 2px 0;
}
.appdown:hover .erwei,
.appdown:hover .img-act {
  display: block;
}
.appdown:hover .img-def {
  display: none;
}
.fontp {
  font-size: 14px;
}
</style>
