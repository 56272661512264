/* eslint-disable no-undef */
import { mqttBase as wsUrl } from '@/base.js';
let messageCb = () => {

};
export default function MQTT({ userId, themeNo, messageFn }) {
    const options = {
        clean: true,
        keepalive: 300,
        reconnectPeriod: 3000,
        connectTimeout: 4000, // 超时时间
        // 认证信息
        clientId: `PC_${userId}_${new Date().getTime()}`,
        username: wsUrl.userName,
        password: wsUrl.passWord,
    }
    let ws = "ws" + (wsUrl.useSSL ? "s" : "");
    let wsURl = `${ws}://${wsUrl.ip}:${wsUrl.port}/mqtt`;
    var client = mqtt.connect(wsURl, options);
    messageCb = messageFn;
    client.on('connect', function () {
        // eslint-disable-next-line no-console
        console.log("onConnect:链接成功！");
        client.subscribe(themeNo, function (err) {
            if (!err) {
                // eslint-disable-next-line no-console
                console.log("订阅成功");
            }
        })
    });
    client.on('message', function (topic, message) {
        let data = JSON.parse(message.toString());
        // eslint-disable-next-line no-console
        messageCb(data);
    });
    client.on("close", function () {
        // eslint-disable-next-line no-console
        console.log("close")
    });
    client.on("error", function () {
        // eslint-disable-next-line no-console
        console.log("error")
    });
    return {
        end() {
            client.end();
            client = null;
        }
    };
}