<template>
  <div class="view">
    <div class="view-contion" v-loading="loading">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item>语音播报器可播报,商家下单成功及交易成功通知,若使用播报器请联系行里配置</el-form-item>
      </el-form>
      <div class="tab">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          size="mini"
          highlight-current-row
        >
          <el-table-column type="index" width="55" label="序号"></el-table-column>
          <el-table-column prop="hornNo" label="播报器编号" width="160"></el-table-column>
          <el-table-column prop="hornMode" label="播报类型" width="100">
            <template slot-scope="scope">{{hornMode[scope.row.hornMode]}}</template>
          </el-table-column>
          <el-table-column prop="hornStatus" label="播报器状态" width="100">
            <template slot-scope="scope">{{hornStatus[scope.row.hornStatus]}}</template>
          </el-table-column>
          <el-table-column prop="hornBindTime" label="绑定时间" width="150"></el-table-column>
          <el-table-column prop="address" label="编辑">
            <template slot-scope="scope">
              <el-button
                v-if="But.Edit.power()&&scope.row.hornStatus!='3'"
                type="text"
                @click="editData(scope.row.hornId)"
              >设置</el-button>
              <el-button
                v-if="But.Delete.power()&&scope.row.hornStatus=='3'"
                type="text"
                @click="delet(scope.row.hornId)"
              >删除</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="hornStatus" label="停用/启用" width="120">
            <template slot-scope="scope" v-if="But.SwitchAble.power()&&(scope.row.hornStatus!='3')">
              <el-switch
                v-model="scope.row.hornStatus"
                :active-value="1"
                :inactive-value="2"
                @change="change($event,scope.row.hornId)"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog
        title="播报器设置"
        :visible.sync="dialogVisible"
        width="450px"
        :close-on-click-modal="false"
      >
        <el-form
          :inline="true"
          :model="formzine"
          class="demo-form-inline"
          size="mini"
          :rules="rules"
          ref="formzine"
        >
          <el-form-item label="播报器编号:" class="addPlace" prop="hornNo">
            <span>{{formzine.hornNo}}</span>
          </el-form-item>
          <el-form-item label="播报类型:" class="addPlace" prop="hornMode">
            <el-select v-model="formzine.hornMode" placeholder="请选择">
              <el-option v-for="(v,k) in hornMode" :key="k" :label="v" :value="k"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSubmit" size="mini">保存</el-button>
          <el-button @click="dialogVisible = false" size="mini">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.search();
  },
  data() {
    return {
      loading: false,
      But: window.UserPower.RestRoomSet.searchMechanism.Bobaoqi,
      dialogVisible: false,
      formInline: {},
      hornStatus: {
        1: "启用",
        2: "关闭",
        3: "解绑",
        4: "删除",
      },
      formzine: {},
      hornMode: {
        1: "通用公共",
        2: "交易通知",
        3: "订单通知",
      },
      tableData: [],
      rules: {},
    };
  },
  methods: {
    search() {
      this.loading = true;
      this.$http
        .post(`/order-admin/w/horn/search`, {})
        .then((d) => {
          this.loading = false;
          this.tableData = d.hornList;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    editData(hornId) {
      this.loading = true;
      this.$http
        .get(`/order-admin/w/horn/detail`, {
          params: {
            hornId: hornId,
          },
        })
        .then((data) => {
          this.dialogVisible = true;
          this.loading = false;
          this.formzine = data.hornInfo;
          this.formzine.hornMode = data.hornInfo.hornMode.toString();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.loading = true;
      this.$http
        .post(`/order-admin/w/horn/modify`, {
          ...this.formzine,
        })
        .then((data) => {
          this.dialogVisible = false;
          this.loading = false;
          this.$message({
            type: "success",
            message: "编辑成功",
          });
          this.search();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    delet(hornId) {
      this.$msgbox({
        title: "删除",
        message: "确定删除该播报器？",
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
      }).then((action) => {
        this.loading = true;
        this.$http
          .get(`/order-admin/w/horn/delete`, {
            params: {
              hornId: hornId,
            },
          })
          .then((data) => {
            this.loading = false;
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.search();
          })
          .catch((error) => {
            this.loading = false;
          });
      });
    },
    change(hornStatus, hornId) {
      let inner = hornStatus == 2 ? "停用" : "启用";
      this.loading = true;
      this.$http
        .post(`/order-admin/w/horn/update-status`, {
          hornId: hornId,
          hornStatus: hornStatus,
        })
        .then((data) => {
          this.loading = false;
          this.$message({
            message: `${inner}成功`,
            type: "success",
          });
          this.search();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.view {
  position: relative;
}
.tab {
  width: 100%;
  margin-top: 20px;
}
.addPlace {
  margin-bottom: 10px;
}
.ptn {
  color: #303133;
  font-size: 14px;
  margin: 5px 0 20px 6px;
}
</style>
