<template>
  <div class="h200" v-loading="loading">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="h200 znut" type="border-card">
      <el-tab-pane class="h200 zint" v-for="(item, index) in tableRegions" :key="index" :label="item.tableRegionName" :name="item.tableRegionId">
        <view-info class="h200" :activeName="activeName" :ref="'viewInfo' + index"></view-info>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import viewInfo from "./inner.vue";
export default {
  created() {
  },
  mounted() {
    this.loadzin();
  },
  components: {
    viewInfo,
  },
  data() {
    return {
      intervalFunc: "",
      activeName: "",
      merchantId: localStorage.getItem("merchantId"),
      tableRegions: [],
      loading: false,
      index: 0,
    };
  },
  watch: {
    $route(to, from) {
      this.loadzin();
    },
  },
  destroyed() {

  },
  methods: {
    handleClick(tab, event) {
      this.index = tab.index;
      this.$nextTick((_) => {
        this.$refs["viewInfo" + tab.index][0].load("all", 0);
      });
    },
    loadzin() {
      this.loading = true;
      this.$http
        .get(`/cashier-api/api/table/regions?merchantId=${this.merchantId}`, {})
        .then((res) => {
          this.loading = false;
          var arr = res.tableRegions;
          arr.unshift({
            tableRegionId: "all",
            tableRegionName: "全部",
            tablesCount: "",
          });
          this.tableRegions = arr;
          this.activeName = "all";
          if (this.$route.query.type == 2) {
            this.$nextTick((_) => {
              this.$refs.viewInfo0[0].load(0);
            });
          } else {
            this.$nextTick((_) => {
              this.$refs.viewInfo0[0].load("all", 0);
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.iconfont {
  color: #333333;
}
</style>