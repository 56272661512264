<template>
  <div v-loading="loading">
    <div class="box_zin">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        size="mini"
      >
        <div v-if="title=='添加'">
          <div class="hr_title">满送活动</div>
          <el-form-item label="活动名称" prop="activityName">
            <el-input class="input-width" placeholder v-model.trim="ruleForm.activityName" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="活动时间" prop="value1">
            <el-date-picker
              v-model="ruleForm.value1"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="expireTimeOption"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="会员类型" prop="memberTypeId">
            <el-select v-model="ruleForm.memberTypeId" placeholder="请选择" clearable>
              <el-option
                v-for="item in typelist"
                :key="item.memberTypeId"
                :label="item.memberTypeName"
                :value="item.memberTypeId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="满送设置">
            <div class="contain_f">
              <div>
                <span>充值金额</span>
                <span style="margin-left:140px">满送金额</span>
              </div>
              <div class="my-class-errnot" style="display:flex;" v-for="(item,key) in ruleForm.rebate" :key="key">
                <el-form-item
                  :rules=" { validator: startM, trigger: 'blur' }"
                  :prop="'rebate.'+key+'.startAmount'"
                >
                  <el-input
                    class="input-width"
                    v-model="item.startAmount"
                    :disabled="key!=(ruleForm.rebate.length-1)"
                  >
                    <template slot="prepend">￥</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  :rules=" { validator: startD, trigger: 'blur' }"
                  :prop="'rebate.'+key+'.reduceAmount'"
                >
                  <el-input
                    class="input-width"
                    v-model="item.reduceAmount"
                    style="margin-left:20px"
                    :disabled="key!=(ruleForm.rebate.length-1)"
                  >
                    <template slot="prepend">￥</template>
                  </el-input>
                </el-form-item>
                <el-button
                  type="text"
                  @click="del(key)"
                  size="mini"
                  class="del"
                  v-show="key&&(key==(ruleForm.rebate.length-1))"
                >删除</el-button>
              </div>
              <el-button @click="addman" size="mini" class="btn" type="primary">增加一级优惠</el-button>
            </div>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model.trim="ruleForm.remark" style="width:200px"></el-input>
          </el-form-item>
        </div>
        <div v-if="title!='添加'" class="font">
          <el-form-item label="活动名称:">
            <span>{{ruleForm.activityName}}</span>
          </el-form-item>
          <el-form-item label="活动时间:">
            <span>{{ruleForm.activityBeginTm}}-{{ruleForm.activityEndTm}}</span>
          </el-form-item>
          <el-form-item label="会员类型:">
            <span>{{ruleForm.memberTypeName}}</span>
          </el-form-item>
          <el-form-item label="满送设置:">
            <div v-for="(item,key) in ruleForm.rebate" :key="key">
              <p>满{{item.startAmount|money}}送{{item.reduceAmount|money}}</p>
            </div>
          </el-form-item>
          <el-form-item label="备注:">
            <span>{{ruleForm.remark}}</span>
          </el-form-item>
        </div>
      </el-form>
      <div class="footer">
        <el-button type="primary" @click="onSubmit" size="small" v-if="title!='详情'">保存</el-button>
        <el-button @click="closezin" size="small">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.loadlist();
    this.title = this.$route.query.title;
    if (this.$route.query.title != "添加") {
      this.detail();
    }
  },
  data() {
    return {
      title: "",
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      loading: false,
      rebate: [],
      dialogVisible: false,
      ruleForm: {
        activityName: "",
        value1: [],
        activityBeginTm: "",
        activityEndTm: "",
        rebate: [
          {
            startAmount: "",
            reduceAmount: "",
          },
        ],
      },
      rules: {
        activityName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 1, max: 50, message: "支持1—50个字", trigger: "blur" },
        ],
        value1: [
          { required: true, message: "请选择活动时间", trigger: "blur" },
        ],
        memberTypeId: [
          { required: true, message: "请选择会员类型", trigger: "change" },
        ],
      },
      typelist: [],
      type: this.$route.query.type,
      startM: (r, v, c) => {
        let reg = /^[0-9]{0,6}(\.[0-9]{1,2})?$/;
        if (!reg.test(v)) {
          return c(new Error("支持0—999999.99"));
        }
        let arr = this.ruleForm.rebate,
          len = this.ruleForm.rebate.length;
        if (len > 1) {
          if (+arr[len - 1].startAmount <= +arr[len - 2].startAmount) {
            return c(new Error("请输入大于上一个的起始金额"));
          }
        }
        return c();
      },
      startD: (r, v, c) => {
        let reg = /^[0-9]{0,7}(\.[0-9]{1,2})?$/;
        if (!reg.test(v)) {
          return c(new Error("支持0—9999999.99"));
        }
        let arr = this.ruleForm.rebate,
          len = this.ruleForm.rebate.length;

        if (len > 1) {
          if (+arr[len - 1].reduceAmount <= +arr[len - 2].reduceAmount) {
            return c(new Error("请输入大于上一个的满送金额"));
          }
        }
        if (+arr[len - 1].reduceAmount >= +arr[len - 1].startAmount) {
          return c(new Error("满送金额应小于充值金额"));
        }

        return c();
      },
    };
  },
  methods: {
    loadlist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/memberType/s/all", {})
        .then((res) => {
          this.loading = false;
          this.typelist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    detail() {
      this.loading = true;
      this.$http
        .post(`/order-admin/w/member/activity/detail`, {
          memberActivityId: this.$route.query.id,
        })
        .then((res) => {
          this.loading = false;
          let data = res.data;
          this.ruleForm = data;
          this.ruleForm.rebate = data.rebateList;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    dele(index, row) {
      row.splice(index, 1);
      this.$message({
        message: "删除成功",
        type: "success",
      });
    },
    add() {
      this.dialogVisible = true;
    },
    indexMethod(index) {
      return index + 1;
    },
    addman() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.rebate.length >= 5) {
            this.$message({
              message: "最多添加5条",
              type: "warning",
            });
          } else {
            this.ruleForm.rebate.push({
              startAmount: "",
              reduceAmount: "",
            });
          }
        }
      });
    },
    del(key) {
      this.ruleForm.rebate.splice(key, 1);
    },
    onSubmit() {
      this.ruleForm.activityBeginTm = this.ruleForm.value1
        ? this.ruleForm.value1[0]
        : "";
      this.ruleForm.activityEndTm = this.ruleForm.value1
        ? this.ruleForm.value1[1]
        : "";
      let datainner = { ...this.ruleForm };
      datainner.rebate = JSON.parse(JSON.stringify(this.ruleForm.rebate));
      for (let i in datainner.rebate) {
        datainner.rebate[i].startAmount = +(
          datainner.rebate[i].startAmount * 100
        ).toFixed(2);
        datainner.rebate[i].reduceAmount = +(
          datainner.rebate[i].reduceAmount * 100
        ).toFixed(2);
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post(`/order-admin/w/member/activity/add/rebate`, datainner)
            .then((res) => {
              this.loading = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.$router.push({
                path: "MemberActivity",
                query: { type: 1 },
              });
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    closezin() {
      this.$router.push("/MemberActivity");
    },
  },
};
</script>

<style scoped>
.box_zin {
  height: calc(100% - 60px);
  margin-bottom: 60px;
}
.del {
  margin-left: 15px;
}
.input-width {
  width: 170px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.hr_title {
  margin-bottom: 20px;
}
.footer {
  width: 100%;
  position: fixed;
  height: 60px;
  bottom: 0px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 400;
}
span {
  color: #606266;
}
.contain_f {
  width: 402px;
  background: #fafafa;
  padding: 20px 20px;
}
.btn {
  background: #ffffff;
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #333333;
}
.font span {
  color: black;
}

</style>
