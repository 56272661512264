<template>
  <div>
    <div class="cm10">
      <a style="color: #409eff;" download="导入菜品模版.xlsx" :href="require('@/assets/templet/item.xlsx')">下载excel模板</a>
    </div>
    <br />
    <div class="cm10">(请先下载模版,并按模版格式添加数据)</div>
    <br />
    <div class="flexbox">
      <a href="#" class="filea">
        选择文件
        <input type="file" ref="file" @change="changFilezin()" class="cm10" />
      </a>
      <p style="margin-top:4px;margin-left:47px;">{{fileinner}}</P>
    </div>
    <div class="cm10" style="margin-left:48px;margin-top:10px" v-show="totalFailNum==true">
      <a style="color: #409eff;" download :href="str">点击下载错误文件</a>
    </div>
    <el-row style="text-align:right;margin:15px 0px;">
      <el-button
        size="mini"
        :loading="loading"
        @click="submit()"
        type="primary"
        :disabled="disabled"
      >确认</el-button>
      <el-button size="mini" @click="close">取消</el-button>
    </el-row>
  </div>
</template>

<script>
import { baseurl } from "../../../../base.js";
export default {
  props: {},
  created() {},
  data() {
    return {
      fileinner: "",
      loading: false,
      str: "",
      header: { "Access-Control-Allow-Origin": "*" },
      baseurl: baseurl,
      disabled: true,
      msg: "",
      totalFailNum: false
    };
  },
  methods: {
    changFilezin() {
      if (this.$refs["file"].files[0]) {
        this.fileinner = this.$refs["file"].files[0].name;
        this.disabled = false;
      } else {
        this.fileinner = "";
        this.disabled = true;
      }
      
    },
    submit(a) {
      this.loading = true;
      let data = new FormData();
      data.append("file", this.$refs["file"].files[0]);
      this.$http
        .post("/order-admin/w/disher/import/excel", data, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(
          data => {
            this.loading = true;
            if (data.data) {
              // this.$refs.file.value=""
              this.loading = false;
              this.$message.error(
                "有错误文件，请下载错误文件"
              );
              this.str = data.data;
              this.totalFailNum = true;

              this.$refs["file"].value = '';
              this.fileinner = "";
              this.disabled = true;
            } else {
              this.loading = false;
              this.$message({
                message: "导入成功",
                type: "success"
              });
              this.$emit("close");
              this.$emit("load");
            }
          },
          e => {  this.loading = false;
          
            this.$refs["file"].value = '';
            this.fileinner = "";
            this.disabled = true;
          }
        );
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
<style scope>
.cm10 {
  margin-left: 50px;
}
.filea {
  width: 50px;
  height: 20px;
  position: relative;
  display: inline-block;
  background: #409eff;
  border-radius: 4px;
  padding: 4px 12px;
  overflow: hidden;
  color: #1e88c7;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
  margin-left: 50px;
  color: white;
  font-size: 12px;
  text-align: center;
}
.filea input {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}
</style>
