<template>
  <div class="view_ab" v-loading="loading">
    <div class="phone_tazy_sub">
      <div class="header_phone">
        <div v-show="orderMode!=2">
          <span>{{ orderVo.tableRegionName }} {{ orderVo.tableName }}</span>
          <span
            >{{ orderVo.dinersCount && orderVo.dinersCount + "/"
            }}{{ orderVo.seatingCapacity }}座</span
          >
        </div>
        <div>
          <span>
            <span>下单员:</span>
            {{ orderVo.operatorName }}
          </span>
          <span>{{ orderVo.openTableTimeStr }}</span>
        </div>
      </div>
      <div class="header_container">
        <el-table
          ref="multipleTable"
          :data="dishersInfoList"
          tooltip-effect="dark"
          default-expand-all
          @row-click="clickRow"
          class="tab tab-click"
          size="mini"
          :show-header="false"
          style="width: 100%"
          row-key="dishersId"
          :row-class-name="tabclass"
          :tree-props="{ children: 'garnishList', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="dishersName" width="180">
            <template slot-scope="scope">
              <p>
                {{ scope.row.dishersName }}
                <span class="huiyuan" v-if="scope.row.dishersType == 3"
                  >(赠)</span
                >
              </p>
              <p class="detail">
                <el-popover
                  trigger="hover"
                  placement="top"
                  v-if="scope.row.dishersRemark"
                >
                  <p>{{ scope.row.dishersRemark }}</p>
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">备注详情</el-tag>
                  </div>
                </el-popover>
              </p>
              <!-- <p
                style="color: #108AF9;"
                v-if="scope.row.hasGgarnish==1&&payflag=='true'"
                @click="addside(scope.row.dishersId)"
              >添加配菜</p>-->
            </template>
          </el-table-column>
          <el-table-column prop="selectCount" width="60">
            <template slot-scope="scope">
              <p>x{{ scope.row.selectCount }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="priceUnitName" width="60"></el-table-column>
          <el-table-column prop="price" width="100">
            <template slot-scope="scope">
              <p v-if="scope.row.alertPrice > 0">
                ¥{{ scope.row.alertPrice | money }}
              </p>
              <p v-if="scope.row.specialPrice > 0">
                ¥{{ scope.row.specialPrice | money }}
              </p>
              <template v-if="!scope.row.alertPrice && !scope.row.specialPrice">
                <p v-if="scope.row.price">¥{{ scope.row.price | money }}</p>
                <template v-if="scope.row.memberPrice">
                  <p class="huiyuan" v-if="merchantInfo.openCommonMermber != 0">
                    ¥{{ scope.row.memberPrice | money }}
                  </p>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="closetabblack">
          <div>订单备注:{{orderVo.orderRemark}}</div>
          <el-button type="text" @click="addremarks(2)" style="margin-left:30px;">编辑</el-button>
        </div>-->
        <!-- <div class="closetab" @click="empty" v-if="payflag=='true'&&dishersInfoList.length>0">清空菜单</div> -->
      </div>
      <div class="btnfoot" v-if="typezin == '2'">
        <el-button
          class="header_botton_b"
          @click="topay('确认下单')"
          :class="{ activezintar: dishersInfoList.length > 0 }"
          >确认下单</el-button
        >
      </div>
      <div v-if="payflag == 'true' && typezin == '1'">
        <div class="header_phone_containe">
          <div>
            <span>合计:</span>
            {{ dishersvo.totalCount }}
          </div>
          <div class="zongproce">
            <div>
              <span>总计:</span>
            </div>
            <div>
              <p>¥{{ dishersvo.orderOriginAmount | money }}</p>
              <p v-if="merchantInfo.openCommonMermber != 0">
                ¥{{ dishersvo.orderMemberAmount | money }}
              </p>
            </div>
          </div>
        </div>
        <div class="btnfoot" v-if="typezin == '1'">
          <el-button
            class="header_botton_a"
            @click="empty"
            :class="{ activezintarcolor: dishersInfoList.length > 0 }"
            >清空菜单</el-button
          >
          <el-button
            class="header_botton"
            @click="topay('下一步')"
            :class="{ activezintar: dishersInfoList.length > 0 }"
            >下一步</el-button
          >
        </div>
      </div>
    </div>
    <div v-if="orderVo.operatorType != 1 && typezin == '1'" class="btn_list">
      <el-button type="text" @click="give">赠送</el-button>
      <el-button type="text" @click="add">+</el-button>
      <el-button type="text" @click="reduce">-</el-button>
      <el-button type="text" @click="dele">删除</el-button>
      <el-button v-show="orderMode!=2" type="text" @click="pricerevision"
        >改价</el-button
      >
      <el-button v-show="orderMode!=2" type="text" @click="changetable"
        >换桌</el-button
      >
      <el-button type="text" @click="changetastes">口味</el-button>
      <el-button v-show="orderMode!=2" type="text" @click="pack">打包</el-button>
      <el-button type="text" @click="addremarks">备注</el-button>
      <el-button v-show="orderMode!=2" type="text" @click="clear">清台</el-button>
      <el-button type="text" @click="clearzin" class="clerabtn">刷新</el-button>
    </div>
    <div v-if="typezin == '3' && orderMode!=2" class="btn_list">
      <el-button type="text" @click="adddishes" v-if="operatorType != '1'"
        >加菜</el-button
      >
      <!-- 退菜&&flagbtn==2 -->
      <el-button type="text" v-show="But.QuitCai.power()" @click="vegetables"
        >退菜</el-button
      >
      <el-button type="text" @click="changetable">换桌</el-button>
      <el-button type="text" @click="clear" class="clerabtn">清台</el-button>
    </div>
    <div v-if="orderVo.operatorType == 1 && typezin == '1'">
      <div class="btn_list">
        <el-button type="text" @click="pricerevision" v-if="payflag == 'true'"
          >改价</el-button
        >
        <!-- <el-button
          type="text"
          @click="clearadd"
          v-if="payflag=='true'&&orderVo.hasMobilCreateAdditionalOrder==1"
        >清加单</el-button>-->
        <el-button type="text" @click="clear">清台</el-button>
        <el-button type="text" @click="clearzin" class="clerabtn"
          >刷新</el-button
        >
      </div>
    </div>
    <el-dialog
      title="改价"
      :visible.sync="dialogtable"
      width="450px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-tables
        @close="closedialogtable"
        :val="val"
        :orderNo="orderNo"
        v-if="dialogtable"
      ></view-tables>
    </el-dialog>
    <el-dialog
      title="更改口味"
      :visible.sync="dialogtastes"
      width="450px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-tastes
        @close="closedialogtastes"
        :val="val"
        :orderNo="orderNo"
        @detail="dishersInfo"
        :options="options"
        ref="zint"
        v-if="dialogtastes"
      ></view-tastes>
    </el-dialog>
    <el-dialog
      title="打包"
      :visible.sync="dialogpack"
      width="450px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-pack
        @close="closedialogpack"
        :orderNo="orderNo"
        v-if="dialogpack"
      ></view-pack>
    </el-dialog>
    <el-dialog
      title="备注"
      :visible.sync="dialogremarks"
      width="450px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-remarks
        @close="closeremarks"
        :val="val"
        :orderNo="orderNo"
        :orderRemark="orderVo.orderRemark"
        @detail="dishersInfo"
        :marka="marka"
        v-if="dialogremarks"
      ></view-remarks>
    </el-dialog>
    <!-- <el-dialog
      title="挂单"
      :visible.sync="dialoglist"
      width="450px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-list @close="closelist" :objzin="objzin" @detail="detail" v-if="dialoglist" :obj="obj"></view-list>
    </el-dialog>-->
    <el-dialog
      title="退菜"
      :visible.sync="dialogreduce"
      width="450px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-reduce
        @close="closereduce"
        :val="val"
        @detail="dishersInfo"
        ref="viewredus"
        :orderNo="orderNo"
        v-if="dialogreduce"
      ></view-reduce>
    </el-dialog>
    <el-dialog
      title="添加配菜"
      :visible.sync="dialogside"
      width="630px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-side
        @close="closeside"
        ref="viewside"
        :val="val"
        :orderNo="orderNo"
        v-if="dialogside"
        @detail="dishersInfo"
      ></view-side>
    </el-dialog>
    <el-dialog
      title="菜品规格"
      :visible.sync="dialogVisible"
      width="630px"
      append-to-body
      :close-on-click-modal="false"
    >
      <view-order
        @close="closeorder"
        @detail="dishersInfo"
        :orderNo="orderNo"
        :dishersId="dishersId"
        ref="viewguige"
      ></view-order>
    </el-dialog>
  </div>
</template>

<script>
import viewReduce from "../operation/reduce"; //退菜
import viewTables from "../operation/tables"; //改价
// import viewList from "../operation/list"; //挂单
import viewTastes from "../operation/tastes"; //更改口味
import viewPack from "../operation/pack"; //打包
import viewRemarks from "../operation/remarks"; //备注
import viewSide from "../addside"; //添加配菜
import viewOrder from "../dishes.vue"; //菜品规格
export default {
  created() {
    if (this.payflag != "true") {
      this.flagbtn = 2;
    }
  },
  async mounted() {
    // await this.getDetail()
    this.orderNo = this.$route.query.orderNo;
    this.tableId = this.$route.query.tableId;
    this.orderMode = this.$route.query.orderMode;
    // this.detail();
    await this.dishersInfo();
    if (this.typezin == "2" && this.dishersInfoList.length == 0) {
      localStorage.setItem("payflag", false);
      this.showpay("确认下单");
    }
  },
  props: {
    dishersId: String,
    type: String,
    obj: Object,
    act: Boolean,
    typezin: String,
    remarkzin: String,
  },
  components: {
    viewTables,
    viewTastes,
    viewPack,
    viewRemarks,
    // viewList,
    viewReduce,
    viewSide,
    viewOrder,
  },
  data() {
    return {
      orderMode: "",
      loading: false,
      inner: "",
      But: window.UserPower.serve.statusopen,
      objzin: {},
      dishersvo: {}, //右边列表总计
      flagbtn: 1,
      tableId: "",
      dialogVisible: false,
      dialogside: false,
      dialogreduce: false,
      // dialoglist: false,
      dialogpack: false,
      dialogtable: false,
      dialogtastes: false,
      dialogremarks: false,
      orderVo: {},
      merchantInfo: {},
      dishersInfoList: [],
      operatorType: "",
      val: {},
      orderNo: "",
      payflag: localStorage.getItem("payflag"), //合计按钮
      paymentMode: "",
      acttin: true,
      options: [],
      index: null,
      recalculate: 0,
      totalCount: 0,
      marka: "",
    };
  },
  watch: {
    act: function (val) {
      //确认结账按钮变蓝，右边按钮消失
      if (val == true) {
        this.acttin = false;
      }
    },
    dishersInfoList: function (val) {
      if (val.length < 1) {
        this.$emit("disable");
      }
    },
    typezin: function (val) {
      console.log(val);
    },
  },
  methods: {
    getDetail() {
      if (!this.$route.query.orderNo) {
        return;
      }
      this.loading = true;
      return this.$http
        .post(`/cashier-api/f/order/detail`, {orderId: this.$route.query.orderNo})
        .then((res) => {
          this.loading = false;
          this.orderMode = res.orderMode;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    clearzin() {
      this.dishersInfo();
    },
    // detail() {
    //   if (!this.orderNo) {
    //     return;
    //   }
    //   if (this.typezin == "3") {
    //     this.recalculate = 1;
    //   }
    //   this.loading = true;
    //   this.$http
    //     .post("/cashier-api/api/order/detail", {
    //       merchantId: localStorage.getItem("merchantId"),
    //       orderNo: this.orderNo,
    //       recalculate: this.recalculate
    //     })
    //     .then(res => {
    //       this.loading = false;
    //       this.orderVo = res.orderVo;
    //       this.totalCount = res.totalCount;
    //       this.paymentMode = res.orderVo.merchantInfo.paymentMode;
    //       this.merchantInfo = res.orderVo.merchantInfo;
    //       // if (this.payflag != "true") {
    //       //   this.detailall();
    //       // }
    //     })
    //     .catch(error => {
    //       this.loading = false;
    //     });
    // },
    dishersInfo() {
      //右边下单列表
      if (!this.orderNo) {
        return;
      }
      this.loading = true;
      if (this.typezin == "3") {
        this.recalculate = 1;
      }
      let formData = {
        orderNo: this.orderNo,
        recalculate: this.recalculate,
      };
      if (this.orderMode==2) {
        formData.orderMode = this.orderMode;
      }
      return this.$http
        .post(`/cashier-api/api/order-dishes/list`, formData)
        .then((res) => {
          this.orderVo = res;
          this.loading = false;
          this.dishersInfoList = res.dishersInfoList;
          this.merchantInfo = res.merchantInfo;
          this.operatorType = res.operatorType;
          this.dishersvo = res;
          if (this.marka != "2" && this.inner != "删除") {
            this.val = this.dishersInfoList[this.index];
          } else {
            this.val = {};
          }
          this.$emit("detailleft");
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    // detailall() {
    //   this.$emit("detailleft");
    // },
    clear() {
      this.$confirm("是否继续清台?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/dishers/cleanTable", {
              merchantId: localStorage.getItem("merchantId"),
              orderNo: this.orderNo,
              tableId: this.tableId,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                type: "success",
                offset: 160,
                message: "清台成功!",
              });
              this.$router.push("/selectTable");
            })
            .catch((error) => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            offset: 160,
            message: "已取消",
          });
        });
    },
    clearadd() {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/order/cancle-additional", {
          merchantId: localStorage.getItem("merchantId"),
          orderNo: this.orderNo,
        })
        .then((res) => {
          this.loading = false;
          this.$message({
            type: "success",
            offset: 160,
            message: "清加单成功!",
          });
          this.$router.push("/selectTable");
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    empty() {
      if (this.dishersInfoList.length > 0) {
        this.$confirm(" 是否继续清空菜单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            this.$http
              .get("/cashier-api/api/dishers/cleanDishers", {
                params: {
                  orderNo: this.orderNo,
                },
              })
              .then((res) => {
                this.loading = false;
                this.$message({
                  type: "success",
                  offset: 160,
                  message: "清空菜单成功!",
                });
                this.dishersInfo();
              })
              .catch((error) => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              offset: 160,
              message: "已取消",
            });
          });
      } else {
        this.$message({
          message: "请添加要下单的菜品",
          type: "warning",
        });
      }
    },
    closeorder() {
      this.dialogVisible = false;
    },
    // Weigh() {},
    detailzin() {
      this.dialogVisible = true;
      this.$nextTick((_) => {
        this.$refs.viewguige.loaddetail();
      });
    },
    addside(id) {
      this.val.dishersId = id;
      this.dialogside = true;
      this.$nextTick((_) => {
        this.$refs.viewside.detail();
      });
    },
    closeside() {
      this.dialogside = false;
    },
    adddishes() {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/order/additional", {
          orderNo: this.orderNo,
        })
        .then((res) => {
          this.loading = false;
          this.$router.push({
            path: "payorder",
            query: {
              orderNo: this.orderNo,
              tableId: this.tableId,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    showpay(a) {
      this.flag = 2;
      this.loading = true;
      if (a == "下一步") {
        let formData = {
          orderNo: this.orderNo,
        };
        if (this.orderMode==2) {
          formData.orderMode = this.orderMode;
        }
        this.$http
          .post("/cashier-api/api/order/goto_confirm_order", formData)
          .then((res) => {
            this.loading = false;
            this.$router.push({
              path: "/toorder",
              query: {
                orderNo: this.orderNo,
                tableId: this.tableId,
                orderMode: this.orderMode,
                type: "2",
              },
            });
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        let formData = {
          orderNo: this.orderNo,
          orderRemark: this.remarkzin.toString(),
        };
        if (this.orderMode==2) {
          formData.orderMode = this.orderMode;
        }
        this.$http
          .post("/cashier-api/api/order/confirm_order", formData)
          .then((res) => {
            this.loading = false;
            this.$router.push({
              path: "/topay",
              query: {
                orderNo: this.orderNo,
                tableId: this.tableId,
                orderMode: this.orderMode,
              },
            });
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    topay(a) {
      if (this.dishersInfoList.length > 0) {
        localStorage.setItem("payflag", false);
        this.showpay(a);
      } else {
        this.$message({
          message: "请添加要下单的菜品",
          type: "warning",
        });
      }
    },
    give() {
      if (this.val && this.val.dishersType) {
        let text = this.val.dishersType == "3" ? "取消赠送" : "赠送";
        if (this.payflag != "true") {
          this.recalculate = 1;
        }
        this.$confirm(`是否确认${text}`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.loading = true;
            this.$http
              .post(`/cashier-api/api/dishers/present`, {
                merchantId: localStorage.getItem("merchantId"),
                dishersId: this.val.dishersId,
                orderDishesId: this.val.orderDishesId,
                orderNo: this.orderNo,
                recalculate: this.recalculate,
              })
              .then((res) => {
                this.loading = false;
                this.$message({
                  message: "操作成功",
                  offset: 160,
                  type: "success",
                });
                this.dishersInfo();
                // if (this.payflag != "true") {
                //   this.detailall();
                // }
              })
              .catch((error) => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              offset: 160,
              message: "已取消",
            });
          });
      } else {
        this.$message({
          message: "请选中要操作的菜品",
          type: "warning",
        });
        return;
      }
    },
    closereduce() {
      this.dialogreduce = false;
    },
    closeremarks() {
      this.dialogremarks = false;
    },
    closedialogpack() {
      this.dialogpack = false;
      if (this.payflag != "true") {
        this.recalculate = 1;
      }
      this.dishersInfo();
    },
    closedialogtable() {
      this.dialogtable = false;
      if (this.payflag != "true") {
        this.recalculate = 1;
      }
      this.dishersInfo();
      // this.$emit('load');
    },
    closedialogtastes() {
      this.dialogtastes = false;
    },
    addremarks(a) {
      if (a == 2) {
        let arr = document.getElementsByClassName("active-row");
        arr.forEach((v) => {
          v.className = v.className.replace(" active-row", "");
        });
        this.val = {};
        this.marka = a + "";
      } else {
        this.marka = "1";
      }
      if (!this.val) {
        this.val = {};
      }
      this.dialogremarks = true;
    },
    pack() {
      this.dialogpack = true;
    },
    changetastes() {
      if (!this.val || !this.val.dishersId) {
        this.$message({
          message: "请选中要操作的菜品",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      this.$http
        .post("/cashier-api/api/dishers/taste/list", {
          dishersId: this.val.dishersId,
          orderDishesId: this.val.orderDishesId,
          orderNo: this.orderNo,
        })
        .then((res) => {
          this.loading = false;
          this.options = res.data;
          this.dialogtastes = true;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    gualist() {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/order/toPay", {
          activityType: this.obj.activityType,
          orderNo: this.obj.orderNo,
          memberpayment: this.obj.memberpayment,
          notCountAmount: Number(this.obj.notCountAmount),
        })
        .then((res) => {
          this.loading = false;
          this.objzin.shouldReceiveAmount = res.shouldReceiveAmount;
          this.objzin.orderNo = this.orderNo;
          this.dialoglist = true;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    changetable() {
      this.$router.push({
        path: "/selectTable",
        query: { type: 2, orderNo: this.orderNo },
      });
    },
    pricerevision() {
      if (!this.val || !this.val.dishersId) {
        this.$message({
          message: "请选中要操作的菜品",
          type: "warning",
        });
        return;
      } else {
        // this.val.priceType == "3"
        if (
          !this.val.hasSpecialPrice &&
          this.val.specialPrice <= 0 &&
          this.val.priceType == "3"
        ) {
          this.dialogtable = true;
        } else {
          this.$message({
            message: "非时价菜品不允许改价",
            type: "warning",
          });
        }
      }
    },
    add() {
      this.functionzin("加");
    },
    reduce() {
      this.functionzin("减");
    },
    dele() {
      this.functionzin("删除");
    },
    functionzin(a) {
      this.inner = a;
      let url;
      if (!this.val || !this.val.dishersId) {
        this.$message({
          message: "请选中要操作的菜品",
          type: "warning",
        });
        return;
      }
      if (a == "加") {
        url = "/cashier-api/api/dishers/add";
      } else if (a == "减") {
        url = "/cashier-api/api/dishers/reduce";
        if (this.val.selectCount == 1) {
          this.$message({
            message: "不能再减了",
            type: "warning",
          });
          return;
        }
      } else if (a == "删除") {
        url = "/cashier-api/api/dishers/del";
      }
      if (this.payflag != "true") {
        this.recalculate = 1;
      }
      this.loading = true;
      this.$http
        .post(`${url}`, {
          merchantId: localStorage.getItem("merchantId"),
          dishersId: this.val.dishersId,
          orderDishesId: this.val.orderDishesId,
          dailyPresellCount: this.val.dailyPresellCount,
          orderNo: this.orderNo,
          recalculate: this.recalculate,
        })
        .then((res) => {
          this.loading = false;
          if (a == "删除") {
            this.$message({
              message: "操作成功",
              offset: 160,
              type: "success",
            });
          }
          this.dishersInfo();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    vegetables() {
      if (!this.val || !this.val.dishersId) {
        this.$message({
          message: "请选中要操作的菜品",
          type: "warning",
        });
        return;
      }
      this.dialogreduce = true;
      this.$nextTick((_) => {
        this.$refs.viewredus.loaddetail();
      });
    },
    tabclass({ row, rowIndex }) {
      row.index = rowIndex;
    },
    clickRow(row, column, e) {
      let index = this.dishersInfoList.indexOf(row);
      let arr = document.getElementsByClassName("active-row");
      arr.forEach((v) => {
        v.className = v.className.replace(" active-row", "");
      });
      var tr = document
        .getElementsByClassName("tab-click")[0]
        .getElementsByTagName("tr");
      tr[index].className = tr[index].className + " active-row";
      if (column) {
        this.val = row;
        this.index = row.index;
      }
    },
  },
};
</script>

<style scoped>
.header_phone {
  height: 111px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header_phone div {
  display: flex;
  justify-content: space-between;
  margin: 0 26px;
  font-size: 22px;
}
.header_phone div:nth-of-type(2) {
  font-size: 14px;
  margin-top: 14px;
}
.header_container {
  font-size: 14px;
  color: #333333;
  height: calc(100% - 232px);
  overflow: auto;
}
.header_container div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.active {
  border: 1px solid #108af9;
}
.price_all {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price_all span:nth-of-type(2) {
  color: #ff6969;
}
.header_phone_containe {
  width: 100%;
  height: 60px;
  border-top: 1px solid #dedede;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 60px;
}
.header_phone_containe div span {
  color: #999999;
  margin-left: 34px;
}
.zongproce {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 26px;
}
.btnfoot {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
}
.zongproce div:nth-of-type(2) {
  margin-left: 11px;
}
.zongproce div p:nth-of-type(2) {
  color: #ff6969;
}
.header_botton,
.header_botton_a,
.header_botton_b {
  height: 60px;
  color: #ffffff;
  background: #7bbefb;
  font-size: 14px;
  border-radius: 0 !important;
  border: none;
}
.header_botton {
  width: 70%;
}
.header_botton_a {
  width: 30%;
  color: #ffffff;
  background: #ff9b9b;
}
.header_botton_b {
  width: 100%;
}
.activezintarcolor {
  background: #ff3a3a;
}
.activezintar {
  background: #108af9 !important;
}
.huiyuan {
  color: #ff6969;
}
.detail {
  display: flex;
  justify-content: flex-start;
}
.closetab {
  color: #108af9;
  padding: 0 5%;
  margin: 30px 0;
  justify-content: flex-start !important;
}
.closetabblack {
  color: #333333;
  padding: 0 5%;
  margin: 30px 0;
  justify-content: flex-start !important;
}
.phone_tazy_sub {
  width: 400px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-left: 20px;
  position: relative;
}
.btn_list {
  width: 80px;
  margin-left: 10px;
  overflow: auto;
  z-index: 100;
}
.btn_list button {
  width: 100%;
  height: 50px;
  border: 1px solid #dedede;
  text-align: center;
  font-size: 14px;
  color: #333333;
  border-radius: 0;
  border-bottom: none;
}
.el-button + .el-button {
  margin-left: 0;
}
.clerabtn {
  border-bottom: 1px solid #dedede !important;
}
.btn_list button:hover {
  cursor: pointer;
  background: #adadad;
  border: 1px solid #dedede;
}
.active {
  border: 1px solid #108af9;
}
.view_ab {
  width: 530px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  background: #f9f9f9;
}
.btn_list button:active {
  background: #108af9 !important;
  color: white;
}
</style>