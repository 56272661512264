<template>
  <div>
    <el-form
      v-loading="loading"
      :inline="true"
      :model="formzinernil"
      class="demo-form-inline"
      size="mini"
      ref="formzinernil"
      :rules="rules"
    >
      <div class="form_ant">
        <el-form-item label="菜品名称:" prop="itemName">{{val.dishersName}}</el-form-item>
        <el-form-item label="改价金额:" prop="alertPrice">
          <el-input v-model="formzinernil.alertPrice"></el-input>
        </el-form-item>
        <el-form-item label="改价原因:" prop="alertPriceMessage">
          <el-input v-model="formzinernil.alertPriceMessage"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitedzin" size="mini">确认改价</el-button>
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
export default {
  created() {
    this.merchantInfo = JSON.parse(localStorage.getItem("merchantInfoVo"));
  },
  props: { val: Object, orderNo: String },
  data() {
    var numzin = (rule, value, callback) => {
      let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
      if (!rez.test(value)) {
        return callback(new Error("支持0.01—999999.99之间"));
      }
      callback();
    };
    return {
      loading: false,
      formzinernil: {
        alertPrice: "",
        alertPriceMessage: ""
      },
      rules: {
        alertPrice: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" }
        ],
        alertPriceMessage: [
          { min: 1, max: 20, message: "最多20个字符", trigger: "blur" }
        ]
      },
      merchantInfo: {}
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.$refs["formzinernil"].resetFields();
    },
    submitedzin() {
      let data = { ...this.formzinernil };
      data.alertPrice = +(data.alertPrice * 100).toFixed(2);
      this.$refs["formzinernil"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/dishers/alter-price", {
              ...data,
              dishersId: this.val.dishersId,
              orderDishesId: this.val.orderDishesId,
              orderNo: this.orderNo
            })
            .then(res => {
              this.loading = false;
              this.$message({
                message: "确认改价成功",
                offset: 160,
                type: "success"
              });
              this.$emit("close");
            })
            .catch(error => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.form_ant {
  display: flex;
  flex-direction: column;
}
</style>