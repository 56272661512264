<template>
  <div class="h100">
    <el-tabs class="h100" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-if="But.management.power.power()"
        label="员工管理"
        name="first"
        class="h100"
      >
        <view-info class="h100"> </view-info>
      </el-tab-pane>
      <el-tab-pane
        v-if="But.privilege.power.power()"
        label="权限管理"
        name="second"
        class="h100"
      >
        <view-set class="h100"></view-set>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import viewInfo from "./management"; //员工管理
import viewSet from "./privilege"; //权限管理
export default {
  created() {
    if (this.But.management.power.power()) {
      return (this.activeName = "first");
    }
    if (this.But.privilege.power.power()) {
      return (this.activeName = "second");
    }
    if (this.$route.query.type == 2) {
      return (this.activeName = "second");
    }
  },
  components: {
    viewInfo,
    viewSet,
  },
  data() {
    return {
      But: window.UserPower.RestRoomSet.Staffmanagement,
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab, event) {},
  },
};
</script>

<style scoped>
</style>
