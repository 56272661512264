<template>
  <div class="v-contion" v-loading="fullscreenLoading">
    <div class="v-top">
      <div class="view-top">{{title}}</div>
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
        :rules="rules"
      >
        <el-form-item label="角色名称:" prop="roleName">
          <el-input v-model.trim="formInline.roleName" clearable maxlength="10"></el-input>
        </el-form-item>
      </el-form>
      <div class="view-top">权限设置</div>
      <el-tree
        :data="listper"
        :show-checkbox="true"
        :render-after-expand="false"
        node-key="menuId"
        ref="tree"
        highlight-current
        :props="defaultProps"
        :check-strictly="true"
        @check="changeJS"
        @check-change="changeJs"
      ></el-tree>
    </div>

    <div class="flex-view-bottom">
      <el-button
        type="primary"
        size="mini"
        style="margin-right:20px"
        @click="subimted"
        v-if="this.title!='详情'"
      >保存</el-button>
      <el-button size="mini" @click="close">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.roleId = this.$route.query.roleId;
    this.load();
  },
  mounted() {
    if (this.roleId) {
      if (this.$route.query.title != "详情") {
        this.title = "编辑角色";
      } else {
        this.title = "详情";
      }
      this.allcheck();
    } else {
      this.title = "添加角色";
      this.formInline.roleName = "";
    }
  },
  data() {
    var userName = (rule, value, callback) => {
      let rez = /^[0-9a-zA-Z\u4e00-\u9fa5]+$/;
      if (!rez.test(value)) {
        return callback(new Error("支持2—10个字"));
      }
      callback();
    };
    return {
      fullscreenLoading: false,
      title: "",
      roleId: "",
      formInline: {
        roleName: "",
        menus: []
      },
      rules: {
        roleName: [
          { min: 2, max: 10, message: "支持2—10个字", trigger: "blur" },
          { validator: userName, trigger: "blur" }
        ]
      },
      defaultProps: {
        children: "children",
        label: "menuName"
      },
      listper: []
    };
  },
  methods: {
    allcheck() {
      this.$http
        .post("/order-admin/w/role/detail", { roleId: this.roleId })
        .then(data => {
          this.$refs.tree.setCheckedKeys(data.menus);
          this.formInline.roleName = data.roleName;
          this.formInline.roleId = this.roleId;
        });
    },
    load() {
      this.fullscreenLoading = true;
      this.$http
        .get("/order-admin/w/menu/list-all", {})
        .then(data => {
          this.listper = data.data;
          this.fullscreenLoading = false;
        })
        .catch(error => {
          this.fullscreenLoading = false;
        });
    },
    close() {
      this.$router.push({ path: "Staffmanagement", query: { type: 2 } });
    },
    changeJS(data, node, c) {
      let arr = this.$refs.tree.getCheckedKeys();
      if (arr.indexOf(data.menuId) < 0) {
        this.cheackQ(data, arr);
        this.$refs.tree.setCheckedKeys(arr);
      } else {
        this.cheackA(data, arr);
        this.$refs.tree.setCheckedKeys(arr);
      }
    },
    changeJs(data, node, c) {
      if (node) {
        let arr = this.$refs.tree.getCheckedKeys();
        if (arr.indexOf(data.parentId) < 0) {
          arr.push(data.parentId);
          this.$refs.tree.setCheckedKeys(arr);
        }
      }
    },
    cheackA(node, arr) {
      if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          if (arr.indexOf(node.children[i].menuId) < 0) {
            arr.push(node.children[i].menuId);
          }
          this.cheackA(node.children[i], arr);
        }
      }
    },
    cheackQ(node, arr) {
      if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
          let o = node.children[i].menuId;
          if (arr.indexOf(o) >= 0) {
            arr.splice(arr.indexOf(o), 1);
          }
          this.cheackQ(node.children[i], arr);
        }
      }
    },
    subimted() {
      let url =
        this.title == "添加角色"
          ? "/order-admin/w/role/create"
          : "/order-admin/w/role/modify";
      this.formInline.menus = this.$refs.tree.getCheckedKeys();
      this.$refs["formInline"].validate(valid => {
        if (valid) {
          if (this.formInline.menus.length == 0) {
            this.$message({
              showClose: true,
              message: "请选择权限设置",
              type: "warning"
            });
          } else {
            this.fullscreenLoading = true;
            this.$http
              .post(`${url}`, {
                ...this.formInline
              })
              .then(d => {
                this.fullscreenLoading = false;
                this.$message({
                  message: `${this.title}成功`,
                  type: "success"
                });
                this.$router.push({
                  path: "Staffmanagement",
                  query: { type: 2 }
                });
              })
              .catch(error => {
                this.fullscreenLoading = false;
              });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.my-tree {
  margin-left: 15px;
}
.tag-span {
  margin-left: 10px;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  background-color: #409eff;
}
.tag-url {
  background-color: #f56c6c;
}
.custom-tree-node {
  font-size: 13px;
}
.view-top {
  border-bottom: 1px solid #dcdee0;
  margin: 15px;
  padding: 10px 0px;
  font-size: 14px;
  color: #333333;
}
.v-contion {
  height: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.v-top {
  flex: 1;
  overflow: auto;
}
.flex-view-bottom {
  margin-top: 3px;
}
</style>