<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="桌区位置" prop="regionId">
          <el-select v-model="formInline.regionId" filterable placeholder="请选择" clearable>
            <el-option
              v-for="item in equipmentList"
              :key="item.regionNo"
              :label="item.regionName"
              :value="item.regionNo"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="桌位号:" prop="tableNo">
          <el-input v-model.trim="formInline.tableNo"></el-input>
        </el-form-item> -->
        <el-form-item label="桌位名称" prop="tableName">
          <el-input v-model.trim="formInline.tableName"></el-input>
        </el-form-item>
        <el-form-item label="添加时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
        >查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div>
            <el-button v-if="But.AllDown.power()" @click="exporTall()" size="mini">批量下载</el-button>
          </div>
          <div>
            <el-button v-if="But.Add.power()" @click="addposition" size="mini" type="primary">添加桌位</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <!-- <el-table-column prop="tableNo" label="桌位号"></el-table-column> -->
      <el-table-column prop="regionName" label="桌区位置"></el-table-column>
      <el-table-column prop="tableName" label="桌位名称"></el-table-column>
      <el-table-column prop="tableQrcode" label="桌位码" width="160"></el-table-column>
      <el-table-column prop="createTime" label="添加时间" width="160"></el-table-column>
      <el-table-column prop="sourceIp" label="操作" width="150">
        <template slot-scope="scope">
          <el-button v-if="But.Edit.power()" type="text" @click="edit(scope.row)">修改</el-button>
          <el-button v-if="But.Down.power()" type="text" @click="exporT(scope.row.tableId)">下载</el-button>
          <el-button v-if="But.Delete.power()" type="text" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="450px"
      :close-on-click-modal="false"
    >
      <el-form
        :inline="true"
        :model="formzine"
        class="demo-form-inline"
        size="mini"
        ref="formzine"
        :rules="rules"
      >
        <!-- <el-form-item label="桌位号:" prop="tableNo">
          <el-input v-model.trim="formzine.tableNo"></el-input>
        </el-form-item> -->
        <el-form-item label="桌位名称:" prop="tableName">
          <el-input v-model.trim="formzine.tableName"></el-input>
        </el-form-item>
        <el-form-item label="桌区位置:" prop="regionId">
          <el-select v-model="formzine.regionId" placeholder="请选择" clearable style="width:159px">
            <el-option
              v-for="item in equipmentList"
              :key="item.regionNo"
              :label="item.regionName"
              :value="item.regionNo"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="容纳人数:" prop="contain">
          <el-input v-model.trim="formzine.contain"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <export-file URL="/order-admin/w/qrcode/download-picture" ref="File"></export-file>
    <export-file URL="/order-admin/w/qrcode/download-batch" ref="Fileli"></export-file>
  </div>
</template>

<script>
export default {
  created() {
    this.loadlist();
    this.searchzin();
  },
  data() {
    // var validatePass = (rule, value, callback) => {
    //   let rez = /^[0-9]{1,3}$/;
    //   if (!rez.test(value)) {
    //     return callback(new Error("支持1—999之间的正整数"));
    //   }
    //   callback();
    // };
    var validatePass = (rule, value, callback) => {
      let rez = /^[1-9][0-9]?$/;
      if (!rez.test(value)) {
        return callback(new Error("请输入两位以内的正整数"));
      }
      callback();
    };
    var validatePass1 = (rule, value, callback) => {
      let rez = /([u4e00-u9fa5\dA-Za-z]){1,10}/;
      if (!rez.test(value)) {
        return callback(new Error("支持1—10个字符"));
      }
      callback();
    };
    var reg = (rule, value, callback) => {
      let rez = /^\d{1,10}$/;
      if (!rez.test(value)) {
        return callback(new Error("支持1-10个数字"));
      }
      callback();
    };
    return {
      loading: false,
      But: window.UserPower.Restauranttable.equipmentAdminBoard,
      equipmentList: [],
      dialogVisible: false,
      formInline: {
        regionId: "",
        tableName: "",
        tableNo: "",
        value1: null,
        startTime: "",
        endTime: ""
      },
      formzine: {
        regionId: "",
        tableName: "",
        contain: "4",
        tableNo: "",
        tableId: ""
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      title: "",
      rules: {
        tableNo: [
          // { validator: validatePass1, trigger: "blur" },
          { validator: reg, trigger: "blur" }
        ],
        tableName: [
          { required: true, message: "请输入桌位名称", trigger: "blur" },
          { min: 2, max: 6, message: "支持2—6个字", trigger: "blur" }
        ],
        regionId: [
          { required: true, message: "请选择桌区位置", trigger: "blur" }
        ],
        contain: [{ validator: validatePass, trigger: "blur" }]
      },
      multipleSelection: []
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    exporT(tableId) {
      this.$refs.File.export({
        tableId: tableId,
        authToken: localStorage.getItem("tokenIdzin"),
        platGroup:localStorage.getItem("changyum")
      });
    },
    exporTall() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择批量下载的内容",
          type: "warning"
        });
      } else {
        let ids = "";
        for (let i in this.multipleSelection) {
          ids += this.multipleSelection[i].tableId + ",";
        }
        this.$refs.Fileli.export({
          ids: ids,
          authToken: localStorage.getItem("tokenIdzin")
        });
      }
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
    loadlist() {
      this.loading = true;
      this.$http
        .get("/order-admin/w/region/list", {})
        .then(res => {
          this.loading = false;
          this.equipmentList = res.data;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    addposition() {
      this.loadlist();
      this.dialogVisible = true;
      this.title = "添加桌位";
      this.$refs["formzine"] && this.$refs["formzine"].resetFields();
      this.loading = true;
      this.$http
        .get("/order-admin/w/table/create-no", {})
        .then(res => {
          this.loading = false;
          this.formzine.tableNo = res.data;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    edit(data) {
      this.dialogVisible = true;
      this.title = "编辑桌位";
      this.$refs["formzine"] && this.$refs["formzine"].resetFields();
      this.loading = true;
      this.$http
        .post("/order-admin/w/table/detail", {
          tableId: data.tableId
        })
        .then(res => {
          this.loading = false;
          this.formzine = res;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    del(data) {
      if (data.tableStatus != "0") {
        this.$message({
          message: "该桌位正在使用中，不能删除",
          type: "warning"
        });
      } else {
        this.$confirm("确定删除该桌位?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.loading = true;
            this.$http
              .post("/order-admin/w/table/delete", {
                tableId: data.tableId
              })
              .then(res => {
                this.loading = false;
                this.$message({
                  message: "删除成功",
                  offset: 160,
                  type: "success"
                });
                this.search();
              })
              .catch(error => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              offset: 160,
              message: "已取消删除"
            });
          });
      }
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.startTime = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.endTime = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/table/search", {
          ...this.formInline,
          ...this.page
        })
        .then(res => {
          this.loading = false;
          this.tableData = res.page.records;
          this.page.total = +res.page.total;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$refs["formzine"].validate(valid => {
        if (valid) {
          let url =
            this.title == "添加桌位"
              ? "/order-admin/w/table/create"
              : "/order-admin/w/table/modify";
          this.loading = true;
          this.$http
            .post(`${url}`, {
              ...this.formzine
            })
            .then(res => {
              this.loading = false;
              this.dialogVisible = false;
              this.$message({
                message: `${this.title}成功`,
                type: "success"
              });
              this.search();
            })
            .catch(error => {
              this.loading = false;
            });
        }
      });
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    }
  }
};
</script>

<style scoped>
</style>


