<template>
  <div class="guige" v-loading="loading">
    <el-form
      :inline="true"
      :model="formzinernil"
      class="demo-form-inline"
      size="mini"
      ref="formzinernil"
      :rules="rules"
    >
      <div class="fontal_a">选择配菜</div>
      <div class="contain_inner">
        <div
          class="contain_inner_a"
          v-for="(item,index) in formzinernil.garnishInfoVos"
          :key="index"
        >
          <div>{{item.garnishName}}</div>
          <div>
            <div>¥{{item.price|money}}</div>
            <div class="add_reduce">
              <img
                src="@/assets/image/icon-jian.png"
                @click="loadfuntion('减',item)"
                v-if="item.garnishCount>0"
              />
              <span>{{item.garnishCount}}</span>
              <img src="@/assets/image/icon-jia.png" @click="loadfuntion('加',item)" />
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <div class="footer_a">
      <el-button type="primary" @click="submitedzin" size="mini">确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: { val: Object, orderNo: String },
  data() {
    return {
      formzinernil: {
        garnishInfoVos: [],
        dishersId: ""
      },
      rules: {},
      loading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submitedzin() {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/dishers/add-detail", {
          merchantId: localStorage.getItem("merchantId"),
          orderNo: this.orderNo,
          ...this.formzinernil
        })
        .then(res => {
          this.loading = false;
          this.$message({
            message: "添加成功",
            type: "success",
            offset: 160
          });
          this.$emit("close");
          this.$emit("detail");
        })
        .catch(error => {
          this.loading = false;
        });
    },
    loadfuntion(a, item) {
      if (a == "加") {
        item.garnishCount++;
      } else {
        item.garnishCount--;
      }
    },
    detail() {
      this.loading = true;
      this.$http
        .post("/cashier-api/api/dishers/garnish/list", {
          merchantId: localStorage.getItem("merchantId"),
          orderNo: this.orderNo,
          dishersId: this.val.dishersId
        })
        .then(res => {
          this.loading = false;
          this.formzinernil.garnishInfoVos = res.dishersInfoVo.garnishInfoVos;
          this.formzinernil.dishersId = res.dishersInfoVo.dishersId;
        })
        .catch(error => {
          this.loading = false;
        });
    }
  }
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.fontal_a {
  margin: 15px 0 10px 0;
}
.contain_inner {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.contain_inner_a {
  width: 150px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10px;
  margin: 0 20px 20px 0;
}
.contain_inner_a div:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
}
.contain_inner_a div:nth-of-type(2) img {
  width: 12px;
  height: 12px;
}
.add_reduce {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.add_reduce img,
span {
  margin-right: 5px;
}
.footer_a {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}
</style>