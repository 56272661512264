<template>
  <div>
    <el-form :inline="true">
      <div class="searchGoodsBox">
        <el-input placeholder="综合搜索" v-model="ruleForm.disherName" size="mini" clearable>
          <i slot="suffix" class="el-icon-search el-input__icon" @click="search(1)"></i>
        </el-input>
      </div>
      <div class="allGoodsImagesBox">
        <div
          class="goodsMainBox"
          v-for="(item,idx) in equipmentlist"
          :key="idx"
          @click="checkTab(item,idx)"
        >
          <img :src="item.previewUrl" class="goodsImage" :class="index==idx?'hover':''" />
          <span class="name">{{item.disherName}}</span>
        </div>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page.pageNo"
          :page-size="page.pageSize"
          layout="prev, pager, next, jumper"
          :total="page.total"
        ></el-pagination>
      </div>
      <div class="confirmSelectGoods">
        <el-button type="primary" size="mini" @click="submit">确定</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  mounted() {
    this.search();
    this.ruleForm.supportWebp =
      !![].map &&
      document
        .createElement("canvas")
        .toDataURL("image/webp")
        .indexOf("data:image/webp") == 0;
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        disherName: "",
        supportWebp: ""
      },
      page: {
        pageSize: 10,
        pageNo: 1,
        total: 0
      },
      equipmentlist: [],
      index: 0,
      inner: {}
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    search(pageNum) {
      this.page.pageNo = pageNum ? pageNum : this.page.pageNo;
      let data = {
        ...this.page,
        ...this.ruleForm
      };
      this.$http
        .post("/order-admin/w/disher/gallery/s/page", data)
        .then(res => {
          this.equipmentlist = res.data.records;
          this.inner=this.equipmentlist[0];
          this.page.total = res.data.total;
        });
    },
    checkTab(val, idx) {
      this.index = idx;
      this.inner = val;
    },
    submit() {
      this.$emit("close", this.inner);
    }
  }
};
</script>

<style scoped>
.searchGoodsBox {
  width: 193px;
  display: flex;
  justify-content: right;
  margin-left: auto;
}
.allGoodsImagesBox {
  margin: 10px 21px;
  display: flex;
  flex-wrap: wrap;
}
.goodsMainBox {
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.goodsImage {
  width: 110px;
  height: 110px;
  border: 1px solid #979797;
}
.goodsImage.hover {
  border: 2px solid #108af9;
}
.confirmSelectGoods {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px;
}
.name{
  display: block;
  width:114px;
  height:20px;
  overflow: hidden;
}
</style>