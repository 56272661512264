import axios from 'axios'
import router from './router'
import {
  Notification, MessageBox
} from 'element-ui'
import {
  baseurl, LocationKey
} from './base.js'
let messageUserNo = null;
let http = axios.create({
  baseURL: baseurl,
  timeout: 120000,
  withCredentials:false,
});
http.interceptors.request.use(function (config) {
  if (config.data) {
    config.data.messageUserNo = messageUserNo;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
http.interceptors.response.use((res) => {
  if ('00000' == res.data.retCode) {
    return Promise.resolve(res.data);
  } else if ('00038' == res.data.retCode) {
    router.push({
      path: '/login'
    })
    Notification.error({
      title: '错误',
      message: res.data.retMessage
    });
    return Promise.reject(res.data);
  } else {
    if (res.data.retCode == "70036") {
      MessageBox.alert(`${res.data.retMessage}`, '提示', {
        confirmButtonText: '刷新',
        callback: () => {
          router.go(0);
        }
      })
      return Promise.reject(res.data);
    }
    Notification.error({
      title: '错误',
      message: res.data.retMessage
    });
    return Promise.reject(res.data);
  }
}, (error) => {
  //404等问题可以在这里处理
  Notification.error({
    title: '错误',
    message: error
  });
  return Promise.reject(error);
});
if (localStorage.getItem("tokenIdzin")) {
  http.defaults.headers['jwt-ov3'] = localStorage.getItem("tokenIdzin");
}
//判断域名
let chanines = location.host;
//["normal", 1]
let [changyum, tuimage] = LocationKey[chanines] || ["normal", 1];
localStorage.setItem("tuimage", tuimage);
localStorage.setItem("changyum", changyum);
http.defaults.headers['platGroup'] = localStorage.getItem("changyum");
//end
export function setToken(token) {
  http.defaults.headers['jwt-ov3'] = token;
}
export function setMessageUserNo(data) {
  messageUserNo = data;
}
export default http;