<template>
  <div class="view_contant" v-loading="loading">
    <div class="contant_left">
      <div class="contant_top">
        <el-form
          :model="obj"
          :rules="rules"
          ref="obj"
          label-width="100px"
          class="demo-ruleForm"
          size="mini"
        >
          <el-row>
            <div class="hr_title" style="margin-bottom: 25px">
              <span>金额信息</span>
            </div>
            <el-col :span="24" class="price_alls">
              <div class="price">
                <div>菜品金额:</div>
                <div>{{ orderVo.orderOriginAmount | money }}元</div>
              </div>
              <div class="price yel">
                <div>其他金额:</div>
                <div>{{ orderVo.totalOtherAmount | money }}元</div>
              </div>
              <div class="price green">
                <div>订单金额:</div>
                <div>{{ orderVo.shouldReceiveAmount | money }}元</div>
              </div>
            </el-col>
            <el-col
              :span="24"
              v-if="orderVo.totalOtherAmount && orderMode != 2"
            >
              <div class="hr_title" style="margin-bottom: 25px">
                <span>其他金额</span>
              </div>
              <div class="box_op">
                <div>
                  <span>餐具费:</span>
                  <span
                    >{{ orderVo.tablewareFeeAmount | money }}元（{{
                      mer.tablewareFee | money
                    }}元/个）</span
                  >
                </div>
                <div>
                  <span>餐位费:</span>
                  <span
                    >{{ orderVo.restaurantSeatFeeAmount | money }}元 （{{
                      mer.restaurantSeatFee | money
                    }}元/人）</span
                  >
                </div>
                <div>
                  <span>打包费:</span>
                  <span
                    >{{ orderVo.packingFeeAmount | money }}元（{{
                      mer.packingFee | money
                    }}元/个）</span
                  >
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <div class="hr_title" style="margin: 15px 0">全单备注</div>
            <el-col :span="24">
              <el-form-item label="全单备注:" prop="phone">
                <el-input
                  placeholder="请输入备注"
                  v-model="remarkzin"
                  clearable
                  style="width: 300px"
                  maxlength="30"
                ></el-input>
              </el-form-item>
              <div class="zinbox">
                <div
                  v-for="(item, idx) in remark"
                  :key="idx"
                  @click="zindex(item, idx)"
                >
                  {{ item }}
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="footer_zin">
        <div class="footer_btn">
          <el-button type="primary" size="mini" @click="closeorder" class="act"
            >返回餐台</el-button
          >
          <el-button type="primary" size="mini" @click="stap">上一步</el-button>
        </div>
      </div>
    </div>
    <div class="height_100">
      <view-phone
        ref="viewinfo"
        type="下单"
        :typezin="typezin"
        :remarkzin="remarkzin"
      ></view-phone>
    </div>
  </div>
</template>

<script>
import number from "@/common/num.js";
import viewPhone from "./rightside/rightmiddle"; //右侧部分
import Eve from "@/common/bus.js";
export default {
  components: {
    viewPhone,
  },
  created() {
    this.obj.orderNo = this.$route.query.orderNo;
    this.obj.tableId = this.$route.query.tableId;
    this.orderMode = this.$route.query.orderMode;
    this.typezin = this.$route.query.type;
    this.detail();
  },
  mounted() {
    if (this.orderMode == 2) {
      Eve.$emit("getTarget", "无桌点餐");
    }
  },
  data() {
    return {
      orderMode: "",
      typezin: "",
      rules: {},
      orderVo: {},
      loading: false,
      mer: {},
      obj: {},
      remark: ["不要辣", "少放辣", "多放辣", "不要蒜", "不要葱", "不要香菜"],
      checked1: [],
      remarkzin: "",
      index: null,
    };
  },
  methods: {
    backOrder() {
      this.$http
        .post("/cashier-api/api/dishers/cleanTable", {
          merchantId: localStorage.getItem("merchantId"),
          orderNo: this.obj.orderNo,
          orderMode: this.orderMode,
        })
        .then((res) => {
          this.$router.push("/notTable");
        });
    },
    zindex(item, index) {
      this.index = index;
      if (this.remarkzin.length <= 26) {
        this.remarkzin += item;
      }
    },
    closeorder() {
      if (this.orderMode==2) {
        return this.backOrder();
      }
      this.$router.push({
        path: "/selectTable",
        meta: {
          title: "点餐",
        },
      });
    },
    stap() {
      return this.$router.go(-1);
      this.$router.push({
        path: "payorder",
        query: { orderNo: this.obj.orderNo, tableId: this.obj.tableId },
      });
    },
    detail() {
      if (!this.obj.orderNo) {
        return;
      }
      this.loading = true;
      let formData = {
        orderNo: this.obj.orderNo,
      };
      if (this.orderMode==2) {
        formData.orderMode = this.orderMode;
      }
      this.$http
        .post(`/cashier-api/api/order/detail`, formData)
        .then((res) => {
          this.loading = false;
          this.orderVo = res.orderVo;
          this.mer = res.orderVo.merchantInfo;
          if (res.orderVo.orderRemark) {
            this.remarkzin = res.orderVo.orderRemark;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.view_contant {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.contant_left {
  flex: 1;
  border: 1px solid #dedede;
  position: relative;
}
.contant_top {
  height: calc(100% - 70px);
  margin-bottom: 70px;
  overflow: auto;
}
.footer_zin {
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  margin-top: 15px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-width {
  width: 238px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.dayinOrder {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 80px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.dayinReceipt {
  width: 100px;
  height: 40px;
  border: 1px solid #108af9;
  border-radius: 2px;
  color: #108af9;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
}
.dayinReceipt:nth-of-type(2) {
  margin-left: 10px;
}
.font {
  color: #ff6969;
}
.footer_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.footer_btn button {
  width: 160px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #108af9;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 0;
}
.footer_btn button:nth-of-type(2) {
  background: #999999;
  color: #ffffff;
}
.phone_inner {
  width: 38%;
  border: 1px solid #dedede;
  margin: 0 1%;
}
.price {
  width: 200px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.price:nth-of-type(1) {
  margin-left: 53px;
}
.price:nth-of-type(2) {
  margin-left: 20px;
}
.price_alls {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0 16px 0;
}
.price div:nth-of-type(1) {
  height: 26px;
  font-size: 18px;
  color: #108af9;
  text-align: center;
  line-height: 26px;
}
.price div:nth-of-type(2) {
  font-size: 20px;
  color: #ff6969;
  text-align: center;
  margin-left: 10px;
}
.yel div:nth-of-type(1) {
  color: #feb822;
}
.green div:nth-of-type(1) {
  color: #11cd6e;
}
.phone_tazy_sub {
  width: 400px;
  background: #ffffff;
  border: 1px solid #dedede;
  margin-left: 10px;
  position: relative;
}
.header_phone {
  height: 111px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header_phone div {
  display: flex;
  justify-content: space-between;
  margin: 0 26px;
  font-size: 22px;
}
.header_phone div:nth-of-type(2) {
  font-size: 14px;
  margin-top: 14px;
}
.header_container {
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
}
.header_container div {
  height: 66px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.price_all {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.price_all span:nth-of-type(2) {
  color: #ff6969;
}
.reductionall {
  display: flex;
  justify-content: flex-start;
}
.redioyou,
.redioyou1 {
  width: 140px;
  height: 36px;
  border: 1px solid #dcdee0;
  color: #333333;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-right: 20px;
  cursor: pointer;
}
.redioyou1 {
  width: 101px;
  height: 36px;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.redioyou1 img {
  margin-left: 4px;
}
.active {
  border: 1px solid #108af9;
  color: #108af9;
}
.redioyou img {
  width: 25px;
}
.act {
  background: #108af9 !important;
  color: white !important;
}
.spansize {
  width: 75%;
  font-size: 12px;
  text-align: center;
}
.icon {
  color: #feb822;
  margin-right: 5px;
}
.btn {
  margin-left: 20px;
  color: #108af9;
  border: 1px solid #108af9;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 0;
}
.box_op {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.box_op > div {
  margin-top: 14px;
}
.box_op > div > span:nth-of-type(1) {
  margin-left: 45px;
}
.box_op > div > span:nth-of-type(2) {
  margin-left: 22px;
}
.box_op > span > span {
  margin-left: 10px;
}
.check {
  margin-left: 24px;
}
.zinbox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin: 20px 0 0 20px;
}
.zinbox div {
  width: 90px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  text-align: center;
  line-height: 35px;
  margin-left: 10px;
  font-size: 13px;
  cursor: pointer;
}
.height_100 {
  height: 100%;
}
/* .acter{
  border:1px solid #108af9 !important;
} */
</style>
