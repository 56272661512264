<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="formzine"
      class="demo-form-inline"
      size="mini"
      ref="formzine"
      :rules="rules"
    >
      <el-form-item label="就餐人数:" prop="dinersCount">
        <el-input v-model="formzine.dinersCount"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submited" size="mini">保存</el-button>
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
export default {
  created() {},
  props: { tableId: String },
  data() {
    var numzin = (rule, value, callback) => {
      let rez = /^[1-9][0-9]?$/;
      if (!rez.test(value)) {
        return callback(new Error("请输入两位以内的正整数"));
      }
      callback();
    };
    return {
      loading: false,
      formzine: {
        dinersCount: ""
      },
      rules: {
        dinersCount: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzin, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    close() {
      this.$emit("close");
      this.$refs["formzine"].resetFields();
    },
    submited() {
      this.$refs["formzine"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/cashier-api/api/dishers/begin", {
              dinersCount: this.formzine.dinersCount,
              merchantId: localStorage.getItem("merchantId"),
              tableId: this.tableId,
            })
            .then(res => {
              this.loading = false;
              this.$router.push({
                path: "payorder",
                query: { orderNo: res.data.orderNo }
              });
            })
            .catch(error => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>