<template>
  <div class="view" v-loading="loading">
    <div
      class="con_wid"
      v-for="(item,idx) in merList"
      :key="idx"
      :class="{'active':idx==index}"
      @click="tabmer(idx,item)"
    >
      <div>
        <p class="p_zin">{{item.platName}}</p>
        <p class="p_gray">{{item.merchantName}}</p>
      </div>
      <div>
        <p class="p_blur">
          {{item.wlkLoginId}}
          <img src="@/assets/image/icon_jiantou_you.png" v-if="idx!=index" />
          <img src="@/assets/image/icon_duigou_1.png" v-if="idx==index" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  props: { merList: Array },
  data() {
    return {
      index: null,
      loading: false
    };
  },
  methods: {
    tabmer(idx, item) {
      this.index = idx;
      this.loading = true;
      this.$http
        .post("/order-admin/w/auth/switch-merchant", {
          userNo: item.userNo,
          loginName: item.loginName
        })
        .then(data => {
          this.loading = false;
          this.$emit("tabmer");
        })
        .catch(error => {
          this.loading = false;
        });
    }
  }
};
</script>

<style  scoped>
.active {
  border: 1px solid #108af9;
}
.con_wid {
  width: 340px;
  height: 80px;
  background: #f6f6f6;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.p_zin {
  font-size: 16px;
  color: #333333;
}
.p_gray {
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
}
.p_blur {
  font-size: 14px;
  color: #108af9;
}
</style>