<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="活动类型" prop="activityType">
          <el-select
            v-model="formInline.activityType"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(v, k) in activetype"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动名称" prop="activityName">
          <el-input v-model="formInline.activityName"></el-input>
        </el-form-item>
        <el-form-item label="活动状态" prop="activityStatus">
          <el-select
            v-model="formInline.activityStatus"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(v, k) in activestatus"
              :key="k"
              :label="v"
              :value="k"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="添加时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-date-picker
            v-model="formInline.value2"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
          v-if="But.Add.power()"
          >查询</el-button
        >
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div></div>
          <div>
            <el-button
              type="primary"
              @click="addreduction"
              size="mini"
              v-if="But.Add.power()"
            >
              添加满送活动</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
    >
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column prop="activityType" label="活动类型">
        <template slot-scope="scope">{{
          activetype[scope.row.activityType]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="activityName"
        label="活动名称"
        width="120"
      ></el-table-column>
      <el-table-column prop="activityStatus" label="活动状态">
        <template slot-scope="scope">{{
          activestatus[scope.row.activityStatus]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="createTm"
        label="添加时间"
        width="150"
      ></el-table-column>
      <el-table-column prop="activityBeginTm" label="活动时间" width="300">
        <template slot-scope="scope"
          >{{ scope.row.activityBeginTm }}-{{
            scope.row.activityEndTm
          }}</template
        >
      </el-table-column>
      <el-table-column
        prop="createUser"
        label="操作人"
        width="150"
      ></el-table-column>
      <el-table-column prop="sourceIp" label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            v-if="But.Detail.power()"
            type="text"
            @click="detail(scope.row.activityId)"
            >详情</el-button
          >
          <el-button
            v-if="But.Delete.power()"
            type="text"
            @click="dele(scope.row.activityId, scope.row.activityName)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  paymentStatus,
  paymentType,
  recordMode,
} from "@/common/options.js";
export default {
  created() {
    this.loadlist();
   // if (this.$router.query && this.$router.query.type == 1) {
      this.search();
    //}
  },
  data() {
    return {
      paymentStatus: paymentStatus,
      paymentType: paymentType,
      recordMode: recordMode,
      But: window.UserPower.Membership.MemberActivity,
      dialogVisible: false,
      activetype: {
        1: "满送活动",
      },
      typelist: [],
      Visibleupdatezin: false,
      formInline: {
        activityType: "",
        activityName: "",
        activityStatus: "",
        activityBeginTm: "",
        activityEndTm: "",
        createTmStart: "",
        createTmEnd: "",
        value1: null,
        value2: null,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      activestatus: {
        0: "未开始",
        1: "活动中",
        2: "已结束",
      },
      loading: false,
    };
  },
  methods: {
    detail(id) {
      this.$router.push({
        path: "Membershiprechargeadd",
        query: { title: "详情", id: id },
      });
    },
    dele(id, name) {
      this.$confirm(`活动名称:${name},确定删除所选择的活动吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$http
            .post("/order-admin/w/member/activity/del", {
              memberActivityId: id,
            })
            .then((res) => {
              this.loading = false;
              this.typelist = res.data;
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.search();
            })
            .catch((error) => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    loadlist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/memberType/s/all", {})
        .then((res) => {
          this.loading = false;
          this.typelist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.createTmStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.createTmEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.formInline.activityBeginTm = this.formInline.value2
        ? this.formInline.value2[0]
        : "";
      this.formInline.activityEndTm = this.formInline.value2
        ? this.formInline.value2[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/member/activity/s", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.page.records;
          this.page.total = res.page.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    addreduction() {
      this.$router.push({
        path: "Membershiprechargeadd",
        query: { title: "添加" },
      });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
      this.formInline.value2 = [];
    },
    exportzin() {
      this.Visibleupdatezin = true;
    },
  },
};
</script>

<style scoped>
.fonsize span {
  margin-left: 10px;
}
.fonsize span span {
  color: #409eff;
  margin-left: 0px;
}
</style>


